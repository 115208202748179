import { SKILL_BUILDER_TYPES, SkillBuilderData } from '@services/member';

export enum DECISION_PAGE {
	AFFECTS = 'AFFECTS',
	ACTIONS = 'ACTIONS',
	OUTCOMES = 'OUTCOMES',
	IMPACTS = 'IMPACTS',
	RANKS = 'RANKS',
	TAKEN_ACTION = 'TAKEN_ACTION',
	RIGHT_CHOICE_NO = 'RIGHT_CHOICE_NO',
	RIGHT_CHOICE_YES = 'RIGHT_CHOICE_YES',
}

export const DECISION_PREVIOUS_PAGE = {};

// Start flow.
DECISION_PREVIOUS_PAGE[DECISION_PAGE.AFFECTS] = null;
DECISION_PREVIOUS_PAGE[DECISION_PAGE.ACTIONS] = DECISION_PAGE.AFFECTS;
DECISION_PREVIOUS_PAGE[DECISION_PAGE.OUTCOMES] = DECISION_PAGE.ACTIONS;
DECISION_PREVIOUS_PAGE[DECISION_PAGE.IMPACTS] = DECISION_PAGE.OUTCOMES;
DECISION_PREVIOUS_PAGE[DECISION_PAGE.RANKS] = DECISION_PAGE.IMPACTS;

// Track flow.
DECISION_PREVIOUS_PAGE[DECISION_PAGE.TAKEN_ACTION] = null;
DECISION_PREVIOUS_PAGE[DECISION_PAGE.RIGHT_CHOICE_NO] =
	DECISION_PAGE.TAKEN_ACTION;
DECISION_PREVIOUS_PAGE[DECISION_PAGE.RIGHT_CHOICE_YES] =
	DECISION_PAGE.TAKEN_ACTION;

export interface DecisionAction {
	action: string;
	positiveOutcome?: string;
	negativeOutcome?: string;
	positiveImpact?: number; // Integers [0-4].
	negativeImpact?: number; // Integers [0-4].
	rank?: number; // Integers [-4-4]
}

export interface DecisionData extends SkillBuilderData {
	type: SKILL_BUILDER_TYPES.DECISION;
	page?: DECISION_PAGE;
	affect?: string;
	actions?: Array<DecisionAction | string>;
	logUUID?: string;
	action?: string;
	rightChoice?: string;
	reason?: string;
}

export interface DecisionReportPie {
	yes: number;
	no: number;
	notSure: number;
}

export interface DecisionReportData {
	bestAction: number;
	decisions: number;
	pie: DecisionReportPie;
}
