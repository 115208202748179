import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ChartConfiguration } from 'chart.js/auto';
import { Group } from './group.service';
import {
	Domain,
	DomainService,
	LoaderService,
	LogoutService,
	LanguageService,
	LOGOUT_EVENT,
} from '@services/public';
import {
	AssessmentScore,
	AssessmentScores,
	AssessmentScoresService,
} from './assessment-scores.service';

const ONE_DAY = 864e5;
/*Set to 0 so updates are made each time an assessment is completed.
 * Change to 300000 to update every 5 minutes in milliseconds.
 */
const UPDATE_INTERVAL = 0;

export interface StrengthsAndWeaknesses {
	name: string;
	url: string;
}

export interface SelfAwareness {
	name: string;
	url: string;
}

export interface ExternalScore {
	domainUID: number;
	uid: number;
	color: string;
}

export interface ResponseMemberAssessmentList {
	uuid: string;
	name: string;
	type: string;
	startDate: string;
	endDate: string;
	groupUUID: string;
	status: string;
}

// Tied to challenges. Needs to be removed or relocated.
export interface ImprovedArea {
	domainUID: number;
	uid: number;
	worstScore: number;
	currentScore: number;
}

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	nextPage = false;
	language: any;

	// Local data storage from API calls.
	private _domains: Array<Domain> = null;
	private _assessmentScores: AssessmentScores | null = null;
	private _updated: Date | null = null;
	private _languageLoaded: boolean = false;

	// Page filters.
	private _domain: Domain = null;
	private _group: Group = null;
	private _timespan: string = 'all time';
	private _growthDomain: Domain = null;

	// Filtered data storage.
	private _filteredScores: AssessmentScores = null;
	private _filteredScoresNoDomain: AssessmentScores = null;

	// Report subjects.
	private _selfVsPeer: ReplaySubject<ChartConfiguration> =
		new ReplaySubject<ChartConfiguration>(1);
	private _strengthsAndWeaknesses: ReplaySubject<
		Array<StrengthsAndWeaknesses>
	> = new ReplaySubject<Array<StrengthsAndWeaknesses>>(1);
	private _selfAwareness: ReplaySubject<Array<SelfAwareness>> =
		new ReplaySubject<Array<SelfAwareness>>(1);
	private _growth: ReplaySubject<ChartConfiguration> =
		new ReplaySubject<ChartConfiguration>(1);
	private _topSkills: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(
		1,
	);

	private _isPeerDataAvailable: boolean = false;

	// Report charts.
	private _selfVsPeerChart: ChartConfiguration = {
		type: 'bar',
		data: {
			labels: [],
			datasets: [
				{
					label: '',
					data: [],
					borderRadius: 5,
					backgroundColor: '#3CE986',
				},
				{
					label: '',
					data: [],
					borderRadius: 5,
					backgroundColor: '#74A2FF',
				},
				{
					label: '',
					data: [],
					borderRadius: 5,
					backgroundColor: '#DE91FF',
				},
			],
		},
		options: {
			plugins: {
				legend: {
					display: true,
					position: 'top',
					align: 'end',
					labels: {
						useBorderRadius: true,
						borderRadius: 7.5,
						boxWidth: 15,
						boxHeight: 15,
					},
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			indexAxis: 'y',
			scales: {
				y: {
					grid: {
						display: false,
					},
				},
				x: {
					min: 0,
					max: 5,
					title: {
						display: true,
						text: '',
						color: '#757575',
					},
				},
			},
		},
	};
	private _growthChart: ChartConfiguration = {
		type: 'line',
		data: {
			labels: [],
			datasets: [
				{
					label: '',
					data: [],
					backgroundColor: '#3BE684',
					borderColor: '#3BE684',
				},
				{
					label: '',
					data: [],
					backgroundColor: '#74A2FF',
					borderColor: '#74A2FF',
				},
				{
					label: '',
					data: [],
					backgroundColor: '#DE91FF',
					borderColor: '#DE91FF',
				},
			],
		},
		options: {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: 'top',
					align: 'end',
					labels: {
						useBorderRadius: true,
						borderRadius: 5.5,
						boxWidth: 10,
						boxHeight: 10,
					},
				},
			},
			elements: {
				line: {
					fill: false,
					spanGaps: true,
				},
				point: {
					pointStyle: 'circle',
					radius: 5,
				},
			},
		},
	};

	constructor(
		private logoutSvc: LogoutService,
		private assessmentScoresSvc: AssessmentScoresService,
		private domainSvc: DomainService,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
	) {
		// Bind logout service.
		this.logoutSvc.subscribe(LOGOUT_EVENT.POST_API, this.logout.bind(this));
	}

	private async _loadAPIs(): Promise<void> {
		const loader: unique symbol = Symbol();
		// Only show loader for the initial load.
		if (!this._updated)
			this.loaderSvc.addLoader(loader, 'services/user/report:_loadScores');
		this.assessmentScoresSvc.scores.subscribe((next) => {
			this._assessmentScores = next;
			this._loadCharts();
			this._loadTopSkills();
			this._updated = new Date();
			this.loaderSvc.removeLoader(loader);
		});

		// Load domains.
		if (!this._domains) {
			this.domainSvc.getDomains().then((response) => {
				this._domains = response;
				this._loadCharts();
			});
		}

		this._languageSvc.get([`pages.member.assessment.page`]).then((value) => {
			this.language = value[`pages.member.assessment.page`];
			this._selfVsPeerChart.data.datasets[0].label = this.language.self;
			this._selfVsPeerChart.data.datasets[1].label = this.language.peer;
			this._selfVsPeerChart.data.datasets[2].label = this.language.external;
			(<any>this._selfVsPeerChart.options.scales.x).title.text =
				this.language.skillLevel;
			this._growthChart.data.datasets[0].label = this.language.self;
			this._growthChart.data.datasets[1].label = this.language.peer;
			this._growthChart.data.datasets[2].label = this.language.external;
			this._languageLoaded = true;
			this._loadCharts();
		});
	}

	private _loadCharts(): void {
		// Skip until everything is loaded.
		if (!this._assessmentScores) return;
		if (!this._domains) return;
		if (!this._growthDomain) return;
		if (!this._languageLoaded) return;

		// Show loader.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader, 'services/user/report:_loadCharts');

		// Initialize filter.
		this._filteredScores = this._assessmentScores;

		// Filter by timespan.
		this._filteredScores = {
			self: this._filteredScores.self.filter((score) =>
				this._scoreWithinTimespan(score),
			),
			peer: this._filteredScores.peer.filter((score) =>
				this._scoreWithinTimespan(score),
			),
			external: this._filteredScores.external.filter((score) =>
				this._scoreWithinTimespan(score),
			),
		};

		// Filter by group.
		this._filteredScores = {
			self: this._filteredScores.self,
			peer: !this._group
				? this._filteredScores.peer
				: this._filteredScores.peer.filter(
						(score) => this._group.uuid === score.groupUUID,
					),
			external: this._filteredScores.external,
		};

		// Filter by domain.
		this._filteredScoresNoDomain = this._filteredScores;
		this._filteredScores = {
			self: !this._domain
				? this._filteredScores.self
				: this._filteredScores.self.filter(
						(score) => this._domain.uid === score.domainUID,
					),
			peer: !this._domain
				? this._filteredScores.peer
				: this._filteredScores.peer.filter(
						(score) => this._domain.uid === score.domainUID,
					),
			external: !this._domain
				? this._filteredScores.external
				: this._filteredScores.external.filter(
						(score) => this._domain.uid === score.domainUID,
					),
		};

		// Load reports.
		this._loadSelfVsPeer();
		this._loadStrengthsAndWeaknesses();
		this._loadSelfAwareness();
		this._loadGrowth();

		// Clear loader.
		this.loaderSvc.removeLoader(loader);
	}

	private _loadSelfVsPeer(): void {
		const labels: Array<string> = [];
		const selfData: Array<number> = [];
		const peerData: Array<number> = [];
		const externalData: Array<number> = [];

		if (!this._domain) {
			// Get domain UIDs and names.
			const compiledScores = {};
			this._domains.forEach((domain) => {
				compiledScores[domain.uid] = {};
				compiledScores[domain.uid].name = domain.name;
			});

			// Filter to the most recent self data.
			this._filteredScores.self.forEach(
				({ domainUID, skillUID, date, score }) => {
					if (!compiledScores[domainUID][skillUID])
						compiledScores[domainUID][skillUID] = {};
					if (!compiledScores[domainUID][skillUID].self)
						compiledScores[domainUID][skillUID].self = { date, score };
					else if (date > compiledScores[domainUID][skillUID].self.date)
						compiledScores[domainUID][skillUID].self = { date, score };
				},
			);

			// Sort the historical peer data.
			this._filteredScores.peer.forEach(({ domainUID, skillUID, score }) => {
				if (!compiledScores[domainUID][skillUID])
					compiledScores[domainUID][skillUID] = {};
				if (!compiledScores[domainUID][skillUID].peer)
					compiledScores[domainUID][skillUID].peer = [score];
				else compiledScores[domainUID][skillUID].peer.push(score);
			});

			// Sort the historical external data.
			this._filteredScores.external.forEach(
				({ domainUID, skillUID, score }) => {
					if (!compiledScores[domainUID][skillUID])
						compiledScores[domainUID][skillUID] = {};
					if (!compiledScores[domainUID][skillUID].external)
						compiledScores[domainUID][skillUID].external = [score];
					else compiledScores[domainUID][skillUID].external.push(score);
				},
			);

			// Calculate labels and scores.
			for (const domainUID in compiledScores) {
				labels.push(compiledScores[domainUID].name);
				const skillScores = {
					self: [],
					peer: [],
					external: [],
				};
				for (const skillUID in compiledScores[domainUID]) {
					if (!!compiledScores[domainUID][skillUID].self)
						skillScores.self.push(
							compiledScores[domainUID][skillUID].self.score,
						);
					if (!!compiledScores[domainUID][skillUID].peer) {
						const peerScores = compiledScores[domainUID][skillUID].peer;
						skillScores.peer.push(
							peerScores.reduce((a, b) => a + b, 0) / peerScores.length,
						);
					}
					if (!!compiledScores[domainUID][skillUID].external) {
						const externalScores = compiledScores[domainUID][skillUID].external;
						skillScores.external.push(
							externalScores.reduce((a, b) => a + b, 0) / externalScores.length,
						);
					}
				}
				if (skillScores.self.length === 0) selfData.push(null);
				else
					selfData.push(
						+(
							skillScores.self.reduce((a, b) => a + b, 0) /
							skillScores.self.length
						).toFixed(1),
					);
				if (skillScores.peer.length === 0) peerData.push(null);
				else
					peerData.push(
						+(
							skillScores.peer.reduce((a, b) => a + b, 0) /
							skillScores.peer.length
						).toFixed(1),
					);
				if (skillScores.external.length === 0) externalData.push(null);
				else
					externalData.push(
						+(
							skillScores.external.reduce((a, b) => a + b, 0) /
							skillScores.external.length
						).toFixed(1),
					);
			}
		} else {
			// Get skill UIDs and names.
			const compiledScores = {};
			const domain = this._domains.find(
				(domain) => domain.uid === this._domain.uid,
			);
			domain.skills.forEach((skill) => {
				compiledScores[skill.uid] = {};
				compiledScores[skill.uid].name = skill.name;
			});

			// Filter to the most recent self data.
			this._filteredScores.self.forEach(({ skillUID, date, score }) => {
				if (!compiledScores[skillUID].self)
					compiledScores[skillUID].self = { date, score };
				else if (date > compiledScores[skillUID].self.date)
					compiledScores[skillUID].self = { date, score };
			});

			// Sort the historical peer data.
			this._filteredScores.peer.forEach(({ skillUID, score }) => {
				if (!compiledScores[skillUID]) compiledScores[skillUID] = {};
				if (!compiledScores[skillUID].peer)
					compiledScores[skillUID].peer = [score];
				else compiledScores[skillUID].peer.push(score);
			});

			// Sort the historical external data.
			this._filteredScores.external.forEach(({ skillUID, score }) => {
				if (!compiledScores[skillUID]) compiledScores[skillUID] = {};
				if (!compiledScores[skillUID].external)
					compiledScores[skillUID].external = [score];
				else compiledScores[skillUID].external.push(score);
			});

			// Calculate labels and scores.
			for (const skillUID in compiledScores) {
				labels.push(compiledScores[skillUID].name);
				if (!compiledScores[skillUID].self) selfData.push(null);
				else selfData.push(compiledScores[skillUID].self.score);
				if (!compiledScores[skillUID].peer) peerData.push(null);
				else
					peerData.push(
						+(
							compiledScores[skillUID].peer.reduce((a, b) => a + b, 0) /
							compiledScores[skillUID].peer.length
						).toFixed(1),
					);
				if (!compiledScores[skillUID].external) externalData.push(null);
				else
					externalData.push(
						+(
							compiledScores[skillUID].external.reduce((a, b) => a + b, 0) /
							compiledScores[skillUID].external.length
						).toFixed(1),
					);
			}
		}

		// Update chart configuration.
		this._selfVsPeerChart.data.labels = labels;
		this._selfVsPeerChart.data.datasets[0].data = selfData;
		if (!this._selfVsPeerChart.data.datasets[2]) {
			const peer = {
				label: this.language.peer,
				data: [],
				borderRadius: 5,
				backgroundColor: '#74A2FF',
			};

			this._selfVsPeerChart.data.datasets.splice(1, 0, peer);
		}

		if (
			!(
				peerData &&
				peerData.length > 0 &&
				peerData.some((element) => element !== null)
			)
		) {
			this._selfVsPeerChart.data.datasets.splice(1, 1);
			this._selfVsPeerChart.data.datasets[1].data = externalData;
		} else {
			this._selfVsPeerChart.data.datasets[1].data = peerData;
			this._selfVsPeerChart.data.datasets[2].data = externalData;
			this._isPeerDataAvailable = true;
		}

		this._selfVsPeer.next(this._selfVsPeerChart);
	}

	private _loadStrengthsAndWeaknesses(): void {
		const strengthsAndWeaknesses: Array<StrengthsAndWeaknesses> = [];

		if (!this._domain) {
			// Get domain UIDs and names.
			const compiledScores = {};
			this._domains.forEach((domain) => {
				compiledScores[domain.uid] = {};
				compiledScores[domain.uid].name = domain.name;
			});

			// Filter to the most recent self data.
			this._filteredScores.self.forEach(
				({ domainUID, skillUID, date, score }) => {
					if (!compiledScores[domainUID][skillUID])
						compiledScores[domainUID][skillUID] = {};
					if (!compiledScores[domainUID][skillUID].self)
						compiledScores[domainUID][skillUID].self = { date, score };
					else if (date > compiledScores[domainUID][skillUID].self.date)
						compiledScores[domainUID][skillUID].self = { date, score };
				},
			);

			// Calculate labels and scores.
			for (const domainUID in compiledScores) {
				const skillScores = [];
				for (const skillUID in compiledScores[domainUID])
					if (!!compiledScores[domainUID][skillUID].self)
						skillScores.push(compiledScores[domainUID][skillUID].self.score);
				if (skillScores.length === 0) {
					strengthsAndWeaknesses.push({
						name: compiledScores[domainUID].name,
						url: 'assets/img/graph-insufficient1.svg',
					});
				} else {
					const score =
						skillScores.reduce((a, b) => a + b, 0) / skillScores.length;
					if (score > 4)
						strengthsAndWeaknesses.push({
							name: compiledScores[domainUID].name,
							url: 'assets/img/graph-good.svg',
						});
					else if (score > 3)
						strengthsAndWeaknesses.push({
							name: compiledScores[domainUID].name,
							url: 'assets/img/graph-ok.svg',
						});
					else
						strengthsAndWeaknesses.push({
							name: compiledScores[domainUID].name,
							url: 'assets/img/graph-improvement.svg',
						});
				}
			}
		} else {
			// Get skill UIDs and names.
			const compiledScores = {};
			const domain = this._domains.find(
				(domain) => domain.uid === this._domain.uid,
			);
			domain.skills.forEach((skill) => {
				compiledScores[skill.uid] = {};
				compiledScores[skill.uid].name = skill.name;
			});

			// Filter to the most recent self data.
			this._filteredScores.self.forEach(({ skillUID, date, score }) => {
				if (!compiledScores[skillUID].self)
					compiledScores[skillUID].self = { date, score };
				else if (date > compiledScores[skillUID].self.date)
					compiledScores[skillUID].self = { date, score };
			});

			// Calculate labels and scores.
			for (const skillUID in compiledScores) {
				if (!compiledScores[skillUID].self) {
					strengthsAndWeaknesses.push({
						name: compiledScores[skillUID].name,
						url: 'assets/img/graph-insufficient1.svg',
					});
				} else if (compiledScores[skillUID].self.score > 4)
					strengthsAndWeaknesses.push({
						name: compiledScores[skillUID].name,
						url: 'assets/img/graph-good.svg',
					});
				else if (compiledScores[skillUID].self.score > 3)
					strengthsAndWeaknesses.push({
						name: compiledScores[skillUID].name,
						url: 'assets/img/graph-ok.svg',
					});
				else
					strengthsAndWeaknesses.push({
						name: compiledScores[skillUID].name,
						url: 'assets/img/graph-improvement.svg',
					});
			}
		}

		// Update chart configuration.
		this._strengthsAndWeaknesses.next(strengthsAndWeaknesses);
	}

	private _loadSelfAwareness(): void {
		const selfAwareness: Array<SelfAwareness> = [];

		if (!this._domain) {
			// Get domain UIDs and names.
			const compiledScores = {};
			this._domains.forEach((domain) => {
				compiledScores[domain.uid] = {};
				compiledScores[domain.uid].name = domain.name;
			});

			// Filter to the most recent self data.
			this._filteredScores.self.forEach(
				({ domainUID, skillUID, date, score }) => {
					if (!compiledScores[domainUID][skillUID])
						compiledScores[domainUID][skillUID] = {};
					if (!compiledScores[domainUID][skillUID].self)
						compiledScores[domainUID][skillUID].self = { date, score };
					else if (date > compiledScores[domainUID][skillUID].self.date)
						compiledScores[domainUID][skillUID].self = { date, score };
				},
			);

			// Sort the historical peer data.
			this._filteredScores.peer.forEach(({ domainUID, skillUID, score }) => {
				if (!compiledScores[domainUID][skillUID])
					compiledScores[domainUID][skillUID] = {};
				if (!compiledScores[domainUID][skillUID].peer)
					compiledScores[domainUID][skillUID].peer = [score];
				else compiledScores[domainUID][skillUID].peer.push(score);
			});

			// Sort the historical external data and add to peer.
			this._filteredScores.external.forEach(
				({ domainUID, skillUID, score }) => {
					if (!compiledScores[domainUID][skillUID])
						compiledScores[domainUID][skillUID] = {};
					if (!compiledScores[domainUID][skillUID].peer)
						compiledScores[domainUID][skillUID].peer = [score];
					else compiledScores[domainUID][skillUID].peer.push(score);
				},
			);

			// Calculate labels and scores.
			for (const domainUID in compiledScores) {
				const skillScores = [];
				for (const skillUID in compiledScores[domainUID]) {
					if (
						!!compiledScores[domainUID][skillUID].self &&
						!!compiledScores[domainUID][skillUID].peer
					) {
						const peerScores = compiledScores[domainUID][skillUID].peer;
						skillScores.push(
							Math.abs(
								peerScores.reduce((a, b) => a + b, 0) / peerScores.length -
									compiledScores[domainUID][skillUID].self.score,
							),
						);
					}
				}
				if (skillScores.length === 0) {
					selfAwareness.push({
						name: compiledScores[domainUID].name,
						url: 'assets/img/gauge-gray.svg',
					});
				} else {
					const score =
						skillScores.reduce((a, b) => a + b, 0) / skillScores.length;
					if (score <= 0.25)
						selfAwareness.push({
							name: compiledScores[domainUID].name,
							url: 'assets/img/gauge-green.svg',
						});
					else if (score <= 0.5)
						selfAwareness.push({
							name: compiledScores[domainUID].name,
							url: 'assets/img/gauge-purpul.svg',
						});
					else
						selfAwareness.push({
							name: compiledScores[domainUID].name,
							url: 'assets/img/gauge-org.svg',
						});
				}
			}
		} else {
			// Get skill UIDs and names.
			const compiledScores = {};
			const domain = this._domains.find(
				(domain) => domain.uid === this._domain.uid,
			);
			domain.skills.forEach((skill) => {
				compiledScores[skill.uid] = {};
				compiledScores[skill.uid].name = skill.name;
			});

			// Filter to the most recent self data.
			this._filteredScores.self.forEach(({ skillUID, date, score }) => {
				if (!compiledScores[skillUID].self)
					compiledScores[skillUID].self = { date, score };
				else if (date > compiledScores[skillUID].self.date)
					compiledScores[skillUID].self = { date, score };
			});

			// Sort the historical peer data.
			this._filteredScores.peer.forEach(({ skillUID, score }) => {
				if (!compiledScores[skillUID]) compiledScores[skillUID] = {};
				if (!compiledScores[skillUID].peer)
					compiledScores[skillUID].peer = [score];
				else compiledScores[skillUID].peer.push(score);
			});

			// Sort the historical external data and add to peer.
			this._filteredScores.external.forEach(({ skillUID, score }) => {
				if (!compiledScores[skillUID]) compiledScores[skillUID] = {};
				if (!compiledScores[skillUID].peer)
					compiledScores[skillUID].peer = [score];
				else compiledScores[skillUID].peer.push(score);
			});

			// Calculate labels and scores.
			for (const skillUID in compiledScores) {
				if (!compiledScores[skillUID].self || !compiledScores[skillUID].peer) {
					selfAwareness.push({
						name: compiledScores[skillUID].name,
						url: 'assets/img/gauge-gray.svg',
					});
				} else {
					const peerScores = compiledScores[skillUID].peer;
					const score = Math.abs(
						peerScores.reduce((a, b) => a + b, 0) / peerScores.length -
							compiledScores[skillUID].self.score,
					);
					if (score <= 0.25)
						selfAwareness.push({
							name: compiledScores[skillUID].name,
							url: 'assets/img/gauge-green.svg',
						});
					else if (score <= 0.1)
						selfAwareness.push({
							name: compiledScores[skillUID].name,
							url: 'assets/img/gauge-purpul.svg',
						});
					else
						selfAwareness.push({
							name: compiledScores[skillUID].name,
							url: 'assets/img/gauge-org.svg',
						});
				}
			}
		}

		// Update chart configuration.
		this._selfAwareness.next(selfAwareness);
	}

	private _loadGrowth(): void {
		let labels: Array<string> = [];
		const selfData: Array<number> = [];
		const externalData: Array<number> = [];
		const peerData: Array<number> = [];

		// Handle data filters.
		const filteredScores = {
			self: this._filteredScoresNoDomain.self.filter(
				(score) => this._growthDomain.uid === score.domainUID,
			),
			peer: this._filteredScoresNoDomain.peer.filter(
				(score) => this._growthDomain.uid === score.domainUID,
			),
			external: this._filteredScoresNoDomain.external.filter(
				(score) => this._growthDomain.uid === score.domainUID,
			),
		};

		// Filter distinct self dates.
		const selfDatesSet: Set<string> = new Set();
		filteredScores.self.forEach((score) => selfDatesSet.add(score.date));
		const selfDates = Array.from(selfDatesSet)
			.sort((a, b) => (a < b ? -1 : 1))
			.map((date) => {
				return { date, score: 0, skills: {} };
			});

		// Filter to the most recent self data per date.
		filteredScores.self.forEach(({ skillUID, date, score }) => {
			for (const selfDate of selfDates) {
				if (date <= selfDate.date) {
					if (!selfDate.skills[skillUID])
						selfDate.skills[skillUID] = { date, score };
					else if (date > selfDate.skills[skillUID].date)
						selfDate.skills[skillUID] = { date, score };
				}
			}
		});

		// Calculate self scores per date.
		for (const date of selfDates) {
			const scores = Object.values(date.skills).map(
				(skill: any) => skill.score,
			);
			date.score = scores.reduce((a, b) => a + b, 0) / scores.length;
		}

		// Filter distinct external dates.
		const externalDatesSet: Set<string> = new Set();
		filteredScores.external.forEach((score) =>
			externalDatesSet.add(score.date),
		);
		const externalDates = Array.from(externalDatesSet)
			.sort((a, b) => (a < b ? -1 : 1))
			.map((date) => {
				return { date, score: 0, skills: {} };
			});

		// Filter to the most recent external data per date.
		filteredScores.external.forEach(({ skillUID, date, score }) => {
			for (const externalDate of externalDates) {
				if (date <= externalDate.date) {
					if (!externalDate.skills[skillUID])
						externalDate.skills[skillUID] = { date, score };
					else if (date > externalDate.skills[skillUID].date)
						externalDate.skills[skillUID] = { date, score };
				}
			}
		});

		// Calculate peer scores per date.
		for (const date of externalDates) {
			const scores = Object.values(date.skills).map(
				(skill: any) => skill.score,
			);
			date.score = scores.reduce((a, b) => a + b, 0) / scores.length;
		}

		// Filter distinct peer dates.
		const peerDatesSet: Set<string> = new Set();
		filteredScores.peer.forEach((score) => peerDatesSet.add(score.date));
		const peerDates = Array.from(peerDatesSet)
			.sort((a, b) => (a < b ? -1 : 1))
			.map((date) => {
				return { date, score: 0, skills: {} };
			});

		// Filter to the most recent peer data per date.
		filteredScores.peer.forEach(({ skillUID, date, score }) => {
			for (const peerDate of peerDates) {
				if (date <= peerDate.date) {
					if (!peerDate.skills[skillUID])
						peerDate.skills[skillUID] = { date, score };
					else if (date > peerDate.skills[skillUID].date)
						peerDate.skills[skillUID] = { date, score };
				}
			}
		});

		// Calculate peer scores per date.
		for (const date of peerDates) {
			const scores = Object.values(date.skills).map(
				(skill: any) => skill.score,
			);
			date.score = scores.reduce((a, b) => a + b, 0) / scores.length;
		}

		// Filter distinct dates.
		const datesSet: Set<string> = new Set();
		const datesModifiedSet = [];
		selfDates.forEach((date) => datesSet.add(date.date));
		externalDates.forEach((date) => datesSet.add(date.date));
		peerDates.forEach((date) => datesSet.add(date.date));
		labels.push(...Array.from(datesSet).sort((a, b) => (a < b ? -1 : 1)));

		// Compile growth data.
		if (
			selfDates.length > 0 ||
			externalDates.length > 0 ||
			peerDates.length > 0
		) {
			// Buffer arrays to reduce needed checks during iteration.
			selfDates.push({ date: null, score: null, skills: null });
			externalDates.push({ date: null, score: null, skills: null });
			peerDates.push({ date: null, score: null, skills: null });

			// Iterate with separate indexes. All arrays are sorted.
			let a = 0;
			let b = 0;
			let c = 0;
			for (const date of labels) {
				if (selfDates[a].date === date) {
					selfData.push(selfDates[a].score);
					a++;
				} else selfData.push(null);
				if (externalDates[b].date === date) {
					externalData.push(externalDates[b].score);
					b++;
				} else externalData.push(null);
				if (peerDates[c].date === date) {
					peerData.push(peerDates[c].score);
					c++;
				} else peerData.push(null);
			}
			labels.forEach((date) =>
				datesModifiedSet.push(this.modifyDateFormat(date)),
			);
			labels = datesModifiedSet;
		}

		// Update chart configuration.
		this._growthChart.data.labels = labels;
		this._growthChart.data.datasets[0].data = selfData;
		this._growthChart.data.datasets[1].data = peerData;
		this._growthChart.data.datasets[2].data = externalData;
		this._growth.next(this._growthChart);
	}

	private async _loadTopSkills(): Promise<void> {
		const scores = this._assessmentScores.peer.concat(
			this._assessmentScores.external,
		);
		const compiled = {};
		for (const score of scores) {
			if (!compiled[score.skillUID]) compiled[score.skillUID] = [score.score];
			else compiled[score.skillUID].push(score.score);
		}
		const topSkills = [];
		for (const skillUID in compiled)
			topSkills.push({
				uid: skillUID,
				score:
					compiled[skillUID].reduce((a, b) => a + +b, 0) /
					compiled[skillUID].length,
			});
		topSkills.sort((a, b) => b.score - a.score);
		topSkills.splice(3);
		for (const skill of topSkills)
			skill.name = (await this.domainSvc.getSkill(skill.uid)).name;
		this._topSkills.next(topSkills);
	}

	get selfVsPeer(): Observable<any> {
		return this._selfVsPeer.asObservable();
	}

	get hasPeerData(): boolean {
		return this._isPeerDataAvailable;
	}

	get strengthsAndWeaknesses(): Observable<any> {
		return this._strengthsAndWeaknesses.asObservable();
	}

	get selfAwareness(): Observable<any> {
		return this._selfAwareness.asObservable();
	}

	get growth(): Observable<any> {
		return this._growth.asObservable();
	}

	get topSkills(): Observable<any> {
		return this._topSkills.asObservable();
	}

	updateFilters(
		domain: Domain,
		group: Group,
		timespan: string,
		growthDomain: Domain,
	): void {
		this._domain = domain;
		this._group = group;
		this._timespan = timespan;
		this._growthDomain = growthDomain;
		if (!this._updated) this._loadAPIs();
		else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf())
			this.assessmentScoresSvc.loadScores();
		else this._loadCharts();
	}

	updateGrowthFilters(growthDomain: Domain): void {
		this._growthDomain = growthDomain;
		if (!this._updated) this._loadAPIs();
		else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf())
			this.assessmentScoresSvc.loadScores();
		else if (!this._filteredScoresNoDomain) this._loadCharts();
		else this._loadGrowth();
	}

	_scoreWithinTimespan(score: AssessmentScore): boolean {
		switch (this._timespan) {
			case 'all time':
				return true;
			case '30 days':
				if (
					30 * ONE_DAY + new Date(score.date).valueOf() >
					new Date().valueOf()
				)
					return true;
				return false;
			case '90 days':
				if (
					90 * ONE_DAY + new Date(score.date).valueOf() >
					new Date().valueOf()
				)
					return true;
				return false;
			case '180 days':
				if (
					180 * ONE_DAY + new Date(score.date).valueOf() >
					new Date().valueOf()
				)
					return true;
				return false;
			case '1 year': {
				const date = new Date(score.date);
				date.setFullYear(date.getFullYear() + 1);
				if (date > new Date()) return true;
				return false;
			}
			case '5 years': {
				const date = new Date(score.date);
				date.setFullYear(date.getFullYear() + 5);
				if (date > new Date()) return true;
				return false;
			}
			default:
				this._timespan = 'all time';
				return true;
		}
	}

	private logout(): void {
		this._selfVsPeer.complete();
		this._selfVsPeer = new ReplaySubject<ChartConfiguration>(1);
		this._growth.complete();
		this._growth = new ReplaySubject<ChartConfiguration>(1);
		this._strengthsAndWeaknesses.complete();
		this._strengthsAndWeaknesses = new ReplaySubject<
			Array<StrengthsAndWeaknesses>
		>(1);
		this._selfAwareness.complete();
		this._selfAwareness = new ReplaySubject<Array<SelfAwareness>>(1);
		this._growth.complete();
		this._growth = new ReplaySubject<ChartConfiguration>(1);
		this._updated = null;
	}

	modifyDateFormat(objectDate) {
		const date = new Date(objectDate);
		let day = date.getDate();
		let month = date.getMonth();
		let year = date.getFullYear();
		return day + ' ' + this.getMonthName(month + 1).slice(0, 3) + ' ' + year;
	}

	getMonthName(monthNumber) {
		const date = new Date();
		date.setMonth(monthNumber - 1);
		return date.toLocaleString('en-US', { month: 'long' });
	}
}
