import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutService, LanguageService } from '@services/public';
import { application } from 'environment';
import { BsModalService } from 'ngx-bootstrap/modal';

const PAGE_PATH: string = 'pages.account.accountModal.page';

@Component({
	selector: 'app-account-modal',
	templateUrl:
		// @ts-ignore
		application === 'jakapa.website'
			? './account-modal.component.website.html'
			: './account-modal.component.html',
	styleUrls: ['./account-modal.component.scss'],
})
export class AccountModalComponent implements OnInit {
	isWeb: boolean = false;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		description: '',
		instructions: '',
		renew: '',
		logout: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _logoutSvc: LogoutService,
		private _modalSvc: BsModalService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});

		// @ts-ignore
		this.isWeb = application === 'jakapa.website';
	}

	logout(): void {
		this._logoutSvc.logout();
		this._modalSvc.hide();
		this._router.navigate(['/']);
	}
}
