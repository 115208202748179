import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { TEMP_KEYS, StorageService, STORAGE_KEYS } from './storage.service';
import { Observable, ReplaySubject } from 'rxjs';
import { LOGOUT_EVENT, LogoutFunction, LogoutService } from './logout.service';

export enum Role {
	SUPER_ADMIN = 'super-admin',
	ADMIN = 'admin',
	RESEARCHER = 'researcher',
	VIEWER = 'viewer',
	MEMBER = 'member',
	LEADER = 'leader',
	ACCOUNT = 'account',
}

export const ROLES_ORDER = [
	Role.ACCOUNT,
	Role.MEMBER,
	Role.RESEARCHER,
	Role.VIEWER,
	Role.LEADER,
	Role.ADMIN,
	Role.SUPER_ADMIN,
];

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private _accessToken: string | null = null;
	private _accessTokenExpiration: Date = new Date();
	private _role: ReplaySubject<Role | null> = new ReplaySubject<Role | null>(1);

	constructor(
		private logoutSvc: LogoutService,
		private _storageSvc: StorageService,
	) {
		// Bind logout service.
		this.logoutSvc.subscribe(LOGOUT_EVENT.POST_API, this._logout);
		this._role.next(null);
	}

	login(res: any): boolean {
		if (!res || !res.accessToken || !res.refreshToken) return false;
		this.accessToken = res.accessToken;
		this.refreshToken = res.refreshToken;
		this._storageSvc.setTempStorage(TEMP_KEYS.NAME, res.name || '');
		this._storageSvc.setTempStorage(TEMP_KEYS.AVATAR, res.avatar || '');
		this._storageSvc.setTempStorage(TEMP_KEYS.IS_GHOST, !!res.isGhost);
		this._storageSvc.setTempStorage(TEMP_KEYS.IS_FIRST_LOGIN, !!res.firstLogin);
		return true;
	}

	get accessToken(): string | null {
		return this._accessToken;
	}

	set accessToken(token: string) {
		try {
			const payload: any = jwtDecode(token);
			if (
				!payload ||
				!payload.exp ||
				!payload.sub ||
				!payload.role ||
				new Date((payload.exp - 300) * 1000) < new Date()
			) {
				this.clearAccessToken();
				return;
			}
			this._accessToken = token;
			this._accessTokenExpiration = new Date((payload.exp - 300) * 1000);
			this._role.next(payload.role);
			this._storageSvc.userUUID = payload.sub;
		} catch (_e) {
			this.clearAccessToken();
		}
	}

	clearAccessToken(): void {
		this._accessToken = null;
		this._accessTokenExpiration = new Date();
		this._role.next(null);
		this._storageSvc.userUUID = null;
	}

	get accessTokenExpiration(): Date {
		return this._accessTokenExpiration;
	}

	get refreshToken(): string | null {
		return localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
	}

	set refreshToken(token: string) {
		try {
			const payload: any = jwtDecode(token);
			if (
				!payload ||
				(!!payload.exp && new Date((payload.exp - 300) * 1000) < new Date())
			) {
				this.clearRefreshToken();
				return;
			}
			localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, token);
		} catch (_e) {
			this.clearRefreshToken();
		}
	}

	clearRefreshToken(): void {
		localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
	}

	get refreshTokenExpiration(): Date {
		const refreshToken: string | null = this.refreshToken;
		if (!refreshToken) return new Date();
		try {
			const payload: any = jwtDecode(refreshToken);
			if (
				!payload ||
				(!!payload.exp && new Date((payload.exp - 300) * 1000) < new Date())
			) {
				this.clearRefreshToken();
				return new Date();
			}
			if (!payload.exp) return new Date();
			return new Date((payload.exp - 300) * 1000);
		} catch (_e) {
			return new Date();
		}
	}

	get role(): Observable<Role | null> {
		return this._role.asObservable();
	}

	private _logout: LogoutFunction = () => {
		this.clearAccessToken();
		this.clearRefreshToken();
		localStorage.removeItem('userLoginFirst');
		localStorage.removeItem('isUserCloseVideo');
	};
}
