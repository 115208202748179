// Aymara

import { LocaleData } from 'ngx-bootstrap/chronos';

export const ayLocale: LocaleData = {
	months: [
		'Sata willka',
		'Pachaqala',
		'Achuma',
		'Waraqaña',
		'Inti',
		'Willka',
		'Anta',
		'Qollqa',
		'Qantum',
		'Suma',
		'Noviya',
		'Jallu',
	],
	monthsShort: [
		'Sat',
		'Pach',
		'Ach',
		'Waraq',
		'Int',
		'Wil',
		'Ant',
		'Qoll',
		'Qant',
		'Sum',
		'Nov',
		'Jall',
	],
	weekdays: ['Kullaka', 'P’unchay', 'Sawa', 'P’isi', 'Q’illa', 'Wawa', 'Waya'],
	weekdaysShort: ['Kul', 'P’un', 'Saw', 'P’is', 'Q’il', 'Waw', 'Way'],
	weekdaysMin: ['Ku', 'P’', 'Sa', 'P’i', 'Q’i', 'Wa', 'Wa'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Jichha] LT',
		nextDay: '[Jichha] LT',
		nextWeek: 'dddd [urukis] LT',
		lastDay: '[Nayra] LT',
		lastWeek: '[Nayra] dddd [urukis] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s kipa',
		past: '%s qhipa',
		s: 'kikiti sekuntuwa',
		m: 'maya minuta',
		mm: '%d minutawa',
		h: 'maya uruwa',
		hh: '%d uruwa',
		d: 'maya p’unchawa',
		dd: '%d p’unchawa',
		M: 'maya phaxsi',
		MM: '%d phaxsi',
		y: 'maya marawa',
		yy: '%d marawa',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
