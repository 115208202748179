import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@services/public';
import { GoalData } from '../common';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { APIErrors, NO_HTML_PATTERN, isAPIErrors } from '@util';
import { GoalService, GoalServiceCreatePlanResponse } from '@services/member';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.goal.pages.customAction';

@Component({
	selector: 'app-member-skill-builder-goal-custom-action',
	templateUrl: './custom-action.component.html',
	styleUrls: ['./custom-action.component.scss'],
})
export class MemberSkillBuilderGoalCustomActionComponent implements OnInit {
	@Input() data: GoalData;
	form: UntypedFormGroup;
	submitted: boolean = false;
	maxChars: number = 400;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
		placeholder: '',
		error1: '',
		error2: '',
		submit: '',
	};

	constructor(
		private _goalSvc: GoalService,
		private _languageSvc: LanguageService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				switch (key) {
					case 'title': {
						this._languageSvc
							.template(this.page[key], { skill: this.data.skill })
							.then((value) => (this.page[key] = value));
						break;
					}
					default: {
						this._languageSvc
							.template(this.page[key])
							.then((value) => (this.page[key] = value));
					}
				}
			}
		});

		// Setup form.
		this.form = new UntypedFormGroup({
			action: new UntypedFormControl(this.data?.action || '', [
				Validators.required,
				Validators.pattern(NO_HTML_PATTERN),
			]),
		});
	}

	submitOnClick(): void {
		this.form.controls.action.setValue(
			(this.form.controls.action.value || '').trim(),
		);
		this.submitted = true;
		if (!this.form.valid) return;
		this.data.action = this.form.controls.action.value;
		this._goalSvc
			.createPlan(this.data)
			.subscribe((res: GoalServiceCreatePlanResponse | APIErrors) => {
				if (!isAPIErrors(res)) this._router.navigate(['member/dashboard']);
			});
	}
}
