import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService, LOGOUT_EVENT, LogoutService } from '@services/public';
import { config } from 'environment';

const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

export interface AssessmentScores {
	self: Array<AssessmentScore>;
	peer: Array<AssessmentScore>;
}

export interface AssessmentScore {
	organizationUUID: string;
	assessmentUUID: string;
	userUUID: string;
	genericName: string;
	groupUUID: string;
	domainUID: number;
	skillUID: number;
	date: string; // 'YYYY-MM-DD' format.
	score: number;
}

@Injectable({
	providedIn: 'root',
})
export class AssessmentScoresService {
	private _scores: ReplaySubject<AssessmentScores> =
		new ReplaySubject<AssessmentScores>(1);
	private _updated: Date | null = null;

	constructor(
		private http: HttpClient,
		private logoutSvc: LogoutService,
		private loaderSvc: LoaderService,
	) {
		this.logoutSvc.subscribe(LOGOUT_EVENT.POST_API, this.logout.bind(this));
	}

	get scores(): Observable<AssessmentScores> {
		if (
			!this._updated ||
			this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()
		)
			this.loadScores();
		return this._scores.asObservable();
	}

	loadScores(): void {
		const loader: unique symbol = Symbol();
		// Only show loader for the initial load.
		if (!this._updated)
			this.loaderSvc.addLoader(
				loader,
				'services/leader/assessment-scores:loadScores',
			);
		this.http
			.get<any>(`${config.apiBase}researcher/assessment/scores`)
			.pipe(
				map((response: any) => {
					if (!response.errors) {
						const scores: AssessmentScores = {
							self: [],
							peer: [],
						};
						for (const type in response) {
							for (const organization of response[type]) {
								for (const group of organization.groups) {
									for (const assessment of group.assessments) {
										for (const user of assessment.users) {
											for (const score of user.scores) {
												scores[type].push({
													organizationUUID: organization.organizationUUID,
													assessmentUUID: assessment.assessmentUUID,
													userUUID: user.userUUID,
													genericName: user.genericName,
													groupUUID: group.groupUUID,
													domainUID: +score.skillUID.toString()[0],
													skillUID: score.skillUID,
													date: assessment.date,
													score: score.score,
												});
											}
										}
									}
								}
							}
						}
						this._scores.next(scores);
						this.loaderSvc.removeLoader(loader);
						this._updated = new Date();
					}
				}).bind(this),
			)
			.subscribe();
	}

	private logout(): void {
		this._scores.complete();
		this._scores = new ReplaySubject<AssessmentScores>(1);
		this._updated = null;
	}
}
