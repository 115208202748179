// Nepali
import { LocaleData } from 'ngx-bootstrap/chronos';

export const neLocale: LocaleData = {
	months: [
		'जनवरी',
		'फेब्रुअरी',
		'मार्च',
		'अप्रिल',
		'मे',
		'जून',
		'जुलाई',
		'अगस्ट',
		'सप्टेम्बर',
		'अक्टोबर',
		'नोभेम्बर',
		'डिसेम्बर',
	],
	monthsShort: [
		'जन',
		'फेब',
		'मार्च',
		'अप्रिल',
		'मे',
		'जून',
		'जुलाई',
		'अग',
		'सप्ट',
		'अक्टो',
		'नोभ',
		'डिस',
	],
	weekdays: [
		'आइतबार',
		'सोमबार',
		'मंगलबार',
		'बुधबार',
		'बिहीबार',
		'शुक्रबार',
		'शनिबार',
	],
	weekdaysShort: ['आइत', 'सोम', 'मंगल', 'बुध', 'बिही', 'शुक्र', 'शनि'],
	weekdaysMin: ['आ', 'सो', 'मं', 'बु', 'बि', 'शु', 'श'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[आज] LT',
		nextDay: '[भोलि] LT',
		nextWeek: 'dddd [को] LT',
		lastDay: '[हिजो] LT',
		lastWeek: '[पछिल्लो] dddd [को] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s मा',
		past: '%s अगाडि',
		s: 'केही सेकेण्ड',
		m: 'एक मिनेट',
		mm: '%d मिनेट',
		h: 'एक घण्टा',
		hh: '%d घण्टा',
		d: 'एक दिन',
		dd: '%d दिन',
		M: 'एक महिना',
		MM: '%d महिना',
		y: 'एक वर्ष',
		yy: '%d वर्ष',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 0, // Sunday is the first day of the week
		doy: 6, // The week that contains Jan 6 is the first week of the year
	},
};
