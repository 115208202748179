// Tsonga
import { LocaleData } from 'ngx-bootstrap/chronos';

export const tsLocale: LocaleData = {
	months: [
		'Januwari',
		'Februwari',
		'Matshelu',
		'Avril',
		'Meyi',
		'Juni',
		'Julaayi',
		'Nyovember',
		'Spthemba',
		'Okthobha',
		'November',
		'Disemba',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mar',
		'Avr',
		'Meyi',
		'Jun',
		'Jul',
		'Nyov',
		'Sep',
		'Okt',
		'Nov',
		'Dis',
	],
	weekdays: [
		'Sonto',
		'Monday',
		'Swasabeka',
		'Wede',
		'Thursday',
		'Friday',
		'Saturday',
	],
	weekdaysShort: ['Son', 'Mon', 'Swa', 'Wed', 'Thu', 'Fri', 'Sat'],
	weekdaysMin: ['So', 'Mo', 'Sw', 'We', 'Th', 'Fr', 'Sa'],
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd, D MMMM YYYY HH:mm',
	},
	calendar: {
		sameDay: '[Laha] LT',
		nextDay: '[Laha] LT',
		nextWeek: 'dddd [Laha] LT',
		lastDay: '[Laha] LT',
		lastWeek: '[Laha] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'Ka %s',
		past: '%s ku lulama',
		s: 'Nhlayo nkomo',
		ss: '%d Nkomo',
		m: 'Mphapo mambirhi',
		mm: '%d mphapo',
		h: 'Nhlay’ yinhle',
		hh: '%d Nhlay’',
		d: 'Tsiku',
		dd: '%d Tiharu',
		M: 'N’wheti',
		MM: '%d N’wheti',
		y: 'Ngwembu',
		yy: '%d Ngwembu',
	},
	ordinal: (num: number): string => `${num}`,
	week: {
		dow: 0, // Sunday is the first day of the week.
		doy: 6, // The week that contains Jan 6th is the first week of the year.
	},
};
