<div class="main-container w-100" *ngIf="display">
	<div class="container-fluid pb-5-sm-c pb-5-md-c">
		<div class="row w-100">
			<div class="col-sm-12 col-lg-6">
				<div class="col-12 px-0">
					<div class="card card-rounded">
						<div class="col-12 donut-wrapper position-relative legend-lg">
							<app-skill-builder-pie-chart
								[title]="page.pieTitle"
								chartTitle=""
								[data]="pieData"
								class="w-100 mb-3"
							></app-skill-builder-pie-chart>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-lg-6">
				<div class="col-12">
					<app-skill-builder-calendar
						[events]="calendarEvents"
						[legend]="calendarLegend"
						[subtext]="page.subtext"
						skillBuilderType="journal"
						class="w-100 mb-3"
					></app-skill-builder-calendar>
				</div>
				<div class="col-12">
					<div class="card card-rounded">
						<div class="card-body pt-3 d-flex align-items-center">
							<div class="col-12 d-flex align-items-center px-0">
								<div
									class="count-box d-flex align-items-center justify-content-center"
								>
									{{ journalsCompleted }}
								</div>
								<div
									class="text-center card-label-md w-75 d-flex align-items-center justify-content-center"
								>
									{{ page.journalsCompleted }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
