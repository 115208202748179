<!-- Skill builder header -->
<!-- TODO: Refactor SCSS -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
				<span class="cursor-pointer txt-md">
					<i class="fas fa-arrow-left" (click)="back()"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
			</div>
		</div>
	</div>
</div>

<!-- Skill builder start flow -->
<ng-container *ngIf="data?.page === DECISION_PAGE.AFFECTS">
	<app-member-skill-builder-decision-affects [data]="data">
	</app-member-skill-builder-decision-affects>
</ng-container>
<ng-container *ngIf="data?.page === DECISION_PAGE.ACTIONS">
	<app-member-skill-builder-decision-actions [data]="data">
	</app-member-skill-builder-decision-actions>
</ng-container>
<ng-container *ngIf="data?.page === DECISION_PAGE.OUTCOMES">
	<app-member-skill-builder-decision-outcomes [data]="data">
	</app-member-skill-builder-decision-outcomes>
</ng-container>
<ng-container *ngIf="data?.page === DECISION_PAGE.IMPACTS">
	<app-member-skill-builder-decision-impacts [data]="data">
	</app-member-skill-builder-decision-impacts>
</ng-container>
<ng-container *ngIf="data?.page === DECISION_PAGE.RANKS">
	<app-member-skill-builder-decision-ranks [data]="data">
	</app-member-skill-builder-decision-ranks>
</ng-container>

<!-- Skill builder track flow -->
<ng-container *ngIf="data?.page === DECISION_PAGE.TAKEN_ACTION">
	<app-member-skill-builder-decision-taken-action [data]="data">
	</app-member-skill-builder-decision-taken-action>
</ng-container>
<ng-container *ngIf="data?.page === DECISION_PAGE.RIGHT_CHOICE_NO">
	<app-member-skill-builder-decision-right-choice-no [data]="data">
	</app-member-skill-builder-decision-right-choice-no>
</ng-container>
<ng-container *ngIf="data?.page === DECISION_PAGE.RIGHT_CHOICE_YES">
	<app-member-skill-builder-decision-right-choice-yes [data]="data">
	</app-member-skill-builder-decision-right-choice-yes>
</ng-container>
