<!-- Brand Logo -->
<div class="closeMenuMain">
	<a class="closeMenu" (click)="toggleMenu.emit()">
		<i class="fas fa-times"></i>
	</a>
</div>

<!-- Sidebar -->
<div class="sidebar">
	<!-- Sidebar Menu -->
	<nav class="mt-2" style="overflow-y: hidden">
		<ul
			class="nav nav-pills nav-sidebar flex-column"
			data-widget="treeview"
			role="menu"
			data-accordion="false"
		>
			<ng-container *ngFor="let option of menu">
				<a
					(click)="onOptionClick(option)"
					class="nav-link"
					[ngClass]="{ active: true }"
				>
					<img class="nav-icon" [src]="option.image" />
					<p>{{ option.name }}</p>
				</a>
			</ng-container>
		</ul>
	</nav>
</div>

<ng-template #template>
	<div class="modal-body px-0">
		<div class="modal-header px-0 pr-4 pt-0 pt-md-3 pb-0 pb-md-3">
			<h4 class="modal-title d-flex align-items-center justify-content-center">
				<label class="lbl-md pl-3 mb-0">{{ page.howToUse }}</label>
			</h4>
			<button
				type="button"
				class="btn-close close pull-right"
				aria-label="Close"
				(click)="decline()"
			>
				<span aria-hidden="true" class="visually-hidden">&times;</span>
			</button>
		</div>
		<div class="col-12 my-4 px-4 embed-responsive embed-responsive-16by9">
			<iframe
				class="embed-responsive-item"
				src="https://www.youtube.com/embed/eLOJPAjA91s?autoplay=0&loop=1&autopause=0&controls=1"
				width="420"
				height="360"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
			></iframe>
		</div>
		<div
			class="pt-0 pt-md-3 d-flex d-lg-none align-items-center justify-content-center"
		>
			<button (click)="decline()" type="button" class="btn btn-md">
				{{ page.close }}
			</button>
		</div>
	</div>
</ng-template>
