<app-skill-builder-select-list
	[mainTitle]="page.title"
	[options]="options"
	[change]="onChange.bind(this)"
	[next]="next"
>
</app-skill-builder-select-list>
<div
	class="main-container w-100 align-items-center justify-content-center d-flex"
	*ngIf="!!selected && selected !== page.no"
>
	<div class="container px-0 w-sm-100 w-50">
		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center">
					<div class="col-12">
						<div class="card card-white card-white-sm">
							<div class="card-body">
								<h5>
									{{ page.subTitle }}
								</h5>
								<div class="pt-4">
									<button
										type="button"
										(click)="choice = 'no'"
										class="btn btn-md ml-3 {{
											choice === 'no'
												? 'btn-secondary'
												: 'btn-outline btn-outline-danger'
										}}"
									>
										{{ page.no }}
									</button>
									<button
										type="button"
										(click)="choice = 'yes'"
										class="btn btn-md ml-3 {{
											choice === 'yes'
												? 'btn-secondary'
												: 'btn-outline btn-outline-danger'
										}}"
									>
										{{ page.yes }}
									</button>
								</div>
								<div class="pt-4">
									<button
										type="button"
										(click)="choice = 'not-sure'"
										class="btn btn-md ml-3 {{
											choice === 'not-sure'
												? 'btn-secondary'
												: 'btn-outline btn-outline-danger'
										}}"
									>
										{{ page.notSure }}
									</button>
								</div>
							</div>
						</div>
						<div *ngIf="submitted && !choice" class="invalid-feedback">
							{{ page.error1 }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #reminder>
	<div class="modal-body modal-center">
		<p class="mt-3 text-center success-msg px-5 title-main">
			{{ page.reminder }}
		</p>
		<div class="pt-4 d-flex align-items-center justify-content-center">
			<button
				(click)="closeReminder()"
				type="button"
				class="btn btn-md btn-bordered-purple"
			>
				{{ page.ok }}
			</button>
		</div>
	</div>
</ng-template>
