import { Component, Input, OnInit } from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { LABELS, LanguageService } from '@services/public';
import { NO_HTML_PATTERN } from '@util';

export interface TextListTextInput {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
}

export interface TextListDisclaimer {
	display: string;
	error: string;
}

export interface TextListNext {
	display?: string; // The display text for the Next button.
	onClick: Function; // The function to call to submit the selected options.
}

@Component({
	selector: 'app-skill-builder-text-list',
	templateUrl: './text-list.component.html',
	styleUrls: ['./text-list.component.scss'],
})
export class SkillBuilderTextListComponent implements OnInit {
	@Input() mainTitle: string = '';
	@Input() subTitle: string = '';
	@Input() textInputs: Array<TextListTextInput> = [];
	@Input() disclaimer: TextListDisclaimer = null;
	@Input() next: TextListNext = null;
	@Input() validationError: string = '';
	@Input() parentLoaded: Promise<void> = Promise.resolve();
	@Input() min: number = 1;

	form: UntypedFormGroup;
	submitted: boolean = false;

	// Page langauge.
	labels: { [key: string]: string } = {
		[LABELS.ERROR_NO_HTML]: '',
		[LABELS.NEXT]: '',
	};

	constructor(private _languageSvc: LanguageService) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.getLabels(this.labels);

		// Setup form.
		this.form = new UntypedFormGroup({
			textInputList: new UntypedFormArray([]),
			disclaimer: new UntypedFormControl(
				!this.disclaimer,
				Validators.requiredTrue,
			),
		});
		this.parentLoaded.then((_value) => {
			for (const textInput of this.textInputs)
				this.textInputList.push(
					new UntypedFormControl(
						{ value: textInput.value, disabled: textInput.disabled },
						Validators.pattern(NO_HTML_PATTERN),
					),
				);
		});
	}

	get textInputList(): UntypedFormArray {
		return <UntypedFormArray>this.form.controls['textInputList'];
	}

	get validCount(): number {
		let count = 0;
		for (const textInputControl of this.textInputList.controls)
			if (
				!!textInputControl.value &&
				textInputControl.valid &&
				!textInputControl.disabled
			)
				count++;
		return count;
	}

	nextOnClick(): void {
		this.textInputList.controls.forEach((control) =>
			control.setValue((control.value || '').trim()),
		);
		this.submitted = true;
		if (!this.form.valid) return;
		if (this.validCount < this.min) return;
		this.next.onClick(
			this.textInputList.getRawValue().filter((textInput) => !!textInput),
		);
	}
}
