import {
	Component,
	OnInit,
	AfterViewInit,
	ViewChild,
	ElementRef,
} from '@angular/core';
import {
	LANGUAGE_CODES,
	LanguageService,
	STORAGE_KEYS,
} from '@services/public';
import { Filter } from '@util';

export interface Language {
	display: string;
	value: string;
}

const LANGUAGE_UPDATE_DEFAULT = '2000-01-01 00:00:00.0';

@Component({
	selector: 'app-language-select',
	templateUrl: './language-select.component.html',
	styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit, AfterViewInit {
	@ViewChild('languageDropdown', { static: false })
	languageDropdown: ElementRef;

	languageFilter: Filter<Language> = {
		key: 'languages',
		value: {
			display: '',
			value: '',
		},
		options: [],
	};

	constructor(private _languageSvc: LanguageService) {}

	ngOnInit(): void {
		// Get options list.
		const path: string = `languages`;
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] !== 'object' || value[path] === null) return;
			const knownLanguageCodes: Array<string> = Object.values(LANGUAGE_CODES);
			this.languageFilter.options = this._languageSvc
				.objectToOrderedArray(value[path])
				.filter((option: Language) =>
					knownLanguageCodes.includes(option.value),
				);
			this.languageFilter.value = this.languageFilter.options.find(
				(option: Language) => option.value === this._languageSvc.language,
			);
		});
	}

	ngAfterViewInit(): void {
		(
			this.languageDropdown.nativeElement as HTMLElement
		).parentElement.parentElement.classList.add('language-parent');
	}

	onLanguageSelect(): void {
		localStorage.setItem(
			STORAGE_KEYS.LANGUAGE,
			JSON.stringify({
				language: this.languageFilter.value.value,
				update: LANGUAGE_UPDATE_DEFAULT,
				compiled: {},
			}),
		);
		location.reload();
	}
}
