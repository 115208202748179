import { Component, Input, OnInit } from '@angular/core';
import { DECISION_PAGE, DecisionAction, DecisionData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import {
	TextListDisclaimer,
	TextListNext,
	TextListTextInput,
} from '@components';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.decision.pages.actions';

@Component({
	selector: 'app-member-skill-builder-decision-actions',
	templateUrl: './actions.component.html',
	styleUrls: ['./actions.component.scss'],
})
export class MemberSkillBuilderDecisionActionsComponent implements OnInit {
	@Input() data: DecisionData;
	actions: Array<TextListTextInput> = [
		{ placeholder: '' },
		{ placeholder: '' },
		{ placeholder: '' },
		{ value: '', disabled: true },
	];
	disclaimer: TextListDisclaimer = { display: '', error: '' };
	next: TextListNext = { onClick: this.nextOnClick.bind(this) };
	loaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		subTitle: '',
		error1: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Load decision data.
		const actions: Array<DecisionAction> = <Array<DecisionAction>>(
			this.data?.actions
		);
		if (!!actions)
			for (let i = 0; i < actions.length - 1; i++)
				this.actions[i].value = actions[i].action;

		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<void>> = [];
			for (const key in this.page) {
				switch (key) {
					case 'placeholder1':
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
								this.actions[0].placeholder = value;
							}),
						);
						break;
					case 'placeholder2':
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
								this.actions[1].placeholder = value;
							}),
						);
						break;
					case 'placeholder3':
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
								this.actions[2].placeholder = value;
							}),
						);
						break;
					case 'doNothing':
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
								this.actions[3].value = value;
							}),
						);
						break;
					case 'disclaimer':
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
								this.disclaimer.display = value;
							}),
						);
						break;
					case 'error2':
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
								this.disclaimer.error = value;
							}),
						);
						break;
					default:
						promises.push(
							this._languageSvc.template(this.page[key]).then((value) => {
								this.page[key] = value;
							}),
						);
				}
			}
			Promise.all(promises).then((_value) => this.loaded.resolve());
		});
	}

	nextOnClick(actions: Array<string>): void {
		const data: DecisionData = Object.assign({}, this.data, {
			page: DECISION_PAGE.OUTCOMES,
			actions: actions.map((action) => {
				return { action };
			}),
		});
		this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, data);
	}
}
