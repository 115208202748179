import { Component, OnInit } from '@angular/core';
import { LABELS, LanguageService } from '@services/public';
import {
	CertificatesService,
	certificateNoticeData,
	WEEKLY_CERT_LEVELS,
	DOMAIN_CERT_LEVELS,
} from '@services/member';
import { BsModalRef } from 'ngx-bootstrap/modal';

const PAGE_PATH: string = 'pages.member.certificateModal.page';

@Component({
	selector: 'app-certificate-modal',
	templateUrl: './certificate-modal.component.html',
	styleUrls: ['./certificate-modal.component.scss'],
})
export class CertificateModalComponent implements OnInit {
	certificateData: Array<certificateNoticeData>;
	letters: string[];
	page: { [key: string]: string } = {
		achieved: '',
		congrats: '',
		download: '',
		level: '',
		messageMany: '',
		messageOne: '',
		title: '',
		weekly: '',
	};
	labels: { [key: string]: string } = {
		[LABELS.CLOSE]: '',
	};
	domains: { [key: string]: string } = {
		1: '',
		2: '',
		3: '',
		4: '',
		5: '',
	};
	language: string;

	constructor(
		private _languageSvc: LanguageService,
		private _certificateSvc: CertificatesService,
		private _modalRef: BsModalRef,
	) {
		this.language = this._languageSvc.language;
	}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				switch (key) {
					case 'messageOne':
						const level = this.certificateData[0]?.level ?? 1;
						const domain = this.certificateData[0]?.domain_uid ?? null;
						const num = domain
							? DOMAIN_CERT_LEVELS[domain]
							: WEEKLY_CERT_LEVELS[level];
						this._languageSvc
							.template(this.page[key], {
								cert_level: level.toString(),
								num_of_challenges: num.toString(),
							})
							.then((value) => (this.page[key] = value));
						break;
					default:
						this._languageSvc
							.template(this.page[key])
							.then((value) => (this.page[key] = value));
						break;
				}
			}
			this.letters = this.page.title.split('');
		});
		this._languageSvc.getLabels(this.labels);
		this._languageSvc.get([`domains2`]).then((value) => {
			if (typeof value[`domains2`] !== 'object' || value[`domains2`] === null)
				return;
			for (const key in value[`domains2`]) {
				if (key in this.domains) {
					this.domains[key] = value[`domains2`][key].name;
				}
			}
		});
	}

	close() {
		this._modalRef.hide();
		this._certificateSvc
			.updateNotification({ certificates: this.certificateData })
			.subscribe();
	}

	downloadCertificate(
		type: string,
		level: number,
		domain: number | null,
		achieved: string,
		language: string,
	) {
		this._certificateSvc.downloadCertificate({
			type: type,
			level: level,
			domain_uid: domain,
			achieved: achieved,
			language: language,
			numOfChallenges:
				type === 'weekly'
					? WEEKLY_CERT_LEVELS[level]
					: DOMAIN_CERT_LEVELS[level],
		});
	}
}
