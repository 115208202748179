<div class="w-100 title-md-gray challenge-info-mob">
	<h1 class="d-flex d-md-none">{{ page.title }}</h1>
	<div
		class="col-12 px-0 d-flex d-md-none align-items-start justify-content-between mb-2 pb-3"
	>
		<span
			><strong>{{ domainsName }}</strong
			>&nbsp;<span [innerHTML]="description"></span
		></span>
		<!-- <span [ngbTooltip]="pageLanguage?.info" triggers="click" tooltipClass="my-custom-class" placement="left">
      <span class="info-circle">
        <img src="./../../../../assets/img/Info-icon.svg" alt="pageLanguage?.info" />
      </span>
    </span> -->
	</div>
</div>
<div class="col-md-12 mb-4 px-4 px-lg-0">
	<div class="title-md-gray col-12">
		<h1 class="d-none d-md-flex">{{ page.title }}</h1>
		<div
			class="col-12 px-0 d-none d-md-flex align-items-center justify-content-between mb-2"
		>
			<span
				><strong>{{ domainsName }}</strong
				>&nbsp;<span [innerHTML]="description"></span
			></span>
			<!-- <span [ngbTooltip]="page.info" triggers="click" tooltipClass="my-custom-class" placement="left">
          <span class="info-circle">
            <img src="./../../../../assets/img/Info-icon.svg" alt="page.info" />
          </span>
        </span> -->
		</div>
	</div>
	<div class="card card-white card-white-sm border-radius-md">
		<div class="col-md-12">
			<div class="row">
				<div class="col-3 col-md-2">
					<div
						class="badge-section d-flex align-items-center justify-content-center position-relative challenge-badges"
					>
						<img
							[src]="domainLogo"
							class="img-fluid mid-img-badge mid-img-badge-sm"
							alt="page.badges"
						/>
						<svg
							viewBox="0 0 36 36"
							class="activity {{
								score >= 60
									? score >= 80
										? score >= 100
											? 'green'
											: 'purple'
										: 'blue'
									: 'gray'
							}}"
						>
							<path
								class="circle-bg-chart"
								d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
							<path
								class="circle"
								[attr.stroke-dasharray]="progress"
								d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
						</svg>
					</div>
					<div class="flag-holder">
						<span class="small white mid-txt-badge flag current"
							>{{ labels.level | slice: 0 : 1 }}{{ level }}</span
						>
					</div>
				</div>
				<div class="col-6 col-md-8">
					<div
						class="row reward-section d-flex align-items-center justify-content-center pt-3"
					>
						<span class="d-flex pl-2 align-items-center">{{
							page.progress
						}}</span>
						<div class="col-12 mt-3">
							<div class="progress progress-bg">
								<div
									class="progress-bar progress-bar-warning"
									role="progressbar"
									[style.width.%]="score"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-3 col-md-2">
        <div *ngIf="WeeklyChallenge.domainUID === 0" class="badge-section gold-badge challenge-badges">
          <img src="./../../../../assets/img/badges/0.svg" class="img-fluid" alt="Badge" />
        </div>
    </div> -->
			</div>
		</div>
		<div class="card-gray d-flex align-items-center border-top-radius">
			<div class="col-12 challenges-legend">
				<ul>
					<li>
						<span class="circle-xs d-flex good"></span>{{ labels.completed }}
					</li>
					<li>
						<span class="circle-xs d-flex missed"></span
						>{{ page.missedOpportunities }}
					</li>
				</ul>
			</div>
		</div>
		<div class="path">
			<div class="bg-box" *ngIf="mondayActivity.length > 0">
				<span class="days-label {{ isOrientationWeek ? 'left' : '' }}">{{
					isOrientationWeek ? '' : page.monday
				}}</span>
				<div class="curve">
					<ul>
						<li
							[attr.id]="monday.type + '-' + monday.skillBuiderType"
							class="activity animate__animated animate__tada {{
								monday.isDisabled ? 'disabled' : ''
							}}"
							*ngFor="let monday of mondayActivity; let i = index"
							(click)="activityAction(monday)"
						>
							<span class="activity-counter">
								{{ monday.sort }}
							</span>
							<div
								class="circle-challenge  {{
									monday.status == 'open' || monday.status == 'locked'
										? 'circle-notstarted'
										: monday.status == 'completed'
											? 'circle-completed'
											: 'circle-missed'
								}} "
							>
								<i class="{{ monday.img }}"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="bg-box" *ngIf="tuesdayActivity.length > 0">
				<span class="days-label {{ isOrientationWeek ? 'left' : '' }}">{{
					isOrientationWeek ? '' : page.tuesday
				}}</span>
				<div class="curve">
					<ul>
						<li
							[attr.id]="tuesday.type + '-' + tuesday.skillBuilderType"
							class="activity animate__animated animate__bounce {{
								tuesday.isDisabled ? 'disabled' : ''
							}}"
							*ngFor="let tuesday of tuesdayActivity; let i = index"
							(click)="activityAction(tuesday)"
						>
							<span class="activity-counter">
								{{ tuesday.sort }}
							</span>
							<div
								class="circle-challenge  {{
									tuesday.status == 'open' || tuesday.status == 'locked'
										? 'circle-notstarted'
										: tuesday.status == 'completed'
											? 'circle-completed'
											: 'circle-missed'
								}} "
							>
								<i class="{{ tuesday.img }}"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="bg-box" *ngIf="wednesdayActivity.length > 0">
				<span class="days-label {{ isOrientationWeek ? 'left' : '' }}">{{
					isOrientationWeek ? '' : page.wednesday
				}}</span>
				<div
					class="{{
						thursdayActivity.length > 0
							? 'curve'
							: ' curve last-challenge-curve'
					}}"
				>
					<ul>
						<li
							[attr.id]="wednesday.type + '-' + wednesday.skillBuilderType"
							class="activity animate__animated animate__bounce {{
								wednesday.isDisabled ? 'disabled' : ''
							}}"
							*ngFor="
								let wednesday of wednesdayActivity;
								let i = index;
								let last = last
							"
							[class.last-challenge]="last"
							(click)="activityAction(wednesday)"
						>
							<span class="activity-counter">
								{{ wednesday.sort }}
							</span>
							<div
								class="circle-challenge {{
									wednesday.status == 'open' || wednesday.status == 'locked'
										? 'circle-notstarted'
										: wednesday.status == 'completed'
											? 'circle-completed'
											: 'circle-missed'
								}} "
							>
								<i class="{{ wednesday.img }}"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="bg-box" *ngIf="thursdayActivity.length > 0">
				<span class="days-label {{ isOrientationWeek ? 'left' : '' }}">{{
					isOrientationWeek ? '' : page.thursday
				}}</span>
				<div
					class="{{
						fridayActivity.length > 0 ? 'curve' : ' curve last-challenge-curve'
					}}"
				>
					<ul>
						<li
							[attr.id]="thursday.type + '-' + thursday.skillBuilderType"
							class="activity animate__animated animate__bounce {{
								thursday.isDisabled ? 'disabled' : ''
							}}"
							*ngFor="
								let thursday of thursdayActivity;
								let i = index;
								let last = last
							"
							[class.last-challenge]="last"
							(click)="activityAction(thursday)"
						>
							<span class="activity-counter">
								{{ thursday.sort }}
							</span>
							<div
								class="circle-challenge {{
									thursday.status == 'open' || thursday.status == 'locked'
										? 'circle-notstarted'
										: thursday.status == 'completed'
											? 'circle-completed'
											: 'circle-missed'
								}} "
							>
								<i class="{{ thursday.img }}"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="bg-box" *ngIf="fridayActivity.length > 0">
				<span class="days-label {{ isOrientationWeek ? 'left' : '' }}">{{
					isOrientationWeek ? '' : page.friday
				}}</span>
				<div class="curve">
					<ul>
						<li
							[attr.id]="friday.type + '-' + friday.skillBuilderType"
							class="activity animate__animated animate__bounce {{
								friday.isDisabled ? 'disabled' : ''
							}}"
							*ngFor="let friday of fridayActivity; let i = index"
							(click)="activityAction(friday)"
						>
							<span class="activity-counter">
								{{ friday.sort }}
							</span>
							<div
								class="circle-challenge {{
									friday.status == 'open' || friday.status == 'locked'
										? 'circle-notstarted'
										: friday.status == 'completed'
											? 'circle-completed'
											: 'circle-missed'
								}} "
							>
								<i class="{{ friday.img }}"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<ng-template #template>
		<div class="modal-body px-0">
			<div
				class="modal-header px-0 pr-4 pt-0 pt-md-3 pb-0 pb-md-3 border-bottom-0"
			>
				<h4
					class="modal-title d-flex align-items-center justify-content-center"
				>
					<label class="pl-3 mb-0">{{ page.challengeAlreadyComplete }}</label>
				</h4>
				<button
					type="button"
					class="btn-close close pull-right"
					aria-label="labels.close"
					(click)="decline()"
				>
					<span aria-hidden="true" class="visually-hidden">&times;</span>
				</button>
			</div>
		</div>
	</ng-template>
</div>
