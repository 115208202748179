import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

export interface Tab {
	key: string;
	id: string;
	display: string;
	link: string;
}

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
	@Input() tabs: Array<Tab>;
	@Input() selectTab: Observable<string>;

	selectedTab: Tab;

	constructor() {}

	ngOnInit(): void {
		this.selectTab.subscribe((url: string) => {
			this.selectedTab = this.tabs.find((tab) => url.includes(tab.link));
		});
	}
}
