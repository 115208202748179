import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDData, WWYD_PAGE } from '../common';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { NO_HTML_PATTERN } from '@util';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.customDilemma';

@Component({
	selector: 'app-member-skill-builder-wwyd-custom-dilemma',
	templateUrl: './custom-dilemma.component.html',
	styleUrls: ['./custom-dilemma.component.scss'],
})
export class MemberSkillBuilderWwydCustomDilemmaComponent implements OnInit {
	@Input() data: WWYDData;
	form: UntypedFormGroup;
	submitted: boolean = false;
	maxChars: number = 400;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		placeholder: '',
		error1: '',
		error2: '',
		next: '',
		dilemmaDisplay: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Setup form.
		this.form = new UntypedFormGroup({
			dilemma: new UntypedFormControl(this.data?.dilemma?.description || '', [
				Validators.required,
				Validators.pattern(NO_HTML_PATTERN),
			]),
		});
	}

	nextOnClick(): void {
		this.form.controls.dilemma.setValue(
			(this.form.controls.dilemma.value || '').trim(),
		);
		this.submitted = true;
		if (!this.form.valid) return;
		this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
			...this.data,
			page: WWYD_PAGE.FIRST_CHOICE,
			dilemma: {
				display: this.page.dilemmaDisplay,
				description: this.form.controls.dilemma.value,
				custom: true,
			},
		});
	}
}
