import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	LanguageService,
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	VOLATILE_KEYS,
} from '@services/public';
import { Subscription } from 'rxjs';
import { JOURNAL_PAGE, JOURNAL_PREVIOUS_PAGE, JournalData } from './common';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIErrors, isAPIErrors } from '@util';

const PAGE_PATH: string = 'skillBuilders.journal.pages.main';

@Component({
	selector: 'app-member-skill-builder-journal',
	templateUrl: './journal.component.html',
	styleUrls: ['./journal.component.scss'],
})
export class MemberSkillBuilderJournalComponent implements OnInit, OnDestroy {
	@ViewChild('dailyCompletedTemplate', { static: false })
	dailyCompletedTemplate: TemplateRef<void>;
	JOURNAL_PAGE: typeof JOURNAL_PAGE = JOURNAL_PAGE;
	data: JournalData;
	modalRef: BsModalRef;
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		reminder: '',
		ok: '',
	};

	constructor(
		private _router: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
		private _modalSvc: BsModalService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		this.data = this._storageSvc.getTempStorage(TEMP_KEYS.JOURNAL);
		const goalPlanUUID = this._storageSvc.getVolatileStorage(
			VOLATILE_KEYS.GOAL_PLAN_UUID,
			true,
		);
		if (!this.data) {
			this._skillBuilderSvc
				.getStatus(SKILL_BUILDER_TYPES.JOURNAL, goalPlanUUID)
				.subscribe((res: JournalData | APIErrors) => {
					if (!isAPIErrors(res) && !this.data) {
						if (res.dailyCompleted) {
							this.modalRef = this._modalSvc.show(this.dailyCompletedTemplate, {
								class: 'modal-sm modal-dialog-centered',
								backdrop: 'static',
								keyboard: true,
							});
							return;
						}

						if (res.yesterdayCompleted) {
							this.data = {
								...res,
								page: JOURNAL_PAGE.HIGHLIGHT,
								yesterday: false,
							};
						} else {
							this.data = {
								...res,
								page: JOURNAL_PAGE.YESTERDAY,
							};
						}

						this._setStorageSubscription();
					}
				});
		} else this._setStorageSubscription();
	}

	private _setStorageSubscription() {
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
					this.data = update.value[TEMP_KEYS.JOURNAL];
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) this.data = null;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.JOURNAL)
					this.data = update.value;
				if (!this.data && !this._router.getCurrentNavigation())
					this._router.navigate(['member/dashboard']);
			}),
		);
	}

	back() {
		const page: JOURNAL_PAGE = JOURNAL_PREVIOUS_PAGE[this.data?.page];
		if (
			!page ||
			(page === JOURNAL_PAGE.YESTERDAY && this.data.yesterdayCompleted)
		) {
			this._router.navigate(['member/dashboard']);
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, { ...this.data, page });
	}

	closePopup() {
		this.modalRef?.hide();
		this._router.navigate(['member/dashboard']);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, null);
	}
}
