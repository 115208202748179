import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy,
	Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	TokenService,
	LoaderService,
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	LanguageService,
	LogoutService,
} from '@services/public';
import { GhostService } from '@services/jakapa';
import { Subscription, map } from 'rxjs';

const PAGE_PATH = `pages.member.header`;

@Component({
	selector: 'app-member-main-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class MemberMainHeaderComponent implements OnInit, OnDestroy {
	@Input() showAchievements: boolean = false;
	@Output() toggleMenu: EventEmitter<any> = new EventEmitter<any>();
	@Output() toggleAchievements: EventEmitter<any> = new EventEmitter<any>();
	name: string;
	isMobileNavTab: boolean = false;
	modalRef: BsModalRef;
	endDate: any;
	isMemberView: any;
	isGhost: boolean;
	userEmail: string;
	isOnboarding: boolean = false;
	page: { [key: string]: string } = {
		achievements: '',
		cancel: '',
		clipboard: '',
		enterUsersEmail: '',
		ghost: '',
		hi: '',
		home: '',
		reports: '',
		rewards: '',
		settings: '',
		logout: '',
	};

	private _subscriptions: Subscription = new Subscription();

	constructor(
		private tokenSvc: TokenService,
		private modalSvc: BsModalService,
		private ghostSvc: GhostService,
		private loaderSvc: LoaderService,
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
		private logoutSvc: LogoutService,
		public route: Router,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL) {
					this.name = update.value[TEMP_KEYS.NAME];
					this.isGhost = update.value[TEMP_KEYS.IS_GHOST];
				}
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) {
					this.name = '';
					this.isGhost = false;
				}
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.NAME)
					this.name = update.value;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.IS_GHOST)
					this.isGhost = update.value;
			}),
		);
		this.name = this._storageSvc.getTempStorage(TEMP_KEYS.NAME) || '';
		this.isGhost = !!this._storageSvc.getTempStorage(TEMP_KEYS.IS_GHOST);
		if (this.route.url.includes('member')) {
			this.isMobileNavTab = true;
		}
	}

	displayMobileNavTab(): boolean {
		const urls: Array<string> = [
			'member/dashboard',
			'member/settings',
			'member/tasks',
		];
		for (const url of urls) if (this.route.url.includes(url)) return true;
		return false;
	}

	ghostClicked(template) {
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-md modal-dialog-centered',
		});
	}

	decline() {
		this.modalRef?.hide();
	}

	ghost(email: string) {
		if (email && email.trim() !== '') {
			this.modalRef?.hide();
			const loader: unique symbol = Symbol();
			this.loaderSvc.addLoader(loader);
			this.ghostSvc
				.ghost(email)
				.pipe(
					map((response: any) => {
						this.loaderSvc.removeLoader(loader);
						if (!!response && !!response.refreshToken) {
							// Clear everything except the refresh token for a clean log in.
							this.tokenSvc.refreshToken = response.refreshToken;
							this.tokenSvc.clearAccessToken();
							this._storageSvc.clearMainStorage();
							this._storageSvc.clearTempStorage();
							location.reload();
						}
					}).bind(this),
				)
				.subscribe();
		} else {
			console.log('Email is required');
		}
	}

	onboardingLogout() {
		this.logoutSvc.logout();
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
