import { SKILL_BUILDER_TYPES, SkillBuilderData } from '@services/member';

export enum ABSTRACT_PAGE {
	EXAMPLE = 'EXAMPLE',
	IDEA = 'IDEA',
	POSITIVE_REASONS = 'POSITIVE_REASONS',
	NEGATIVE_REASONS = 'NEGATIVE_REASONS',
	POSITIVE_IMPACT = 'POSITIVE_IMPACT',
	NEGATIVE_IMPACT = 'NEGATIVE_IMPACT',
	RATING = 'RATING',
	SUMMARY = 'SUMMARY',
	COMFORT = 'COMFORT',
}

export const ABSTRACT_PREVIOUS_PAGE = {};

// Default start flow.
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.EXAMPLE] = null;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.IDEA] = ABSTRACT_PAGE.EXAMPLE;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.POSITIVE_REASONS] = ABSTRACT_PAGE.IDEA;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.NEGATIVE_REASONS] =
	ABSTRACT_PAGE.POSITIVE_REASONS;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.POSITIVE_IMPACT] =
	ABSTRACT_PAGE.NEGATIVE_REASONS;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.NEGATIVE_IMPACT] =
	ABSTRACT_PAGE.POSITIVE_IMPACT;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.RATING] = ABSTRACT_PAGE.NEGATIVE_IMPACT;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.SUMMARY] = ABSTRACT_PAGE.RATING;
ABSTRACT_PREVIOUS_PAGE[ABSTRACT_PAGE.COMFORT] = ABSTRACT_PAGE.SUMMARY;

export enum ABSTRACT_COMFORT {
	VERY_COMFORTABLE = 'very-comfortable',
	SOMEWHAT_COMFORTABLE = 'somewhat-comfortable',
	SOMEWHAT_UNCOMFORTABLE = 'somewhat-uncomfortable',
	VERY_UNCOMFORTABLE = 'very-uncomfortable',
}

export interface AbstractReason {
	display: string;
	extreme?: number; // Integers [1-5].
	big?: number; // Integers [1-5].
	rating?: number;
}

export interface AbstractData extends SkillBuilderData {
	type: SKILL_BUILDER_TYPES.ABSTRACT;
	page?: ABSTRACT_PAGE;
	idea?: string;
	positiveReasons?: Array<AbstractReason>;
	negativeReasons?: Array<AbstractReason>;
	userRating?: number; // Integers [1-10].
	generatedRating?: number; // Integers [0-10];
	comfort?: ABSTRACT_COMFORT;
}

export interface AbstractReportIdea {
	idea: string;
	ratingDifference: number;
}

export interface AbstractReportData extends Array<AbstractReportIdea> {}
