<div class="modal-header px-0 pr-4 pt-md-3 pb-3 weekly-challenge feature-modal">
	<button
		type="button"
		class="btn-close close pull-right d-none d-lg-flex"
		aria-label="Close"
		(click)="bsModalRef.hide()"
	>
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
	<div class="custom-shape-divider-bottom-1706161881">
		<div>
			<p class="name">
				{{ pageLanguage?.heyThere }} <span>{{ name }}!</span>
			</p>
		</div>
		<svg
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1200 120"
			preserveAspectRatio="none"
		>
			<path
				d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
				class="shape-fill"
			></path>
		</svg>
	</div>
</div>
<h1 class="modal-title d-flex align-items-center justify-content-center">
	<label class="lbl-md pl-3 mb-0"
		>{{ pageLanguage?.title }} 🚀
		{{ pageLanguage?.version | lowercase | slice: 0 : 1 }}.
		{{ version }}:</label
	>
</h1>
<div class="modal-body modal-center px-0 pt-0 modal-scroll feature-modal-body">
	<div class="col-12 my-4 px-4 notification">
		<div
			class="row col-12 headline_date"
			*ngFor="let item of userNotifications"
		>
			<div class="col-8 headline"><h4 [innerHTML]="item.title"></h4></div>
			<div class="col-4 mt-0 pb-1 date">
				<span>{{ item.posted | date: 'mediumDate' }}</span>
			</div>
			<div *ngIf="isHTML(item.message)" class="note">
				<div [innerHTML]="item.message"></div>
			</div>
			<div *ngIf="!isHTML(item.message)" class="col-12 note">
				<p>{{ item.message }}</p>
			</div>
		</div>
	</div>
</div>
<div
	class="pt-0 pt-md-3 d-flex d-lg-none align-items-center justify-content-center"
>
	<button (click)="bsModalRef.hide()" type="button" class="btn btn-md">
		{{ labels?.close }}
	</button>
</div>
