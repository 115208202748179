// Haitian Creole
import { LocaleData } from 'ngx-bootstrap/chronos';

export const htLocale: LocaleData = {
	months: [
		'Janvye',
		'Fevriye',
		'Mas',
		'Avril',
		'Me',
		'Jen',
		'Jiyè',
		'Out',
		'Septanm',
		'Oktòb',
		'Novanm',
		'Desanm',
	],
	monthsShort: [
		'Jan',
		'Fev',
		'Mas',
		'Avr',
		'Me',
		'Jen',
		'Jiy',
		'Out',
		'Sep',
		'Okt',
		'Nov',
		'Des',
	],
	weekdays: [
		'Dimanch',
		'Lendi',
		'Madi',
		'Mèkredi',
		'Jedi',
		'Vandredi',
		'Samdi',
	],
	weekdaysShort: ['Dim', 'Len', 'Mad', 'Mèk', 'Jed', 'Van', 'Sam'],
	weekdaysMin: ['Di', 'Le', 'Ma', 'Mè', 'Je', 'Va', 'Sa'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Jodi a nan] LT',
		nextDay: '[Demain nan] LT',
		nextWeek: 'dddd [nan] LT',
		lastDay: '[Yè nan] LT',
		lastWeek: '[Te] dddd [nan] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'nan %s',
		past: 'sa gen %s',
		s: 'kèk segond',
		m: 'yon minit',
		mm: '%d minit',
		h: 'yon èdtan',
		hh: '%d èdtan',
		d: 'yon jou',
		dd: '%d jou',
		M: 'yon mwa',
		MM: '%d mwa',
		y: 'yon ane',
		yy: '%d ane',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
