import { Injectable } from '@angular/core';
import { ImageChartEntry } from './common';
import { AssessmentScores } from '../assessment-scores.service';
import { Domain } from '@services/public';

@Injectable({
	providedIn: 'root',
})
export class SelfAwarenessService {
	constructor() {}

	generateReport(
		scores: AssessmentScores,
		domain: Domain,
	): Array<ImageChartEntry> {
		// Reset reports.
		const report: Array<ImageChartEntry> = [];

		// Filter to the most recent self data.
		const compiledAssessmentScores = {};
		scores.self.forEach(({ userUUID, skillUID, date, score }) => {
			if (!compiledAssessmentScores[skillUID])
				compiledAssessmentScores[skillUID] = {};
			if (!compiledAssessmentScores[skillUID][userUUID])
				compiledAssessmentScores[skillUID][userUUID] = {};
			if (!compiledAssessmentScores[skillUID][userUUID].self)
				compiledAssessmentScores[skillUID][userUUID].self = { date, score };
			else if (
				new Date(date) >
				new Date(compiledAssessmentScores[skillUID][userUUID].self.date)
			)
				compiledAssessmentScores[skillUID][userUUID].self = { date, score };
		});

		// Sort the historical peer data.
		scores.peer.forEach(({ userUUID, skillUID, score }) => {
			if (!compiledAssessmentScores[skillUID])
				compiledAssessmentScores[skillUID] = {};
			if (!compiledAssessmentScores[skillUID][userUUID])
				compiledAssessmentScores[skillUID][userUUID] = {};
			if (!compiledAssessmentScores[skillUID][userUUID].peer)
				compiledAssessmentScores[skillUID][userUUID].peer = [score];
			else compiledAssessmentScores[skillUID][userUUID].peer.push(score);
		});

		// Calculate score differences.
		for (const skillUID in compiledAssessmentScores) {
			compiledAssessmentScores[skillUID].differences = [];
			for (const userUUID in compiledAssessmentScores[skillUID]) {
				if (userUUID !== 'differences') {
					if (
						!!compiledAssessmentScores[skillUID][userUUID].self &&
						!!compiledAssessmentScores[skillUID][userUUID].peer
					)
						compiledAssessmentScores[skillUID].differences.push(
							Math.abs(
								compiledAssessmentScores[skillUID][userUUID].peer.reduce(
									(a, b) => a + b,
									0,
								) /
									compiledAssessmentScores[skillUID][userUUID].peer.length -
									compiledAssessmentScores[skillUID][userUUID].self.score,
							),
						);
				}
			}
		}

		// Load self awareness data.
		for (const skill of domain.skills) {
			const skillUID = skill.uid.toString();
			if (
				!!compiledAssessmentScores[skillUID] &&
				!!compiledAssessmentScores[skillUID].differences
			) {
				const difference =
					compiledAssessmentScores[skillUID].differences.reduce(
						(a, b) => a + b,
						0,
					) / compiledAssessmentScores[skillUID].differences.length;
				if (difference <= 0.25)
					report.push({ name: skill.name, image: 'gauge-green.svg' });
				else if (difference <= 0.5)
					report.push({ name: skill.name, image: 'gauge-purpul.svg' });
				else report.push({ name: skill.name, image: 'gauge-org.svg' });
			} else {
				report.push({ name: skill.name, image: 'gauge-gray.svg' });
			}
		}
		return report;
	}
}
