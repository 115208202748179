// Ilocano
import { LocaleData } from 'ngx-bootstrap/chronos';

export const iloLocale: LocaleData = {
	months: [
		'Enero',
		'Pebrero',
		'Marso',
		'Abril',
		'Mayo',
		'Hunyo',
		'Hulyo',
		'Agosto',
		'Setyembre',
		'Oktubre',
		'Nobyembre',
		'Disyembre',
	],
	monthsShort: [
		'Ene',
		'Peb',
		'Mar',
		'Abr',
		'May',
		'Hun',
		'Hul',
		'Ago',
		'Set',
		'Okt',
		'Nob',
		'Dis',
	],
	weekdays: [
		'Domingo',
		'Lunes',
		'Martes',
		'Miyerkules',
		'Huebes',
		'Biernes',
		'Sabado',
	],
	weekdaysShort: ['Dom', 'Lun', 'Mar', 'Miy', 'Hue', 'Bie', 'Sab'],
	weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Hu', 'Bi', 'Sa'],
	longDateFormat: {
		L: 'MM/DD/YYYY',
		LL: 'MMMM D, YYYY',
		LLL: 'MMMM D, YYYY LT',
		LLLL: 'dddd, MMMM D, YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Ita ti] LT',
		nextDay: '[Intuno bigat ti] LT',
		nextWeek: 'dddd [ti] LT',
		lastDay: '[Idi kalman ti] LT',
		lastWeek: '[Idi] dddd [ti] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'iti %s',
		past: '%s kalpas',
		s: 'segundo',
		m: 'maysa nga minuto',
		mm: '%d minutos',
		h: 'maysa nga oras',
		hh: '%d oras',
		d: 'maysa nga aldaw',
		dd: '%d nga aldaw',
		M: 'maysa nga bulan',
		MM: '%d nga bulan',
		y: 'maysa nga tawen',
		yy: '%d nga tawen',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
