import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import {
	LoaderService,
	DomainService,
	LanguageService,
} from '@services/public';
import {
	AssessmentService,
	AssessmentStatus,
	ExternalAssessment,
	ExternalScore,
} from '@services/member';

@Component({
	selector: 'app-member-assessment-external-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberAssessmentExternalReportComponent implements OnInit {
	assessments: Array<ExternalAssessment> = [];
	selectedAssessment: ExternalAssessment = null;

	allDomainsAndSkills: any = {};
	domainScores: Array<ExternalScore> = [];
	skillScores: Array<ExternalScore> = [];

	// Language Translation
	pageLanguage: any;
	appLabels: any;
	basePath = `pages.member.assessment`;

	constructor(
		public domainSvc: DomainService,
		private assessmentSvc: AssessmentService,
		private route: ActivatedRoute,
		private router: Router,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Load Language
		const pageLanguagePath = this.basePath + `.external.pages`;
		this._languageSvc.get([pageLanguagePath]).then((value) => {
			this.pageLanguage = value[pageLanguagePath];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.appLabels = value[`labels`];
		});
		// Load assessments.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.getExternals()
			.pipe(
				map((response: any) => {
					if (!!response)
						this.assessments = response.filter(
							(assessment) => assessment.status === AssessmentStatus.COMPLETED,
						);
					this.selectedAssessment = this.assessments.find(
						(assessment) =>
							assessment.uuid === this.route.snapshot.paramMap.get('uuid'),
					);
					this.loadReports();
					this.loaderSvc.removeLoader(loader);
				}).bind(this),
			)
			.subscribe();

		// Load domains and skills.
		this.domainSvc.getAll().then((response) => {
			this.allDomainsAndSkills = response;
		});
	}

	loadReports() {
		// Reset reports.
		this.domainScores = [];
		this.skillScores = [];

		// Load reports.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.getExternal(this.selectedAssessment?.uuid)
			.pipe(
				map((response: any) => {
					if (!!response) {
						// Fill default skills.
						this.skillScores = response.skills.map((skillUID) => {
							return {
								domainUID: Number(String(skillUID.uid)[0]),
								uid: skillUID.uid,
								color: 'assets/img/graph-insufficient1.svg',
							};
						});
						this.skillScores.sort((a, b) => a.uid - b.uid);

						// Fill default domains.
						this.domainScores = this.skillScores.reduce<Array<ExternalScore>>(
							(a, b) => {
								if (
									a.findIndex((skill) => skill.domainUID === b.domainUID) === -1
								)
									a.push({
										domainUID: b.domainUID,
										uid: b.uid,
										color: 'assets/img/graph-insufficient1.svg', //grey
									});
								return a;
							},
							[],
						);

						// Update skill and domain colors based on score.
						if (!!response.skills) {
							let i = 1;
							const rawDomainScores = {};
							for (const score of response.skills) {
								if (!score.score) continue;
								if (score.score === 5)
									this.skillScores.find(
										(skill) => skill.uid === score.uid,
									).color = 'assets/img/graph-good.svg';
								else if (score.score === 4)
									this.skillScores.find(
										(skill) => skill.uid === score.uid,
									).color = 'assets/img/graph-ok.svg';
								else
									this.skillScores.find(
										(skill) => skill.uid === score.uid,
									).color = 'assets/img/graph-improvement.svg';
								if (!rawDomainScores[score.domainUID])
									rawDomainScores[score.uid] = [];
								rawDomainScores[score.uid].push(score.score);
							}
							for (const domainUID in rawDomainScores) {
								const score =
									rawDomainScores[domainUID].reduce((a, b) => a + b, 0) /
									rawDomainScores[domainUID].length;
								if (score > 4) {
									this.domainScores.forEach((domain) => {
										if (domain?.uid === +domainUID) {
											domain.color = 'assets/img/graph-good.svg'; //red  url: "assets/img/graph-improvement.svg";
										}
									});
								}
								//green
								else if (score > 3) {
									this.domainScores.forEach((domain) => {
										if (domain?.uid === +domainUID) {
											domain.color = 'assets/img/graph-ok.svg'; //red  url: "assets/img/graph-improvement.svg";
										}
									});
								}
								//yellow
								else {
									this.domainScores.forEach((domain) => {
										if (domain?.uid === +domainUID) {
											domain.color = 'assets/img/graph-improvement.svg'; //red  url: "assets/img/graph-improvement.svg";
										}
									});
								}
							}
						}
						this.loaderSvc.removeLoader(loader);
					}
				}).bind(this),
			)
			.subscribe();
	}

	onSearch() {
		if (this.selectedAssessment?.uuid) {
			this.router.navigate([
				'/member/assessment/external/report',
				this.selectedAssessment.uuid,
			]);
			this.loadReports();
		}
	}
}
