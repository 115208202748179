// Belarusian

import { LocaleData } from 'ngx-bootstrap/chronos';

export const beLocale: LocaleData = {
	months: [
		'Студзень',
		'Люты',
		'Март',
		'Красавік',
		'Травень',
		'Чэрвень',
		'Ліпень',
		'Жнівень',
		'Верасень',
		'Кастрычнік',
		'Лістапад',
		'Снежань',
	],
	monthsShort: [
		'Стд',
		'Лют',
		'Мрт',
		'Крс',
		'Трв',
		'Чрв',
		'Ліп',
		'Жнв',
		'Врс',
		'Ксч',
		'Лст',
		'Снж',
	],
	weekdays: [
		'Нядзеля',
		'Панядзелак',
		'Аўторак',
		'Серада',
		'Чацвер',
		'Пятніца',
		'Субота',
	],
	weekdaysShort: ['Няд', 'Пн', 'Аўт', 'Срд', 'Чцв', 'Пт', 'Сб'],
	weekdaysMin: ['Ня', 'Пн', 'Аў', 'Ср', 'Чц', 'Пт', 'Сб'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Сёння ў] LT',
		nextDay: '[Заўтра ў] LT',
		nextWeek: 'dddd [у] LT',
		lastDay: '[Учора ў] LT',
		lastWeek: '[У мінулую] dddd [у] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s пасля',
		past: '%s таму',
		s: 'нескалькі секунд',
		m: 'адна хвіліна',
		mm: '%d хвіліны',
		h: 'адзін гадзіну',
		hh: '%d гадзіны',
		d: 'адзін дзень',
		dd: '%d дні',
		M: 'адзін месяц',
		MM: '%d месяцы',
		y: 'адзін год',
		yy: '%d гады',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
