import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
} from '@capacitor/push-notifications';
import { AccountModalComponent } from '@pages/account/account-modal/account-modal.component';
import { MemberOnboardingService, SkillBuilderService } from '@services/member';
import {
	AuthService,
	Domain2Service,
	LanguageLoaderService,
	LanguageService,
	Role,
	TokenService,
} from '@services/public';
import { toVoidPromise } from '@util';
import { ExposedPromise } from 'jakapa-utilities';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, throwError } from 'rxjs';

const PAGE_PATH: string = 'pages.app.page';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	loaded: boolean = false;
	private _credentialsLoaded: ExposedPromise<void> = new ExposedPromise<void>();
	private _redirect: string;

	// Page langauge.
	page: { [key: string]: string } = {
		sso: '',
		ssoDeclined: '',
	};

	constructor(
		private _authSvc: AuthService,
		private _domainSvc: Domain2Service,
		// Initializes this service.
		public _languageLoaderSvc: LanguageLoaderService,
		private _languageSvc: LanguageService,
		private _modalSvc: BsModalService,
		private _onboardingSvc: MemberOnboardingService,
		private _routerSvc: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _toastSvc: ToastrService,
		private _tokenSvc: TokenService,
	) {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});
	}

	async ngOnInit(): Promise<void> {
		if (Capacitor.isPluginAvailable('PushNotifications')) {
			PushNotifications.addListener(
				'pushNotificationReceived',
				(notification: PushNotificationSchema) => {
					let navPath = notification.data.navigation;
					delete notification.data;
					delete notification.id;
					alert(JSON.stringify(notification.body));
					PushNotifications.removeAllDeliveredNotifications();
					window.location.href = navPath;
				},
			);

			PushNotifications.addListener(
				'pushNotificationActionPerformed',
				(notification: ActionPerformed) => {
					window.location.href = notification.notification.data.navigation;
					PushNotifications.removeAllDeliveredNotifications();
				},
			);
		}

		// Check for EdLink login.
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('code')) {
			this._toastSvc.info(this.page.sso, '', {
				enableHtml: true,
				closeButton: true,
			});
			const authPromise = this._authSvc
				.edlink(urlParams.get('code'), true)
				.pipe(
					map((response: any) => {
						this._saveAuth(response);
					}).bind(this),
				);
			authPromise.subscribe();
			await toVoidPromise(authPromise);
		} else if (urlParams.get('error') === 'declined_authorization') {
			this._toastSvc.error(this.page.ssoDeclined, '', {
				enableHtml: true,
				closeButton: true,
			});
		}

		// Load credentials.
		if (!!this._tokenSvc.refreshToken) {
			this._authSvc
				.refreshTokens()
				.pipe(
					catchError((err) => {
						this._credentialsLoaded.resolve();
						return throwError(() => err);
					}).bind(this),
					map((response: any) => {
						if (this._tokenSvc.login(response))
							this._authSvc.confirm().subscribe();
						this._credentialsLoaded.resolve();
					}).bind(this),
				)
				.subscribe();
		} else this._credentialsLoaded.resolve();

		// Load services.
		await Promise.all([
			this._credentialsLoaded,
			this._domainSvc.loaded,
			this._languageSvc.loadingLabels,
			this._skillBuilderSvc.loaded,
		]);

		if (this._redirect) {
			this._routerSvc.navigate([this._redirect]);
		}

		// Check onboarding.
		if (!!this._tokenSvc.accessToken) {
			await this._onboardingSvc.showOnboarding();
		}

		// Set loaded to true.
		this.loaded = true;
	}

	private _saveAuth(res): boolean {
		if (!this._tokenSvc.login(res)) return false;
		this._authSvc.confirm().subscribe();
		this._checkAccountRole();
		this._redirect = res.redirect;
		return true;
	}

	private _checkAccountRole() {
		this._tokenSvc.role.subscribe((role) => {
			if (role === Role.ACCOUNT) {
				// @ts-ignore
				if (application === 'jakapa.website') {
					this._modalSvc.show(AccountModalComponent, {
						class: 'modal-md modal-dialog-centered',
						backdrop: 'static',
						keyboard: false,
					});
				} else {
					this._toastSvc.error('Account inactive!');
				}
			} else {
				this._toastSvc.clear();
			}
		});
	}
}
