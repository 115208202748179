// Serbian
import { LocaleData } from 'ngx-bootstrap/chronos';

export const srLocale: LocaleData = {
	months: [
		'Јануар',
		'Фебруар',
		'Март',
		'Април',
		'Мај',
		'Јун',
		'Јул',
		'Август',
		'Септембар',
		'Октобар',
		'Новембар',
		'Децембар',
	],
	monthsShort: [
		'Јан',
		'Феб',
		'Мар',
		'Апр',
		'Мај',
		'Јун',
		'Јул',
		'Авг',
		'Сеп',
		'Окт',
		'Нов',
		'Дец',
	],
	weekdays: [
		'Недеља',
		'Понедељак',
		'Уторак',
		'Среда',
		'Четвртак',
		'Петак',
		'Субота',
	],
	weekdaysShort: ['Нед', 'Пон', 'Уто', 'Сре', 'Чет', 'Пет', 'Суб'],
	weekdaysMin: ['Не', 'По', 'Ут', 'Ср', 'Че', 'Пе', 'Су'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Данас] LT',
		nextDay: '[Сутра] LT',
		nextWeek: 'dddd [у] LT',
		lastDay: '[Јуче] LT',
		lastWeek: '[Прошле] dddd [у] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'за %s',
		past: 'пре %s',
		s: 'неколико секунди',
		m: 'један минут',
		mm: '%d минута',
		h: 'један сат',
		hh: '%d сата',
		d: 'један дан',
		dd: '%d дана',
		M: 'један месец',
		MM: '%d месеци',
		y: 'једна година',
		yy: '%d године',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1, // Monday is the first day of the week
		doy: 4, // The week that contains Jan 4 is the first week of the year
	},
};
