import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';

@Injectable({
	providedIn: 'root',
})
export class PublicCalendarDateFormatterService extends CalendarDateFormatter {
	public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'EEEEE', locale);
	}

	public monthViewTitle({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'MMMM y', locale);
	}

	public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'EEE', locale);
	}

	public dayViewHour({ date, locale }: DateFormatterParams): string {
		return formatDate(date, 'HH:mm', locale);
	}
}
