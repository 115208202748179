import { Injectable } from '@angular/core';
import { config } from 'environment';
import { Observable, Subject } from 'rxjs';

export enum STORAGE_KEYS {
	CLIENT_ID = 'client-id',
	DEVICE_TOKEN = 'device-token',
	LANGUAGE = 'language',
	REFRESH_TOKEN = 'refresh-token',
	TEST = 'test',
}

export const MAIN_KEY_PREFIX = 'main.';
export enum MAIN_KEYS {
	ALL = 'all',
	CLEAR = 'clear',
	ADVENTURE_CHOOSE = 'adventure-choose',
	BRAIN_AFFIRMATIONS = 'brain-affirmations',
	DECISION_AFFECTS = 'decision-affects',
	GOAL_ACTIONS = 'goal-actions',
	GOAL_SKILLS = 'goal-skills',
	JOURNAL_FREQUENCY = 'journal-frequency',
	JOURNAL_ENJOYMENT = 'journal-enjoyment',
	JOURNAL_SOCIAL = 'journal-social',
	JOURNAL_CONFIDENCE = 'journal-confidence',
	JOURNAL_EMOTIONS = 'journal-emotions',
	JOURNAL_SYMPTOMS = 'journal-symptoms',
	JOURNAL_THOUGHTS = 'journal-thoughts',
	REFLECTION_IMPACTS = 'reflection-impacts',
	STRESS_FEEL = 'stress-feel',
	STRESS_LOOK = 'stress-look',
	STRESS_IMPACT = 'stress-impact',
	STRESS_DESTRESS = 'stress-destress',
}

export const TEMP_KEY_PREFIX = 'temp.';
export enum TEMP_KEYS {
	ALL = 'all',
	CLEAR = 'clear',
	NAME = 'name',
	AVATAR = 'avatar',
	IS_GHOST = 'is-ghost',
	IS_FIRST_LOGIN = 'is-first-login',
	ABSTRACT = 'abstract',
	ADVENTURE = 'adventure',
	BRAIN = 'brain',
	DECISION = 'decision',
	GOAL = 'goal',
	JOURNAL = 'journal',
	MESSAGE = 'message',
	REFLECTION = 'reflection',
	STRESS = 'stress',
	WWYD = 'wwyd',
	LANGUAGE = 'language',
	IS_FIRST_SELF_ASSESSMENT = 'is-first-self-assessment',
	IS_REWARDS_ORIENTATION = 'is-rewards-orientation',
}

export enum VOLATILE_KEYS {
	DOMAIN_UID = 'domain-uid',
	GOAL_PLAN_UUID = 'goal-plan-uuid',
}

export interface Update {
	key: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private _userUUID: string | null = null;
	private _mainStorage: Storage = localStorage;
	private _tempStorage: Storage = sessionStorage;
	private _updates: Subject<Update> = new Subject<Update>();
	private _volatileStorage: { [key: string]: any } = {};

	constructor() {
		// Use session storage for main storage if necessary.
		try {
			localStorage.setItem(STORAGE_KEYS.TEST, STORAGE_KEYS.TEST);
			localStorage.removeItem(STORAGE_KEYS.TEST);
		} catch (_e) {
			this._mainStorage = sessionStorage;
		}

		// Event triggers only for main storage as long as no internal iframes are
		// used.
		addEventListener('storage', (event) => {
			if (!event.key)
				this._updates.next({
					key: MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR,
					value: null,
				});
			if (event.key !== MAIN_KEY_PREFIX + this._userUUID) return;
			const oldValue: { [key: string]: any } = event.oldValue
				? JSON.parse(event.oldValue)
				: {};
			const newValue: { [key: string]: any } = event.newValue
				? JSON.parse(event.newValue)
				: {};
			for (const key in newValue)
				if (!this._deepEqual(oldValue[key], newValue[key]))
					this._updates.next({
						key: MAIN_KEY_PREFIX + key,
						value: newValue[key],
					});
			for (const key in oldValue)
				if (typeof newValue[key] === 'undefined')
					this._updates.next({
						key: MAIN_KEY_PREFIX + key,
						value: newValue[key],
					});
		});
	}

	private _deepEqual(a: any, b: any): boolean {
		if (typeof a !== 'object') return a === b;
		if (Object.keys(a).length !== Object.keys(b).length) return false;
		for (const key in a) if (!this._deepEqual(a[key], b[key])) return false;
		return true;
	}

	get userUUID(): string | null {
		return this._userUUID;
	}

	set userUUID(uuid: string | null) {
		if (uuid === this._userUUID) return;
		if (this._userUUID) this.clearTempStorage();
		this._userUUID = uuid;
		this._updates.next({
			key: MAIN_KEY_PREFIX + MAIN_KEYS.ALL,
			value: this._getMainCache(),
		});
		this._updates.next({
			key: TEMP_KEY_PREFIX + TEMP_KEYS.ALL,
			value: this._getTempCache(),
		});
	}

	get updates(): Observable<Update> {
		return this._updates.asObservable();
	}

	private _getMainCache(): { [key: string]: any } {
		const mainJSON: string | null = this._mainStorage.getItem(
			MAIN_KEY_PREFIX + this._userUUID,
		);
		if (!mainJSON) return {};
		return JSON.parse(mainJSON);
	}

	getMainStorage(path: MAIN_KEYS): any {
		return this._getMainCache()[path];
	}

	setMainStorage(path: MAIN_KEYS, value: any): void {
		if (!this._userUUID) {
			if (!config.production)
				console.error(`Attempting to set main storage without a user.`, path);
			return;
		}
		const cache = this._getMainCache();
		if (value === null) delete cache[path];
		else cache[path] = value;
		if (Object.keys(cache).length === 0)
			this._mainStorage.removeItem(MAIN_KEY_PREFIX + this._userUUID);
		else
			this._mainStorage.setItem(
				MAIN_KEY_PREFIX + this._userUUID,
				JSON.stringify(cache),
			);
		this._updates.next({ key: MAIN_KEY_PREFIX + path, value });
	}

	clearMainStorage(): void {
		this._mainStorage.removeItem(MAIN_KEY_PREFIX + this._userUUID);
		this._updates.next({ key: MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR, value: null });
	}

	private _getTempCache(): { [key: string]: any } {
		const tempJSON: string | null = this._mainStorage.getItem(
			TEMP_KEY_PREFIX + this._userUUID,
		);
		if (!tempJSON) return {};
		return JSON.parse(tempJSON);
	}

	getTempStorage(path: TEMP_KEYS): any {
		return this._getTempCache()[path];
	}

	setTempStorage(path: TEMP_KEYS, value: any): void {
		if (!this._userUUID) {
			if (!config.production)
				console.error(`Attempting to set temp storage without a user.`, path);
			return;
		}
		const cache = this._getTempCache();
		if (value === null) delete cache[path];
		else cache[path] = value;
		if (Object.keys(cache).length === 0)
			this._mainStorage.removeItem(TEMP_KEY_PREFIX + this._userUUID);
		else
			this._mainStorage.setItem(
				TEMP_KEY_PREFIX + this._userUUID,
				JSON.stringify(cache),
			);
		this._updates.next({ key: TEMP_KEY_PREFIX + path, value });
	}

	clearTempStorage(): void {
		this._tempStorage.removeItem(TEMP_KEY_PREFIX + this._userUUID);
		this._updates.next({ key: TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR, value: null });
	}

	getVolatileStorage(path: VOLATILE_KEYS, clear: boolean = false): any {
		const value = this._volatileStorage[path];
		if (clear) delete this._volatileStorage[path];
		return value;
	}

	setVolatileStorage(path: VOLATILE_KEYS, value: any): void {
		if (value === null) delete this._volatileStorage[path];
		else this._volatileStorage[path] = value;
	}

	clearVolatileStorage(): void {
		this._volatileStorage = {};
	}
}
