import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	MessageData,
	MessageReportData,
} from '@pages/member/skill-builder/message/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MessageService {
	constructor(private http: HttpClient) {}

	createLog(data: MessageData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/message/log/create`,
			{
				message: data.message,
				values: data.values,
				name: data.name,
				goalPlanUUID: data.goalPlanUUID ?? null,
			},
		);
	}

	updateLog(data: MessageData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/message/log/update`,
			{
				logUUID: data.logUUID,
				name: data.name,
				impact: data.impact,
			},
		);
	}

	getReport(): Observable<MessageReportData | APINoData> {
		return this.http.get<MessageReportData | APINoData>(
			`${config.apiBase}member/challenge/message/report`,
		);
	}
}
