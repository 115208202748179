import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from 'environment';

export interface LeaderMetricsData {
	metadata: {
		totalItems: number;
		pageNumber: number;
		pageSize: number;
		totalPages: number;
		sortColumn: string;
		sortOrder: string;
	};
	links: {
		current: string;
		next: string;
		previous: string;
		first: string;
		last: string;
	};
	records: Array<LeaderMetricItem>;
}

export interface LeaderMetricItem {
	userUUID: string;
	name?: string;
	score: number;
	extraCredit: number;
}

@Injectable({
	providedIn: 'root',
})
export class LeaderRewardsService {
	constructor(private http: HttpClient) {}

	loadLeaderboardData(orgUUID?: string, userUUID?: string): Observable<any> {
		let optionalParameters: string = '';
		if (userUUID !== undefined && userUUID !== '') {
			optionalParameters += `?uuid=${userUUID}`;
		}
		if (orgUUID !== undefined && orgUUID !== '') {
			// check to see if we already have a userUUID
			// so we can switch out the ?/& in the url
			let paramSymbol = optionalParameters.length > 0 ? '&' : '?';
			optionalParameters += `${paramSymbol}organizationUUID=${orgUUID}`;
		}
		return this.http.get<any>(
			`${config.apiBase}member/reward/leaderboard/view${optionalParameters}`,
		);
	}

	loadMetricsData(
		startDate: string,
		groupUUID: string,
		currentPage?: number,
		sortOrder?: string,
		sortColumn?: string,
		isOrientation?: boolean,
	): Observable<LeaderMetricsData> {
		return this.http.get<any>(
			`${config.apiBase}leader/reward/challenge/list?startDate=${startDate}&groupUUID=${groupUUID}${currentPage == undefined ? '' : '&currentPage=' + currentPage}${sortOrder == undefined ? '' : '&sortOrder=' + sortOrder}${sortColumn == undefined ? '' : '&sortColumn=' + sortColumn}${isOrientation == undefined ? '' : '&isOrientation=' + isOrientation}`,
		);
	}
}
