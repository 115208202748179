import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RewardMetric, RewardsService } from '@services/member';
import { Domain2Service, LABELS, LanguageService } from '@services/public';
import { APIErrors, isAPIErrors } from '@util';
import { JDate } from 'jakapa-utilities';

const PAGE_PATH: string = 'pages.member.dashboard.rewards.metrics.page';
const DEFAULT_START_DATE: string = new JDate().addDays(-14).toString();
const DEFAULT_END_DATE: string = new JDate().addDays(7).toString();

@Component({
	selector: 'app-member-dashboard-rewards-metrics',
	templateUrl: './metrics.component.html',
	styleUrls: ['./metrics.component.scss'],
})
export class MemberDashboardRewardsMetricsComponent implements OnInit {
	metrics: Array<RewardMetric> = [];
	form: UntypedFormGroup;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		score: '',
		extraCredit: '',
		noMetrics: '',
	};
	labels: { [key: string]: string } = {
		[LABELS.ONBOARDING]: '',
		[LABELS.SELECT_DATE]: '',
		[LABELS.TO]: '',
	};

	constructor(
		private _domainSvc: Domain2Service,
		private _languageSvc: LanguageService,
		private _rewardSvc: RewardsService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
			}
		});
		this._languageSvc.getLabels(this.labels);

		// Initialize form.
		this.form = new UntypedFormGroup({ date: new UntypedFormControl(null) });

		// Load metrics.
		this._rewardSvc
			.loadMetricsData(DEFAULT_START_DATE, DEFAULT_END_DATE)
			.subscribe((res: Array<RewardMetric> | APIErrors) => {
				if (!isAPIErrors(res)) this._loadMetrics(res);
			});
	}

	private _loadMetrics(metrics: Array<RewardMetric>) {
		this.metrics = metrics.reverse().map((metric) => {
			if (metric.domainUID === 0) {
				metric.domain = this.labels[LABELS.ONBOARDING];
			} else {
				metric.domain = this._domainSvc.getDomain(metric.domainUID).name;
			}
			metric.class = 'metric';
			if (metric.score < 60) metric.class += ' incomplete';
			return metric;
		});
	}

	dateOnSelect(date: Date): void {
		this._rewardSvc
			.loadMetricsData(
				new JDate(date).addDays(-14).toString(),
				new JDate(date).addDays(7).toString(),
			)
			.subscribe((res: Array<RewardMetric> | APIErrors) => {
				if (!isAPIErrors(res)) this._loadMetrics(res);
			});
	}
}
