// Somali
import { LocaleData } from 'ngx-bootstrap/chronos';

export const soLocale: LocaleData = {
	months: [
		'Janaayo',
		'Febraayo',
		'Maarso',
		'Abriil',
		'May',
		'Juun',
		'Luuliyo',
		'Ogosto',
		'Sebtembar',
		'Oktoobar',
		'Nofembar',
		'Desembar',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mar',
		'Abr',
		'May',
		'Juun',
		'Luul',
		'Ogs',
		'Seb',
		'Okt',
		'Nof',
		'Des',
	],
	weekdays: [
		'Axad',
		'Isniin',
		'Talaado',
		'Arbaco',
		'Khamiis',
		'Jimco',
		'Sabti',
	],
	weekdaysShort: ['Axa', 'Isn', 'Tal', 'Arb', 'Kha', 'Jim', 'Sab'],
	weekdaysMin: ['Ax', 'Is', 'Ta', 'Ar', 'Kh', 'Ji', 'Sa'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Maanta] LT',
		nextDay: '[Beri] LT',
		nextWeek: 'dddd LT',
		lastDay: '[Shalay] LT',
		lastWeek: '[Todobaadkii hore] dddd LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s kadib',
		past: '%s kahor',
		s: 'ilbiriqsiyo',
		m: 'daqiiqad',
		mm: '%d daqiiqo',
		h: 'saacad',
		hh: '%d saacadood',
		d: 'maalin',
		dd: '%d maalmood',
		M: 'bil',
		MM: '%d bilood',
		y: 'sano',
		yy: '%d sano',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1, // Monday is the first day of the week
		doy: 4, // The week that contains Jan 4 is the first week of the year
	},
};
