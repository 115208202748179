import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	StressData,
	StressReportData,
} from '@pages/member/skill-builder/stress/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StressService {
	constructor(private http: HttpClient) {}

	createPlan(data: StressData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/stress/plan/create`,
			{
				feel: data.feel,
				look: data.look,
				brain: data.brain,
				destress: data.destress,
				goalPlanUUID: data.goalPlanUUID ?? null,
			},
		);
	}

	createLog(data: StressData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/stress/log/create`,
			{
				destress: data.destress,
				stress: data.stress,
				impact: data.impact,
				yesterday: data.yesterday ?? false,
				goalPlanUUID: data.goalPlanUUID ?? null,
			},
		);
	}

	getReport(): Observable<StressReportData | APINoData> {
		return this.http.get<StressReportData | APINoData>(
			`${config.apiBase}member/challenge/stress/report`,
		);
	}
}
