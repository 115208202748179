<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container px-0 w-sm-100 w-50">
		<h4 class="w-auto text-center mb-0 px-3">
			{{ page.title }}
		</h4>

		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
			[formGroup]="form"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center">
					<div class="col-12 mb-3">
						<textarea
							class="form-control txtarea"
							id="feedback"
							rows="3"
							placeholder="{{ page.placeholder }}"
							formControlName="userSummary"
							[maxlength]="maxChars"
							[ngClass]="{
								'is-invalid': submitted && form.controls.userSummary.errors,
							}"
						>
						</textarea>
						<div
							*ngIf="submitted && form.controls.userSummary.errors"
							class="invalid-feedback"
						>
							<div
								*ngIf="
									form.controls.userSummary.errors.required ||
									form.controls.userSummary.errors?.whitespace
								"
							>
								{{ page.error1 }}
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-12">
						<div class="card card-white card-white-sm mb-0">
							<div class="card-body">
								<h5>{{ page.scale }}</h5>
								<div class="cont">
									<div class="stars">
										<ngx-star-rating
											formControlName="rating"
											[ngClass]="{
												'is-invalid': submitted && form.controls.rating.errors,
											}"
											[id]="'rating'"
										></ngx-star-rating>
									</div>
								</div>
								<div class="col-12 px-3">
									<div class="dot-sm"></div>
									<div class="dotted-line">
										<i class="fas fa-chevron-right right-arrow"></i>
									</div>
									<div class="col-12 rating-label">
										<div class="lft">{{ page.bad }}</div>
										<div class="rgt">{{ page.great }}</div>
									</div>
								</div>
							</div>
						</div>
						<div
							*ngIf="submitted && form.controls.rating.errors"
							class="invalid-feedback"
						>
							<div *ngIf="form.controls.rating.errors.required">
								{{ page.error2 }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-inner-action">
			<button
				type="button"
				(click)="nextOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ page.next }}
			</button>
		</div>
	</div>
</div>
