<div class="card card-rounded">
	<div class="card-header card-header-with-ico">
		<div class="col-12">
			<div class="row">
				<div class="col-10 pl-0">
					{{ title }}
				</div>
				<div class="col-2 d-flex align-items-center justify-content-end">
					<div
						*ngIf="data.length > show || show !== showMin"
						class="circle-md d-flex align-items-center justify-content-center"
						(click)="toggleMore()"
					>
						<i
							class="{{
								show === showMin ? ICON_CLASSES.DOWN : ICON_CLASSES.UP
							}}"
						></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body">
		<ul class="list-bordered-btm">
			<li
				class="d-flex justify-content-between"
				*ngFor="let item of data | slice: 0 : show; let i = index"
			>
				{{ item.left }}
				<span class="skill-builder-list-right">
					{{ item.right }}
				</span>
			</li>
		</ul>
	</div>
	<div
		*ngIf="data.length > show || show !== showMin"
		class="btn-row py-4 d-flex align-items-center justify-content-center"
	>
		<button
			type="button"
			(click)="toggleMore()"
			class="btn btn-small btn-outline btn-outline-purple"
		>
			{{ show === showMin ? labels.viewMore : labels.viewLess }}
		</button>
	</div>
</div>
