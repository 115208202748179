import { Observable } from 'rxjs';

export const EMAIL_PATTERN = new RegExp(
	[
		/^/,

		// TODO: Accept quoted characters. Prevent double periods.
		// local-part (can't begin or end with '.'; 1-64 characters)
		/[\w!#$%&'*+/=?^`{|}~-](?:[\w!#$%&'*+/=?^`{|}~.-]{0,62}[\w!#$%&'*+/=?^`{|}~-])?/,

		// domain-part (can't be more than 255 characters)
		/@(?=.{3,255}$)/,

		// sub-domains (can't begin or end with '-'; 1-63 characters per sub-domain; separated by '.')
		/(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+/,

		// top-level domain (can't begin or end with '-'; 1-63 characters; cannot be all numbers)
		/(?=.*[a-zA-Z-])[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.?/,

		/$/,
	]
		.map((r) => r.source)
		.join(''),
);

export const PASSWORD_PATTERN = new RegExp(
	[
		/^/,
		/(?=.*[a-z])/, // includes at least 1 lower-case letter
		/(?=.*[A-Z])/, // includes at least 1 upper-case letter
		/(?=.*[0-9])/, // includes at least 1 number
		/(?=.*[^a-zA-Z0-9])/, // includes at least 1 symbol
		/.{8,32}/, // between 8 and 32 characters
		/$/,
	]
		.map((r) => r.source)
		.join(''),
);

export const NAME_PATTERN = /^[^<>.&]*$/; // Prevent HTML injection

export const NO_HTML_PATTERN =
	/^(?![^<]*%3C)(?![^<]*&lt)(?![^<]*&#)(?![^<]*\\x3c)(?![^<]*\\u003c)[^<]*$/i;

export const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}$/;

export function keyPressOnlyAlphabets(event: any): void {
	const reg = /^[a-zA-Z\s]*$/;
	const input = event.target.value + String.fromCharCode(event.charCode);
	if (!reg.test(input)) {
		event.preventDefault();
	}
}

export function keyPressNoHTML(event: any): void {
	if (
		!NO_HTML_PATTERN.test(
			event.target.value + String.fromCharCode(event.charCode),
		)
	)
		event.preventDefault();
}

export interface Filter<T> {
	key: string;
	value: T;
	options: Array<T>;
}

/**
 * Converts an observable to a promise so that it can be awaited.
 * @param {Observable<any>} observable The observable to await.
 * @returns {Promise<void>}
 *  A promise that resolves when the observable completes or errors.
 */
export async function toVoidPromise(
	observable: Observable<any>,
): Promise<void> {
	return new Promise((resolve) => {
		observable.subscribe({
			error(): void {
				resolve();
			},
			complete(): void {
				resolve();
			},
		});
	});
}
