<div class="col-12 border-btm-wht mb-4 pl-0">
	<div class="col-md-12 d-flex align-items-center filters pl-0">
		<div class="row mb-3 w-100">
			<div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
				<select
					[(ngModel)]="selectedOrganization"
					(change)="onOrganizationChange()"
				>
					<option
						*ngFor="let organization of organizations"
						[ngValue]="organization"
					>
						{{ organization.name }}
					</option>
				</select>
			</div>
			<span class="divider-rgt pl-2 mr-1 d-none d-md-flex"></span>

			<div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
				<select [(ngModel)]="selectedDomain" (change)="onDomainChange()">
					<option *ngFor="let domain of domains" [ngValue]="domain">
						{{ domain.name }}
					</option>
				</select>
			</div>

			<div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
				<select [(ngModel)]="selectedTimespan" (change)="loadReports()">
					<option value="all time">{{ page?.allTime }}</option>
					<option value="30 days">30 {{ page?.days }}</option>
					<option value="90 days">90 {{ page?.days }}</option>
					<option value="180 days">180 {{ page?.days }}</option>
					<option value="1 year">1 {{ page?.year }}</option>
					<option value="5 years">5 {{ page?.years }}</option>
				</select>
			</div>

			<div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
				<select
					class="select-w"
					[(ngModel)]="selectedGroup"
					(change)="onGroupChange()"
				>
					<option [ngValue]="null">{{ page?.allGroups }}</option>
					<option *ngFor="let group of filteredGroups" [ngValue]="group">
						<a class="{{ selectedGroup === group ? 'active' : '' }}">{{
							group.name
						}}</a>
					</option>
				</select>
			</div>

			<div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
				<select [(ngModel)]="selectedUser" (ngModelChange)="loadReports()">
					<option [ngValue]="null">{{ page?.allUsers }}</option>
					<option *ngFor="let user of filteredUsers" [ngValue]="user">
						{{ user.name }}
					</option>
				</select>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-12">
		<div class="row">
			<div class="col-xs-12 col-md-12 col-lg-8 mb-3 mb-xl-0">
				<div class="col-12 mb-3">
					<div
						class="boxMain card-white card-white-sm border-radius-md"
						[ngStyle]="{
							height: viewMoreDomainOverallScores ? 'auto' : '478px',
						}"
					>
						<div class="boxHead card-title-main">
							<h5>{{ page?.domainScores }}</h5>
							<div class="d-flex justify-content-end align-items-center">
								<span type="button" class="toggle-view mr-2 px-0 px-md-2">
									<button
										type="button"
										class="px-2"
										(click)="isPieChartVisible = true"
										[ngClass]="{ active: isPieChartVisible }"
									>
										<img src="assets/img/pie-chart-ico.svg" alt="pie" />
									</button>
									<span class="divider-wht"></span>
									<button
										type="button"
										class="px-2"
										(click)="isPieChartVisible = false"
										[ngClass]="{ active: !isPieChartVisible }"
									>
										<img src="assets/img/grid-ico.svg" alt="grid" />
									</button>
								</span>
								<span
									[ngbTooltip]="page?.scoreDistroTip"
									triggers="click"
									tooltipClass="my-custom-class"
									placement="left"
								>
									<span class="info-circle">
										<img src="assets/img/info-ico-card.svg" alt="info" />
									</span>
								</span>
							</div>
						</div>
						<div *ngIf="isPieChartVisible">
							<div
								class="chartArea {{
									!!domainOverallScores ? '' : 'd-none'
								}} donut-wrapper"
							>
								<ngx-charts-pie-chart
									[view]="[400, 280]"
									[scheme]="{
										domain: ['#FF8888', '#74A2FF', '#DE91FF', '#3CE986'],
									}"
									[results]="domainOverallScores"
									[legend]="true"
									[labels]="true"
									[doughnut]="true"
									[legendPosition]="'bottom'"
									[legendTitle]="''"
									[labelFormatting]="
										pieChartLabel.bind(this, domainOverallScores)
									"
									[animations]="false"
								>
								</ngx-charts-pie-chart>
							</div>
						</div>
						<ng-container *ngIf="!domainOverallScores">
							<div class="p-3">{{ page?.insufficientData }}</div>
						</ng-container>
						<div *ngIf="!isPieChartVisible && !!domainOverallScores">
							<div class="pt-4 d-flex scroll-area">
								<div class="col-12">
									<div class="row list-box">
										<div
											class="col-12 col-md-3 d-flex align-items-stretch"
											*ngFor="
												let column of domainOverallScores;
												let isLast = last
											"
										>
											<div class="card" [ngClass]="{ 'arrow-list': !isLast }">
												<div
													class="step-counter"
													[ngClass]="{
														'bg-org': column.name === '1-2',
														'bg-blue': column.name === '2-3',
														'bg-purple': column.name === '3-4',
														'bg-green': column.name === '4-5',
													}"
												>
													{{ column.name }}
												</div>
												<ul>
													<li *ngFor="let user of column.users">{{ user }}</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 mb-3">
					<div class="boxMain card-white card-white-sm border-radius-md">
						<div class="boxHead card-title-main position-sm">
							<h5>{{ page?.growth }}</h5>
							<div
								class="col-auto d-flex mt-2 mt-md-0 mb-2 mb-lg-0 align-items-center px-0"
							>
								<select
									[(ngModel)]="selectedGrowthDomain"
									(change)="onGrowthDomainChange()"
								>
									<option *ngFor="let domain of domains" [ngValue]="domain">
										{{ domain.name }}
									</option>
								</select>
								<span
									[ngbTooltip]="page?.growthTip"
									triggers="click"
									tooltipClass="my-custom-class"
									placement="left"
									class="pl-2"
								>
									<span class="info-circle">
										<img
											src="assets/img/info-ico-card.svg"
											alt="{{ page?.info }}"
										/>
									</span>
								</span>
							</div>
						</div>
						<div class="{{ !growth ? 'd-none' : '' }}">
							<div class="chartArea line-chart p-0">
								<canvas #growthCanvas></canvas>
							</div>
						</div>
						<ng-container *ngIf="!growth">
							<div class="p-3">{{ page?.insufficientData }}</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-12 col-lg-4">
				<div class="row">
					<div class="col-12 mb-3">
						<div class="boxMain card-white card-white-sm border-radius-md">
							<div class="boxHead card-title-main">
								<h5>{{ page?.strengthWeak }}</h5>
								<span
									[ngbTooltip]="page?.strengthWeakTip"
									triggers="click"
									tooltipClass="my-custom-class"
									placement="left"
								>
									<span class="info-circle">
										<img src="assets/img/info-ico-card.svg" alt="info" />
									</span>
								</span>
							</div>
							<div
								class="chartArea {{
									!strengthsAndWeaknesses?.length ? 'd-none' : ''
								}}"
							>
								<div class="strengthMain">
									<ng-container *ngIf="strengthsAndWeaknesses">
										<div
											*ngFor="let entry of strengthsAndWeaknesses"
											class="strengthRow list-bordered-btm-dotted"
										>
											<div
												class="strengthCol w-100 d-flex align-items-center justify-content-between"
											>
												<div class="list-item">
													{{ entry.name }}
												</div>
												<div class="strengthCol">
													<div class="list-img">
														<img
															src="assets/img/{{ entry.image }}"
															alt="graph"
														/>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
							<div
								*ngIf="!!strengthsAndWeaknesses?.length"
								class="chartBtmArea legend-btm col-12 d-inline-block"
							>
								<div class="row">
									<div class="chartBtmCol col-3">
										<img src="assets/img/graph-good.svg" alt="graph" />
										{{ page?.good }} &gt;4
									</div>
									<div class="chartBtmCol col-3">
										<img src="assets/img/graph-ok.svg" alt="graph" />
										{{ page?.ok }} &gt;3-4
									</div>
									<div class="chartBtmCol col-3">
										<img src="assets/img/graph-insufficient.svg" alt="graph" />
										{{ page?.improvement }} &lt;=3
									</div>
									<div class="chartBtmCol col-3">
										<div>
											<img
												src="assets/img/graph-insufficient1.svg"
												alt="graph"
											/>
										</div>
										<div>{{ page?.insufficientData }}</div>
									</div>
								</div>
							</div>
							<ng-container *ngIf="!strengthsAndWeaknesses?.length">
								<div class="p-3">{{ page?.insufficientData }}</div>
							</ng-container>
						</div>
					</div>
					<!-- <div class="col-12 mb-3">
            <div class="boxMain card-white card-white-sm border-radius-md">
              <div class="boxHead card-title-main">
                <h5>{{page?.selfAwareness}}</h5>
                <span [ngbTooltip]="
                  page?.selfAwareTip
                " triggers="click" tooltipClass="my-custom-class" placement="left">
                  <span class="info-circle">
                    <img src="assets/img/info-ico-card.svg" alt="{{page?.info}}" />
                  </span>
                </span>
              </div>
              <div class="chartArea padding0" *ngIf="selfAwareness?.length">
                <div class="pt-4 strengthRow list-bordered-btm-dotted">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-4 mt-2" *ngFor="let entry of selfAwareness">
                        <div class="text-center">
                          <img src="assets/img/{{entry.image}}" alt="graph" />
                        </div>
                        <div class="text-center text-gray-lgt">
                          {{ entry.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chartBtmArea btn-row-chart">
                <div class="chartBtmCol">
                  <span class="no-entry"></span>
                  {{page?.insufficientData}}
                </div>
                <div class="chartBtmCol">
                  <span class="bad"></span>
                  {{page?.notAlign}} <br />(> +/-0.5)
                </div>
                <div class="chartBtmCol">
                  <span class="ok"></span>
                  {{page?.partialAlign}} <br />(+/-0.25 to +/-0.5)
                </div>
                <div class="chartBtmCol">
                  <span class="good"></span>
                  {{page?.align}} <br />
                  (< +/-0.25)
                </div>
              </div>
            </div>
          </div>
        </div> -->
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-md-12 col-lg-6 mb-3 mb-xl-0">
					<app-bar-chart
						title="{{ page?.recentSelf }}"
						view="{{ page.viewAll }}"
						[filters]="filtersForRecentSelf"
						info="{{ page?.recentSelf }}"
						[getChartConfiguration]="getRecentSelfChartConfiguration.bind(this)"
						[refresh]="refreshForRecentSelf"
						[isViewAll]="viewAllForRecentSelf"
					>
					</app-bar-chart>
				</div>
				<div class="col-xs-12 col-sm-12 col-lg-6">
					<app-bar-chart
						title="{{ page?.selfVPeer }}"
						view="{{ page.viewAll }}"
						[filters]="filtersForSelfVsPeer"
						info="{{ page?.selfVPeerTip }}"
						[getChartConfiguration]="getSelfVsPeerChartConfiguration.bind(this)"
						[refresh]="refreshForSelfVsPeer"
						[isViewAll]="viewAllForSelfVsPeer"
					>
					</app-bar-chart>
				</div>
			</div>
			<div class="row styled">
				<div class="card-white card-white-sm border-radius-md">
					<div
						class="col-12 d-flex justify-content-start card-title-main border-0 px-3 pt-4"
					>
						<h5>{{ page?.assessmentHistory }}</h5>
					</div>
					<div>
						<div class="col-md-12 col-lg-12">
							<div class="row mb-4 mt-4">
								<div class="col-xs-12 col-sm-12 col-md-12">
									<div
										class="boxMain card-white card-white-sm border-radius-md"
									>
										<div id="no-more-tables">
											<table class="table table-c">
												<thead class="thead-light">
													<tr>
														<th>{{ page?.name }}</th>
														<th>{{ page?.selfAssess }}</th>
														<th>{{ page?.peerTaken }}</th>
														<th>{{ page?.externalAssess }}</th>
													</tr>
												</thead>
												<tbody>
													<ng-container *ngIf="data?.length > 0">
														<tr
															*ngFor="
																let data of data
																	| paginate
																		: { itemsPerPage: 10, currentPage: p }
															"
														>
															<td data-title="{{ page?.name }}">
																{{ data.genericName }}
															</td>
															<td
																data-title="{{ page?.selfAssess }}"
																class="text-capitalize"
															>
																{{ data.selfCompleted }}
															</td>
															<td data-title="{{ page?.peerAssess }}">
																{{ data.peerCompleted }}
															</td>
															<td data-title="{{ page?.externalAssess }}">
																{{ data.externalCompleted }}
															</td>
														</tr>
													</ng-container>
													<ng-container *ngIf="data?.length === 0">
														<tr
															*ngFor="
																let data of data
																	| paginate
																		: { itemsPerPage: 5, currentPage: '1' }
															"
														></tr>
														<td class="text-center pl-0-c" [attr.colspan]="6">
															{{ page?.noData }}
														</td>
													</ng-container>
												</tbody>
											</table>
											<div style="float: right" class="w-sm-100">
												<pagination-controls
													previousLabel=""
													nextLabel=""
													class="my-pagination"
													(pageChange)="p = $event"
													directionLinks="true"
												>
												</pagination-controls>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
