import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	JournalData,
	JournalReportData,
} from '@pages/member/skill-builder/journal/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class JournalService {
	constructor(private http: HttpClient) {}

	createLog(data: JournalData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/journal/log/create`,
			{
				activity: data.highlight,
				frequency: data.frequency,
				enjoyment: data.enjoyment,
				social: data.social,
				confidence: data.confidence,
				emotions: data.emotions,
				symptoms: data.symptoms,
				thoughts: data.thoughts,
				userSummary: data.userSummary,
				generatedSummary: data.generatedSummary,
				accuracy: data.accuracy,
				yesterday: data.yesterday ?? false,
				goalPlanUUID: data.goalPlanUUID ?? null,
			},
		);
	}

	getReport(): Observable<Array<JournalReportData> | APINoData> {
		return this.http.get<Array<JournalReportData> | APINoData>(
			`${config.apiBase}member/challenge/journal/report`,
		);
	}
}
