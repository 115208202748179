<div class="modal-body px-0">
	<div class="modal-header px-0 pr-4 pt-0 pt-md-3 pb-0 pb-md-3">
		<h4 class="modal-title d-flex align-items-center justify-content-center">
			<label class="lbl-md pl-3 mb-0">{{ pageLanguage?.title }}</label>
		</h4>
		<button
			type="button"
			id="first-login"
			class="btn-close close pull-right"
			aria-label="Close"
			(click)="modalRef.hide()"
		>
			<span aria-hidden="true" class="visually-hidden">{{
				pageLanguage?.close
			}}</span>
		</button>
	</div>
	<div class="col-12 my-4">
		<p>
			{{ pageLanguage?.message1 }}<br />
			{{ pageLanguage?.message2 }}
		</p>
	</div>
	<div
		class="pt-0 pt-md-3 d-flex d-lg-none align-items-center justify-content-center"
	>
		<button
			(click)="modalRef.hide()"
			type="button"
			class="btn btn-md btn-primary"
		>
			{{ pageLanguage?.close }}
		</button>
	</div>
</div>
