import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { GOAL_PAGE, GoalData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';

const PAGE_PATH: string = 'skillBuilders.goal.pages.yesterday';

@Component({
	selector: 'app-member-skill-builder-goal-yesterday',
	templateUrl: './yesterday.component.html',
	styleUrls: ['./yesterday.component.scss'],
})
export class MemberSkillBuilderGoalYesterdayComponent implements OnInit {
	@Input() data: GoalData;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
		yesterday: '',
		today: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		await this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});

		// Get the options list.
		this.options.next([
			{
				display: this.page.yesterday,
				value: true,
			},
			{
				display: this.page.today,
				value: false,
			},
		]);
	}

	nextOnClick(option: SelectListOption): void {
		this._storageSvc.setTempStorage(TEMP_KEYS.GOAL, {
			...this.data,
			page: GOAL_PAGE.TRACK,
			yesterday: option.value,
		});
	}
}
