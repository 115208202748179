<div class="modal-header">
	<h5 class="modal-title mb-0" id="exampleModalLongTitle">
		{{ pageLanguage?.title }}
	</h5>
	<button
		#closebutton
		type="button"
		class="close"
		data-dismiss="modal"
		(click)="closeModal()"
		[attr.aria-label]="labels?.close"
	>
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="col-12 mb-4">
			<label for="groupCode">{{ pageLanguage?.groupCode }}</label>
			<input
				id="groupCode"
				formControlName="groupCode"
				maxlength="40"
				type="text"
				placeholder="{{ pageLanguage?.groupAccessCode }}"
				class="form-control"
				[ngClass]="{
					'danger-class': f.groupCode.errors && f.groupCode.touched,
				}"
			/>
			<div
				*ngIf="f.groupCode.errors && f.groupCode.touched"
				class="invalid-feedback"
			>
				<div *ngIf="f.groupCode.errors.required">
					{{ pageLanguage?.accessCodeRequired }}
				</div>
				<div
					*ngIf="
						!f.groupCode.errors.required &&
						f.groupCode.errors.maxlength?.actualLength > 40
					"
				>
					{{ pageLanguage?.accessCodeLength }}
				</div>
			</div>
		</div>
		<div
			class="d-flex justify-content-center align-items-center modal-footer bottomBtnsModal border-top mt-3 pt-3"
		>
			<button class="btn btn-primary">{{ labels?.submit }}</button>
			<button
				type="button"
				class="cancel btn btn-md"
				data-dismiss="modal"
				[attr.aria-label]="labels?.close"
				(click)="closeModal()"
			>
				{{ labels?.cancel }}
			</button>
		</div>
	</form>
</div>
