import { Component, Input, OnInit } from '@angular/core';
import { DecisionData } from '../common';
import { SelectListNext, SelectListOption } from '@components';
import { ReplaySubject } from 'rxjs';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { DecisionService } from '@services/member';
import { Router } from '@angular/router';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.decision.pages.rightChoiceNo';

@Component({
	selector: 'app-member-skill-builder-decision-right-choice-no',
	templateUrl: './right-choice-no.component.html',
	styleUrls: ['./right-choice-no.component.scss'],
})
export class MemberSkillBuilderDecisionRightChoiceNoComponent
	implements OnInit
{
	@Input() data: DecisionData;
	subTitle: string = '';
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitleNo: '',
		subTitleNotSure: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _decisionSvc: DecisionService,
		private _router: Router,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				switch (key) {
					case 'subTitleNo':
						this._languageSvc.template(this.page[key]).then((value) => {
							this.page[key] = value;
							if (this.data?.rightChoice === 'no') this.subTitle = value;
						});
						break;
					case 'subTitleNotSure':
						this._languageSvc.template(this.page[key]).then((value) => {
							this.page[key] = value;
							if (this.data?.rightChoice === 'not-sure') this.subTitle = value;
						});
						break;
					default:
						this._languageSvc
							.template(this.page[key])
							.then((value) => (this.page[key] = value));
				}
			}
		});

		// Get options list.
		const path: string = 'skillBuilders.decision.reasons';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] === 'object' && value[path] !== null)
				this.options.next(Object.values(value[path]));
			else this.options.next([]);
		});
	}

	nextOnClick(option: SelectListOption): void {
		this.data.reason = option.value;
		this._decisionSvc
			.updateLog(this.data)
			.subscribe((res: APISuccess | APIErrors) => {
				if (isAPISuccess(res)) {
					this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, null);
					this._router.navigate(['member/dashboard']);
				}
			});
	}
}
