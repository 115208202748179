<div class="col-12 leader-rewards">
	<div class="row d-flex filters">
		<div class="col-sm-12 col-md-3 mb-3 mb-md-0">
			<select [(ngModel)]="selectedOrg" (change)="onSelectOrganization()">
				<option
					*ngFor="let organization of organizations"
					[value]="organization.uuid"
				>
					{{ organization.name }}
				</option>
			</select>
		</div>
		<div class="col-sm-12 col-md-3 mb-3 mb-md-0">
			<select [(ngModel)]="groupFilter.value" (change)="onSelectGroup()">
				<option *ngFor="let group of groupFilter.options" [ngValue]="group">
					{{ group.name }}
				</option>
			</select>
		</div>
	</div>
	<div class="row d-flex mt-3">
		<div
			class="card-rewards col-lg-4 col-sm-12 pb-4 border-0 height-auto rm-padd mb-3 mb-lg-0"
		>
			<div class="select-wrp mt-1">
				<div class="left-title pb-2">{{ page?.leaderboard }}</div>
			</div>
			<div
				class="d-flex justify-content-between w-75 w-80 mt-4 mb-4 align-items-end user-leaderboard"
			>
				<div
					*ngFor="let top3item of top3Leaderboard"
					class="text-center top3item {{
						top3item.class ? top3item.class : ''
					}}"
					[ngStyle]="{
						width:
							'calc(' +
							(top3item.size ? top3item.size + 'px' : '45px') +
							' + 30px)',
					}"
				>
					<div class="user-ranking">
						<span>{{ top3item.rank }}</span>
						<img
							*ngIf="!top3item.avatar"
							class="user-icon"
							src="../../../../../assets/img/2.svg"
							alt="user icon"
						/>
						<div
							*ngIf="top3item.avatar"
							[ngStyle]="getAvatarStyle(top3item.avatar, top3item.size)"
							[ngClass]="top3item.size ? 'profileImg' : ''"
							class="clipped-svg"
							alt="user-icon"
						></div>
					</div>
					<div class="d-flex pt-2 justify-content-center">
						<div class="user-name font-weight-bold">
							<span *ngIf="top3item.name == '' || top3item.name == null">{{
								top3item.genericName
							}}</span>
							<span *ngIf="top3item.name !== ''">{{ top3item.name }}</span>
							<div class="user-points pt-1">{{ top3item.points }}p</div>
						</div>
					</div>
				</div>
			</div>
			<div class="user-list pb-2">
				<div *ngFor="let lower5item of lower5Leaderboard">
					<div *ngIf="lower5item.idx" class="pinline">
						<span
							><i style="font-size: 1rem; color: #ccc" class="fa"
								>&#xf103;</i
							></span
						>
					</div>
					<div
						class="grey-card d-flex align-items-start justify-content-between mb-3 {{
							lower5item.class ? lower5item.class : ''
						}}"
					>
						<div class="text-right statsRight px-3 pt-2">
							<div class="user-count d-flex">{{ ' ' + lower5item.rank }}</div>
						</div>
						<div class="d-flex">
							<div class="bottom-avatars">
								<img
									*ngIf="!lower5item.avatar"
									src="../../../../assets/img/2.svg"
									alt="user-icon"
								/>
								<div
									*ngIf="lower5item.avatar"
									[ngStyle]="getAvatarStyle(lower5item.avatar, 55)"
									class="clipped-svg lower"
									alt="user-icon"
								></div>
							</div>
							<div class="pl-2">
								<div class="user-name">
									<span
										*ngIf="lower5item.name == '' || lower5item.name == null"
										>{{ lower5item.genericName }}</span
									>
									<span *ngIf="lower5item.name !== ''">{{
										lower5item.name
									}}</span>
								</div>
								<div class="week-action">
									{{ page?.streakDays }} <span>{{ lower5item.streak }}</span>
								</div>
								<div class="week-action">
									{{ page?.avgChallengeScore }}
									<span>{{ lower5item.score }}%</span>
								</div>
							</div>
						</div>
						<div class="text-right pr-4">
							<div class="user-points">
								{{ lower5item.points }}{{ page?.points | slice: 0 : 1 }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-8 col-sm-12 metrics">
			<div class="boxMain card-white ml-lg-3 p-3">
				<div class="row d-flex flex-row list dateSelect">
					<div class="m-3">
						{{ page?.metricsWeek }}
					</div>
					<div class="custom-control custom-switch m-3">
						<input
							type="checkbox"
							class="custom-control-input"
							id="orientationSwitch"
							(change)="onOrientationChange($event.target.checked)"
						/>
						<label class="custom-control-label" for="orientationSwitch">{{
							page?.orientation
						}}</label>
					</div>
					<div
						class="form-group col-sm-12 col-md-4 p-0 ml-md-auto mr-3 ml-3 mt-0 mt-md-3"
					>
						<select
							(change)="onSortChange($event.target.value)"
							class="p-0 w-100"
						>
							<option value="scoreHighToLow">{{ page?.scoreHighToLow }}</option>
							<option value="scoreLowToHigh">{{ page?.scoreLowToHigh }}</option>
							<option value="extraCreditHighToLow">
								{{ page?.extraCreditHighToLow }}
							</option>
							<option value="extraCreditLowToHigh">
								{{ page?.extraCreditLowToHigh }}
							</option>
							<option value="name">{{ page?.name }}</option>
						</select>
					</div>
					<div class="form-group mt-0 ml-3 m-md-3" [formGroup]="form">
						<label for="" class="dateLabel">{{ page?.startDate }}</label>
						<input
							id="startDate"
							type="text"
							formControlName="startDate"
							#startDateField
							[ngClass]="{
								'danger-class': f.startDate.errors && f.startDate.touched,
							}"
							(ngModelChange)="updateMetricsDate(startDateField)"
							placeholder="{{ page?.startDate }}"
							class="input"
							bsDatepicker
							[daysDisabled]="[0, 2, 3, 4, 5, 6, 7]"
							[bsConfig]="{ showWeekNumbers: false }"
							placement="bottom"
							readonly
						/>
					</div>
				</div>
				<div class="col-12 mt-3 px-2">
					<div class="row d-flex flex-column list">
						<ng-container *ngIf="metricsItems.length > 0">
							<table>
								<tr>
									<th scope="col" class="col-5">{{ page?.name }}</th>
									<th scope="col" class="col-4">
										{{ page?.weeklyChallengeScore }}
									</th>
									<th scope="col" class="col-3">{{ page?.extraCredit }}</th>
								</tr>

								<tr *ngFor="let item of metricsItems">
									<td
										class="col-6"
										(click)="goToUserLeaderboard(item.userUUID)"
									>
										{{ item.name }}
									</td>
									<td class="col-3">{{ item.score }}</td>
									<td class="col-3">{{ item.extraCredit }}</td>
								</tr>
							</table>
							<ng-container *ngIf="metricsData.metadata.totalPages > 1">
								<div class="pagination d-flex">
									<button
										[disabled]="
											this.metricsData.links.previous == null ? true : false
										"
										(click)="pageBtnClick('prev')"
									>
										<span class="sr-text">{{ page?.previousPage }}</span>
										<span aria-hidden="true"><</span>
									</button>

									<ng-container *ngFor="let item of pagination">
										<button
											[disabled]="item.disabled"
											class="page {{
												this.currentPage == item.pageNum ? 'current' : ''
											}}"
											(click)="pageBtnClick(item.destination)"
										>
											<span class="sr-text">{{ page?.viewPage }}</span>
											{{ item.pageNum }}
											<span class="sr-text"
												>{{ page?.outOf }}
												{{ this.metricsData.metadata.totalPages }}</span
											>
										</button>
									</ng-container>

									<button
										[disabled]="
											this.metricsData.links.next == null ? true : false
										"
										(click)="pageBtnClick('next')"
									>
										<span class="sr-text">{{ page?.previousPage }}</span>
										<span aria-hidden="true">></span>
									</button>
								</div>
							</ng-container>
						</ng-container>
						<ng-container
							*ngIf="metricsItems.length == 0 || metricsItems == undefined"
						>
							<div class="col-12 d-flex">
								<div class="col-6">{{ page?.noUserInGroup }}</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
