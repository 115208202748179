import { Component, Input, OnInit } from '@angular/core';
import {
	CalendarColors,
	CalendarDate,
	CalendarEvent,
	CalendarLegend,
	ListItem,
	PieChartData,
} from '@components';
import { BrainService } from '@services/member';
import { LanguageService } from '@services/public';
import { APINoData, isAPINoData } from '@util';
import { BrainReportData } from '../common';
import { ExposedPromise, JDate } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.brain.pages.report';
const SCORE_CALENDAR_COLORS: { [key: number]: CalendarColors } = {
	1: CalendarColors.VERY_BAD,
	2: CalendarColors.VERY_BAD,
	3: CalendarColors.OKAY,
	4: CalendarColors.GOOD,
	5: CalendarColors.GOOD,
};

@Component({
	selector: 'app-member-skill-builder-brain-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderBrainReportComponent implements OnInit {
	@Input() noData: () => void;
	display: boolean = false;
	isPie: boolean = false;
	topPositiveThoughtsList: Array<ListItem> = [];
	positiveThoughtsList: Array<ListItem> = [];
	calendarEvents: Map<CalendarDate, CalendarEvent> = new Map<
		CalendarDate,
		CalendarEvent
	>();
	calendarLegend: Array<CalendarLegend> = [];
	pieData: Array<PieChartData> = [];
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		titleTopPositiveThoughts: '',
		titlePositiveThoughts: '',
		calendarTitle: '',
		bad: '',
		ok: '',
		good: '',
		pieTitle: '',
		pieChartTitle: '',
		bigImpact: '',
		littleImpact: '',
		noImpact: '',
	};

	constructor(
		private _brainSvc: BrainService,
		private _languageSvc: LanguageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<any>> = [];
			for (const key in this.page) {
				switch (key) {
					case 'pieTitle':
						break;
					default:
						promises.push(
							this._languageSvc
								.template(this.page[key])
								.then((value) => (this.page[key] = value)),
						);
				}
			}
			Promise.all(promises).then(() => this._languageLoaded.resolve());
		});

		await this._languageLoaded;

		this._brainSvc.getReport().subscribe((res: BrainReportData | APINoData) => {
			if (!isAPINoData(res)) {
				this.display = true;
				this.topPositiveThoughtsList = res.thoughts.map((thought) => {
					return {
						left: thought.thought,
						right: `${thought.percent}%`,
					};
				});
				this.positiveThoughtsList = res.logs.reverse().map((log) => {
					const days: number = JDate.compareDays(
						new JDate(log.date),
						new JDate(),
					);
					return {
						left: log.diary,
						right: `${days} Day${days === 1 ? '' : 's'} Ago`,
					};
				});
				for (const log of res.logs)
					this.calendarEvents.set(log.date, {
						color: SCORE_CALENDAR_COLORS[log.score],
					});
				this.calendarLegend = [
					{
						color: CalendarColors.GOOD,
						display: this.page.good,
					},
					{
						color: CalendarColors.OKAY,
						display: this.page.ok,
					},
					{
						color: CalendarColors.VERY_BAD,
						display: this.page.bad,
					},
				];
				if (!!res.pie) {
					this.isPie = true;
					this._languageSvc
						.template(this.page['pieTitle'], {
							percent: res.followThrough.toString(),
						})
						.then((value) => (this.page['pieTitle'] = value));
					this.pieData = [
						{
							name: this.page.bigImpact,
							value: res.pie.bigImpact,
							label: `${res.pie.bigImpact}%`,
						},
						{
							name: this.page.littleImpact,
							value: res.pie.littleImpact,
							label: `${res.pie.littleImpact}%`,
						},
						{
							name: this.page.noImpact,
							value: res.pie.noImpact,
							label: `${res.pie.noImpact}%`,
						},
					];
				}
			} else {
				this.display = false;
				this.noData();
			}
		});
	}
}
