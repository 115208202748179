<div class="dashContArea dashContAreaStudent">
	<div class="container px-0">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1 top-skils px-2">
				<button
					routerLink="/member/challenges"
					type="button"
					class="btn btn-primary determined-btn w-100"
				>
					{{
						mobileDashboardValues.positiveThought
							? mobileDashboardValues.positiveThought
							: pageLanguage?.amDetermined
					}}
				</button>
			</div>
		</div>

		<div class="card card-column mt-4">
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cols-column">
					<div class="improvements start-box d-flex text-muted">
						<img
							src="./../../../../assets/img/new/skill-builder.svg"
							alt="skill-builder"
							class="bd-placeholder-img me-2 flex-shrink-0 img"
						/>
						<div class="pb-3 mb-0 mid content">
							<div class="d-flex justify-content-between name">
								{{ pageLanguage?.skillBuilder }}
							</div>
							<div class="d-flex justify-content-between status">
								<div class="progress-status">{{ appLabels?.completed }}</div>
								<div class="retings-count">
									<span class="count">{{
										mobileDashboardValues.challengesCompleted
									}}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="improvements mid-box d-flex text-muted">
						<img
							src="./../../../../assets/img/calendar-check.svg"
							alt="pageLanguage?.dailyActivityStreak"
							class="bd-placeholder-img me-2 flex-shrink-0 img"
						/>
						<div class="pb-3 mb-0 mid content">
							<div class="d-flex justify-content-between name">
								{{ pageLanguage?.dailyActivityStreak }}
							</div>
							<div class="d-flex justify-content-between status">
								<div class="progress-status">
									{{ pageLanguage?.dailyInRow }}
								</div>
								<div class="retings-count">
									<span class="count">{{
										mobileDashboardValues.dailyGoalStreak
									}}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="improvements end-box d-flex text-muted">
						<img
							src="./../../../../assets/img/new/daily-goal.svg"
							alt="pageLanguage?.dailyGoal"
							class="bd-placeholder-img me-2 flex-shrink-0 img"
						/>
						<div class="pb-3 mb-0 mid content">
							<div class="d-flex justify-content-between name">
								{{ pageLanguage?.dailyGoal }}
							</div>
							<div class="d-flex justify-content-between status">
								<div class="progress-status">
									{{ pageLanguage?.howOftenGoalComplete }}
								</div>
								<div class="retings-count">
									<span class="count"
										>{{ mobileDashboardValues.dailyGoalPercent }}%</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cols-column">
					<div class="improvements start-box d-flex text-muted">
						<img
							src="./../../../../assets/img/new/positive-impact.svg"
							alt="pageLanguage?positiveImpact"
							class="bd-placeholder-img me-2 flex-shrink-0 img"
						/>
						<div class="pb-3 mb-0 mid content">
							<div class="d-flex justify-content-between name">
								{{ pageLanguage?.positiveImpact }}
							</div>
							<div class="d-flex justify-content-between status">
								<div class="progress-status">
									{{ pageLanguage?.numberOfInstances }}
								</div>
								<div class="retings-count">
									<span class="count">{{
										mobileDashboardValues.positiveImpact
									}}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="improvements end-box d-flex text-muted">
						<img
							src="./../../../../assets/img/new/assessment.svg"
							alt="pageLanguage?.assessment"
							class="bd-placeholder-img me-2 flex-shrink-0 img"
						/>
						<div class="pb-3 mb-0 mid content">
							<div class="d-flex justify-content-between name">
								{{ pageLanguage?.assessment }}
							</div>
							<div class="d-flex justify-content-between status">
								<div class="progress-status">{{ appLabels?.completed }}</div>
								<div class="retings-count">
									<span class="count">{{
										mobileDashboardValues.assessmentsCompleted
									}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
