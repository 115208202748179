import {
	Component,
	OnInit,
	Renderer2,
	HostBinding,
	HostListener,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { CertificatesService } from '@services/member';
import { toVoidPromise } from '@util';

@Component({
	selector: 'app-member-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
})
export class MemberMainComponent implements OnInit, AfterViewInit {
	@HostBinding('class') class = 'wrapper';
	@HostListener('window:click') onClick() {
		if (this.menuOpened) {
			if (this._opening) this._opening = false;
			else this.toggleMenu();
		}
	}

	@ViewChild('slidingPanel', { read: ElementRef }) slidingPanel: ElementRef;
	public menuOpened = true;
	public _opening = false;
	isSlidingPanelOpen: boolean = false;
	showAchievements: boolean = false;
	loaded: boolean = false;

	constructor(
		private _certificateSvc: CertificatesService,
		private renderer: Renderer2,
	) {}

	ngOnInit() {
		this.toggleMenu();
		const addCertificate = this._certificateSvc.addCertificate();
		addCertificate.subscribe();
		toVoidPromise(addCertificate).then(() => {
			this.loaded = true;
			this._certificateSvc.loadCertificates().subscribe((res) => {
				if (!!res && Array.isArray(res) && res.length > 0) {
					this.showAchievements = true;
				}
			});
		});
	}

	ngAfterViewInit(): void {
		document.addEventListener('click', (e) => {
			if (
				this.isSlidingPanelOpen &&
				e.target !== this.slidingPanel.nativeElement &&
				!(e.target as HTMLElement).classList.contains('btn') &&
				!this.slidingPanel.nativeElement.contains(e.target)
			)
				this.isSlidingPanelOpen = false;
		});
	}

	toggleMenu() {
		this.menuOpened = !this.menuOpened;
		if (this.menuOpened) {
			this._opening = true;
			this.renderer.removeClass(
				document.querySelector('app-root'),
				'sidebar-collapse',
			);
			this.renderer.addClass(
				document.querySelector('app-root'),
				'sidebar-open',
			);
			this.renderer.addClass(document.querySelector('body'), 'lock-scroll');
		} else {
			this.renderer.removeClass(
				document.querySelector('app-root'),
				'sidebar-open',
			);
			this.renderer.addClass(
				document.querySelector('app-root'),
				'sidebar-collapse',
			);
			this.renderer.removeClass(document.querySelector('body'), 'lock-scroll');
		}
	}

	toggleSlidingPanel() {
		this.isSlidingPanelOpen = !this.isSlidingPanelOpen;
	}
}
