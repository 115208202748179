// Sinhala (Sinhalese)
import { LocaleData } from 'ngx-bootstrap/chronos';

export const siLocale: LocaleData = {
	months: [
		'ජනවාරි',
		'පෙබරවාරි',
		'මාර්තු',
		'අප්‍රේල්',
		'මැයි',
		'ජුනි',
		'ජුලි',
		'අගෝස්තු',
		'සැප්තැම්බර්',
		'ඔක්තෝබර්',
		'නොවැම්බර්',
		'දෙසැම්බර්',
	],
	monthsShort: [
		'ජන',
		'පෙබ',
		'මාර්',
		'අප්‍රේ',
		'මැයි',
		'ජුනි',
		'ජුලි',
		'අගෝ',
		'සැප්',
		'ඔක්',
		'නොවැ',
		'දෙසැ',
	],
	weekdays: [
		'ඉරිදා',
		'සඳුදා',
		'අඟහරුවාදා',
		'බදාදා',
		'බ්‍රහස්පතින්දා',
		'සිකුරාදා',
		'සෙනසුරාදා',
	],
	weekdaysShort: ['ඉරි', 'සඳු', 'අඟ', 'බදා', 'බ්‍රහ', 'සිකු', 'සෙන'],
	weekdaysMin: ['ඉ', 'ස', 'අ', 'බ', 'බ්‍ර', 'ස', 'සෙ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[අද] LT',
		nextDay: '[හෙට] LT',
		nextWeek: 'dddd LT',
		lastDay: '[ඊයේ] LT',
		lastWeek: '[පසුගිය] dddd LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%sකින්',
		past: '%sකට පෙර',
		s: 'තත්පර කිහිපයක්',
		m: 'මිනිත්තුවක්',
		mm: '%d මිනිත්තු',
		h: 'පැයයක්',
		hh: '%d පැය',
		d: 'දවසක්',
		dd: '%d දින',
		M: 'මාසයක්',
		MM: '%d මාස',
		y: 'වසරක්',
		yy: '%d වසර',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1, // Monday is the first day of the week
		doy: 4, // The week that contains Jan 4 is the first week of the year
	},
};
