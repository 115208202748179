import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReportService, UsersService as UserLeader } from '@services/leader';
import { GroupService, OrganizationService } from '@services/member';
import { LanguageService, LoaderService } from '@services/public';
import { UserService as UserResearcher } from '@services/researcher/user.service';
import { UserService } from '@services/viewer';
import { SUCCESSMESSAGE } from '@shared/constant/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AddUserDialogComponent } from './add-edit-user-dialog/add-edit-user-dialog.component';

const PAGE_PATH = `pages.researcher.dashboard.userManagement.page`;

@Component({
	selector: 'app-user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
	usrObj: any;
	canEdit: boolean = true;
	canDelete: boolean = true;
	@ViewChild('template') template: TemplateRef<any>;
	organizations: any;
	successMessage = SUCCESSMESSAGE;
	selectedOrg: any = null;
	searchText: any = '';
	modalRef?: BsModalRef;
	message?: string;
	selectedGroup = null;
	currentPage: number = 1;
	totalCount: any;
	data: any;
	rowData: any;
	groupData: any;
	groupslist: any;
	userData: any;
	allUsers: any;
	isAddUser: any;
	groups: any;
	cols = [
		{ field: 'name', header: 'Name', sort: true },
		{ field: 'email', header: 'Email', sort: false },
		{ field: 'groupName', header: 'Assigned Group', sort: true },
		{ field: 'role', header: 'Role', sort: false },
		{ field: 'status', header: 'Status', sort: false },
		{ field: 'action', header: 'Actions', status: true },
	];
	// Page language.
	page: { [key: string]: string } = {};

	constructor(
		public modalSvc: BsModalService,
		public reportSvc: ReportService,
		public grpSvc: GroupService,
		public userSvc: UserService,
		private orgSVC: OrganizationService,
		private loaderSvc: LoaderService,
		private researcherSvc: UserResearcher,
		private userLeaderSvc: UserLeader,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			this.cols.push(
				{ field: 'name', header: 'Name', sort: true },
				{ field: 'email', header: 'Email', sort: false },
				{ field: 'groupName', header: 'Assigned Group', sort: true },
				{ field: 'role', header: 'Role', sort: false },
				{ field: 'status', header: 'Status', sort: false },
				{ field: 'action', header: 'Actions', status: true },
			);
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		this.loadOrganization();
		this.grpSvc.groups.subscribe((groups) => {
			this.groups = groups;
			this.groupslist = this.groups.filter(
				(group) => group.organizationUUID === this.selectedOrg,
			);
			this.groupslist.map((group) => {
				if (group.name) {
					group.name = this.capitalizeFirstLetter(group.name);
				}
			});
		});

		//load groups
		this.grpSvc.loadGroups();
		let organizationUUID = `?organizationUUID=${this.selectedOrg}`;
		this.getListUser(organizationUUID);
	}

	loadOrganization() {
		const orgUUID = localStorage.getItem('organizationUUID');
		this.orgSVC.organizations.subscribe((organization) => {
			this.organizations = organization;
			if (orgUUID !== null) {
				const org = this.organizations.find(
					(element) => element.uuid === orgUUID,
				);
				this.selectedOrg = org.uuid;
				this.isAddUser = org.addUser;
			}
		});
		if (orgUUID === null)
			this.organizations.push({
				uuid: null,
				name: this.page.noOrgs,
				active: false,
				addUser: false,
				createGroup: false,
			});
	}

	openModal1() {
		let initialState: any = {
			rowData: this.rowData,
		};
		this.modalRef = this.modalSvc.show(AddUserDialogComponent, {
			class: 'modal-md modal-dialog-centered',
			backdrop: 'static',
			keyboard: true,
			initialState,
		});
	}

	onSelectOrganization(event) {
		if (event[0] !== null) {
			localStorage.setItem('organizationUUID', event);
			const org = this.organizations.find((element) => element.uuid === event);
			this.isAddUser = org.addUser;
		}
		this.loadGroups();
		let organizationUUID = `?organizationUUID=${this.selectedOrg}`;
		this.getListUser(organizationUUID);
	}

	loadGroups() {
		this.groupslist = this.groups.filter(
			(group) => group.organizationUUID === this.selectedOrg,
		);
		this.groupslist.map((group) => {
			if (group.name) {
				group.name = this.capitalizeFirstLetter(group.name);
			}
		});
	}

	addUser() {
		this.rowData = null;
		this.openModal1();
	}

	decline() {
		this.message = this.page?.declined;
		this.modalRef?.hide();
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-sm modal-dialog-centered',
		});
	}

	getListUser(obj?: any) {
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.researcherSvc.getUserList(obj).subscribe((res) => {
			this.currentPage = res.metadata.pageNumber;
			this.totalCount = res.metadata.totalItems;
			if (res.records.length === 0 || res.records[0] === null) {
				this.data = [];
			} else {
				this.usrObj = res.records;
				this.usrObj.map((ele) => {
					const propertiesToCapitalize = ['name', 'groupName', 'role'];
					propertiesToCapitalize.forEach((property) => {
						if (ele[property]) {
							ele[property] = this.capitalizeFirstLetter(ele[property]);
						}
					});
					switch (ele.status) {
						case 'active':
							ele.status = this.page?.active;
							ele.canEdit = true;
							break;
						case 'pending-approval':
							ele.status = this.page?.pendingApproval;
							ele.canEdit = true;
							break;
						case 'new-user-request':
							ele.status = this.page?.groupRequest;
							ele.canEdit = false;
							break;
						case 'main-new-user-request':
							ele.status = this.page?.orgRequest;
							ele.canEdit = false;
							break;
					}
					ele.canDelete = true;
				});
				this.data = this.usrObj;
			}
			this.loaderSvc.removeLoader(loader);
		});
	}

	capitalizeFirstLetter(value) {
		return value
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	onPaginationEventClick(event) {
		let paginationObj;
		const { selectedGroup, searchText } = this;

		if (searchText !== '') {
			paginationObj = `?organizationUUID=${this.selectedOrg}&currentPage=${event}&search=${searchText}`;

			if (selectedGroup) {
				paginationObj += `&groupUUID=${selectedGroup}`;
			}
		} else if (selectedGroup !== null) {
			paginationObj = `?organizationUUID=${this.selectedOrg}&currentPage=${event}&groupUUID=${selectedGroup}`;
		} else {
			paginationObj = `?organizationUUID=${this.selectedOrg}&currentPage=${event}`;
		}

		this.getListUser(paginationObj);
	}

	openEditModal() {
		let initialState: any = {
			rowData: this.rowData,
		};
		this.modalRef = this.modalSvc.show(AddUserDialogComponent, {
			class: 'modal-md modal-dialog-centered',
			backdrop: 'static',
			keyboard: true,
			initialState,
		});
		this.modalRef.content.submitPopUp.subscribe((res) => {
			let organizationUUID = `?organizationUUID=${this.selectedOrg}`;
			this.getListUser(organizationUUID);
		});
	}

	successDialogBox(title: any) {
		Swal.fire({
			title: title,
			icon: 'success',
			confirmButtonColor: '#456790',
			confirmButtonText: this.page?.ok,
		});
	}

	sortByColumn(event) {
		let sortObj = `?organizationUUID=${this.selectedOrg}&sortOrder=${event.sortOrder}&sortColumn=${event.sortColumn}`;
		this.getListUser(sortObj);
	}

	searchKey(data: string) {
		this.searchText = data;
		const { selectedGroup } = this;
		let searchObj = `?organizationUUID=${this.selectedOrg}&search=${this.searchText}`;

		if (selectedGroup) {
			searchObj += `&groupUUID=${selectedGroup}`;
		}

		this.getListUser(searchObj);
	}

	deleteUser() {
		let uuid = this.rowData.row.enrollmentUUID;
		let grpObj = `?organizationUUID=${this.selectedOrg}`;
		if (this.rowData.row.status === 'Pending Approval') {
			this.userLeaderSvc.declineUserGroupRequest(uuid).subscribe((response) => {
				if (response.success) {
					this.modalRef?.hide();
					this.successDialogBox(this.successMessage.user.deleteSuccess);
					this.getListUser(grpObj);
				}
			});
		} else if (this.rowData.row.status === this.page?.groupRequest) {
			this.userLeaderSvc.deleteUserGroupRequest(uuid).subscribe((response) => {
				if (response.success) {
					this.modalRef?.hide();
					this.successDialogBox(this.successMessage.user.deleteSuccess);
					this.getListUser(grpObj);
				}
			});
		} else if (this.rowData.row.status === this.page?.orgRequest) {
			this.userLeaderSvc.deleteUserRequest(uuid).subscribe((response) => {
				if (response.success) {
					this.modalRef?.hide();
					this.successDialogBox(this.successMessage.user.deleteSuccess);
					this.getListUser(grpObj);
				}
			});
		} else {
			const useruuid = this.rowData.row.userUUID;
			const groupuuid = { uuid: this.rowData.row.groupUUID, action: 'D' };
			const objRequire = {
				uuid: useruuid,
				groupMembers: [],
			};
			objRequire.groupMembers.push(groupuuid);
			this.researcherSvc.deleteUser(objRequire).subscribe((response) => {
				this.modalRef?.hide();
				this.successDialogBox(this.successMessage.user.deleteSuccess);
				this.getListUser(grpObj);
			});
		}
	}

	rowClicked(event) {
		this.rowData = event;
		if (event.action === 'delete') {
			this.openModal(this.template);
		}
		if (event.action === 'edit' && event.row.userUUID !== null) {
			this.rowData = event;
			this.openEditModal();
		}
		if (event.action === 'approve') {
			this.rowData = event;
			let approveGroupUser = {
				uuid: this.rowData.row.enrollmentUUID,
			};
			let grpObj = `?organizationUUID=${this.selectedOrg}`;
			this.userLeaderSvc
				.acceptUserGroupRequest(approveGroupUser)
				.subscribe((response) => {
					this.getListUser(grpObj);
				});
		}
	}

	onSelectGroup(event: any) {
		this.selectedGroup = event.target.value;
		let grpObj = `?organizationUUID=${this.selectedOrg}&groupUUID=${event.target.value}`;
		if (this.searchText !== '') {
			grpObj += `&search=${this.searchText}`;
		}
		this.getListUser(grpObj);
	}
}
