// Hawaiian

import { LocaleData } from 'ngx-bootstrap/chronos';

export const hawLocale: LocaleData = {
	months: [
		'Ianuari',
		'Pepeluali',
		'Malaki',
		'ʻApelila',
		'Mei',
		'Iune',
		'Iulai',
		'ʻAukake',
		'Septema',
		'Okakopa',
		'Nowemapa',
		'Dekemapa',
	],
	monthsShort: [
		'Ian',
		'Pep',
		'Mal',
		'ʻAp',
		'Mei',
		'Iun',
		'Iul',
		'ʻAu',
		'Sep',
		'Oka',
		'Now',
		'Dek',
	],
	weekdays: [
		'Lāpule',
		'Pōʻakahi',
		'Pōʻalua',
		'Pōʻalima',
		'Pōʻaha',
		'Pōʻalua',
		'Pōʻakahi',
	],
	weekdaysShort: ['Lā', 'Pō', 'Pō', 'Pō', 'Pō', 'Pō', 'Pō'],
	weekdaysMin: ['Lā', 'Pō', 'Pō', 'Pō', 'Pō', 'Pō', 'Pō'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[I kēia lā] LT',
		nextDay: '[ʻApōpō] LT',
		nextWeek: 'dddd [i] LT',
		lastDay: '[Inā] LT',
		lastWeek: '[I] dddd [i] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'i %s',
		past: '%s ma hope',
		s: 'minuke liʻiliʻi',
		m: 'minuke hoʻokahi',
		mm: '%d minuke',
		h: 'hola hoʻokahi',
		hh: '%d hola',
		d: 'la hoʻokahi',
		dd: '%d lā',
		M: 'mahina hoʻokahi',
		MM: '%d mahina',
		y: 'makahiki hoʻokahi',
		yy: '%d makahiki',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
