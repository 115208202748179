<app-skill-builder-select-list
	[mainTitle]="page.title"
	[options]="options"
	[next]="next"
>
</app-skill-builder-select-list>

<ng-template #noStress>
	<div class="modal-body modal-center">
		<div class="d-flex justify-content-center align-items-center mt-3">
			<div class="success-checkmark">
				<i class="fas fa-check"></i>
			</div>
		</div>
		<p class="mt-3 text-center success-msg px-5 title-main">
			{{ page.noStress }}
		</p>

		<div class="pt-4 d-flex align-items-center justify-content-center">
			<button
				(click)="closeModal()"
				type="button"
				class="btn btn-md btn-bordered-purple"
			>
				{{ page.ok }}
			</button>
		</div>
	</div>
</ng-template>
