<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container px-0 w-sm-100 w-50">
		<h4 class="w-auto text-center mb-3 px-3">
			{{ data.dilemma.display }}
		</h4>
		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center">
					<div class="col-12">
						<div class="card card-white card-white-sm">
							<div class="card-body">
								<h5>
									<span [innerHTML]="page.description"></span>
								</h5>
								<div class="pt-4">
									<button
										type="button"
										(click)="choice = false"
										class="btn btn-md ml-3 {{
											choice === false
												? BUTTON_CLASS.SELECTED
												: BUTTON_CLASS.NOT_SELECTED
										}}"
									>
										{{ page.no }}
									</button>
									<button
										type="button"
										(click)="choice = true"
										class="btn btn-md ml-3 {{
											choice ? BUTTON_CLASS.SELECTED : BUTTON_CLASS.NOT_SELECTED
										}}"
									>
										{{ page.yes }}
									</button>
								</div>
							</div>
						</div>
						<div *ngIf="submitted && choice === null" class="invalid-feedback">
							{{ page.error }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-inner-action">
			<button
				type="button"
				(click)="submitOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ page.submit }}
			</button>
		</div>
	</div>
</div>
