// Basque

import { LocaleData } from 'ngx-bootstrap/chronos';

export const euLocale: LocaleData = {
	months: [
		'Urtarrila',
		'Otsaila',
		'Martxoa',
		'Apirila',
		'Maiatza',
		'Ekaina',
		'Uztaila',
		'Abuztua',
		'Iraila',
		'Urria',
		'Azaroa',
		'Abendua',
	],
	monthsShort: [
		'Urt',
		'Ots',
		'Mar',
		'Api',
		'Mai',
		'Eki',
		'Uzt',
		'Abu',
		'Ira',
		'Urr',
		'Aza',
		'Abe',
	],
	weekdays: [
		'Igandea',
		'Astelehena',
		'Asteartea',
		'Asteazkena',
		'Osteguna',
		'Ostirala',
		'Larunbata',
	],
	weekdaysShort: ['Iga', 'Ast', 'Ast', 'Ast', 'Ost', 'Ost', 'Lar'],
	weekdaysMin: ['Ig', 'As', 'As', 'As', 'Os', 'Os', 'La'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Gaur] LT',
		nextDay: '[Bihar] LT',
		nextWeek: 'dddd [eguna] LT',
		lastDay: '[Atzo] LT',
		lastWeek: '[Pasa den] dddd [eguna] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s barru',
		past: '%s lehen',
		s: 'secondo batzuk',
		m: 'minutu bat',
		mm: '%d minutu',
		h: 'ordu bat',
		hh: '%d ordu',
		d: 'egun bat',
		dd: '%d egun',
		M: 'hilabete bat',
		MM: '%d hilabete',
		y: 'urtez',
		yy: '%d urte',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
