import { Component, OnInit, Input } from '@angular/core';
import { StorageService, TEMP_KEYS } from '@services/public/storage.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { version } from 'environment';
import { LanguageService } from '@services/public';

@Component({
	selector: 'app-feature-modal',
	templateUrl: './feature-modal.component.html',
	styleUrls: ['./feature-modal.component.scss'],
})
export class FeatureModalComponent implements OnInit {
	@Input() public userNotifications: Array<any> | null = null;

	isHTML(content: string): boolean {
		const htmlPattern = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
		return htmlPattern.test(content);
	}

	name: string;
	version: string;
	pageLanguage: any;
	labels: any;
	path = `pages.member.featureModal`;

	constructor(
		public bsModalRef: BsModalRef,
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this.name = this._storageSvc.getTempStorage(TEMP_KEYS.NAME);
		this.version = version;

		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});
	}
}
