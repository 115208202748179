import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { MemberAssessmentExternalCreateOrUpdateComponent } from '@pages/member';
import {
	DomainService,
	Domain,
	LoaderService,
	Skill,
	LanguageService,
} from '@services/public';
import {
	AssessmentService,
	AssessmentStatus,
	ExternalAssessment,
} from '@services/member';

@Component({
	selector: 'app-member-assessment-external-update-skills',
	templateUrl: './update.skills.component.html',
	styleUrls: ['./update.skills.component.scss'],
})
export class MemberAssessmentExternalUpdateSkillsComponent implements OnInit {
	domains: Array<Domain> = [];
	allDomainsAndSkills: any = {};

	assessment: ExternalAssessment = {
		uuid: null,
		assessmentName: '-',
		firstName: '-',
		lastName: '-',
		email: '-',
		relationship: '-',
		startDate: '',
		status: null,
		skills: [],
	};

	modalRef?: BsModalRef;

	// Language Translation
	pageLanguage: any;
	appLabels: any;
	basePath = `pages.member.assessment`;

	constructor(
		private domainSvc: DomainService,
		private assessmentSvc: AssessmentService,
		private router: Router,
		private modalSvc: BsModalService,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Load domains.
		this.domainSvc.getDomains().then((response) => {
			this.domains = response;
		});

		// Load domains and skills.
		this.domainSvc.getAll().then((response) => {
			this.allDomainsAndSkills = response;
		});

		// Load Language
		const pageLanguagePath = this.basePath + `.external.pages`;
		this._languageSvc.get([pageLanguagePath]).then((value) => {
			this.pageLanguage = value[pageLanguagePath];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.appLabels = value[`labels`];
		});

		// Load assessment.
		this.getAssessment();
	}

	getAssessment() {
		this.assessment = JSON.parse(localStorage.getItem('createExternal'));
		this.assessment.status = AssessmentStatus.INACTIVE;
		this.assessment.skills = this.assessment.skills || [];
	}

	editExternal() {
		this.modalRef = this.modalSvc.show(
			MemberAssessmentExternalCreateOrUpdateComponent,
			{
				class: 'modal-dialog-centered',
			},
		);
		this.modalRef.content.assessmentData = this.assessment;
		const subscription = this.modalRef.onHide.subscribe(() => {
			subscription.unsubscribe();
			this.getAssessment();
		});
	}

	toggleSkill(skill: Skill): void {
		if (!this.assessment.skills.includes(skill))
			this.assessment.skills.push(skill);
		else
			this.assessment.skills = this.assessment.skills.filter(
				(selected) => selected !== skill,
			);
	}

	send() {
		// Make sure skills are selected.
		if (!this.assessment.skills.length) return;
		const assessmentObj = {
			assessmentName: this.assessment.assessmentName,
			firstName: this.assessment.firstName,
			lastName: this.assessment.lastName,
			email: this.assessment.email,
			relationship: this.assessment.relationship,
			skillUIDs: this.assessment.skills.map((skill) => skill.uid),
		};
		// Send external assessment email.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.createExternal(assessmentObj)
			.pipe(
				map((response: any) => {
					if (!!response.success) {
						this.loaderSvc.removeLoader(loader);
						localStorage.removeItem('createExternal');
						Swal.fire({
							title: 'Assessment created successfully!',
							icon: 'success',
							confirmButtonColor: '#456790',
							confirmButtonText: 'Ok',
							didDestroy: () => {
								this.router.navigate(['/member/dashboard']);
							},
						});
					}
				}).bind(this),
			)
			.subscribe();
	}

	splitString(str: string, delim: string): Array<string> {
		return str.split(`${delim}`);
	}
}
