import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable({
	providedIn: 'root',
})
export class ConfettiService {
	private canvas: HTMLCanvasElement;

	constructor() {
		this.canvas = document.createElement('canvas');
	}

	getCanvas(): HTMLCanvasElement {
		return this.canvas;
	}

	public canon(): void {
		confetti({
			angle: this.randomInRange(55, 125),
			spread: this.randomInRange(50, 70),
			particleCount: this.randomInRange(50, 100),
			origin: { y: 0.6 },
		});
	}

	private randomInRange(min, max) {
		return Math.random() * (max - min) + min;
	}

	public fireworks(
		duration: number = 15,
		startVelocity: number = 30,
		spread: number = 360,
		ticks: number = 60,
		zIndex: number = 0,
	): any {
		duration = duration * 1000;
		let animationEnd = Date.now() + duration;
		let defaults = {
			startVelocity: startVelocity,
			spread: spread,
			ticks: ticks,
			zIndex: zIndex,
		};

		function randomInRange(min, max) {
			return Math.random() * (max - min) + min;
		}

		let interval = setInterval(function () {
			let timeLeft = animationEnd - Date.now();

			if (timeLeft <= 0) {
				return clearInterval(interval);
			}

			let particleCount = 50 * (timeLeft / duration);
			// since particles fall down, start a bit higher than random
			confetti({
				...defaults,
				particleCount,
				origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
			});
			confetti({
				...defaults,
				particleCount,
				origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
			});
		}, 250);

		return interval;
	}
}
