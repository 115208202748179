import { Component, OnInit, ElementRef } from '@angular/core';
import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from '@services/viewer';

import {
	Assessment,
	AssessmentStatus,
	AssessmentService,
} from '@services/leader';
import { LoaderService, LanguageService } from '@services/public';
import { DatePipe } from '@angular/common';

const PAGE_PATH = `pages.leader.assessment.subgroups`;

@Component({
	selector: 'app-leader-assessment-subgroups',
	templateUrl: './subgroups.component.html',
	styleUrls: ['./subgroups.component.scss'],
})
export class LeaderAssessmentSubgroupsComponent implements OnInit {
	AssessmentStatus: typeof AssessmentStatus = AssessmentStatus; // Expose this enum to the template.

	assessment: Assessment = {
		uuid: null,
		name: '-',
		type: null,
		startDate: '-',
		endDate: '-',
		status: null,
		groupUUID: null,
		group: {
			uuid: null,
			name: '-',
			abbreviation: null,
			timezone: null,
			startDate: null,
			endDate: null,
			memberUUIDs: null,
			users: null,
			organizationUUID: '',
			member: null,
			role: null,
		},
		skills: [],
		subgroups: [[]],
		users: [],
	};
	unassignedUsers: Array<any> = [];
	newSubgroup: Array<any> = [];
	oneUserSubgroup: boolean = false;
	noSubgroups: boolean = false;
	isView: any;

	// Page language.
	page: { [key: string]: string } = {};

	constructor(
		private assessmentSvc: AssessmentService,
		private route: ActivatedRoute,
		private router: Router,
		private loaderSvc: LoaderService,
		public viewerSvc: UserService,
		private _languageSvc: LanguageService,
	) {}
	private elRef: ElementRef;

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		// Get assessment.
		const createAssessment = JSON.parse(
			localStorage.getItem('createAssessment'),
		);
		const assessment = createAssessment.formValue;
		this.isView = createAssessment.from;
		const loader: unique symbol = Symbol();
		let user = [];
		this.loaderSvc.addLoader(loader);
		this.viewerSvc.users.subscribe((users) => {
			assessment.group.memberUUIDs.map((id) => {
				const userFind = users.find((user) => user.uuid === id);
				user.push(userFind);
			});

			let subgroup = [];
			let subgroups = [];
			for (const group of assessment.subgroups) {
				for (const uuid of group) {
					const user = users.find((ele) => ele.uuid === uuid);
					subgroup.push({
						name: user.name,
						uuid: user.uuid,
						status: this.assessment.status,
					});
				}
				subgroups.push(subgroup);
				subgroup = [];
			}
			assessment.subgroups = subgroups;
			assessment.startDate = new Date(assessment.startDate);
			assessment.endDate = new Date(assessment.endDate);
			this.assessment = assessment;
			this.assessment.users = user;
			this.unassignedUsers = this.assessment.users.filter((user) => {
				for (const subgroup of this.assessment.subgroups)
					for (const subgroupUser of subgroup)
						if (user.uuid === subgroupUser.uuid) return false;
				return true;
			});

			// Check validity
			if (
				this.assessment.subgroups.findIndex(
					(subgroup) => subgroup.length < 2,
				) === -1
			)
				this.oneUserSubgroup = false;
			else this.oneUserSubgroup = true;
			if (this.assessment.subgroups.length === 0) this.noSubgroups = true;
			else this.noSubgroups = false;

			this.loaderSvc.removeLoader(loader);
		});
	}

	toggleSelectedPeerClass(event: Event) {
		const selectedPeer = event.target as HTMLElement;
		if (selectedPeer.classList.contains('peers')) {
			selectedPeer.classList.toggle('grabbing');
		}
	}

	dropUser(event: CdkDragDrop<any>): void {
		// Handle user rearrangement within the same subgroup.
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex,
			);
			return;
		}

		// Transfer user between subgroups.
		transferArrayItem(
			event.previousContainer.data,
			event.container.data,
			event.previousIndex,
			event.currentIndex,
		);

		// Remove empty subgroups
		this.assessment.subgroups = this.assessment.subgroups.filter(
			(subgroup) => subgroup.length > 0,
		);

		// Create new subgroup if needed.
		if (this.newSubgroup.length > 0) {
			this.assessment.subgroups.push(this.newSubgroup);
			this.newSubgroup = [];
		}

		// Check validity
		if (
			this.assessment.subgroups.findIndex((subgroup) => subgroup.length < 2) ===
			-1
		)
			this.oneUserSubgroup = false;
		else this.oneUserSubgroup = true;
		if (this.assessment.subgroups.length === 0) this.noSubgroups = true;
		else this.noSubgroups = false;
	}

	onSubmit() {
		const reqSelfData = {
			uuid: '',
			name: this.assessment.name,
			type: this.assessment.type,
			groupUUID: this.assessment.group.uuid,
			startDate: new DatePipe('en-US').transform(
				this.assessment.startDate,
				'yyyy-MM-dd',
			),
			endDate: new DatePipe('en-US').transform(
				this.assessment.endDate,
				'yyyy-MM-dd',
			),
			skillUIDs: this.assessment.skills.map((skill) => skill.uid),
			subgroups: this.assessment.subgroups.map((subgroup) =>
				subgroup.map((user) => user.uuid),
			),
		};
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		if (this.isView !== 'view') {
			// Create assessment subgroups and schedule.
			delete reqSelfData.uuid;
			this.assessmentSvc
				.createAssessment(reqSelfData)
				.pipe(
					map((response: any) => {
						if (!!response.success) {
							this.loaderSvc.removeLoader(loader);
							localStorage.removeItem('createAssessment');
							let title = this.page?.assessmentCreated;
							this.successMessage(title);
						}
					}).bind(this),
				)
				.subscribe();
		} else {
			// Update assessment subgroups and schedule.
			reqSelfData.uuid = this.assessment.uuid;
			this.assessmentSvc
				.updateAssessment(reqSelfData)
				.pipe(
					map((response: any) => {
						if (!!response.success) {
							this.loaderSvc.removeLoader(loader);
							localStorage.removeItem('createAssessment');
							let title = this.page?.assessmentUpdated;
							this.successMessage(title);
						}
					}).bind(this),
				)
				.subscribe();
		}
	}
	successMessage(title) {
		Swal.fire({
			title: title,
			icon: 'success',
			confirmButtonColor: '#456790',
			confirmButtonText: this.page?.ok,
			didDestroy: () => this.router.navigate(['leader/dashboard']),
		});
	}
}
