<div class="assStudentMain">
	<div class="tabHeadMain px-4">
		<div class="container">
			<div class="tabHead">
				<span></span>{{ pageLanguage?.title }} - {{ currentSkillIndex + 1 }}
				{{ labels?.outOf }} {{ skills.length }}
			</div>
		</div>
	</div>

	<div *ngIf="!submitted && !!skills.length" class="container-fluid px-4 pb-lg">
		<div class="pageTopCard">
			<h2>{{ skills[currentSkillIndex].name }}</h2>
			<h5>{{ skills[currentSkillIndex].definition }}</h5>
			<p>{{ skills[currentSkillIndex].description }}</p>
			<p>{{ instructions }}</p>
		</div>

		<form [formGroup]="form">
			<div class="cardForm">
				<div
					class="cardFormRow"
					[ngClass]="{
						'invalid-box':
							form.controls[userName].errors && form.controls[userName].touched,
					}"
				>
					<div class="cardFormQst">{{ userName }}</div>
					<div class="cardFormAnswer">
						<div class="cardFormAnsrCol" *ngFor="let option of options">
							<label class="containerLabel"
								>{{ option.display }}
								<input
									type="radio"
									[formControlName]="userName"
									[value]="option.value"
									[name]="userName"
								/>
								<span class="checkmark"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div
			class="btn-c footer-inner-action d-flex justify-content-center align-items-center"
		>
			<button
				(click)="prev()"
				[disabled]="currentSkillIndex === 0"
				class="btn btn-md"
			>
				{{ labels?.previous }}
			</button>
			<button
				*ngIf="currentSkillIndex < skills.length - 1"
				(click)="next()"
				class="btn btn-primary"
			>
				{{ labels?.next }}
			</button>
			<button
				*ngIf="currentSkillIndex >= skills.length - 1"
				(click)="finish()"
				class="btn btn-primary"
			>
				{{ labels?.finish }}
			</button>
		</div>
	</div>
	<ng-container *ngIf="submitted">
		<div class="emptyAssmntMain">
			<div
				class="emptyAssmntInner d-flex align-items-center justify-content-center flex-column"
			>
				<div class="success-checkmark"><i class="fas fa-check"></i></div>
				<h5 class="black pt-3">{{ labels?.done }}</h5>
				<p>{{ submittedMessage }}</p>
			</div>
		</div>
	</ng-container>
</div>
