import { Component, Input, OnInit } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	ValidationErrors,
} from '@angular/forms';
import { BRAIN_PAGE, BrainData } from '../common';
import { NO_HTML_PATTERN } from '@util';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.brain.pages.diary';

@Component({
	selector: 'app-member-skill-builder-brain-diary',
	templateUrl: './diary.component.html',
	styleUrls: ['./diary.component.scss'],
})
export class MemberSkillBuilderBrainDiaryComponent implements OnInit {
	@Input() data: BrainData;
	form: UntypedFormGroup;
	submitted: boolean = false;
	maxChars: number = 400;

	// Page language.
	page: { [key: string]: string } = {
		title: '',
		titleYesterday: '',
		placeholder: '',
		scale: '',
		scaleYesterday: '',
		error1: '',
		bad: '',
		great: '',
		error2: '',
		next: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	noWhitespaceValidator(control: UntypedFormControl): ValidationErrors | null {
		const isWhitespace = (control.value || '').trim().length === 0;
		return isWhitespace ? { whitespace: true } : null;
	}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			if (!!this.data.yesterday) {
				this.page.title = this.page.titleYesterday;
				this.page.scale = this.page.scaleYesterday;
			}
		});

		// Setup form
		this.form = new UntypedFormGroup({
			userSummary: new UntypedFormControl(this.data?.diary || '', [
				Validators.required,
				Validators.pattern(NO_HTML_PATTERN),
				this.noWhitespaceValidator,
			]),
			rating: new UntypedFormControl(
				this.data?.score || '',
				Validators.required,
			),
		});
	}

	nextOnClick(): void {
		this.form.controls.userSummary.setValue(
			(this.form.controls.userSummary.value || '').trim(),
		);
		this.submitted = true;
		if (!this.form.valid) return;
		this._storageSvc.setTempStorage(TEMP_KEYS.BRAIN, {
			...this.data,
			page: BRAIN_PAGE.DO_TOMORROW,
			diary: this.form.controls.userSummary.value,
			score: this.form.controls.rating.value,
		});
	}
}
