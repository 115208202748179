import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@services/public';
const PAGE_PATH = `pages.leader.dashboard.page`;

@Component({
	selector: 'app-leader-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class LeaderDashboardComponent implements OnInit {
	tab: string = 'Measure';
	// Page language.
	page: { [key: string]: string } = {};

	constructor(private _languageSvc: LanguageService) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
	}
}
