import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '@services/member';
import { LanguageService } from '@services/public';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { GroupTagPopupComponent } from '../group-tag-popup/group-tag-popup.component';

@Component({
	selector: 'app-join-group-tag',
	templateUrl: './join-group-tag.component.html',
})
export class JoinGroupTagComponent implements OnInit {
	modalRef?: BsModalRef;
	message?: string;
	groupsData = [];
	memberGroup: any;
	dialogMessage: string;
	leaveRecoed: any;
	accExpandedGroup: boolean = false;
	pageLanguage: any;
	labels: any;
	path: string = `pages.member.joinGroupTag`;

	constructor(
		public grpSvc: GroupService,
		private modalSvc: BsModalService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});
		this.grpSvc.groups.subscribe((groups) => {
			this.groupsData = groups;
			this.memberGroup = this.groupsData.filter(
				(element) => !(element.pending === false && element.member === false),
			);
			this.memberGroup.map((group) => {
				if (group.name) {
					group.name = this.capitalizeFirstLetter(group.name);
				}
			});
		});

		//load group
		this.grpSvc.loadGroups();
	}

	capitalizeFirstLetter(value) {
		return value
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	expandAccGroup() {
		if (this.accExpandedGroup) {
			this.accExpandedGroup = false;
		} else {
			this.accExpandedGroup = true;
		}
	}

	openModalListPopUp() {
		this.modalRef = this.modalSvc.show(GroupTagPopupComponent, {
			class: 'modal-md modal-dialog-centered',
			backdrop: 'static',
			keyboard: true,
		});
	}

	joinGroup() {
		this.openModalListPopUp();
	}

	leave() {
		this.grpSvc.leaveGroup({ uuid: this.leaveRecoed.uuid }).subscribe((res) => {
			this.modalRef?.hide();
			this.successDialogBox(this.pageLanguage.groupDeleteSuccess);
			this.grpSvc.loadGroups();
		});
	}

	successDialogBox(title: any) {
		Swal.fire({
			title: title,
			icon: 'success',
			confirmButtonColor: '#456790',
			confirmButtonText: 'Ok',
		});
	}

	openModal(template: TemplateRef<any>, leaveRecord) {
		this.leaveRecoed = leaveRecord;
		this.dialogMessage = this.pageLanguage.confirmLeaveGroup;
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-sm modal-dialog-centered',
		});
	}

	decline() {
		this.message = this.pageLanguage.declined;
		this.modalRef?.hide();
	}

	navigation() {
		if (this.activatedRoute.snapshot.paramMap.get('fromSetting')) {
			this.router.navigate(['/member/settings']);
		} else {
			this.router.navigate(['/member/dashboard']);
		}
	}
}
