import {
	Component,
	Input,
	ViewChild,
	AfterViewInit,
	ElementRef,
	OnInit,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VideoViewerComponent } from '../video-viewer/video-viewer.component';
import { WeeklyChallengeService } from '../challenges/services/weekly-challenge.service';
import { Subscription } from 'rxjs';

export interface GenModalInfo {
	title?: string;
	subTitle?: string;
	message?: string;
	linkText?: string;
	linkDestination?: string;
	pdfSrc?: string;
	videoSrc?: string;
	transcript?: string;
}
@Component({
	selector: 'app-general-modal',
	templateUrl: './general-modal.component.html',
	styleUrls: ['./general-modal.component.scss'],
})
export class GeneralModalComponent implements OnInit, AfterViewInit {
	@Input() public modalInfo: GenModalInfo | null = null;
	@Input() public challengeID: string = '';
	@Input() public status: string = '';
	@ViewChild(VideoViewerComponent) videoPlayer: VideoViewerComponent;
	@ViewChild('modalCloseButton') modalCloseButton: ElementRef;

	private _subscriptions: Subscription = new Subscription();

	constructor(
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private weeklyChallengeService: WeeklyChallengeService,
	) {}

	isLoading: boolean = true;

	pdfLoadComplete() {
		this.isLoading = false;
	}

	zoomLevel: number = 1.0;

	zoomIn() {
		this.zoomLevel += 0.2;
	}

	zoomOut() {
		this.zoomLevel -= 0.2;
	}

	handleChallengeComplete(): void {
		this.weeklyChallengeService
			.completeWeeklyChallenge({
				uuid: this.challengeID,
				timezone: 'America/Chicago',
			})
			.subscribe();
	}
	hideModal() {
		this.bsModalRef.hide();
	}

	ngOnInit(): void {
		if (this.modalInfo.pdfSrc) {
			if (!this.checkLinkWithoutCORS(this.modalInfo.pdfSrc)) {
				const srcSplit = this.modalInfo.pdfSrc.split('/');
				this.modalInfo.pdfSrc = [
					...srcSplit.slice(0, -2),
					'eng',
					srcSplit.slice(-1),
				].join('/');
			}
		}
	}

	ngAfterViewInit(): void {
		if (this.modalInfo.videoSrc) {
			this._subscriptions.add(
				this.bsModalRef.onHidden.subscribe(() => {
					if (this.status == 'open')
						this.weeklyChallengeService.setChallengeID(this.challengeID);
					this.videoPlayer.closeVideoPlayer();
					this._subscriptions.unsubscribe();
				}),
			);
			this._subscriptions.add(
				this.modalService.onShown.subscribe(() => {
					this.videoPlayer.playVideo();
				}),
			);
			this.videoPlayer.handleVideoEnded = () => {
				if (this.status == 'open')
					this.weeklyChallengeService.setChallengeID(this.challengeID);
				this.hideModal();
			};
		}

		if (!!this.modalInfo.pdfSrc)
			this.modalCloseButton.nativeElement.classList.add('pdf-bottom');
	}

	async checkLinkWithoutCORS(url: string): Promise<boolean> {
		try {
			const response = await fetch(url, { method: 'HEAD' });
			return response.ok;
		} catch (error) {
			console.error('Error during fetch request:', error);
			return false;
		}
	}
}
