// Myanmar (Burmese)
import { LocaleData } from 'ngx-bootstrap/chronos';

export const myLocale: LocaleData = {
	months: [
		'ဇန်နဝါရီ',
		'ဖေဖော်ဝါရီ',
		'မတ်',
		'ဧပြီ',
		'မေ',
		'ဇွန်',
		'ဇူလိုင်',
		'ဩဂုတ်',
		'စက်တင်ဘာ',
		'အောက်တိုဘာ',
		'နိုဝင်ဘာ',
		'ဒီဇင်ဘာ',
	],
	monthsShort: [
		'ဇန်',
		'ဖေ',
		'မတ်',
		'ဧပြီ',
		'မေ',
		'ဇွန်',
		'ဇူ',
		'ဩဂုတ်',
		'စက်',
		'အောက်',
		'နိုဝင်',
		'ဒီဇင်',
	],
	weekdays: [
		'တနင်္လာ',
		'အင်္ဂါ',
		'ဗုဒ္ဓဟူး',
		'ကြာသပတေး',
		'သောကြာ',
		'စနေ',
		'တနင်္ဂနွေ',
	],
	weekdaysShort: ['တန', 'အင်', 'ဗု', 'ကြာ', 'သော', 'စနေ', 'တနင်'],
	weekdaysMin: ['တ', 'အင်', 'ဗု', 'ကြာ', 'သော', 'စ', 'တ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[ယနေ့] LT',
		nextDay: '[မနက်ဖြန်] LT',
		nextWeek: 'dddd [မှာ] LT',
		lastDay: '[မနေ့က] LT',
		lastWeek: '[တစ်ပတ်က] dddd [မှာ] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s အတွင်း',
		past: '%s အခါက',
		s: 'အချိန်နည်းနည်း',
		m: 'တစ်မိနစ်',
		mm: '%d မိနစ်',
		h: 'တစ်နာရီ',
		hh: '%d နာရီ',
		d: 'တစ်ရက်',
		dd: '%d ရက်',
		M: 'တစ်လ',
		MM: '%d လ',
		y: 'တစ်နှစ်',
		yy: '%d နှစ်',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 0,
		doy: 6,
	},
};
