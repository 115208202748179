// Macedonian
import { LocaleData } from 'ngx-bootstrap/chronos';

export const mkLocale: LocaleData = {
	months: [
		'Јануари',
		'Февруари',
		'Март',
		'Април',
		'Мај',
		'Јуни',
		'Јули',
		'Август',
		'Септември',
		'Октомври',
		'Ноември',
		'Декември',
	],
	monthsShort: [
		'Јан',
		'Фев',
		'Мар',
		'Апр',
		'Мај',
		'Јун',
		'Јул',
		'Авг',
		'Сеп',
		'Окт',
		'Ное',
		'Дек',
	],
	weekdays: [
		'Недела',
		'Понеделник',
		'Вторник',
		'Среда',
		'Четврток',
		'Петок',
		'Сабота',
	],
	weekdaysShort: ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб'],
	weekdaysMin: ['Не', 'По', 'Вт', 'Ср', 'Че', 'Пе', 'Са'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Денес] LT',
		nextDay: '[Утре] LT',
		nextWeek: 'dddd [во] LT',
		lastDay: '[Вчера] LT',
		lastWeek: '[Минатата] dddd [во] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'за %s',
		past: '%s пред',
		s: 'неколку секунди',
		m: 'една минута',
		mm: '%d минути',
		h: 'еден час',
		hh: '%d часа',
		d: 'еден ден',
		dd: '%d дена',
		M: 'еден месец',
		MM: '%d месеци',
		y: 'една година',
		yy: '%d години',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
