import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SkillBuilderService {
	constructor(private http: HttpClient) {}

	getStats(): Observable<any> {
		return this.http.get<any>(`${config.apiBase}researcher/challenge/stats`);
	}
}
