import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	LABELS,
	LanguageService,
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	VOLATILE_KEYS,
} from '@services/public';
import { Subscription } from 'rxjs';
import { BRAIN_PAGE, BRAIN_PREVIOUS_PAGE, BrainData } from './common';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import { APIErrors, isAPIErrors } from '@util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

const PAGE_PATH: string = 'skillBuilders.brain.pages.main';

@Component({
	selector: 'app-member-skill-builder-brain',
	templateUrl: './brain.component.html',
	styleUrls: ['./brain.component.scss'],
})
export class MemberSkillBuilderBrainComponent implements OnInit, OnDestroy {
	@ViewChild('dailyCompletedTemplate', { static: false })
	dailyCompletedTemplate: TemplateRef<void>;
	BRAIN_PAGE: typeof BRAIN_PAGE = BRAIN_PAGE;
	data: BrainData;
	modalRef: BsModalRef;
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		reminder: '',
	};
	labels: { [key: string]: string } = {
		[LABELS.OK]: '',
	};

	constructor(
		private _router: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
		private _modalSvc: BsModalService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});
		this._languageSvc.getLabels(this.labels);

		// Get brain data.
		this.data = this._storageSvc.getTempStorage(TEMP_KEYS.BRAIN);
		const goalPlanUUID = this._storageSvc.getVolatileStorage(
			VOLATILE_KEYS.GOAL_PLAN_UUID,
			true,
		);
		if (!this.data) {
			this._skillBuilderSvc
				.getStatus(SKILL_BUILDER_TYPES.BRAIN, goalPlanUUID)
				.subscribe((res: BrainData | APIErrors) => {
					if (!isAPIErrors(res) && !this.data) {
						if (res.dailyCompleted) {
							this.modalRef = this._modalSvc.show(this.dailyCompletedTemplate, {
								class: 'modal-sm modal-dialog-centered',
								backdrop: 'static',
								keyboard: true,
							});
							return;
						}

						if (!res.start) {
							this.data = {
								...res,
								page: BRAIN_PAGE.IMPACT,
							};
						} else if (res.yesterdayCompleted) {
							this.data = {
								...res,
								page: BRAIN_PAGE.AFFIRMATIONS,
								yesterday: false,
							};
						} else {
							this.data = {
								...res,
								page: BRAIN_PAGE.YESTERDAY,
							};
						}

						this._setStorageSubscription();
					}
				});
		} else this._setStorageSubscription();
	}

	private _setStorageSubscription() {
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
					this.data = update.value[TEMP_KEYS.BRAIN];
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) this.data = null;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.BRAIN)
					this.data = update.value;
				if (!this.data && !this._router.getCurrentNavigation())
					this._router.navigate(['member/dashboard']);
			}),
		);
	}

	back() {
		const page: BRAIN_PAGE = BRAIN_PREVIOUS_PAGE[this.data.page];
		if (
			!page ||
			(page === BRAIN_PAGE.YESTERDAY && this.data.yesterdayCompleted)
		) {
			this._router.navigate(['member/dashboard']);
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.BRAIN, { ...this.data, page });
	}

	closePopup() {
		this.modalRef?.hide();
		this._router.navigate(['member/dashboard']);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this._storageSvc.setTempStorage(TEMP_KEYS.BRAIN, null);
	}
}
