import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { DecisionAction, DecisionData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { DecisionService } from '@services/member';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.decision.pages.ranks';

@Component({
	selector: 'app-member-skill-builder-decision-ranks',
	templateUrl: './ranks.component.html',
	styleUrls: ['./ranks.component.scss'],
})
export class MemberSkillBuilderDecisionRanksComponent implements OnInit {
	@Input() data: DecisionData;
	@ViewChild('reminder', { static: false }) reminderTemplate: TemplateRef<void>;
	actions: Array<DecisionAction> = [];
	modalRef: BsModalRef;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		highest: '',
		lowest: '',
		submit: '',
		reminder: '',
		ok: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _decisionSvc: DecisionService,
		private _router: Router,
		private _languageSvc: LanguageService,
		private _modalSvc: BsModalService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Sort actions by rank descending.
		this.actions = [...(<Array<DecisionAction>>this.data.actions)].sort(
			(a, b) => b.rank - a.rank,
		);
	}

	nextOnClick(): void {
		this._decisionSvc
			.createLog(this.data)
			.subscribe((res: APISuccess | APIErrors) => {
				if (isAPISuccess(res)) {
					this.modalRef = this._modalSvc.show(this.reminderTemplate, {
						class: 'modal-sm modal-dialog-centered',
						backdrop: 'static',
						keyboard: true,
					});
				}
			});
	}

	closeReminder(): void {
		this.modalRef?.hide();
		this._router.navigate(['member/dashboard']);
		this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, null);
	}
}
