// Samoan
import { LocaleData } from 'ngx-bootstrap/chronos';

export const smLocale: LocaleData = {
	months: [
		'Ianuari',
		'Februari',
		'Mati',
		'Aperila',
		'Me',
		'Iuni',
		'Iulai',
		'Aokuso',
		'Setema',
		'Oketopa',
		'Novema',
		'Tesema',
	],
	monthsShort: [
		'Ianu',
		'Feb',
		'Mat',
		'Ape',
		'Me',
		'Iun',
		'Iul',
		'Aok',
		'Set',
		'Oke',
		'Nov',
		'Tes',
	],
	weekdays: [
		'Aso Sa',
		'Aso Lua',
		'Aso Tolu',
		'Aso Fa',
		'Aso Faʻaʻuaʻu',
		'Aso Tofi',
		'Aso Sā',
	],
	weekdaysShort: ['AS', 'AL', 'AT', 'AF', 'AF', 'AT', 'AS'],
	weekdaysMin: ['AS', 'AL', 'AT', 'AF', 'AF', 'AT', 'AS'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Aso] LT',
		nextDay: '[Tālia] LT',
		nextWeek: 'dddd [i le] LT',
		lastDay: '[Ina] LT',
		lastWeek: '[Aso] dddd [o le] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'i %s',
		past: 'talu ai %s',
		s: 'sekoniti e iti',
		m: 'minute e tasi',
		mm: '%d minute',
		h: 'ora e tasi',
		hh: '%d ora',
		d: 'aso e tasi',
		dd: '%d aso',
		M: 'mese e tasi',
		MM: '%d mese',
		y: 'taʻitasi',
		yy: '%d tausaga',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1, // Monday is the first day of the week
		doy: 4, // The week that contains Jan 4 is the first week of the year
	},
};
