<div
	class="main-container w-100 d-flex align-items-center justify-content-center pb-lg"
>
	<div class="container px-0 w-sm-100 w-50">
		<h4 class="w-auto text-center mb-0 px-3">
			{{ page.title }}
		</h4>
		<div class="subtext">
			{{ page.subtitle }}
		</div>
		<div class="row">
			<div class="col-12 heading-1">
				<h4 class="text-center py-3">
					{{ page.howNegative }}
				</h4>
				<div class="col-12 mx-auto">
					<div class="row">
						<div
							*ngFor="let reason of data.negativeReasons; let i = index"
							class="col-sm-12 col-lg-6 mx-auto"
						>
							<app-skill-builder-star-rating
								[title]="extremeTitles[i]"
								[value]="reason.extreme"
								[left]="page.neutral"
								[right]="page.veryNegative"
								[change]="onChange.bind(this, i, 'extreme')"
								[submitted]="submitted"
								reverseColors="true"
							></app-skill-builder-star-rating>
						</div>
					</div>
				</div>
				<hr class="mx-auto" />
				<div class="col-12 mx-auto">
					<h4 class="text-center py-3">
						{{ page.howBig }}
					</h4>
					<div class="row">
						<div
							*ngFor="let reason of data.negativeReasons; let i = index"
							class="col-sm-12 col-lg-6 mx-auto"
						>
							<app-skill-builder-star-rating
								[title]="bigTitles[i]"
								[value]="reason.big"
								[left]="page.small"
								[right]="page.large"
								[change]="onChange.bind(this, i, 'big')"
								[submitted]="submitted"
								reverseColors="true"
							></app-skill-builder-star-rating>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-inner-action">
			<button
				type="button"
				(click)="nextOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ labels.next }}
			</button>
		</div>
	</div>
</div>
