import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GhostService {
	constructor(private http: HttpClient) {}

	ghost(email: string): Observable<any> {
		return this.http.post<any>(`${config.apiBase}jakapa/ghost`, { email });
	}
}
