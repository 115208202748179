<div class="modal-header px-0 pr-4 pt-md-3 pb-3">
	<h1 class="modal-title d-flex align-items-center justify-content-center">
		<label class="lbl-md pl-3 mb-0">{{ page.title }}</label>
	</h1>
</div>
<div class="modal-body modal-center px-0 pt-0 modal-scroll">
	<div class="d-flex align-items-center justify-content-center">
		{{ page.description }}
	</div>
</div>
<div>
	<div class="d-flex align-items-center justify-content-center">
		{{ page.instructions }}
	</div>
	<div class="d-flex justify-content-center" style="padding: 20px">
		<a href="https://buy.stripe.com/cN2dRJ4y37vtevm00j" target="_blank">
			<button class="btn btn-primary">{{ page.renew }}</button>
		</a>
		<button
			class="btn btn-primary"
			style="margin-left: 10px"
			(click)="logout()"
		>
			{{ page.logout }}
		</button>
	</div>
</div>
