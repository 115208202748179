import { Injectable } from '@angular/core';
import {
	WalkthroughToolTipBackdrop,
	WalkthroughToolTipScreenPosition,
	WalkthroughToolTipElementPosition,
} from '@services/public';
import { Router } from '@angular/router';
import { WalkthroughToolTipService } from '@services/public';

@Injectable({
	providedIn: 'root',
})
export class Guides {
	assessmentUUID: string = '';
	page: { [key: string]: string } = {
		activitiesLink: '',
		assessmentGuide1: '',
		assessmentGuide2: '',
		assessmentGuide3: '',
		assessmentGuide4: '',
		assessmentGuide5: '',
		createExternalButton: '',
		createExternalLink: '',
		externalForm: '',
		externalGuide1: '',
		externalGuide2: '',
		externalGuide3: '',
		externalGuide4: '',
		externalGuide5: '',
		externalGuide6: '',
		skillGuide1: '',
		skillGuide2: '',
		skillGuide3: '',
		skillGuide4: '',
		skillGuide5: '',
		skillGuide6: '',
		skillGuide7: '',
		skillGuide8: '',
		reportsGuide1: '',
		reportsGuide2: '',
		reportsGuide3: '',
		reportsGuide4: '',
		reportsGuide5: '',
		reportsGuide6: '',
		rewardsGuide1: '',
		rewardsGuide2: '',
		rewardsGuide3: '',
		rewardsGuide4: '',
		rewardsGuide5: '',
	};
	windowWidth: number = window.innerWidth;
	formValid: boolean = false;
	constructor(
		private _router: Router,
		private _walkthroughTTSvc: WalkthroughToolTipService,
	) {}

	setPage(key: string, value: string): void {
		this.page[key] = value;
	}

	setAssessmentUUID(uuid: string): void {
		this.assessmentUUID = uuid;
	}

	getGuides() {
		return [
			// Assessment guide 0
			{
				url: this._router
					.createUrlTree(['member/assessment/self', this.assessmentUUID])
					.toString(),
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'screen',
							position: WalkthroughToolTipScreenPosition.CENTER,
						},
						display: this.page.assessmentGuide1,
						next: this._walkthroughTTSvc.showNext,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.LEFT
									: WalkthroughToolTipElementPosition.TOP,
							element: `form > div > div:nth-child(2)
              > div.cardFormAnswer > div:nth-child(4)`,
						},
						display: this.page.assessmentGuide2,
						next: () => {
							this._walkthroughTTSvc.clickElement(`form > div >
                div:nth-child(2) > div.cardFormAnswer >
                div:nth-child(4)  input`);
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},

					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.TOP,
							element: `form > div > div:nth-child(3)
                > div.cardFormAnswer > div:nth-child(4)`,
						},
						display: this.page.assessmentGuide3,
						next: () => {
							this._walkthroughTTSvc.clickElement(`form > div >
                div:nth-child(3) > div.cardFormAnswer >
                div:nth-child(4)  input`);
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},

					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: 'button.btn-primary',
						},
						display: this.page.assessmentGuide4,
						clickElement: 'button.btn-primary',
						next: this._walkthroughTTSvc.hide,
						back: this._walkthroughTTSvc.showPrevious,
					},
				],
			},
			//Assessment guide 1
			{
				url: 'member/dashboard/reports',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'screen',
							position: WalkthroughToolTipScreenPosition.CENTER,
						},
						display: this.page.assessmentGuide5,
						next: this._walkthroughTTSvc.hide,
					},
				],
			},
			// Skill builder guide 2
			{
				url: 'member/skill-builder/goal',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.TOP,
							element: `app-skill-builder-select-list 
                .list-group.list-group-checkable > div`,
						},
						display: this.page.skillGuide1,
						next: this._walkthroughTTSvc.showNext,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.BOTTOM,
							element: `app-skill-builder-select-list > div:nth-child(1)
                > div > div > div > div:nth-child(4)`,
						},
						display: this.page.skillGuide2,
						clickElement: `app-skill-builder-select-list > div:nth-child(1)
                > div > div > div > div:nth-child(4)`,
						next: () => {
							this._walkthroughTTSvc.clickElement(
								`app-skill-builder-select-list > div:nth-child(1)
                  > div > div > div > div:nth-child(4) > input`,
							);
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: 'button.btn-primary',
							fixed: true,
						},
						display: this.page.skillGuide3,
						clickElement: 'button.btn-primary',
						next: () => {
							this._walkthroughTTSvc.clickElement('button.btn-primary');
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.BOTTOM,
							element: `app-skill-builder-select-list > div:nth-child(1)
                > div > div > div > div:nth-child(6)`,
						},
						display: this.page.skillGuide4,
						clickElement: `app-skill-builder-select-list > div:nth-child(1)
                > div > div > div > div:nth-child(6)`,
						next: () => {
							this._walkthroughTTSvc.clickElement(
								`app-skill-builder-select-list > div:nth-child(1)
                  > div > div > div > div:nth-child(6) > input`,
							);
							this._walkthroughTTSvc.showNext();
						},
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: 'button.btn-primary',
							fixed: true,
						},
						display: this.page.skillGuide3,
						clickElement: 'button.btn-primary',
						next: () => {
							this._walkthroughTTSvc.clickElement('button.btn-primary');
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.TOP,
							element: `app-skill-builder-select-list > div:nth-child(1)
                > div > div > div > div:nth-child(4)`,
						},
						display: this.page.skillGuide6,
						clickElement: `app-skill-builder-select-list > div:nth-child(1)
                > div > div > div > div:nth-child(4)`,
						next: () => {
							this._walkthroughTTSvc.clickElement(
								`app-skill-builder-select-list > div:nth-child(1)
                  > div > div > div > div:nth-child(4) > input`,
							);
							this._walkthroughTTSvc.showNext();
						},
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: 'button.btn-primary',
							fixed: true,
						},
						display: this.page.skillGuide7,
						disableElement: 'button.btn-primary',
						next: this._walkthroughTTSvc.hide,
						back: this._walkthroughTTSvc.showPrevious,
					},
				],
			},
			//Skill builder guide 3
			{
				url: 'member/dashboard/activities',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.GREY,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.BOTTOM,
							element: 'li#skill-builder-goal',
							redirect: true,
						},
						display: this.page.skillGuide8,
						next: this._walkthroughTTSvc.hide,
					},
				],
			},
			// External Assessment guide 4
			{
				url:
					this.windowWidth > 768
						? 'member/dashboard/activities'
						: 'member/tasks',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'screen',
							position: WalkthroughToolTipScreenPosition.CENTER,
						},
						display: this.page.externalGuide0,
						next: this._walkthroughTTSvc.showNext,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth < 769
									? WalkthroughToolTipElementPosition.BOTTOM
									: this.windowWidth < 1024
										? WalkthroughToolTipElementPosition.TOP
										: WalkthroughToolTipElementPosition.LEFT,
							element: 'ul.nav.nav-tabs li:nth-child(2)',
						},
						display: this.page.externalGuide1,
						clickElement: 'ul.nav.nav-tabs li:nth-child(2)',
						next: () => {
							this._walkthroughTTSvc.clickElement(
								'ul.nav.nav-tabs li:nth-child(2) a',
							);
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.LEFT,
							element: 'button#createExternal',
						},
						display: this.page.externalGuide2,
						clickElement: 'button#createExternal',
						next: () => {
							this._walkthroughTTSvc.clickElement('button#createExternal');
							const modal = document.querySelector(
								'app-member-assessment-external-create-or-update',
							);
							const assess = modal.querySelector(
								'#assessmentName',
							) as HTMLInputElement;
							assess.value = 'Test Assessment';
							assess.dispatchEvent(new Event('input'));
							const fName = modal.querySelector(
								'#firstName',
							) as HTMLInputElement;
							fName.value = 'Eloise';
							fName.dispatchEvent(new Event('input'));
							const lName = modal.querySelector(
								'#lastName',
							) as HTMLInputElement;
							lName.value = 'Rodriguez';
							lName.dispatchEvent(new Event('input'));
							const email = modal.querySelector('#email') as HTMLInputElement;
							email.value = 'you.see.123@jakapa.com';
							email.dispatchEvent(new Event('input'));
							const rela = modal.querySelector(
								'#relationship',
							) as HTMLInputElement;
							rela.value = 'friend';
							rela.dispatchEvent(new Event('input'));
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.LEFT
									: WalkthroughToolTipElementPosition.TOP,
							element: `app-member-assessment-external-create-or-update
                  button.btn.btn-primary`,
							disableScroll: true,
						},
						display: this.page.externalGuide3,
						clickElement: `app-member-assessment-external-create-or-update
                  button.btn.btn-primary`,
						next: () => {
							this._walkthroughTTSvc.hide();
							this._walkthroughTTSvc.clickElement(
								`app-member-assessment-external-create-or-update
                    button.btn.btn-primary`,
							);
						},
						// back: ()=> {
						//   this._walkthroughTTSvc.clickElement(`modal-container
						//   .modal-footer.bottomBtnsModal > button.cancel.mr-0`);
						//   this._walkthroughTTSvc.showPrevious();}
					},
				],
			},
			// External Guides 5
			{
				url: 'member/assessment/external/update-skills',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.GREY,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.TOP,
							element: `app-member-assessment-external-update-skills >
                  div.container-fluid.pb-xl.px-4 > div.cardDsplMain >
                  div:nth-child(1) > div.cardDsplLft`,
						},
						display: this.page.externalGuide4,
						clickElement: `app-member-assessment-external-update-skills >
                  div.container-fluid.pb-xl.px-4 > div.cardDsplMain >
                  div:nth-child(1) > div.cardDsplLft`,
						next: () => {
							if (this.windowWidth <= 768) {
								document
									.querySelectorAll(`.cardDsplLftInner`)
									.forEach((div) => (div as HTMLElement).click());
								(
									document.querySelector(`#collapse0 > ul >
                      li:nth-child(6) > div`) as HTMLElement
								).click();
								(
									document.querySelector(`#collapse1 > ul >
                       li:nth-child(3) > div`) as HTMLElement
								).click();
								(
									document.querySelector(`#collapse2 > ul >
                      li:nth-child(2) > div`) as HTMLElement
								).click();
								(
									document.querySelector(`#collapse4 > ul >
                      li:nth-child(3) > div`) as HTMLElement
								).click();
								this._walkthroughTTSvc.showNext();
							}
							this._walkthroughTTSvc.showNext();
						},
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.TOP
									: WalkthroughToolTipElementPosition.BOTTOM,
							element: '#collapse0 > ul > li:nth-child(6) > div',
						},
						display: this.page.externalGuide5,
						clickElement: '#collapse0 > ul > li:nth-child(6) > div',
						next: () => {
							if (this.windowWidth > 768) {
								this._walkthroughTTSvc.clickElement(
									`#collapse0 > ul > li:nth-child(6) > div`,
								);
								this._walkthroughTTSvc.clickElement(
									`#collapse1 > ul > li:nth-child(3) > div`,
								);
								this._walkthroughTTSvc.clickElement(
									`#collapse2 > ul > li:nth-child(2) > div`,
								);
								this._walkthroughTTSvc.clickElement(
									`#collapse4 > ul > li:nth-child(3) > div`,
								);
								this._walkthroughTTSvc.showNext();
							}
						},
						back: this._walkthroughTTSvc.showPrevious,
					},

					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: `div.footer-inner-action.timesheetBtns-c.btn-c
                  > button.btn.btn-primary`,
							disableScroll: true,
						},
						disableElement: `div.footer-inner-action.timesheetBtns-c.btn-c`,
						display: this.page.externalGuide6,
						next: () => {
							const inputs: NodeList = document.querySelectorAll(
								`app-member-assessment-external-create-or-update
                    > div.modal-body.external > form input`,
							);
							inputs.forEach((input: HTMLInputElement) => {
								input.value = '';
							});
							localStorage.setItem('createExternal', '');
							this._walkthroughTTSvc.hide();
						},
					},
				],
			},
			//External guide 6
			{
				url:
					this.windowWidth > 768
						? 'member/dashboard/activities'
						: 'member/tasks',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth < 769
									? WalkthroughToolTipElementPosition.BOTTOM
									: this.windowWidth < 1024
										? WalkthroughToolTipElementPosition.TOP
										: WalkthroughToolTipElementPosition.LEFT,
							element: 'ul.nav.nav-tabs li:nth-child(2)',
						},
						display: this.page.createExternalLink,
						clickElement: 'ul.nav.nav-tabs li:nth-child(2)',
						hideNext: true,
						next: () => {
							this._walkthroughTTSvc.clickElement(
								'ul.nav.nav-tabs li:nth-child(2) a',
							);
							this._walkthroughTTSvc.showNext();
						},
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.LEFT
									: WalkthroughToolTipElementPosition.BOTTOM,
							element: 'button#createExternal',
						},
						display: this.page.createExternalButton,
						clickElement: 'button#createExternal',
						hideNext: true,
						next: () => {
							this._walkthroughTTSvc.clickElement('button#createExternal');
							this._walkthroughTTSvc.showNext();
						},
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.TOP
									: WalkthroughToolTipElementPosition.BOTTOM,
							element: `modal-container .modal-content
                    app-member-assessment-external-create-or-update`,
							disableScroll: true,
						},
						display: this.page.externalForm,
						disableElement: `modal-container .modal-content
                app-member-assessment-external-create-or-update
                .modal-body.external .modal-footer button.cancel`,
						clickElement: `modal-container .modal-content
                app-member-assessment-external-create-or-update
                .modal-body.external .modal-footer >
                button.btn.btn-primary`,
						hideNext: true,
						next: () => {
							this._walkthroughTTSvc.clickElement(
								`modal-container .modal-content
                    app-member-assessment-external-create-or-update
                    .modal-body.external .modal-footer >
                    button.btn.btn-primary`,
							);
							this._walkthroughTTSvc.hide();
						},
					},
				],
			},
			// Rewards guide 7
			{
				url: 'member/dashboard/rewards',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth < 1024
									? WalkthroughToolTipElementPosition.TOP
									: WalkthroughToolTipElementPosition.LEFT,
							element:
								this.windowWidth > 768
									? `app-member-dashboard > div >
                   app-tabs > nav > a:nth-child(3)`
									: `app-member-main > app-member-main-header 
                  > nav.mobile-nav > ul > li:nth-child(4) > a`,
							disableScroll: true,
							fixed: this.windowWidth <= 768,
						},
						clickElement:
							this.windowWidth > 768
								? `app-member-dashboard > div >
                app-tabs > nav > a:nth-child(3)`
								: `app-member-main-header > nav.mobile-nav >
               ul > li:nth-child(4) a`,
						display: this.page.rewardsGuide1,
						next: this._walkthroughTTSvc.showNext,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'screen',
							position: WalkthroughToolTipScreenPosition.CENTER,
						},
						display: this.page.rewardsGuide2,
						next: this._walkthroughTTSvc.showNext,
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth < 1024
									? WalkthroughToolTipElementPosition.TOP
									: WalkthroughToolTipElementPosition.RIGHT,
							element: 'app-leaderboard > div',
						},
						display: this.page.rewardsGuide3,
						next: this._walkthroughTTSvc.showNext,
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth < 768
									? WalkthroughToolTipElementPosition.BOTTOM
									: this.windowWidth < 1024
										? WalkthroughToolTipElementPosition.TOP
										: WalkthroughToolTipElementPosition.LEFT,
							element: `app-member-dashboard-rewards-metrics`,
						},
						display: this.page.rewardsGuide4,
						next: this._walkthroughTTSvc.showNext,
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element:
								this.windowWidth > 1024
									? `app-member-dashboard-rewards-skill-builder-badges`
									: `.badges-small.hide-large`,
						},
						display: this.page.rewardsGuide5,
						next: this._walkthroughTTSvc.hide,
						back: this._walkthroughTTSvc.showPrevious,
					},
				],
			},
			// Reports guide 8
			{
				url: 'member/dashboard/reports',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'screen',
							position: WalkthroughToolTipScreenPosition.CENTER,
						},
						display: this.page.reportsGuide1,
						next: this._walkthroughTTSvc.showNext,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.RIGHT,
							element: 'div.boxHead.card-title-main.flex-wrap > h5',
						},
						display: this.page.reportsGuide2,
						next: this._walkthroughTTSvc.showNext,
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 768
									? WalkthroughToolTipElementPosition.RIGHT
									: WalkthroughToolTipElementPosition.TOP,
							element: `div.chartArea.px-0 > div.chart-wrapper`,
						},
						display: this.page.reportsGuide3,
						next: this._walkthroughTTSvc.showNext,
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position:
								this.windowWidth > 1023
									? WalkthroughToolTipElementPosition.LEFT
									: WalkthroughToolTipElementPosition.TOP,
							element: `div > div:nth-child(2) > select`,
						},
						display: this.page.reportsGuide4,
						clickElement: `div > div:nth-child(2) > select`,
						next: () => {
							this._walkthroughTTSvc.clickElement(
								`div > div:nth-child(2) > select`,
							);
							this._walkthroughTTSvc.showNext();
						},
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: ` div.dashContArea.dashContAreaStudent.px-5.mt-2
                  > div.row > div > div:nth-child(1) > div:nth-child(2)`,
						},
						display: this.page.reportsGuide5,
						next: this._walkthroughTTSvc.showNext,
						back: this._walkthroughTTSvc.showPrevious,
					},
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.TOP,
							element: `div.dashContArea.dashContAreaStudent.px-5.mt-2
                  > div.row > div > div:nth-child(2) > div`,
						},
						display: this.page.reportsGuide6,
						next: this._walkthroughTTSvc.hide,
						back: this._walkthroughTTSvc.showPrevious,
					},
				],
			},
			// Assessment Complete guide 9
			{
				url: 'member/dashboard/reports',
				tts: [
					{
						backdrop: WalkthroughToolTipBackdrop.CLEAR,
						position: {
							type: 'element',
							position: WalkthroughToolTipElementPosition.RIGHT,
							element:
								this.windowWidth > 768
									? '#member-dashboard-page-activities-tab'
									: `app-member-main-header > nav.mobile-nav
                  > ul > li:nth-child(1) > a`,
							disableScroll: true,
							fixed: this.windowWidth <= 768,
						},
						display: this.page.activitiesLink,
						clickElement:
							this.windowWidth > 768
								? '#member-dashboard-page-activities-tab'
								: `app-member-main-header > nav.mobile-nav
                  > ul > li:nth-child(1) > a`,
						hideNext: true,
						next: this._walkthroughTTSvc.hide,
					},
				],
			},
		];
	}
}
