import { Component, Input, OnInit } from '@angular/core';
import { JOURNAL_PAGE, JournalData } from '../common';
import {
	SelectListCustom,
	SelectListNext,
	SelectListOption,
} from '@components';
import {
	LanguageService,
	MAIN_KEYS,
	MAIN_KEY_PREFIX,
	StorageService,
	TEMP_KEYS,
} from '@services/public';
import { ReplaySubject, Subscription } from 'rxjs';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.journal.pages.confidence';

@Component({
	selector: 'app-member-skill-builder-journal-confidence',
	templateUrl: './confidence.component.html',
	styleUrls: ['./confidence.component.scss'],
})
export class MemberSkillBuilderJournalConfidenceComponent implements OnInit {
	@Input() data: JournalData;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	defaultOptions: Array<SelectListOption> = [];
	customOptions: Array<SelectListOption> = [];
	custom: SelectListCustom = {
		add: this.addCustom.bind(this),
		delete: this.deleteCustom.bind(this),
	};
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};
	defaultLoaded: ExposedPromise<void> = new ExposedPromise<void>();
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Get options list.
		const path: string = 'skillBuilders.journal.confidence';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] === 'object' && value[path] !== null)
				this.defaultOptions = Object.values(value[path]);
			this.defaultLoaded.resolve();
		});
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL) {
					this.customOptions = [];
					if (Array.isArray(update.value[MAIN_KEYS.JOURNAL_CONFIDENCE])) {
						for (const option of update.value[MAIN_KEYS.JOURNAL_CONFIDENCE]) {
							if (typeof option !== 'string') continue;
							this.customOptions.push({ display: option, custom: true });
						}
					}
					this._mergeAndSelectOptions();
				}
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR) {
					this.customOptions = [];
					this._mergeAndSelectOptions();
				}
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.JOURNAL_CONFIDENCE) {
					this.customOptions = [];
					if (Array.isArray(update.value)) {
						for (const option of update.value) {
							if (typeof option !== 'string') continue;
							this.customOptions.push({ display: option, custom: true });
						}
					}
					this._mergeAndSelectOptions();
				}
			}),
		);
		let options: Array<string> = this._storageSvc.getMainStorage(
			MAIN_KEYS.JOURNAL_CONFIDENCE,
		);
		if (Array.isArray(options)) {
			for (const option of options) {
				if (typeof option !== 'string') continue;
				this.customOptions.push({ display: option, custom: true });
			}
		}

		this._mergeAndSelectOptions();
	}

	private async _mergeAndSelectOptions(): Promise<void> {
		await this.defaultLoaded;
		this.options.next(
			[...this.customOptions, ...this.defaultOptions].map(
				(value: SelectListOption) => {
					if (value.display === this.data?.confidence)
						return { ...value, selected: true };
					return value;
				},
			),
		);
	}

	addCustom(customOption: string): void {
		if (
			this.customOptions.findIndex(
				(option) => option.display === customOption,
			) !== -1
		)
			return;
		if (
			this.defaultOptions.findIndex(
				(option) => option.display === customOption,
			) !== -1
		)
			return;
		this.customOptions.push({ display: customOption, custom: true });
		this._storageSvc.setMainStorage(
			MAIN_KEYS.JOURNAL_CONFIDENCE,
			this.customOptions.map((option) => option.display),
		);
	}

	deleteCustom(option: SelectListOption): void {
		this.customOptions = this.customOptions.filter(
			(customOption) => customOption.display !== option.display,
		);
		if (this.customOptions.length === 0)
			this._storageSvc.setMainStorage(MAIN_KEYS.JOURNAL_CONFIDENCE, null);
		else
			this._storageSvc.setMainStorage(
				MAIN_KEYS.JOURNAL_CONFIDENCE,
				this.customOptions.map((option) => option.display),
			);
	}

	nextOnClick(option: SelectListOption): void {
		const data: JournalData = Object.assign({}, this.data, {
			page: JOURNAL_PAGE.EMOTIONS,
			confidence: option.display,
		});
		this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, data);
	}
}
