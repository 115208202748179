<div class="container certificate">
	<header class="certificate-header">
		<div class="title-wrapper">
			<div class="title-text">
				<span
					*ngFor="let letter of letters; index as i"
					class="title-letter key"
					[ngClass]="'key' + i"
					>{{ letter }}</span
				>
			</div>
		</div>
	</header>
	<div class="certificate-main-content">
		<div class="main-text-wrapper">
			<div class="congrats">
				<h3>{{ page.congrats }}</h3>
			</div>
			<div *ngIf="certificateData.length < 2" class="main-text">
				<p class="card-text">{{ page.messageOne }}</p>
			</div>
			<div *ngIf="certificateData.length > 1" class="main-text">
				<p class="card-text">{{ page.messageMany }}</p>
			</div>
		</div>
		<div class="certificate-download-wrapper">
			<div
				class="certificate-download"
				*ngFor="let certificate of certificateData"
			>
				<img src="assets/img/certificate/certificate.svg" alt="certificate" />
				<div class="certificate-data">
					<div class="cert-title">
						<span class="certificate-title">
							{{
								certificate.type == 'weekly'
									? page.weekly
									: domains[certificate.domain_uid]
							}}</span
						>
						<span>{{ page.level }} {{ certificate.level }}</span>
					</div>
					<div class="cert-date">
						<span>{{ certificate.achieved | date }}</span>
					</div>
				</div>
				<button
					(click)="
						downloadCertificate(
							certificate.type,
							certificate.level,
							certificate.domain_uid,
							certificate.achieved,
							language
						)
					"
					type="button"
					class="btn btn-md cert-btn"
				>
					{{ page.download | lowercase }}
				</button>
			</div>
		</div>
	</div>
	<button
		#closebutton
		type="button"
		class="close close-top"
		data-dismiss="modal"
		(click)="close()"
		aria-label="labels.close"
	>
		<span aria-hidden="true">&times;</span>
	</button>
</div>
