import { Component, OnInit, Input } from '@angular/core';
import {
	UntypedFormGroup,
	Validators,
	UntypedFormControl,
	ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	LABELS,
	LanguageService,
	WalkthroughToolTipService,
} from '@services/public';
import { MemberOnboardingService } from '@services/member';
import { keyPressOnlyAlphabets, EMAIL_PATTERN } from '@util/common';

const PAGE_PATH = 'pages.member.assessment.external.pages.createOrUpdate';

@Component({
	selector: 'app-member-assessment-external-create-or-update',
	templateUrl: './create.or.update.component.html',
	styleUrls: ['./create.or.update.component.scss'],
})
export class MemberAssessmentExternalCreateOrUpdateComponent implements OnInit {
	// Expose this function to the template.
	keyPressOnlyAlphabets: typeof keyPressOnlyAlphabets = keyPressOnlyAlphabets;

	@Input() public set assessmentData(data: any) {
		if (data) {
			this._assessmentData = data;
			this.form.patchValue(data);
		}
	}
	public get assessmentData(): any {
		return this._assessmentData;
	}

	form: UntypedFormGroup;
	private _assessmentData: any;
	page: { [key: string]: string } = {
		assessmentName: '',
		assessmentNameRequired: '',
		email: '',
		emailInvalid: '',
		emailRequired: '',
		firstName: '',
		firstNameRequired: '',
		lastName: '',
		modalTitle: '',
		nameLength: '',
		relationship: '',
		relationshipRequired: '',
		title: '',
	};

	labels: { [key: string]: string } = {
		[LABELS.CLOSE]: '',
		[LABELS.SAVE]: '',
		[LABELS.CANCEL]: '',
	};
	// Language Translation
	pageLanguage: any;
	appLabels: any;
	basePath = `pages.member.assessment`;

	constructor(
		private router: Router,
		private modalSvc: BsModalService,
		private _languageSvc: LanguageService,
		private _walkthroughSvc: WalkthroughToolTipService,
		private _memberOnboardingSvc: MemberOnboardingService,
	) {}

	ngOnInit(): void {
		this.form = new UntypedFormGroup({
			uuid: new UntypedFormControl(null),
			assessmentName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(40),
				this.noWhitespaceValidator1,
			]),
			firstName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(40),
				this.noWhitespaceValidator1,
			]),
			lastName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(40),
				this.noWhitespaceValidator1,
			]),
			email: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(EMAIL_PATTERN),
				this.noWhitespaceValidator1,
			]),
			relationship: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(40),
				this.noWhitespaceValidator1,
			]),
		});
		const pageLanguagePath = this.basePath + `.external.pages.createOrUpdate`;
		this._languageSvc.get([pageLanguagePath]).then((value) => {
			this.pageLanguage = value[pageLanguagePath];
			this._walkthroughSvc.updateElement();
		});
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
			}
		});
		this._languageSvc.getLabels(this.labels);
	}

	noWhitespaceValidator1(control: UntypedFormControl): ValidationErrors | null {
		const isWhitespace = (control.value || '').trim().length === 0;
		return isWhitespace ? { whitespace: true } : null;
	}

	get f(): any {
		return this.form.controls;
	}

	public onSave() {
		// Validate form.
		this.form.markAllAsTouched();
		if (this.form.invalid) return;
		localStorage.setItem('createExternal', JSON.stringify(this.form.value));
		this._walkthroughSvc.emitExternalFormValid({ valid: true });
		this.modalSvc.hide();
		this.router.navigate(['member/assessment/external/update-skills']);
	}

	closeModal() {
		if (this._memberOnboardingSvc.isRunning) {
			return;
		}
		this.modalSvc.hide();
	}
}
