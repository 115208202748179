import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@services/member/notifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { LoaderService, LanguageService } from '@services/public';
import { FeatureModalComponent } from '@pages/member/feature-modal/feature-modal.component';
const PAGE_PATH = `pages.researcher.dashboard.page`;
@Component({
	selector: 'app-researcher-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class ResearcherDashboardComponent implements OnInit {
	IsTrack: any = false;
	// User Update Notifications
	notifications: Array<object> = null;
	modalRef?: BsModalRef;
	// Page language.
	page: { [key: string]: string } = {};
	constructor(
		private _languageSvc: LanguageService,
		private modalSvc: BsModalService,
		private notificationsSvc: NotificationsService,
		private loaderSvc: LoaderService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		this.loadFeatureNotifications();
	}
	changeTab(e) {
		if (e === 'Track') {
			this.IsTrack = true;
		}
	}
	loadFeatureNotifications() {
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.notificationsSvc
			.loadNotifications()
			.pipe(
				map((response: any) => {
					if (!response.errors) {
						this.notifications = response.notificationsToDisplay;
						this.loaderSvc.removeLoader(loader);
						if (this.notifications.length !== 0) this.showNotificationPopup();
					}
				}).bind(this),
			)
			.subscribe();
	}

	showNotificationPopup() {
		const initialState = { userNotifications: this.notifications };
		this.modalRef = this.modalSvc.show(FeatureModalComponent, { initialState });
	}
}
