import {
	Component,
	OnInit,
	TemplateRef,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	TEMP_KEYS,
	StorageService,
	TEMP_KEY_PREFIX,
	LogoutService,
	LanguageService,
} from '@services/public';
import { Subscription } from 'rxjs';
import { application } from 'environment';

const PAGE_PATH: string = 'pages.public.settings';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
	modalRef?: BsModalRef;
	message?: string;
	@ViewChild('slidingPanel', { read: ElementRef }) slidingPanel: ElementRef;
	@ViewChild('template') template: TemplateRef<any>;
	isGhost: boolean;
	application: boolean;
	pageLanguage: any;
	isSlidingPanelOpen = false;
	private _subscriptions: Subscription = new Subscription();

	constructor(
		private router: Router,
		private modalSvc: BsModalService,
		private logoutSvc: LogoutService,
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc
			.get([PAGE_PATH])
			.then((value) => (this.pageLanguage = value[PAGE_PATH]));
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) {
					this.isGhost = false;
				}
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.IS_GHOST)
					this.isGhost = update.value;
			}),
		);
		this.isGhost = !!this._storageSvc.getTempStorage(TEMP_KEYS.IS_GHOST);
		// @ts-ignore
		this.application = application === 'jakapa.website';
	}

	ngAfterViewInit(): void {
		document.addEventListener('click', (e) => {
			if (
				!!this.isSlidingPanelOpen &&
				e.target !== this.slidingPanel.nativeElement &&
				!(e.target as HTMLElement).classList.contains('list-btn') &&
				!this.slidingPanel.nativeElement.contains(e.target)
			)
				this.isSlidingPanelOpen = false;
		});
	}

	logout(): void {
		this.logoutSvc.logout();
		this.router.navigate(['/']);
	}

	openModal(template: any) {
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-md modal-dialog-centered',
			backdrop: 'static',
			keyboard: true,
		});
	}
	resources() {
		window.open('https://jakapa.com/resources');
	}

	decline(): void {
		this.message = this.pageLanguage?.declined;
		this.modalRef?.hide();
	}

	toggleSlidingPanel() {
		this.isSlidingPanelOpen = !this.isSlidingPanelOpen;
	}
}
