// Assamese

import { LocaleData } from 'ngx-bootstrap/chronos';

export const asLocale: LocaleData = {
	months: [
		'জানুৱাৰী',
		'ফেব্ৰুৱাৰী',
		'মাৰ্চ',
		'এপ্ৰিল',
		'মে',
		'জুন',
		'জুলাই',
		'আগষ্ট',
		'ছেপ্টেম্বৰ',
		'অক্টোবৰ',
		'নৱেম্বৰ',
		'ডিচেম্বৰ',
	],
	monthsShort: [
		'জানু',
		'ফেব্ৰু',
		'মাৰ্চ',
		'এপ্ৰি',
		'মে',
		'জুন',
		'জুলাই',
		'আগ',
		'ছেপ্টে',
		'অক্টো',
		'নৱে',
		'ডিচে',
	],
	weekdays: [
		'দেওবাৰ',
		'সোমবাৰ',
		'মঙ্গলবাৰ',
		'বুধবাৰ',
		'বৃহস্পতিবাৰ',
		'শুক্ৰবাৰ',
		'শনিবাৰ',
	],
	weekdaysShort: ['দেও', 'সোম', 'মঙ্গল', 'বুধ', 'বৃহস্পতি', 'শুক্ৰ', 'শনি'],
	weekdaysMin: ['দে', 'সো', 'ম', 'বু', 'বৃ', 'শু', 'শ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[আজি] LT',
		nextDay: '[কালি] LT',
		nextWeek: 'dddd [দিন] LT',
		lastDay: '[কালি] LT',
		lastWeek: '[গতিকাল] dddd [দিন] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s পিচত',
		past: '%s পূৰ্বে',
		s: 'কিছু সেকেণ্ড',
		m: 'এখন মিনিট',
		mm: '%d মিনিট',
		h: 'এখন ঘণ্টা',
		hh: '%d ঘণ্টা',
		d: 'এখন দিন',
		dd: '%d দিন',
		M: 'এখন মাহ',
		MM: '%d মাহ',
		y: 'এটা বছৰ',
		yy: '%d বছৰ',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 0,
		doy: 4,
	},
};
