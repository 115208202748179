<!-- /.content-header -->
<div class="tabHeadMain innertabHeadMain-sm px-5">
	<div class="container">
		<div class="tabHead">
			<span [routerLink]="['/leader/assessment/skills']">
				<i class="fas fa-arrow-left subgroup-back"></i>
			</span>
			{{ page?.subgroupAssigner }}
		</div>
	</div>
</div>

<div
	*ngIf="
		assessment.status === AssessmentStatus.INACTIVE ||
		assessment.status === AssessmentStatus.SCHEDULED
	"
	class="container"
>
	<div class="row">
		<div class="col-xs-12 col-sm-4 col-md-3">
			<div class="pageTopCard">
				<div class="topCardLbl">
					<h5>{{ page?.assessmentDetail }}</h5>
				</div>

				<div class="topCardCntr">
					<div class="row">
						<div class="col-xs-12 col-6 topCardCol">
							<label>{{ page?.assessmentName }}</label>
							<span>{{ assessment.name }}</span>
						</div>
						<div class="col-xs-12 col-6 topCardCol">
							<label>{{ page?.AssessmentType }}</label>
							<span class="text-capitalize">{{
								!!assessment.type ? assessment.type : '-'
							}}</span>
						</div>
						<div class="col-xs-12 col-6 topCardCol">
							<label>{{ page?.groupName }}</label>
							<span>{{ assessment.group.name }}</span>
						</div>
						<div class="col-xs-12 col-6 topCardCol">
							<label>{{ page?.startDate }}</label>
							<span>{{ assessment.startDate | date: 'MM-dd-yyyy' }}</span>
						</div>
						<div class="col-xs-12 col-6 topCardCol">
							<label>{{ page?.endDate }}</label>
							<span>{{ assessment.endDate | date: 'MM-dd-yyyy' }}</span>
						</div>
						<div class="col-xs-12 col-6 topCardCol">
							<label>{{ page?.status }}</label>
							<span>{{ assessment.status }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-xs-12 col-sm-6 col-md-9">
			<div class="group-modal">
				<div class="groups">
					<div class="row">
						<div class="col groupRytTop">
							<p>
								{{ page?.instructions }}
							</p>
							<ng-container *ngIf="assessment.users.length < 2">
								<p>{{ page?.notEnoughUsers }}</p>
							</ng-container>
						</div>
					</div>
					<ng-container *ngIf="assessment.users.length >= 2">
						<div cdkDropListGroup class="row groupRytBot">
							<div class="col-5">
								<h5>{{ page?.unassigned }}</h5>
								<div class="group-container">
									<div
										cdkDropList
										class="group-list"
										[cdkDropListData]="unassignedUsers"
										(cdkDropListDropped)="dropUser($event)"
									>
										<div
											(mousedown)="toggleSelectedPeerClass($event)"
											class="group-box peers"
											*ngFor="let user of unassignedUsers"
											cdkDrag
										>
											{{ user.name }}
										</div>
									</div>
								</div>
							</div>

							<div class="col-2 pt-5">
								<div class="double-arrows">
									<i class="fas fa-exchange-alt"></i>
								</div>
							</div>

							<div class="col-5 groupRyt">
								<div class="group-container">
									<h5>{{ page?.subgroups }}</h5>
									<ng-container *ngFor="let subgroup of assessment.subgroups">
										<div
											cdkDropList
											class="group-list"
											[cdkDropListData]="subgroup"
											(cdkDropListDropped)="dropUser($event)"
										>
											<div
												class="group-box"
												*ngFor="let user of subgroup"
												cdkDrag
											>
												{{ user.name }}
											</div>
										</div>
									</ng-container>
									<div
										cdkDropList
										class="group-list"
										[cdkDropListData]="newSubgroup"
										(cdkDropListDropped)="dropUser($event)"
									></div>
									<div
										*ngIf="oneUserSubgroup || noSubgroups"
										class="invalid-feedback mb-4"
									>
										<div *ngIf="oneUserSubgroup">
											{{ page?.minimumUsers }}
										</div>
										<div *ngIf="noSubgroups">
											{{ page?.minimumSubgroupsAssigned }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="footer-inner-action btn-c">
					<button routerLink="/leader/dashboard" class="btn btn-md">
						{{ page?.cancel }}
					</button>

					<button
						[disabled]="
							assessment.users.length < 2 || oneUserSubgroup || noSubgroups
						"
						class="btn btn-primary"
						(click)="onSubmit()"
					>
						{{ page?.save }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="assessment.status === AssessmentStatus.COMPLETED">
	<div class="emptyAssmntMain">
		<div class="emptyAssmntInner">
			<span><i class="fa fa-check-circle done" aria-hidden="true"></i></span>
			<p>{{ page?.alreadyCompleted }}</p>
		</div>
	</div>
</div>
<div *ngIf="assessment.status === AssessmentStatus.ACTIVE">
	<div class="emptyAssmntMain">
		<div class="emptyAssmntInner">
			<span><i class="fa fa-check-circle done" aria-hidden="true"></i></span>
			<p>{{ page?.alreadyActive }}</p>
		</div>
	</div>
</div>
