<div class="card card-rounded components-skill-builder-calendar">
	<div class="card-header">{{ title }}</div>
	<div class="card-body d-flex align-items-start justify-content-center">
		<div class="calendarMain custom-cal">
			<div class="col-12 px-0 d-flex">
				<div class="col-sm-10 col-md-8 text-left cal-head">
					<h3>{{ viewDate | calendarDate: 'monthViewTitle' : 'en' }}</h3>
				</div>
				<div class="col-sm-2 col-md-4 text-right">
					<div class="btn-group">
						<div
							class="btn-link"
							mwlCalendarPreviousView
							view="month"
							[(viewDate)]="viewDate"
						>
							<i class="fas fa-angle-left"></i>
						</div>
						<div
							class="btn-link"
							mwlCalendarNextView
							view="month"
							[(viewDate)]="viewDate"
						>
							<i class="fas fa-angle-right"></i>
						</div>
					</div>
				</div>
				<br />
			</div>
			<mwl-calendar-month-view
				[viewDate]="viewDate"
				[refresh]="refresh"
				(beforeViewRender)="beforeMonthViewRender($event)"
				(dayClicked)="dayClicked($event)"
			>
			</mwl-calendar-month-view>
			<div class="col-12">
				<ul class="legend">
					<li *ngFor="let item of legend">
						<span class="{{ item.color }} circle-sm"></span>
						{{ item.display }}
					</li>
				</ul>
				<ul class="legend">
					<li>
						{{ subtext }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<ng-template #journalSummary>
	<div class="modal-body modal-center">
		<div class="d-flex align-items-center justify-content-center"></div>
		<p
			class="mt-3 text-center success-msg px-5 title-main font-weight-normal"
			[innerHTML]="summary"
		></p>
		<div class="pt-4 d-flex align-items-center justify-content-center">
			<button
				(click)="okOnClick()"
				type="button"
				class="btn btn-md btn-bordered-purple"
			>
				{{ labels.ok }}
			</button>
		</div>
	</div>
</ng-template>
