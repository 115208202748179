import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export type LogoutFunction = () => void | Promise<void>;

export enum LOGOUT_EVENT {
	CLEAR_APIS = 0,
	PRE_API = 1,
	API = 2,
	POST_API = 3,
}

interface LogoutQueueEntry {
	event: LOGOUT_EVENT;
	func: LogoutFunction;
}

@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	private _logoutQueue: Map<symbol, LogoutQueueEntry> = new Map<
		symbol,
		LogoutQueueEntry
	>();

	constructor(private _router: Router) {}

	/**
	 * Adds functions to the logout queue.
	 * @param {LOGOUT_EVENT} event When the function should be called.
	 * @param {LogoutFunction} func The function to call.
	 * @param {symbol?} key Optional. The function key.
	 */
	subscribe(
		event: LOGOUT_EVENT,
		func: LogoutFunction,
		key: symbol = Symbol(),
	): void {
		this._logoutQueue.set(key, { event, func });
	}

	/**
	 * Unsubscribes a function from the logout queue.
	 * @param {symbol} key The function's key.
	 */
	unsubscribe(key: symbol): void {
		this._logoutQueue.delete(key);
	}

	/**
	 * Call all functions in the logout queue.
	 * @param {boolean?} callAPI If the logout API should be called. Defaults to true.
	 */
	async logout(callAPI: boolean = true): Promise<void> {
		for (const entry of [...this._logoutQueue.values()].sort(
			(a, b) => a.event - b.event,
		)) {
			if (!callAPI && entry.event === LOGOUT_EVENT.API) continue;
			await entry.func();
		}
		this._router.navigate(['/']);
	}
}
