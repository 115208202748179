import {
	OnInit,
	AfterViewInit,
	Component,
	Input,
	ViewChild,
	ElementRef,
	OnDestroy,
} from '@angular/core';
import { VideoViewerService } from '@services/member/video-viewer.service';
import { WeeklyChallengeService } from '../challenges/services/weekly-challenge.service';
import { LanguageService } from '@services/public';

@Component({
	selector: 'app-video-viewer',
	templateUrl: './video-viewer.component.html',
	styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() videoSrc: string;
	@Input() challengeID: string;
	@Input() transcript: string;
	@ViewChild('videoPlayer') videoPlayer!: ElementRef;
	poster: string;
	language: string;
	videos: string[] = [];

	constructor(
		private _videoViewerSvc: VideoViewerService,
		private _weeklyChallengeSvc: WeeklyChallengeService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this.language = this._languageSvc.language;
		this.poster = '/assets/img/videothumbnail.jpg';
		for (const encode of ['webm', 'mp4', 'ogv']) {
			this.addVideoLinks(encode);
		}
	}

	ngAfterViewInit(): void {
		this._videoViewerSvc.init(this.videoPlayer.nativeElement);
		this._weeklyChallengeSvc.challengeID$.subscribe((challengeID) => {
			this.challengeID = challengeID;
		});
	}

	async addVideoLinks(encode: string): Promise<void> {
		const link = `https://Jakapa.b-cdn.net/${this.language}/${this.videoSrc}.${encode}`;
		try {
			const isValid = await this.checkLinkWithoutCORS(link);
			if (isValid) {
				this.videos.push(link);
			} else {
				this.videos.push(
					`https://Jakapa.b-cdn.net/eng-${this.videoSrc}.${encode}`,
				);
			}
		} catch (error) {
			console.error('Error checking link:', error);
		}
	}

	async checkLinkWithoutCORS(url: string): Promise<boolean> {
		try {
			const response = await fetch(url, { method: 'HEAD' });
			return response.ok;
		} catch (error) {
			console.error('Error during fetch request:', error);
			return false;
		}
	}

	handleVideoEnded(): void {
		this.closeVideoPlayer();
	}

	closeVideoPlayer(): void {
		this._videoViewerSvc.stop();
	}

	getFileExtension(filename: string): string {
		return filename.split('.').pop()?.toLowerCase() || '';
	}

	playVideo(): void {
		this._videoViewerSvc.play();
	}

	ngOnDestroy(): void {
		this._videoViewerSvc.stop();
	}
}
