import { Injectable } from '@angular/core';
import { ImageChartEntry } from './common';
import { AssessmentScores } from '../assessment-scores.service';
import { Domain } from '@services/public';

@Injectable({
	providedIn: 'root',
})
export class StrengthsAndWeaknessesService {
	constructor() {}

	generateReport(
		scores: AssessmentScores,
		domain: Domain,
	): Array<ImageChartEntry> {
		const report: Array<ImageChartEntry> = [];

		// Get skill UIDs and names.
		const compiledScores = {};
		domain.skills.forEach(
			(skill) => (compiledScores[skill.uid] = { name: skill.name, users: {} }),
		);

		// Filter to the most recent self data per user.
		scores.self.forEach(({ userUUID, skillUID, date, score }) => {
			if (!compiledScores[skillUID].users[userUUID])
				compiledScores[skillUID].users[userUUID] = {};
			if (!compiledScores[skillUID].users[userUUID].self)
				compiledScores[skillUID].users[userUUID].self = { date, score };
			else if (
				new Date(date) >
				new Date(compiledScores[skillUID].users[userUUID].self.date)
			)
				compiledScores[skillUID].users[userUUID].self = { date, score };
		});

		// Calculate labels and scores.
		for (const skillUID in compiledScores) {
			const entry: ImageChartEntry = {
				name: compiledScores[skillUID].name,
				image: 'graph-improvement.svg',
			};
			const selfScores: Array<number> = Object.values(
				compiledScores[skillUID].users,
			)
				.map((user: any) => (!user.self ? null : user.self.score))
				.filter((score) => score !== null);
			const score: number = !selfScores.length
				? null
				: +(selfScores.reduce((a, b) => a + b, 0) / selfScores.length).toFixed(
						1,
					);
			if (!score) entry.image = 'graph-insufficient1.svg';
			else if (score > 4) entry.image = 'graph-good.svg';
			else if (score > 3) entry.image = 'graph-ok.svg';
			report.push(entry);
		}
		return report;
	}
}
