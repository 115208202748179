<div
	class="info-card {{ type == 'reading' ? 'domain' : 'skb' }}
  {{ showFooter ? 'show-footer' : '' }}"
>
	<div class="domain-logo {{ type == 'reading' ? 'domain' : 'skb' }}">
		<div class="{{ type == 'reading' ? domain : type }} icon"></div>
	</div>
	<div class="header {{ type == 'reading' ? 'domain' : 'skb' }}">
		<h2>{{ header }}</h2>
	</div>
	<div *ngIf="isHTML(body)">
		<div [innerHTML]="body" class="body"></div>
	</div>
	<div *ngIf="!isHTML(body)" #plainText class="body">
		{{ body }}
	</div>
	<div class="card-footer {{ type == 'reading' ? 'bg-reading' : 'bg-skb' }}">
		<div class="card-close button-pushable" role="button">
			<button #carouselClose class="button-shadow" (click)="onClose()">
				<span
					class="button-inner {{
						type == 'reading' ? 'icon reading' : 'icon skb'
					}} "
				></span>
				<span class="button-front text"></span>
			</button>
		</div>
	</div>
</div>
