import { Component, Input, OnInit } from '@angular/core';
import { LABELS, LanguageService } from '@services/public';

export interface ListItem {
	left: string;
	right?: string;
}

@Component({
	selector: 'app-skill-builder-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
})
export class SkillBuilderListComponent implements OnInit {
	ICON_CLASSES: { [key: string]: string } = {
		UP: 'double-up',
		DOWN: 'double-down',
	};
	@Input() title: string;
	@Input() data: Array<ListItem>;
	@Input() showMin: number = 3;
	show: number = 3;

	// Page langauge.
	labels: { [key: string]: string } = {
		[LABELS.VIEW_LESS]: '',
		[LABELS.VIEW_MORE]: '',
	};

	constructor(private _languageSvc: LanguageService) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.getLabels(this.labels);

		this.show = this.showMin;
	}

	toggleMore() {
		this.show = this.data.length > this.show ? this.data.length : this.showMin;
	}
}
