import { Component, Input, OnInit } from '@angular/core';
import {
	CalendarColors,
	CalendarDate,
	CalendarEvent,
	CalendarLegend,
	ListItem,
} from '@components';
import { StressService } from '@services/member';
import { LanguageService } from '@services/public';
import { APINoData, isAPINoData } from '@util';
import { StressReportData } from '../common';
import { ExposedPromise, JDate } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.stress.pages.report';
const SCORE_CALENDAR_COLORS: { [key: number]: CalendarColors } = {
	3: CalendarColors.VERY_BAD,
	2: CalendarColors.BAD,
	1: CalendarColors.OKAY,
	0: CalendarColors.GOOD,
};

interface DestressItem {
	destress: string;
	0: number;
	1: number;
	2: number;
}

@Component({
	selector: 'app-member-skill-builder-stress-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderStressReportComponent implements OnInit {
	ICON_CLASSES: { [key: string]: string } = {
		UP: 'double-up',
		DOWN: 'double-down',
	};
	SHOW_MIN: number = 3;
	@Input() noData: () => void;
	display: boolean = false;
	calendarEvents: Map<CalendarDate, CalendarEvent> = new Map<
		CalendarDate,
		CalendarEvent
	>();
	calendarLegend: Array<CalendarLegend> = [];
	stressLevelColor: CalendarColors;
	show: number = 3;
	destressList: Array<DestressItem> = [];
	symptomsList: Array<ListItem> = [];
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		calendarTitle: '',
		notStressed: '',
		sometimesStressed: '',
		mostlyStressed: '',
		alwaysStressed: '',
		stressLevel: '',
		stressLevelNotStressed: '',
		stressLevelSometimesStressed: '',
		stressLevelMostlyStressed: '',
		stressLevelAlwaysStressed: '',
		destressTitle: '',
		veryHelpful: '',
		somewhatHelpful: '',
		notHelpful: '',
		viewMore: '',
		viewLess: '',
		symptomsTitle: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _stressSvc: StressService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<any>> = [];
			for (const key in this.page)
				promises.push(
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value)),
				);
			Promise.all(promises).then(() => this._languageLoaded.resolve());
		});

		await this._languageLoaded;

		this._stressSvc
			.getReport()
			.subscribe((res: StressReportData | APINoData) => {
				if (!isAPINoData(res)) {
					this.display = true;
					const stressCounts: { [key: number]: number } = {
						0: 0,
						1: 0,
						2: 0,
						3: 0,
					};
					const destressCounts: Array<DestressItem> = [];
					const lastMonth: JDate = new JDate().addDays(-30);
					for (const log of res.logs) {
						this.calendarEvents.set(log.date, {
							color: SCORE_CALENDAR_COLORS[log.stress],
						});
						if (JDate.compareDays(lastMonth, new JDate(log.date)) > 0)
							stressCounts[log.stress]++;
						const destress = destressCounts.find(
							(value) => value.destress === log.destress,
						);
						if (!destress)
							destressCounts.push({
								destress: log.destress,
								0: log.impact === 0 ? 1 : 0,
								1: log.impact === 1 ? 1 : 0,
								2: log.impact === 2 ? 1 : 0,
							});
						else destress[log.impact]++;
					}
					this.calendarLegend = [
						{
							color: CalendarColors.GOOD,
							display: this.page.notStressed,
						},
						{
							color: CalendarColors.OKAY,
							display: this.page.sometimesStressed,
						},
						{
							color: CalendarColors.BAD,
							display: this.page.mostlyStressed,
						},
						{
							color: CalendarColors.VERY_BAD,
							display: this.page.alwaysStressed,
						},
					];
					const sortedStressCounts: Array<Array<string | number>> =
						Object.entries(stressCounts).sort((a, b) => {
							if (a[1] < b[1]) return 1;
							if (a[1] > b[1]) return -1;
							return +a[0] - +b[0];
						});
					if (sortedStressCounts[0][1] !== 0) {
						switch (+sortedStressCounts[0][0]) {
							case 0:
								this.page.stressLevel = this.page.stressLevelNotStressed;
								this.stressLevelColor = SCORE_CALENDAR_COLORS[0];
								break;
							case 1:
								this.page.stressLevel = this.page.stressLevelSometimesStressed;
								this.stressLevelColor = SCORE_CALENDAR_COLORS[1];
								break;
							case 2:
								this.page.stressLevel = this.page.stressLevelMostlyStressed;
								this.stressLevelColor = SCORE_CALENDAR_COLORS[2];
								break;
							case 3:
								this.page.stressLevel = this.page.stressLevelAlwaysStressed;
								this.stressLevelColor = SCORE_CALENDAR_COLORS[3];
								break;
						}
					}
					this.destressList = destressCounts
						.map((value) => {
							const total = value[0] + value[1] + value[2];
							return {
								destress: value.destress,
								0: Math.round((value[0] / total) * 100),
								1: Math.round((value[1] / total) * 100),
								2: Math.round((value[2] / total) * 100),
							};
						})
						.sort((a, b) => {
							if (a[2] < b[2]) return 1;
							if (a[2] > b[2]) return -1;
							if (a[1] < b[1]) return 1;
							if (a[1] > b[1]) return -1;
							return b[0] - a[0];
						});
					this.symptomsList = res.symptoms.map((symptom) => {
						return { left: symptom };
					});
				} else {
					this.display = false;
					this.noData();
				}
			});
	}

	toggleMore() {
		this.show =
			this.destressList.length > this.show
				? this.destressList.length
				: this.SHOW_MIN;
	}
}
