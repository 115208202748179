import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

import {
	Assessment,
	AssessmentStatus,
	AssessmentType,
	AssessmentService,
} from '@services/leader';
import {
	LoaderService,
	DomainService,
	LanguageService,
	StorageService,
} from '@services/public';
import { UserService } from '@services/viewer/user.service';
import { GroupService } from '@services/member';

const PAGE_PATH = `pages.leader.assessment.view`;

@Component({
	selector: 'app-leader-assessment-view',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.scss'],
})
export class LeaderAssessmentViewComponent implements OnInit {
	Object: typeof Object = Object; // Expose this object to the template.
	AssessmentStatus: typeof AssessmentStatus = AssessmentStatus; // Expose this enum to the template.
	AssessmentType: typeof AssessmentType = AssessmentType; // Expose this enum to the template.

	assessment: Assessment = {
		uuid: null,
		name: '-',
		type: null,
		startDate: '-',
		endDate: '-',
		status: null,
		groupUUID: null,
		group: {
			uuid: null,
			name: '-',
			abbreviation: null,
			timezone: null,
			startDate: null,
			endDate: null,
			users: null,
			memberUUIDs: null,
			organizationUUID: '',
			member: null,
			role: null,
		},
		skills: [],
		subgroups: null,
		users: null,
	};

	leaderAvg: { [key: number]: number; skills?: number[] } = {};
	results: any = null;
	selectedAssessee: any = null;
	releasedResults: Array<string> = [];
	allResultsReleased: boolean = true;
	allDomainsAndSkills: any = {};
	users: any;
	groups: any;

	// Page language.
	page: { [key: string]: string } = {};

	constructor(
		private domainSvc: DomainService,
		private assessmentSvc: AssessmentService,
		private route: ActivatedRoute,
		private toastSvc: ToastrService,
		private loaderSvc: LoaderService,
		private viewerSvc: UserService,
		private groupSvc: GroupService,
		private location: Location,
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		// Load skills.
		this.domainSvc.getAll().then((response) => {
			this.allDomainsAndSkills = response;
		});
		this.viewerSvc.users.subscribe((users) => {
			this.users = users;
		});
		this.viewerSvc.loadUsers();

		//load group
		this.groupSvc.groups.subscribe((groups) => {
			this.groups = groups;
		});
		this.groupSvc.loadGroups();
		// Get assessment.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.getAssessment(this.route.snapshot.paramMap.get('uuid'))
			.pipe(
				map((response: any) => {
					if (!!response) {
						response.group = this.groups?.find(
							(group) => group.uuid === response.groupUUID,
						);
						this.assessment = response;
						this.assessment.skills = response.skillUIDs;
						this.loaderSvc.removeLoader(loader);
					}
					// Peer assessment response.
					if (!!response.subgroups && !!response.scores) {
						// Build results object.
						this.results = {};
						let subgroup = [];
						let subgroups = [];
						for (const group of response.subgroups) {
							for (const uuid of group) {
								const user = this.users.find((ele) => ele.uuid === uuid);
								let status = response.status;
								if (response.activeUserUUIDs.includes(uuid)) status = 'ACTIVE';
								if (response.completedUserUUIDs.includes(uuid))
									status = 'COMPLETED';
								subgroup.push({
									name: user?.name,
									uuid: user?.uuid,
									status,
								});
							}
							subgroups.push(subgroup);
							subgroup = [];
						}

						response.subgroups = subgroups;
						for (const subgroup of response.subgroups) {
							for (const assessee of subgroup) {
								assessee.released = true;
								assessee.assessors = {};
								for (const assessor of subgroup)
									if (assessee !== assessor)
										assessee.assessors[assessor.uuid] = {
											name: assessor.name,
											outlier: true,
											scores: {},
										};
								this.results[assessee.uuid] = assessee;
							}
						}
						this.selectedAssessee = Object.values(this.results)[0];

						// Fill in scores.
						for (const score of response.scores) {
							const assessee = this.results[score.userUUID];
							const assessor = assessee.assessors[score.peerUUID];
							if (response.type === AssessmentType.PEER) {
								assessor.scores[score.skillUID.toString()] = score?.score;
								if (!score.released) {
									assessee.released = false;
									this.allResultsReleased = false;
								}
								if (assessor.outlier) {
									if (score.score === 1) {
										if (!assessor.outlierScore) assessor.outlierScore = 1;
										else if (assessor.outlierScore !== 1)
											assessor.outlier = false;
									} else if (score.score === 5) {
										if (!assessor.outlierScore) assessor.outlierScore = 5;
										else if (assessor.outlierScore !== 5)
											assessor.outlier = false;
									} else assessor.outlier = false;
								}
							}
						}
					}

					// Calculate average scores for each skill of Anonymous Assessment
					if (response.type === AssessmentType.LEADER) {
						delete this.results[this._storageSvc.userUUID];
						this.leaderAvg = this.leaderAvg || {};
						response.skills.forEach((skill: number) => {
							this.leaderAvg[skill] = parseFloat(
								(
									response.scores
										.filter((score) => score.skillUID === skill)
										.reduce((sum, score) => sum + score.score, 0) /
									response.scores.filter((score) => score.skillUID === skill)
										.length
								).toFixed(1),
							);
						});
						this.leaderAvg['skills'] = response.skills;
					}
					// Self assessment response.
					if (
						(!!response.users || response.type === this.AssessmentType.SELF) &&
						!!response.scores
					) {
						let users = [];
						for (const socre of response.scores) {
							const user = this.users.find(
								(ele) => ele.uuid === socre.userUUID,
							);
							users.push({
								name: user.name,
								uuid: user.uuid,
								status: response.status,
							});
						}
						response.users = users;
						// Build results object.
						this.results = {};
						for (const user of response.users) {
							user.outlier = true;
							user.scores = {};
							this.results[user.uuid] = user;
						}

						// Fill in scores.
						for (const score of response.scores) {
							const user = this.results[score.userUUID];
							user.scores[score.skillUID.toString()] =
								Math.round(score.score * 10) / 10;
							if (user.outlier) {
								if (score.score === 1) {
									if (!user.outlierScore) user.outlierScore = 1;
									else if (user.outlierScore !== 1) user.outlier = false;
								} else if (score.score === 5) {
									if (!user.outlierScore) user.outlierScore = 5;
									else if (user.outlierScore !== 5) user.outlier = false;
								} else user.outlier = false;
							}
						}
					}
				}).bind(this),
			)
			.subscribe();
	}

	onChangeReleaseResults(event: any, user: any): void {
		if (event.currentTarget.checked) this.releasedResults.push(user.uuid);
		else
			this.releasedResults = this.releasedResults.filter(
				(uuid) => uuid !== user.uuid,
			);
	}

	releaseResults() {
		// Release selected users' results.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.releaseAssessmentResults(this.assessment.uuid, this.releasedResults)
			.pipe(
				map((response: any) => {
					if (response.success) {
						for (const userUUID of this.releasedResults)
							this.results[userUUID].released = true;
						this.allResultsReleased = true;
						for (const user of Object.values(this.results))
							if (!(<any>user).released) this.allResultsReleased = false;
						this.releasedResults = [];
						this.loaderSvc.removeLoader(loader);
						this.toastSvc.success(this.page?.resultsReleased);
					}
				}).bind(this),
			)
			.subscribe();
	}

	backstep() {
		this.location.back();
	}
}
