import { Component, Input, OnInit } from '@angular/core';
import { PieChartData } from '@components';
import { AdventureService } from '@services/member';
import { LanguageService } from '@services/public';
import { APINoData, isAPINoData } from '@util';
import { AdventureReportData } from '../common';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.adventure.pages.report';

@Component({
	selector: 'app-member-skill-builder-adventure-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderAdventureReportComponent implements OnInit {
	@Input() noData: () => void;
	display: boolean = false;
	pieData: Array<PieChartData> = [];
	adventures: number;
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		pieTitle: '',
		aLot: '',
		aLittle: '',
		no: '',
		adventures: '',
	};

	constructor(
		private _adventureSvc: AdventureService,
		private _languageSvc: LanguageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<any>> = [];
			for (const key in this.page)
				promises.push(
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value)),
				);
			Promise.all(promises).then(() => this._languageLoaded.resolve());
		});

		await this._languageLoaded;

		this._adventureSvc
			.getReport()
			.subscribe((res: AdventureReportData | APINoData) => {
				if (!isAPINoData(res)) {
					this.display = true;
					this.adventures = res.completed;
					this.pieData = [
						{
							name: this.page.aLot,
							value: res.pie.aLot,
							label: `${res.pie.aLot}%`,
						},
						{
							name: this.page.aLittle,
							value: res.pie.aLittle,
							label: `${res.pie.aLittle}%`,
						},
						{
							name: this.page.no,
							value: res.pie.no,
							label: `${res.pie.no}%`,
						},
					];
				} else {
					this.display = false;
					this.noData();
				}
			});
	}
}
