// Sesotho
import { LocaleData } from 'ngx-bootstrap/chronos';

export const stLocale: LocaleData = {
	months: [
		'Phoo',
		'Hlakola',
		'Hlakubele',
		'Moranang',
		'Mopitlo',
		'Pedi',
		'Ngwaga',
		'Lwetse',
		'Morang',
		'Kwetlano',
		'Ngwedi',
		'Kgwedi',
	],
	monthsShort: [
		'Phoo',
		'Hla',
		'Hla',
		'Mora',
		'Mopi',
		'Ped',
		'Ngwa',
		'Lwe',
		'Mora',
		'Kwe',
		'Ngwe',
		'Kgw',
	],
	weekdays: [
		'Sontaha',
		'Mantaha',
		'Labobedi',
		'Laboraro',
		'Labohlano',
		'Mokibelo',
		'Moqebelo',
	],
	weekdaysShort: ['Son', 'Man', 'Lab', 'Lab', 'Lab', 'Mok', 'Moq'],
	weekdaysMin: ['So', 'Ma', 'La', 'La', 'La', 'Mo', 'Mo'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Kajeno] LT',
		nextDay: '[Bosi] LT',
		nextWeek: 'dddd [kgwedi] LT',
		lastDay: '[Kamis] LT',
		lastWeek: '[Kgotla] dddd [kgwedi] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'ka %s',
		past: '%s e fetileng',
		s: 'sekond',
		m: 'metsotso e le mone',
		mm: '%d metsotso',
		h: 'hora',
		hh: '%d dihora',
		d: 'letsatsi',
		dd: '%d matsatsi',
		M: 'kgwedi',
		MM: '%d dikgwedihadi',
		y: 'ngwaga',
		yy: '%d mekgwa',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1, // Monday is the first day of the week
		doy: 4, // The week that contains Jan 4 is the first week of the year
	},
};
