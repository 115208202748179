// Bosnian

import { LocaleData } from 'ngx-bootstrap/chronos';

export const bsLocale: LocaleData = {
	months: [
		'Januar',
		'Februar',
		'Mart',
		'April',
		'Maj',
		'Jun',
		'Jul',
		'Avgust',
		'Septembar',
		'Oktobar',
		'Novembar',
		'Decembar',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'Maj',
		'Jun',
		'Jul',
		'Avg',
		'Sep',
		'Okt',
		'Nov',
		'Dec',
	],
	weekdays: [
		'Nedjelja',
		'Ponedjeljak',
		'Utorak',
		'Srijeda',
		'Četvrtak',
		'Petak',
		'Subota',
	],
	weekdaysShort: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
	weekdaysMin: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Danas u] LT',
		nextDay: '[Sutra u] LT',
		nextWeek: 'dddd [u] LT',
		lastDay: '[Juče u] LT',
		lastWeek: '[Prošle] dddd [u] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'za %s',
		past: '%s prije',
		s: 'nekoliko sekundi',
		m: 'jedan minut',
		mm: '%d minuta',
		h: ' jedan sat',
		hh: '%d sati',
		d: 'jedan dan',
		dd: '%d dana',
		M: 'jedan mjesec',
		MM: '%d mjeseci',
		y: 'jedna godina',
		yy: '%d godina',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
