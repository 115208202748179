<div class="col-12 mt-2 d-flex justify-content-center justify-content-md-end">
	<div class="row w-sm-100 mb-3">
		<select
			class="w-sm-100"
			[(ngModel)]="domainFilter.value"
			(change)="domainOnChange()"
		>
			<option *ngFor="let domain of domainFilter.options" [ngValue]="domain">
				{{ domain.name }}
			</option>
		</select>
	</div>
</div>

<div *ngIf="display" class="main-container w-100">
	<div class="container-fluid pb-5-sm-c pb-5-md-c">
		<div class="row">
			<div
				*ngFor="let question of questions"
				class="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex"
			>
				<app-skill-builder-list
					[title]="question.display"
					[data]="question.dates"
					class="w-100 mb-3"
				></app-skill-builder-list>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!display" class="main-container w-100">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="card d-flex align-items-center justify-content-center">
					<div
						class="card-body d-flex align-items-center justify-content-center flex-column col-sm-12 col-md-8"
					>
						<div class="text-center">
							<img
								src="assets/img/no-data.svg"
								[alt]="page.noData"
								class="w-75"
							/>
						</div>
						<div class="title-main text-center py-4">
							{{ page.noData }}
						</div>
						<div class="py-1">
							<div class="btn-row d-flex">
								<button
									type="button"
									(click)="goToActivities()"
									class="btn btn-small btin-icon btn-secondary px-3"
								>
									{{ page.takeInventory }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
