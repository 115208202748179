import {
	Component,
	Input,
	OnInit,
	ViewChild,
	ElementRef,
	Renderer2,
	AfterContentChecked,
} from '@angular/core';
import { NgbCarouselConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { CarouselService } from '@services/member/carousel.service';
import { CardService } from '@services/member';
import { NgbSingleSlideEvent } from '@ng-bootstrap/ng-bootstrap/carousel/carousel';
export interface GenCarouselInfo {
	domain?: string;
	cardType?: string;
	readingType?: string;
}

@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	providers: [NgbCarouselConfig], // Ensure NgbCarouselConfig is provided
})
export class CarouselComponent implements OnInit, AfterContentChecked {
	@Input() public slides: any;
	@Input() public slideComponent: any;
	@Input() public carouselInfo: GenCarouselInfo | null = null;
	@ViewChild('carousel') carousel!: NgbCarousel;
	@ViewChild('carouselWrapper') carouselWrapper!: ElementRef;
	carouselShow: boolean = true;
	first_id: string;
	last_id: any;
	leftArrow: HTMLElement;
	rightArrow: HTMLElement;
	last_slide: boolean = false;
	posIni: number = 0;
	currentIndex: number = 0;
	// Define the animation classes
	enterAnimationClass: string;
	exitAnimationClass: string;

	constructor(
		private _carouselSvc: CarouselService,
		private _renderer: Renderer2,
		private _cardSvc: CardService,
	) {
		this._carouselSvc.getConfig().subscribe((config) => {
			this.applyConfig(config);
		});
		this._carouselSvc.getEnterAnimationClass().subscribe((enterClass) => {
			this.enterAnimationClass = enterClass;
		});
		this._carouselSvc.getExitAnimationClass().subscribe((exitClass) => {
			this.exitAnimationClass = exitClass;
		});
	}

	ngOnInit(): void {
		this._cardSvc.nextCardEvent$.subscribe(() => {
			this.carousel.next();
		});
		this._carouselSvc.setCurrentIndex(this.currentIndex);
	}
	get isCarouselVisible() {
		return this._carouselSvc.isCarouselVisible;
	}

	set isCarouselVisible(visible) {
		this._carouselSvc.isCarouselVisible = visible;
	}

	// Method to handle touch events
	move(pos: number) {
		const offset = this.posIni - pos;
		if (offset < -100) this.carousel.prev();

		if (offset > 100) this.carousel.next();
	}

	// Method to update the enter animation class dynamically
	updateEnterAnimationClass(newClass: string): void {
		this.enterAnimationClass = newClass;
	}

	// Method to update the exit animation class dynamically
	updateExitAnimationClass(newClass: string): void {
		this.exitAnimationClass = newClass;
	}

	// Method to apply the configuration to the carousel
	private applyConfig(configuration: {
		[key: string]: string | number | boolean;
	}) {
		Object.entries(configuration).forEach(([property, value]) => {
			this.carousel[property] = value;
		});
	}

	showCarousel() {
		if (this.isCarouselVisible && this.carousel.slides.toArray().length > 0) {
			this.carousel.select(this.carousel.slides.first.id);
		}

		this._carouselSvc.toggleCarousel();
		if (this.isCarouselVisible) {
			setTimeout(() => {
				this.leftArrow = this.carouselWrapper.nativeElement.querySelector(
					'.carousel-control-prev',
				);
				this.rightArrow = this.carouselWrapper.nativeElement.querySelector(
					'.carousel-control-next',
				);
				this.carouselWrapper.nativeElement.classList.remove('hide');
				this._carouselSvc.emitOpenEvent(true);
				const scrollPos = window.scrollY;
				const vpHeight = window.innerHeight;
				if (scrollPos / vpHeight > 0.3) {
					const carouselTop = scrollPos;
					this._renderer.setStyle(
						this.carouselWrapper.nativeElement,
						'top',
						`${carouselTop}px`,
					);
				}
			}, 500);
		} else {
			setTimeout(() => {
				this.carouselWrapper.nativeElement.classList.add('hide');
				this.first_id = null;
				this.last_id = null;
				this._carouselSvc.destroyCarousel();
			}, 100);
		}
	}

	nextSlide(
		leftArrow: HTMLElement,
		rightArrow: HTMLElement,
		first_id: string,
		last_id: string,
	): void {
		if (this.carousel.activeId === this.last_id) {
			rightArrow.classList.add('hide');
			leftArrow.classList.remove('hide');
		}
		if (this.carousel.activeId === first_id) {
			leftArrow.classList.add('hide');
			rightArrow.classList.remove('hide');
		}
		if (
			this.carousel.activeId != last_id &&
			this.carousel.activeId != first_id
		) {
			leftArrow.classList.remove('hide');
			rightArrow.classList.remove('hide');
		}
	}

	onSlide(singleSlide: NgbSingleSlideEvent) {
		this._carouselSvc.emitSlideEvent(singleSlide);
		setTimeout(() => {
			this.currentIndex++;
			this._carouselSvc.setCurrentIndex(this.currentIndex);
		}, 100);
		this.nextSlide(
			this.leftArrow,
			this.rightArrow,
			this.first_id,
			this.last_id,
		);
	}

	hideCarousel() {
		this.carouselWrapper.nativeElement.classList.add('hide');
		this.first_id = null;
		this.last_id = null;
	}

	ngAfterContentChecked(): void {
		if (!!this.isCarouselVisible && !!this.carousel && !!this.carousel.slides) {
			if (!this.first_id || !this.last_id) {
				this.first_id = this.carousel.slides.first.id;
				this.last_id = this.carousel.slides.last.id;
			}
			if (this.leftArrow) {
				if (this.carousel.activeId === this.last_id) {
					this.rightArrow.classList.add('hide');
					this.leftArrow.classList.remove('hide');
					this.last_slide = true;
				} else {
					this.last_slide = false;
				}
				if (this.carousel.activeId === this.first_id) {
					this.leftArrow.classList.add('hide');
					this.rightArrow.classList.remove('hide');
				}
				if (
					this.carousel.activeId != this.last_id &&
					this.carousel.activeId != this.first_id
				) {
					this.leftArrow.classList.remove('hide');
					this.rightArrow.classList.remove('hide');
				}
				if (this.carousel.slides.length <= 1) {
					this.leftArrow.classList.add('hide');
					this.rightArrow.classList.add('hide');
					this.last_slide = true;
				}
			}
		}
	}
}
