<div class="addClassMain">
	<div class="modal-header">
		<h5 class="mb-0">{{ page?.addGroup }}</h5>
		<button
			#closebutton
			type="button"
			class="close"
			data-dismiss="modal"
			(click)="closeModal()"
			[attr.aria-label]="page?.close"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="py-3 px-4">
		<div>
			<form [formGroup]="form" (ngSubmit)="onSubmit()">
				<div class="row">
					<div class="form-group col-8">
						<label for="name"
							>{{ page?.groupName }}
							<span class="required-asterisk">*</span></label
						>
						<input
							id="name"
							type="text"
							maxlength="40"
							placeholder="{{ page?.groupName }}"
							class="form-control"
							formControlName="name"
							[ngClass]="{ 'danger-class': f.name.errors && f.name.touched }"
						/>
						<div
							*ngIf="f.name.errors && f.name.touched"
							class="invalid-feedback"
						>
							<div *ngIf="f.name.errors.required">
								{{ page?.groupNameRequired }}
							</div>
						</div>
					</div>
					<div class="form-group col-4">
						<label for="abbreviation">{{ page?.abbreviation }}</label>
						<input
							id="abbreviation"
							type="text"
							maxlength="10"
							placeholder="{{ page?.abbreviation }}"
							class="form-control"
							formControlName="abbreviation"
							[ngClass]="{ 'danger-class': false }"
						/>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-12">
						<label
							>{{ page?.org }}<span class="required-asterisk">*</span></label
						>
						<div class="input-group">
							<select
								[attr.aria-label]="page?.institute"
								class=""
								[ngClass]="{
									'danger-class':
										f.organization.errors && f.organization.touched,
								}"
								formControlName="organization"
							>
								<option [ngValue]="null" disabled>{{ page?.selectOrg }}</option>
								<option
									*ngFor="let organization of organizations"
									[ngValue]="organization.uuid"
								>
									{{ organization.name }}
								</option>
							</select>
						</div>
						<div
							*ngIf="f.organization.errors && f.organization.touched"
							class="invalid-feedback"
						>
							<div *ngIf="f.organization.errors.required">
								{{ page?.orgRequired }}
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-md-12 col-lg-6">
						<label for="startDate"
							>{{ page?.startDate }}
							<span class="required-asterisk">*</span></label
						>
						<input
							id="startDate"
							type="text"
							formControlName="startDate"
							[ngClass]="{
								'danger-class': f.startDate.errors && f.startDate.touched,
							}"
							placeholder="{{ page?.startDate }}"
							class="form-control"
							bsDatepicker
							[bsConfig]="{ showWeekNumbers: false }"
							placement="top"
							readonly
						/>
						<div
							*ngIf="f.startDate.errors && f.startDate.touched"
							class="invalid-feedback"
						>
							<div *ngIf="f.startDate.errors.required">
								{{ page?.startDateRequired }}
							</div>
						</div>
						<div *ngIf="form?.errors?.dateConflict" class="invalid-feedback">
							<div>
								{{ page?.startBeforeEnd }}
							</div>
						</div>
					</div>
					<div class="form-group col-md-12 col-lg-6">
						<label for="endDate"
							>{{ page?.endDate }} <span class="required-asterisk">*</span>
						</label>
						<input
							id="endDate"
							formControlName="endDate"
							[ngClass]="{
								'danger-class': f.endDate.errors && f.endDate.touched,
							}"
							type="text"
							placeholder="{{ page?.endDate }}"
							class="form-control"
							bsDatepicker
							[bsConfig]="{ showWeekNumbers: false }"
							placement="top"
							readonly
						/>
						<div
							*ngIf="f.endDate.errors && f.endDate.touched"
							class="invalid-feedback"
						>
							<div *ngIf="f.endDate.errors.required">
								{{ page?.endDateRequired }}
							</div>
						</div>
					</div>
				</div>
				<div
					class="modal-footer bottomBtnsModal d-flex justify-content-center align-items-center border-top mt-3 pt-3"
				>
					<app-button [type]="'submit'" [block]="true">
						{{ page?.save }}
					</app-button>
					<button type="button" class="cancel mr-3" (click)="closeModal()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
