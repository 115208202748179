<div class="member-tasks main-container pb-lg">
	<tabset>
		<tab heading="{{ page.assessments }}">
			<div
				class="tasks-assessments boxMain card-white card-white-sm border-radius-md px-0"
			>
				<div class="col-12">
					<div class="row mt-3">
						<div class="col-6 d-flex align-items-center">
							<div class="pr-1"><img src="assets/img/in-progress.svg" /></div>
							<div class="d-flex align-items-center">
								<span class="count-lg">{{ inprogressAssessmentCount }}</span>
								<span class="txt-sm pl-2">{{ page.inProgress }}</span>
							</div>
						</div>
						<div class="col-6 d-flex align-items-center">
							<div class="pr-1"><img src="assets/img/pending.svg" /></div>
							<div class="d-flex align-items-center">
								<span class="count-lg">{{ pendingAssessmentCount }}</span>
								<span class="txt-sm pl-2">{{ page.pending }}</span>
							</div>
						</div>
					</div>
					<hr />
					<div
						class="row mt-3 strengthRow list-bordered-btm-dotted"
						*ngFor="
							let assessment of assessments | slice: 0 : show;
							let i = index
						"
					>
						<ng-container>
							<div
								class="col-12"
								*ngIf="
									assessment.status === 'ACTIVE' ||
									assessment.status === 'SCHEDULED'
								"
							>
								<div class="row">
									<div class="col-8">
										<p class="mb-0">{{ assessment.name }}</p>
									</div>
									<div
										class="col-4 d-flex align-items-center justify-content-end"
									>
										<div class="d-flex">
											<span class="pr-2 txt-green-dark">{{
												assessment.type | titlecase
											}}</span
											><span class="good dot-sm mt-1"></span>
										</div>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-6 grp-name d-flex flex-column">
										<div *ngIf="!!assessment.groupUUID" class="d-flex flex-row">
											<label class="font-weight-normal"
												>{{ labels.group }}:</label
											>
											<label class="text-uppercase black"
												><strong>{{
													getGroupName(assessment.groupUUID)
												}}</strong></label
											>
										</div>

										<div *ngIf="assessment.status === 'SCHEDULED'">
											<label class="font-weight-normal txt-sm"
												>{{ labels.status }}:
												<img class="inline-icon" src="assets/img/pending.svg" />
												<span class="black">{{ page.pending }}</span></label
											>
										</div>
										<div *ngIf="assessment.status === 'ACTIVE'">
											<label class="font-weight-normal txt-sm"
												>{{ labels.status }}:
												<img
													class="inline-icon"
													src="assets/img/in-progress.svg"
												/>
												<span class="black">{{ page.inProgress }}</span></label
											>
										</div>
									</div>
									<div
										class="col-6 d-flex align-items-center justify-content-end"
									>
										<div *ngIf="assessment.status === 'SCHEDULED'">
											<label class="font-weight-normal txt-sm"
												>{{ labels.startsOn }}:
												{{ assessment.startDate | date: 'M/d/yyyy' }}</label
											>
										</div>
										<div
											*ngIf="assessment.status === 'ACTIVE'"
											class="btn-row-c"
										>
											<button
												type="button"
												class="btin-icon btn btn-secondary btn-secondary-sm"
												(click)="startAssessment(assessment)"
											>
												{{ page.resume }}
												<img
													src="./../../../../assets/img/new/play-filled.svg"
													alt="page.resume"
													class="play-icon flex-shrink-0"
												/>
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<div
						class="btn-row-c py-4 d-flex align-items-center justify-content-center"
					>
						<button
							type="button"
							class="btn btn-small btn-outline btn-outline-purple"
							routerLink="/member/assessment/list"
							routerLinkActive="active"
							(click)="showMoreIcon()"
						>
							{{ labels.viewAll }}
						</button>
					</div>
				</div>
			</div>
		</tab>
		<tab
			heading="{{ page.externalAssessments }}"
			[attr.id]="page.externalAssessments"
		>
			<div class="tasks-external-assessments col-12 mt-2">
				<div class="boxMain card-white card-white-sm border-radius-md px-0">
					<div class="row mt-3">
						<div class="col-6 d-flex align-items-center">
							<div class="pr-1"><img src="assets/img/completed.svg" /></div>
							<div class="d-flex pt-0 align-items-center">
								<span class="count-lg">{{ externalAssessmentsCompleted }}</span
								>&nbsp;
								<span class="txt-sm">{{ labels.completed }}</span>
							</div>
						</div>
						<div class="col-6 d-flex align-items-center justify-content-end">
							<button
								id="createExternal"
								class="btn btn-secondary btn-secondary-sm"
								(click)="onCreateExternalAssessmentClick()"
							>
								{{ page.create }}
							</button>
						</div>
					</div>
					<hr />
					<div
						class="row mt-3 strengthRow list-bordered-btm-dotted"
						*ngFor="
							let assessment of externalAssessments | slice: 0 : show;
							let i = index
						"
					>
						<ng-container>
							<div class="col-12">
								<div class="row">
									<div class="col-8">
										<p class="mb-0">{{ assessment.assessmentName }}</p>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-6 grp-name d-flex flex-column">
										<label class="font-weight-normal"
											>{{ labels.name }}:<strong
												>{{ assessment.firstName }}
												{{ assessment.lastName }}</strong
											></label
										>
									</div>
									<div class="col-6 grp-name d-flex flex-column"></div>
									<div class="col-6 d-flex justify-content-start">
										<div class="row">
											<div class="col">
												<label class="font-weight-normal txt-sm"
													>{{ labels.status }}:
												</label>
											</div>
											<div class="col d-flex align-items-center">
												<div class="pr-1">
													<img
														[src]="
															assessment.status == 'ACTIVE'
																? 'assets/img/pending_ass.svg'
																: 'assets/img/success.svg'
														"
													/>
												</div>
												<span class="txt-sm font-weight-bold">{{
													assessment.status | titlecase
												}}</span>
											</div>
										</div>
									</div>
									<div class="col-6 d-flex justify-content-end">
										<div
											*ngIf="assessment.status === 'COMPLETED'"
											class="btn-row-c"
										>
											<span class="circle-ico"
												><img
													src="assets/img/view-report-icon.svg"
													alt="view.svg"
													[routerLink]="[
														'/member/assessment/external/report',
														assessment.uuid,
													]"
											/></span>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<div
						class="btn-row-c py-4 d-flex align-items-center justify-content-center"
					>
						<button
							type="button"
							class="btn btn-small btn-outline btn-outline-purple"
							routerLink="/member/assessment/external/list"
							routerLinkActive="active"
							(click)="showMoreIcon()"
						>
							{{ page.viewAssessmentHistory }}
						</button>
					</div>
				</div>
			</div>
		</tab>
		<tab heading="{{ page.skillBuilders }}">
			<div
				class="tasks-skill-builders boxMain card-white card-white-sm border-radius-md px-0"
			>
				<app-challenges navigationFrom="dashboard"></app-challenges>
			</div>
		</tab>
	</tabset>
</div>
