import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	PathLocationStrategy,
	LocationStrategy,
	registerLocaleData,
	APP_BASE_HREF,
} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RatingModule, RatingConfig } from 'ngx-bootstrap/rating';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CarouselConfig, CarouselModule } from 'ngx-bootstrap/carousel';

// <jakapa-schematics-imports>
import {
	BarChartComponent,
	ButtonComponent,
	CarouselComponent,
	CertificateListComponent,
	ChecklistCardComponent,
	GridComponent,
	InfoCardComponent,
	LanguageSelectComponent,
	LeaderboardComponent,
	SearchFilterPipePipe,
	SkillBuilderCalendarComponent,
	SkillBuilderListComponent,
	SkillBuilderPieChartComponent,
	SkillBuilderSelectListComponent,
	SkillBuilderStarRatingComponent,
	SkillBuilderTextBoxComponent,
	SkillBuilderTextListComponent,
	SpinnerComponent,
	TabsComponent,
	VideoCardComponent,
	ViewCardComponent,
	WalkthroughToolTipComponent,
} from '@components';

import {
	LeaderAssessmentCreateComponent,
	LeaderAssessmentListComponent,
	LeaderAssessmentSkillsComponent,
	LeaderAssessmentSubgroupsComponent,
	LeaderAssessmentViewComponent,
	LeaderDashboardComponent,
	LeaderGroupCreateComponent,
	LeaderReportComponent,
} from '@pages/leader';

import {
	InventoryComponent,
	MemberAssessmentExternalCreateOrUpdateComponent,
	MemberAssessmentExternalListComponent,
	MemberAssessmentExternalReportComponent,
	MemberAssessmentExternalUpdateSkillsComponent,
	MemberAssessmentLeaderComponent,
	MemberAssessmentListComponent,
	MemberAssessmentPeerComponent,
	MemberAssessmentSelfComponent,
	MemberDashboardActivitiesChallengesComponent,
	MemberDashboardActivitiesComponent,
	MemberDashboardActivitiesOnboardingComponent,
	MemberDashboardComponent,
	MemberDashboardReportsComponent,
	MemberDashboardRewardsChallengeBadgesComponent,
	MemberDashboardRewardsComponent,
	MemberDashboardRewardsJourneyComponent,
	MemberDashboardRewardsMetricsComponent,
	MemberDashboardRewardsSkillBuilderBadgesComponent,
	MemberGroupAddComponent,
	MemberInventoryReportComponent,
	MemberModalSelectAvatarComponent,
	MemberSkillBuilderAbstractComfortComponent,
	MemberSkillBuilderAbstractComponent,
	MemberSkillBuilderAbstractExampleComponent,
	MemberSkillBuilderAbstractIdeaComponent,
	MemberSkillBuilderAbstractNegativeImpactComponent,
	MemberSkillBuilderAbstractNegativeReasonsComponent,
	MemberSkillBuilderAbstractPositiveImpactComponent,
	MemberSkillBuilderAbstractPositiveReasonsComponent,
	MemberSkillBuilderAbstractRatingComponent,
	MemberSkillBuilderAbstractReportComponent,
	MemberSkillBuilderAbstractSummaryComponent,
	MemberSkillBuilderAdventureChooseComponent,
	MemberSkillBuilderAdventureComponent,
	MemberSkillBuilderAdventureImpactComponent,
	MemberSkillBuilderAdventureReportComponent,
	MemberSkillBuilderBrainAffirmationsComponent,
	MemberSkillBuilderBrainComponent,
	MemberSkillBuilderBrainDiaryComponent,
	MemberSkillBuilderBrainDoTomorrowComponent,
	MemberSkillBuilderBrainImpactComponent,
	MemberSkillBuilderBrainReportComponent,
	MemberSkillBuilderBrainYesterdayComponent,
	MemberSkillBuilderDecisionActionsComponent,
	MemberSkillBuilderDecisionAffectsComponent,
	MemberSkillBuilderDecisionComponent,
	MemberSkillBuilderDecisionImpactsComponent,
	MemberSkillBuilderDecisionOutcomesComponent,
	MemberSkillBuilderDecisionRanksComponent,
	MemberSkillBuilderDecisionReportComponent,
	MemberSkillBuilderDecisionRightChoiceNoComponent,
	MemberSkillBuilderDecisionRightChoiceYesComponent,
	MemberSkillBuilderDecisionTakenActionComponent,
	MemberSkillBuilderGoalActionComponent,
	MemberSkillBuilderGoalComponent,
	MemberSkillBuilderGoalCustomActionComponent,
	MemberSkillBuilderGoalDomainComponent,
	MemberSkillBuilderGoalReportComponent,
	MemberSkillBuilderGoalSkillComponent,
	MemberSkillBuilderGoalTrackComponent,
	MemberSkillBuilderGoalYesterdayComponent,
	MemberSkillBuilderJournalAccuracyComponent,
	MemberSkillBuilderJournalComponent,
	MemberSkillBuilderJournalConfidenceComponent,
	MemberSkillBuilderJournalEmotionsComponent,
	MemberSkillBuilderJournalEnjoymentComponent,
	MemberSkillBuilderJournalFrequencyComponent,
	MemberSkillBuilderJournalGeneratedSummaryComponent,
	MemberSkillBuilderJournalHighlightComponent,
	MemberSkillBuilderJournalReportComponent,
	MemberSkillBuilderJournalSocialComponent,
	MemberSkillBuilderJournalSymptomsComponent,
	MemberSkillBuilderJournalThoughtsComponent,
	MemberSkillBuilderJournalUserSummaryComponent,
	MemberSkillBuilderJournalYesterdayComponent,
	MemberSkillBuilderMessageComponent,
	MemberSkillBuilderMessageDeliveredMessageComponent,
	MemberSkillBuilderMessageImpactComponent,
	MemberSkillBuilderMessageNameComponent,
	MemberSkillBuilderMessagePositiveMessageComponent,
	MemberSkillBuilderMessageReportComponent,
	MemberSkillBuilderMessageValuesComponent,
	MemberSkillBuilderReflectionActionComponent,
	MemberSkillBuilderReflectionComponent,
	MemberSkillBuilderReflectionCustomActionComponent,
	MemberSkillBuilderReflectionImpactsComponent,
	MemberSkillBuilderReflectionSkillComponent,
	MemberSkillBuilderReflectionStrengthsComponent,
	MemberSkillBuilderReflectionSummaryComponent,
	MemberSkillBuilderReflectionWeaknessesComponent,
	MemberSkillBuilderReportComponent,
	MemberSkillBuilderStressBrainComponent,
	MemberSkillBuilderStressChangePlanComponent,
	MemberSkillBuilderStressComponent,
	MemberSkillBuilderStressDestressComponent,
	MemberSkillBuilderStressFeelComponent,
	MemberSkillBuilderStressFeelTrackComponent,
	MemberSkillBuilderStressImpactComponent,
	MemberSkillBuilderStressLookComponent,
	MemberSkillBuilderStressReportComponent,
	MemberSkillBuilderStressYesterdayComponent,
	MemberSkillBuilderWwydBenefitsComponent,
	MemberSkillBuilderWwydChooseActionComponent,
	MemberSkillBuilderWwydComponent,
	MemberSkillBuilderWwydCustomDilemmaComponent,
	MemberSkillBuilderWwydDilemmaComponent,
	MemberSkillBuilderWwydEthicsComponent,
	MemberSkillBuilderWwydFinalChoiceComponent,
	MemberSkillBuilderWwydFirstChoiceComponent,
	MemberSkillBuilderWwydListActionsComponent,
	MemberSkillBuilderWwydReportComponent,
	MemberTasksComponent,
} from '@pages/member';

import {
	PublicAssessmentExternalComponent,
	PublicEmailVerifyComponent,
	PublicLoginComponent,
	PublicPasswordForgotComponent,
	PublicPasswordResetComponent,
	PublicRegisterComponent,
} from '@pages/public';
// </jakapa-schematics-imports>

import { ApiInterceptorService, AuthService } from '@services/public';
import { config } from 'environment';

import { NgxStarRatingModule } from 'ngx-star-rating';
import { DummyComponent } from './pages/public/dummy/dummy.component';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideAuth } from '@angular/fire/auth';
import { provideFirestore } from '@angular/fire/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { PlatformModule } from '@angular/cdk/platform';
import { NgxPaginationModule } from 'ngx-pagination';
import { GraphModalComponent } from './pages/leader/graph-modal/graph-modal.component';
registerLocaleData(localeEn, 'en-EN');
import { NgxCaptchaModule } from 'ngx-captcha';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CreateGroupComponent } from '@pages/leader/dashboard/group-management/create-group/create-group.component';
import { EditGroupComponent } from '@pages/leader/dashboard/group-management/edit-group/edit-group.component';
import { GroupManagementComponent } from '@pages/leader/dashboard/group-management/group-management.component';
import { StepOneComponent } from '@pages/leader/dashboard/group-management/step-one/step-one.component';
import { StepThreeComponent } from '@pages/leader/dashboard/group-management/step-three/step-three.component';
import { StepTwoComponent } from '@pages/leader/dashboard/group-management/step-two/step-two.component';
import { ChallengesComponent } from '@pages/member/challenges/challenges.component';
import { ChallengeDashboardTabComponent } from '@pages/member/dashboard/challenge-dashboard-tab/challenge-dashboard-tab.component';
import { FeatureModalComponent } from '@pages/member/feature-modal/feature-modal.component';
import { FirstLoginModalComponent } from '@pages/member/first-login-modal/first-login-modal.component';
import { GeneralModalComponent } from '@pages/member/general-modal/general-modal.component';
import { ImpactGoodbadComponent } from '@pages/member/impact-goodbad/impact-goodbad.component';
import {
	MemberMainComponent,
	MemberMainFooterComponent,
	MemberMainHeaderComponent,
	MemberMainMenuComponent,
} from '@pages/member/main';
import { GraphComponent } from '@pages/member/modal';
import { AddEmailComponent } from '@pages/member/profile/add-email/add-email.component';
import { ChangeEmailComponent } from '@pages/member/profile/change-email/change-email.component';
import { MemberProfileComponent } from '@pages/member/profile/profile.component';
import { TodayActivityComponent } from '@pages/member/today-activity/today-activity.component';
import { TryMessageComponent } from '@pages/member/try-message/try-message.component';
import { ResearcherDashboardComponent } from '@pages/researcher/dashboard/dashboard.component';
import { MeasureDashboardComponent } from '@pages/researcher/dashboard/measure-dashboard/measure-dashboard.component';
import { TrackDashboardComponent } from '@pages/researcher/dashboard/track-dashboard/track-dashboard.component';
import { TrainDashboardComponent } from '@pages/researcher/dashboard/train-dashboard/train-dashboard.component';
import { AddUserDialogComponent } from '@pages/researcher/dashboard/user-management/add-edit-user-dialog/add-edit-user-dialog.component';
import { UserManagementComponent } from '@pages/researcher/dashboard/user-management/user-management.component';
import { AssessmentInstructionPopupComponent } from '@pages/member/assessment/assessment-instruction-popup/assessment-instruction-popup.component';
import { JoinGroupTagComponent } from './pages/member/join-group-tag/join-group-tag.component';
import { GroupTagPopupComponent } from './pages/member/group-tag-popup/group-tag-popup.component';
import { AcceptInvitationComponent } from './pages/public/accept-invitation/accept-invitation.component';
import { VideoViewerComponent } from '@pages/member/video-viewer/video-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SettingsComponent } from './pages/public/settings/settings.component';
import { UnsubscribeComponent } from './pages/public/unsubscribe/unsubscribe.component';
import { CongratulationsPopUpComponent } from './pages/member/dashboard/activities/congratulations-pop-up/congratulations-pop-up.component';
import { LeaderRewardComponent } from '@pages/leader/dashboard/leader-reward/leader-reward.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AccountModalComponent } from './pages/account/account-modal/account-modal.component';
import { CarouselHostDirective } from '@components/carousel/carousel-host.directive';
import { CertificateModalComponent } from './pages/member/certificate-modal/certificate-modal.component';

let carouselConfig: CarouselConfig = {
	interval: 0,
	noPause: false,
	showIndicators: true,
	noWrap: false,
	pauseOnFocus: false,
	indicatorsByChunk: false,
	itemsPerSlide: 0,
	singleSlideOffset: false,
};

@NgModule({
	declarations: [
		// <jakapa-schematics-component-declarations>
		BarChartComponent,
		ButtonComponent,
		CarouselComponent,
		CarouselHostDirective,
		CertificateListComponent,
		CertificateModalComponent,
		ChecklistCardComponent,
		GridComponent,
		InfoCardComponent,
		LanguageSelectComponent,
		LeaderboardComponent,
		SearchFilterPipePipe,
		SkillBuilderCalendarComponent,
		SkillBuilderListComponent,
		SkillBuilderPieChartComponent,
		SkillBuilderSelectListComponent,
		SkillBuilderStarRatingComponent,
		SkillBuilderTextBoxComponent,
		SkillBuilderTextListComponent,
		SpinnerComponent,
		TabsComponent,
		VideoCardComponent,
		ViewCardComponent,
		WalkthroughToolTipComponent,
		// </jakapa-schematics-component-declarations>

		// <jakapa-schematics-page-declarations>
		LeaderAssessmentCreateComponent,
		LeaderAssessmentListComponent,
		LeaderAssessmentSkillsComponent,
		LeaderAssessmentSubgroupsComponent,
		LeaderAssessmentViewComponent,
		LeaderDashboardComponent,
		LeaderGroupCreateComponent,
		LeaderReportComponent,
		LeaderRewardComponent,
		MemberAssessmentExternalCreateOrUpdateComponent,
		MemberAssessmentExternalListComponent,
		MemberAssessmentExternalReportComponent,
		MemberAssessmentExternalUpdateSkillsComponent,
		MemberAssessmentLeaderComponent,
		MemberAssessmentListComponent,
		MemberAssessmentPeerComponent,
		MemberAssessmentSelfComponent,
		MemberDashboardActivitiesChallengesComponent,
		MemberDashboardActivitiesComponent,
		MemberDashboardActivitiesOnboardingComponent,
		MemberDashboardComponent,
		MemberDashboardReportsComponent,
		MemberDashboardRewardsChallengeBadgesComponent,
		MemberDashboardRewardsComponent,
		MemberDashboardRewardsJourneyComponent,
		MemberDashboardRewardsMetricsComponent,
		MemberDashboardRewardsSkillBuilderBadgesComponent,
		MemberGroupAddComponent,
		MemberInventoryReportComponent,
		MemberMainComponent,
		MemberMainFooterComponent,
		MemberMainHeaderComponent,
		MemberMainMenuComponent,
		MemberModalSelectAvatarComponent,
		MemberProfileComponent,
		MemberSkillBuilderAbstractComfortComponent,
		MemberSkillBuilderAbstractComponent,
		MemberSkillBuilderAbstractExampleComponent,
		MemberSkillBuilderAbstractIdeaComponent,
		MemberSkillBuilderAbstractNegativeImpactComponent,
		MemberSkillBuilderAbstractNegativeReasonsComponent,
		MemberSkillBuilderAbstractPositiveImpactComponent,
		MemberSkillBuilderAbstractPositiveReasonsComponent,
		MemberSkillBuilderAbstractRatingComponent,
		MemberSkillBuilderAbstractReportComponent,
		MemberSkillBuilderAbstractSummaryComponent,
		MemberSkillBuilderAdventureChooseComponent,
		MemberSkillBuilderAdventureComponent,
		MemberSkillBuilderAdventureImpactComponent,
		MemberSkillBuilderAdventureReportComponent,
		MemberSkillBuilderBrainAffirmationsComponent,
		MemberSkillBuilderBrainComponent,
		MemberSkillBuilderBrainDiaryComponent,
		MemberSkillBuilderBrainDoTomorrowComponent,
		MemberSkillBuilderBrainImpactComponent,
		MemberSkillBuilderBrainReportComponent,
		MemberSkillBuilderBrainYesterdayComponent,
		MemberSkillBuilderDecisionActionsComponent,
		MemberSkillBuilderDecisionAffectsComponent,
		MemberSkillBuilderDecisionComponent,
		MemberSkillBuilderDecisionImpactsComponent,
		MemberSkillBuilderDecisionOutcomesComponent,
		MemberSkillBuilderDecisionRanksComponent,
		MemberSkillBuilderDecisionReportComponent,
		MemberSkillBuilderDecisionRightChoiceNoComponent,
		MemberSkillBuilderDecisionRightChoiceYesComponent,
		MemberSkillBuilderDecisionTakenActionComponent,
		MemberSkillBuilderGoalActionComponent,
		MemberSkillBuilderGoalComponent,
		MemberSkillBuilderGoalCustomActionComponent,
		MemberSkillBuilderGoalDomainComponent,
		MemberSkillBuilderGoalReportComponent,
		MemberSkillBuilderGoalSkillComponent,
		MemberSkillBuilderGoalTrackComponent,
		MemberSkillBuilderGoalYesterdayComponent,
		MemberSkillBuilderJournalAccuracyComponent,
		MemberSkillBuilderJournalComponent,
		MemberSkillBuilderJournalConfidenceComponent,
		MemberSkillBuilderJournalEmotionsComponent,
		MemberSkillBuilderJournalEnjoymentComponent,
		MemberSkillBuilderJournalFrequencyComponent,
		MemberSkillBuilderJournalGeneratedSummaryComponent,
		MemberSkillBuilderJournalHighlightComponent,
		MemberSkillBuilderJournalReportComponent,
		MemberSkillBuilderJournalSocialComponent,
		MemberSkillBuilderJournalSymptomsComponent,
		MemberSkillBuilderJournalThoughtsComponent,
		MemberSkillBuilderJournalUserSummaryComponent,
		MemberSkillBuilderJournalYesterdayComponent,
		MemberSkillBuilderMessageComponent,
		MemberSkillBuilderMessageDeliveredMessageComponent,
		MemberSkillBuilderMessageImpactComponent,
		MemberSkillBuilderMessageNameComponent,
		MemberSkillBuilderMessagePositiveMessageComponent,
		MemberSkillBuilderMessageReportComponent,
		MemberSkillBuilderMessageValuesComponent,
		MemberSkillBuilderReflectionActionComponent,
		MemberSkillBuilderReflectionComponent,
		MemberSkillBuilderReflectionCustomActionComponent,
		MemberSkillBuilderReflectionImpactsComponent,
		MemberSkillBuilderReflectionSkillComponent,
		MemberSkillBuilderReflectionStrengthsComponent,
		MemberSkillBuilderReflectionSummaryComponent,
		MemberSkillBuilderReflectionWeaknessesComponent,
		MemberSkillBuilderReportComponent,
		MemberSkillBuilderStressBrainComponent,
		MemberSkillBuilderStressChangePlanComponent,
		MemberSkillBuilderStressComponent,
		MemberSkillBuilderStressDestressComponent,
		MemberSkillBuilderStressFeelComponent,
		MemberSkillBuilderStressFeelTrackComponent,
		MemberSkillBuilderStressImpactComponent,
		MemberSkillBuilderStressLookComponent,
		MemberSkillBuilderStressReportComponent,
		MemberSkillBuilderStressYesterdayComponent,
		MemberSkillBuilderWwydBenefitsComponent,
		MemberSkillBuilderWwydChooseActionComponent,
		MemberSkillBuilderWwydComponent,
		MemberSkillBuilderWwydCustomDilemmaComponent,
		MemberSkillBuilderWwydDilemmaComponent,
		MemberSkillBuilderWwydEthicsComponent,
		MemberSkillBuilderWwydFinalChoiceComponent,
		MemberSkillBuilderWwydFirstChoiceComponent,
		MemberSkillBuilderWwydListActionsComponent,
		MemberSkillBuilderWwydReportComponent,
		MemberTasksComponent,
		PublicAssessmentExternalComponent,
		PublicEmailVerifyComponent,
		PublicLoginComponent,
		PublicPasswordForgotComponent,
		PublicPasswordResetComponent,
		PublicRegisterComponent,
		// </jakapa-schematics-page-declarations>

		AppComponent,
		AddEmailComponent,
		TryMessageComponent,
		ChangeEmailComponent,
		AddEmailComponent,
		AddEmailComponent,
		AddEmailComponent,
		ChallengesComponent,
		TodayActivityComponent,
		ImpactGoodbadComponent,
		DummyComponent,
		TrainDashboardComponent,
		GraphModalComponent,
		ChallengeDashboardTabComponent,
		MeasureDashboardComponent,
		TrackDashboardComponent,
		AddUserDialogComponent,
		UserManagementComponent,
		CreateGroupComponent,
		StepOneComponent,
		StepThreeComponent,
		StepTwoComponent,
		EditGroupComponent,
		GroupManagementComponent,
		GeneralModalComponent,
		FeatureModalComponent,
		FirstLoginModalComponent,
		GraphComponent,
		AssessmentInstructionPopupComponent,
		ResearcherDashboardComponent,
		JoinGroupTagComponent,
		GroupTagPopupComponent,
		AcceptInvitationComponent,
		InventoryComponent,
		SettingsComponent,
		VideoViewerComponent,
		UnsubscribeComponent,
		CongratulationsPopUpComponent,
		AccountModalComponent,
		ChecklistCardComponent,
		VideoCardComponent,
		ViewCardComponent,
	],
	imports: [
		NgxPaginationModule,
		BrowserModule,
		HttpClientModule,
		AngularFireModule.initializeApp(config.firebaseConfig),
		provideFirebaseApp(() => initializeApp(config.firebaseConfig)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
		AngularFireAuthModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserAnimationsModule,
		TabsModule,
		NgxChartsModule,
		CarouselModule,
		CalendarModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: 'toast-top-right',
			preventDuplicates: true,
		}),
		ModalModule.forRoot(),
		DragDropModule,
		BsDatepickerModule.forRoot(),
		TooltipModule.forRoot(),
		NgbModule,
		RatingModule,
		NgxStarRatingModule,
		NgxChartsModule,
		PlatformModule,
		NgxCaptchaModule,
		CdkStepperModule,
		NgStepperModule,
		NgMultiSelectDropDownModule.forRoot(),
		PdfViewerModule,
		NgScrollbarModule,
	],
	providers: [
		AuthService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptorService,
			multi: true,
		},
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		TabsetConfig,
		RatingConfig,
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: CarouselConfig, useValue: carouselConfig },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
