import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PUBLIC_HEADERS } from './auth.service';
import { config } from 'environment';

export interface Option {
	value: number;
	display: string;
}

@Injectable({
	providedIn: 'root',
})
export class AssessmentService {
	constructor(private http: HttpClient) {}

	getExternal(code: string): Observable<any> {
		return this.http.get<any>(
			`${
				config.apiBase
			}public/assessment/external/view?code=${encodeURIComponent(code)}`,
			{ headers: PUBLIC_HEADERS },
		);
	}

	submitExternal(code: string, skillScores: Array<any>): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/assessment/external/submit`,
			{ code, skillScores },
			{ headers: PUBLIC_HEADERS },
		);
	}
}
