import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chart } from 'chart.js/auto';
import Swal from 'sweetalert2';

import {
	Assessment,
	AssessmentService,
	ReportService,
	AssessmentStatus,
	AssessmentType,
	ImageChartEntry,
	OverallScoresColumn,
	DetailedReports,
} from '@services/leader';
import {
	Domain,
	DomainService,
	LoaderService,
	Skill,
	LanguageService,
} from '@services/public';
import {
	Group,
	GroupService,
	Organization,
	OrganizationService,
} from '@services/member';
import { UserService, User } from '@services/viewer';

const PAGE_PATH = `pages.leader.report`;

@Component({
	selector: 'app-leader-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class LeaderReportComponent implements OnInit, OnDestroy {
	@ViewChild('growthChart', { read: ElementRef }) growthChart: ElementRef;
	@ViewChild('myCanvas', { read: ElementRef }) myCanvas: ElementRef;

	// Page subscriptions.
	private _subscriptions: Subscription = new Subscription();

	// Page filters.
	selectedOrganization: Organization | null = null;
	selectedDomain: Domain = null;
	selectedGroup: Group = null;
	selectedUser: User = null;
	selectedAssessment: Assessment = null;
	selectedSkillOverallScoresSkill: Skill;
	selectedGrowthSkill: Skill;

	// Local data storage from API calls.
	domains: Array<Domain> = [];
	groups: Array<Group> = [];
	allUsers: Array<User> = [];
	users: Array<User> = [];
	assessments: Array<Assessment> = [];
	organizations: Array<Organization> | null = null;

	// Loading flags.
	groupsLoaded = false;

	// Filtered data.
	groupAssessments: Array<Assessment> = [];

	// Filtered data storage.
	filteredGroups: Array<Group> = [];

	// Report data.
	selfVsPeerTitle: string = '';
	selfVsPeer: Chart = null;
	strengthsAndWeaknesses: Array<ImageChartEntry> = [];
	skillOverallScores: Array<OverallScoresColumn> = [];
	growth: Chart = null;
	// Page language.
	page: { [key: string]: string } = {};

	constructor(
		private _languageSvc: LanguageService,
		public domainSvc: DomainService,
		private groupSvc: GroupService,
		public userSvc: UserService,
		private assessmentSvc: AssessmentService,
		private reportSvc: ReportService,
		private loaderSvc: LoaderService,
		private organizationSvc: OrganizationService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			this.selfVsPeerTitle = this.page?.selfVPeer;
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		// Set chart subscriptions.
		const reports: DetailedReports = this.reportSvc.detailedReports;
		this._subscriptions.add(
			reports.selfVsPeerBySkill.subscribe((next) => {
				if (!!this.selfVsPeer) this.selfVsPeer.destroy();
				if (this.myCanvas)
					this.selfVsPeer = new Chart(this.myCanvas.nativeElement, next);
			}),
		);
		this._subscriptions.add(
			reports.strengthsAndWeaknesses.subscribe(
				(next) => (this.strengthsAndWeaknesses = next),
			),
		);
		this._subscriptions.add(
			reports.skillOverallScores.subscribe(
				(next) => (this.skillOverallScores = [...next]),
			),
		);
		this._subscriptions.add(
			reports.skillGrowth.subscribe((next) => {
				if (!!this.growth) this.growth.destroy();
				if (this.growthChart)
					this.growth = new Chart(this.growthChart.nativeElement, next);
			}),
		);

		// Load domains.
		this.domainSvc.getDomains().then((response) => {
			this.domains = response;
			this.selectedDomain = this.domains[0];
			this.selectedGrowthSkill = this.selectedDomain.skills[0];
			this.selectedSkillOverallScoresSkill = this.selectedDomain.skills[0];
			this.updateFilters();
		});

		// Load groups.
		this._subscriptions.add(
			this.groupSvc.groups.subscribe((next) => {
				this.groups = next;
				this.groupsLoaded = true;
				if (!this.groups.includes(this.selectedGroup)) {
					this.selectedGroup = !this.groups.length ? null : this.groups[0];
					this.selectedUser = null;
					if (!this.selectedGroup) this.users = this.allUsers;
					else
						this.users = this.allUsers.filter((user) =>
							this.selectedGroup.memberUUIDs.includes(user.uuid),
						);
					this.selectedAssessment = null;
					this.groupAssessments = !this.groups.length
						? this.assessments
						: this.assessments.filter(
								(assessment) =>
									assessment.groupUUID === this.selectedGroup.uuid,
							);
					this.updateFilters();
				}
			}),
		);
		this.groupSvc.loadGroups();

		// Load users.
		this._subscriptions.add(
			this.userSvc.users.subscribe((next) => {
				this.allUsers = next;
				this.selectedUser = null;
				if (!this.selectedGroup) this.users = this.allUsers;
				else
					this.users = this.allUsers.filter((user) =>
						this.selectedGroup.memberUUIDs.includes(user.uuid),
					);
			}),
		);

		// Load organizations.
		this.organizationSvc.organizations.subscribe((organizations) => {
			this.organizations = organizations;
			if (this.organizations.length === 0)
				this.organizations.push({
					uuid: null,
					name: this.page?.noOrgs,
					active: false,
					addUser: false,
					createGroup: false,
				});
			const organizationUUID = localStorage.getItem('organizationUUID');
			if (!organizationUUID) {
				this.selectedOrganization = this.organizations[0];
				localStorage.setItem(
					'organizationUUID',
					this.selectedOrganization.uuid,
				);
			} else {
				const organization = this.organizations.find(
					(organization) => organization.uuid === organizationUUID,
				);
				this.selectedOrganization = !organization
					? this.organizations[0]
					: organization;
				localStorage.setItem(
					'organizationUUID',
					this.selectedOrganization.uuid,
				);
			}
			this.onOrganizationChange();
		});

		// Load all completed assessments.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.getAssessments()
			.pipe(
				map((response: any) => {
					if (!!response) {
						this.assessments = response.filter(
							(assessment) => assessment.status === AssessmentStatus.COMPLETED,
						);
						if (!this.selectedGroup)
							this.groupAssessments = [...this.assessments];
						else
							this.groupAssessments = this.assessments.filter(
								(assessment) =>
									assessment.groupUUID === this.selectedGroup.uuid,
							);
						this.loaderSvc.removeLoader(loader);
					}
				}).bind(this),
			)
			.subscribe();
	}

	onOrganizationChange() {
		// Skip until everything is loaded.
		if (!this.groups) return;
		if (!this.users) return;
		if (!this.selectedOrganization) return;

		// Store the current organization UUID locally.
		localStorage.setItem('organizationUUID', this.selectedOrganization.uuid);

		// Update the group and filters.
		this.filteredGroups = this.groups.filter(
			(group) => group.organizationUUID === this.selectedOrganization.uuid,
		);
		this.selectedGroup = null;
		this.onGroupChange();
	}

	updateFilters(): void {
		// Skip until everything is loaded.
		if (!this.selectedDomain) return;
		if (!this.groupsLoaded) return;

		// Update filters on all charts.
		this.reportSvc.updateDetailedFilters(
			this.selectedOrganization,
			this.selectedDomain,
			this.selectedGroup,
			this.selectedUser,
			this.selectedAssessment,
			this.selectedSkillOverallScoresSkill,
			this.selectedGrowthSkill,
		);

		// Update self vs peer title.
		if (!this.selectedAssessment) this.selfVsPeerTitle = this.page?.selfVPeer;
		else if (this.selectedAssessment.type === AssessmentType.SELF)
			this.selfVsPeerTitle = this.page?.selfAssessmentVPeer;
		else if (this.selectedAssessment.type === AssessmentType.PEER)
			this.selfVsPeerTitle = this.page?.recentVPeer;
	}

	onGroupChange() {
		this.selectedUser = null;
		if (!this.selectedGroup) this.users = this.allUsers;
		else
			this.users = this.allUsers.filter((user) =>
				this.selectedGroup.memberUUIDs.includes(user.uuid),
			);
		this.selectedAssessment = null;
		if (!this.selectedGroup) this.groupAssessments = this.assessments;
		else
			this.groupAssessments = this.assessments.filter(
				(assessment) => assessment.groupUUID === this.selectedGroup.uuid,
			);
	}

	onSearch() {
		this.updateFilters();
	}

	onDomainChange(domain: Domain) {
		this.selectedDomain = domain;
		this.selectedGrowthSkill = this.selectedDomain.skills[0];
		this.selectedSkillOverallScoresSkill = this.selectedDomain.skills[0];
		this.updateFilters();
	}

	onGrowthSkillChange() {
		this.reportSvc.updateSkillGrowth(this.selectedGrowthSkill);
	}

	onSkillOverallScoresSkillChange() {
		this.reportSvc.updateSkillOverallScores(
			this.selectedSkillOverallScoresSkill,
		);
	}

	allClear() {
		this.selectedGroup = !this.groups.length ? null : this.groups[0];
		this.selectedUser = null;
		this.selectedAssessment = null;
		this.groupAssessments = !this.groups.length
			? this.assessments
			: this.assessments.filter(
					(assessment) => assessment.groupUUID === this.selectedGroup.uuid,
				);
		this.updateFilters();
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		Swal.close();
	}
}
