import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from '@services/member';
import { LanguageService } from '@services/public';
import { MessageReportData } from '../common';
import { APINoData, isAPINoData } from '@util';
import { PieChartData } from '@components';

const PAGE_PATH: string = 'skillBuilders.message.pages.report';

@Component({
	selector: 'app-member-skill-builder-message-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderMessageReportComponent implements OnInit {
	ICON_CLASSES: { [key: string]: string } = {
		UP: 'double-up',
		DOWN: 'double-down',
	};
	SHOW_MIN: number = 3;
	@Input() noData: () => void;
	display: boolean = false;
	isPie: boolean = false;
	pieData: Array<PieChartData> = [];
	positiveMessagesDelivered: any;
	positivePercentage: string;
	peopleList: any;
	show: number = 3;

	// Page langauge.
	page: { [key: string]: string } = {
		pieTitle: '',
		positive: '',
		neutral: '',
		negative: '',
		positiveMessagesDelivered: '',
		peopleComplimented: '',
		totalMessages: '',
		positivelyReceived: '',
		viewMore: '',
		viewLess: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _messageSvc: MessageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		this._messageSvc
			.getReport()
			.subscribe((res: MessageReportData | APINoData) => {
				if (!isAPINoData(res)) {
					this.display = true;
					if (!!res.pie) {
						this.isPie = true;
						this.pieData = [
							{
								name: this.page.positive,
								value: res.pie.positive,
								label: `${res.pie.positive}%`,
							},
							{
								name: this.page.neutral,
								value: res.pie.neutral,
								label: `${res.pie.neutral}%`,
							},
							{
								name: this.page.negative,
								value: res.pie.negative,
								label: `${res.pie.negative}%`,
							},
						];
					}
					this.positiveMessagesDelivered = res.positiveMessagesDelivered;
					this.peopleList = res.members;
				} else {
					this.display = false;
					this.noData();
				}
			});
	}

	toggleMore() {
		this.show =
			this.peopleList.length > this.show
				? this.peopleList.length
				: this.SHOW_MIN;
	}
}
