import { Component, Input, OnInit } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { MESSAGE_PAGE, MessageData } from '../common';
import { NO_HTML_PATTERN } from '@util';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.message.pages.name';

@Component({
	selector: 'app-member-skill-builder-message-name',
	templateUrl: './name.component.html',
	styleUrls: ['./name.component.scss'],
})
export class MemberSkillBuilderMessageNameComponent implements OnInit {
	@Input() data: MessageData;
	form: UntypedFormGroup;
	submitted: boolean = false;

	// Page language.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
		placeholder: '',
		next: '',
		error: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Setup form.
		this.form = new UntypedFormGroup({
			name: new UntypedFormControl(this.data?.name || '', [
				Validators.required,
				Validators.pattern(NO_HTML_PATTERN),
			]),
		});
	}

	next(): void {
		this.form.controls.name.setValue(
			(this.form.controls.name.value || '').trim(),
		);
		this.submitted = true;
		if (!this.form.valid) return;
		this._storageSvc.setTempStorage(TEMP_KEYS.MESSAGE, {
			...this.data,
			page: MESSAGE_PAGE.VALUES,
			name: this.form.controls.name.value,
		});
	}
}
