<div class="member-dashboard-rewards-journey">
	<div class="title">
		{{ page.title }}
	</div>
	<ng-scrollbar
		track="horizontal"
		pointerEventsMethod="scrollbar"
		autoHeightDisabled="false"
	>
		<div class="journey-display">
			<hr class="journey-bar" />
			<div class="journey-icons">
				<div class="journey-main-icon">
					<div class="journey-main-icon-rotate">
						<img
							class="journey-main-icon-image"
							src="assets/img/journey/level-1.svg"
							alt="journey icon"
						/>
						<div class="journey-dot"></div>
					</div>
				</div>
				<div class="journey-user-icons">
					<div *ngFor="let icon of getIcons(1)" class="journey-icon">
						<img class="journey-icon-image" [src]="icon" />
					</div>
				</div>
				<div class="journey-main-icon">
					<div class="journey-main-icon-rotate">
						<img
							src="assets/img/journey/level-2.svg"
							class="journey-main-icon-image"
							alt="journey icons"
						/>
						<div class="journey-dot"></div>
					</div>
				</div>
				<div class="journey-user-icons">
					<div *ngFor="let icon of getIcons(2)" class="journey-icon">
						<img class="journey-icon-image" [src]="icon" />
					</div>
				</div>
				<div class="journey-main-icon">
					<div class="journey-main-icon-rotate">
						<img
							src="assets/img/journey/level-3.svg"
							class="journey-main-icon-image"
							alt="journey icons"
						/>
						<div class="journey-dot"></div>
					</div>
				</div>
				<div class="journey-user-icons">
					<div *ngFor="let icon of getIcons(3)" class="journey-icon">
						<img class="journey-icon-image" [src]="icon" />
					</div>
				</div>
				<div class="journey-main-icon">
					<div class="journey-main-icon-rotate">
						<img
							src="assets/img/journey/level-4.svg"
							class="journey-main-icon-image"
							alt="journey icons"
						/>
						<div class="journey-dot"></div>
					</div>
				</div>
				<div class="journey-user-icons">
					<div *ngFor="let icon of getIcons(4)" class="journey-icon">
						<img class="journey-icon-image" [src]="icon" />
					</div>
				</div>
				<div class="journey-main-icon">
					<div class="journey-main-icon-rotate">
						<img
							src="assets/img/journey/level-5.svg"
							class="journey-main-icon-image"
							alt="journey icons"
						/>
						<div class="journey-dot"></div>
					</div>
				</div>
				<div class="journey-user-icons">
					<div *ngFor="let icon of getIcons(5)" class="journey-icon">
						<img class="journey-icon-image" [src]="icon" />
					</div>
				</div>
			</div>
		</div>
	</ng-scrollbar>
</div>
