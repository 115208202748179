import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { MESSAGE_PAGE, MessageData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.message.pages.deliveredMessage';

@Component({
	selector: 'app-member-skill-builder-message-delivered-message',
	templateUrl: './delivered-message.component.html',
	styleUrls: ['./delivered-message.component.scss'],
})
export class MemberSkillBuilderMessageDeliveredMessageComponent
	implements OnInit
{
	@ViewChild('keepTryingTemplate', { static: false })
	keepTryingTemplate: TemplateRef<void>;
	@Input() data: MessageData;
	choice: boolean | null = null;
	submitted = false;
	modalRef?: BsModalRef;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
		yes: '',
		no: '',
		keepTrying: '',
		ok: '',
		error: '',
		next: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
		private _modalSvc: BsModalService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				if (key === 'subTitle')
					this._languageSvc
						.template(this.page[key], {
							name: this.data?.name,
							message: this.data?.message,
						})
						.then((value) => {
							this.page[key] = value;
						});
				else
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value));
			}
		});
	}

	closeModal() {
		this.modalRef?.hide();
		this._router.navigate(['member/dashboard']);
	}

	next() {
		this.submitted = true;
		if (this.choice === null) return;

		if (!this.choice) {
			this.modalRef = this._modalSvc.show(this.keepTryingTemplate, {
				class: 'modal-sm modal-dialog-centered',
			});
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.MESSAGE, {
			...this.data,
			page: MESSAGE_PAGE.IMPACT,
		});
	}
}
