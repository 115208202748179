import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Subscription, map, Subject, BehaviorSubject } from 'rxjs';
import { Chart } from 'chart.js/auto';
import {
	DomainService,
	Domain,
	LoaderService,
	LanguageService,
} from '@services/public';
import {
	DashboardReports,
	ImageChartEntry,
	OverallScoresColumn,
	ReportService,
} from '@services/leader';
import {
	Group,
	GroupService,
	Organization,
	OrganizationService,
	AssessmentService,
} from '@services/member';
import { User, UserService } from '@services/viewer';
import { Filter } from '@util';
import { ChartConfiguration } from 'chart.js';
const PAGE_PATH = `pages.researcher.dashboard.measureDashboard`;
@Component({
	selector: 'app-measure-dashboard',
	templateUrl: './measure-dashboard.component.html',
	styleUrls: ['./measure-dashboard.component.scss'],
})
export class MeasureDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('growthCanvas', { read: ElementRef }) growthCanvas: ElementRef;

	// View all links.
	viewAllSelf: boolean;
	viewAllSelfVsPeer: boolean;
	title: string;
	message: string;
	data: any;
	userAssessment: any;
	userData: any;

	// Domain Overall Scores variables.
	isPieChartVisible: boolean = true;
	viewMoreDomainOverallScores: boolean;

	// Page subscriptions.
	private _subscriptions: Subscription = new Subscription();

	// Page filters.
	selectedOrganization: Organization | null = null;
	selectedDomain: Domain | null = null;
	selectedTimespan: string = 'all time';
	selectedGroup: Group | null = null;
	selectedUser: User | null = null;
	selectedGrowthDomain: Domain | null = null;
	private _domainFilterForRecentSelf: Filter<Domain> = {
		key: 'domainFilter',
		value: null,
		options: null,
	};
	private _domainFilterForSelfVsPeer: Filter<Domain> = {
		key: 'domainFilter',
		value: null,
		options: null,
	};
	filtersForRecentSelf: Array<Filter<Domain>> = [
		this._domainFilterForRecentSelf,
	];
	filtersForSelfVsPeer: Array<Filter<Domain>> = [
		this._domainFilterForSelfVsPeer,
	];

	// Chart refresh subjects.
	refreshForRecentSelf: Subject<boolean> = new Subject<boolean>();
	refreshForSelfVsPeer: Subject<boolean> = new Subject<boolean>();

	// Chart view all subjects.
	viewAllForRecentSelf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	);
	viewAllForSelfVsPeer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	);

	// Local data storage from API calls.
	organizations: Array<Organization> | null = null;
	domains: Array<Domain> | null = null;
	groups: Array<Group> | null = null;
	users: Array<User> | null = null;

	// Filtered data storage.
	filteredGroups: Array<Group> = [];
	filteredUsers: Array<User> = [];

	// Report data.
	domainOverallScores: Array<OverallScoresColumn> = [];
	growth: Chart = null;
	strengthsAndWeaknesses: Array<ImageChartEntry> = [];
	selfAwareness: Array<ImageChartEntry> = [];
	self: Chart = null;
	selfVsPeer: Chart = null;
	assesmentObj: any;
	p: number = 1;
	// Page language.
	page: { [key: string]: string } = {};
	constructor(
		private _languageSvc: LanguageService,
		public domainSvc: DomainService,
		private organizationSvc: OrganizationService,
		private groupSvc: GroupService,
		private reportSvc: ReportService,
		private usersSvc: UserService,
		private loaderSvc: LoaderService,
		private assessmentSvc: AssessmentService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		// Set chart subscriptions.
		const reports: DashboardReports = this.reportSvc.dashboardReports;
		this._subscriptions.add(
			reports.domainOverallScores.subscribe(
				(next) => (this.domainOverallScores = [...next]),
			),
		);
		this._subscriptions.add(
			reports.domainGrowth.subscribe((next) => {
				if (!!this.growth) this.growth.destroy();
				if (this.growthCanvas)
					this.growth = new Chart(this.growthCanvas.nativeElement, next);
			}),
		);
		this._subscriptions.add(
			reports.strengthsAndWeaknesses.subscribe(
				(next) => (this.strengthsAndWeaknesses = next),
			),
		);
		this._subscriptions.add(
			reports.selfAwareness.subscribe((next) => (this.selfAwareness = next)),
		);
		this._subscriptions.add(
			reports.selfByGroup.subscribe((_next) => {
				this.refreshForRecentSelf.next(true);
			}),
		);
		this._subscriptions.add(
			reports.selfVsPeerByUser.subscribe((_next) => {
				this.refreshForSelfVsPeer.next(true);
			}),
		);

		// Load users.
		this._subscriptions.add(
			this.usersSvc.users.subscribe((users) => {
				this.users = users;
				if (!this.selectedOrganization) return;
				if (!this.selectedGroup)
					this.filteredUsers = this.users.filter(
						(user) => user.organizationUUID === this.selectedOrganization.uuid,
					);
				else
					this.filteredUsers = this.users.filter((user) =>
						this.selectedGroup.memberUUIDs.includes(user.uuid),
					);
				if (!!this.selectedUser) {
					const user = this.filteredUsers.find(
						(user) => user.uuid === this.selectedUser.uuid,
					);
					this.selectedUser = !user ? null : user;
				}
				this.loadReports();
				this.loadGridData();
			}),
		);

		// Load groups.
		this._subscriptions.add(
			this.groupSvc.groups.subscribe((groups) => {
				this.groups = groups.filter(
					(group) => !!group.role && group.memberUUIDs.length > 0,
				);
				if (!this.selectedOrganization) return;
				this.filteredGroups = this.groups.filter(
					(group) => group.organizationUUID === this.selectedOrganization.uuid,
				);
				if (!!this.selectedGroup) {
					const group = this.filteredGroups.find(
						(group) => group.uuid === this.selectedGroup.uuid,
					);
					this.selectedGroup = !group ? null : group;
				}
				this.onGroupChange();
			}),
		);
		this.groupSvc.loadGroups();

		// Load organizations.
		this.organizationSvc.organizations.subscribe((organizations) => {
			this.organizations = organizations;
			if (this.organizations.length === 0)
				this.organizations.push({
					uuid: null,
					name: 'No Organizations',
					active: false,
					addUser: false,
					createGroup: false,
				});
			const organizationUUID = localStorage.getItem('organizationUUID');
			if (!organizationUUID) {
				this.selectedOrganization = this.organizations[0];
				localStorage.setItem(
					'organizationUUID',
					this.selectedOrganization.uuid,
				);
			} else {
				const organization = this.organizations.find(
					(organization) => organization.uuid === organizationUUID,
				);
				this.selectedOrganization = !organization
					? this.organizations[0]
					: organization;
				localStorage.setItem(
					'organizationUUID',
					this.selectedOrganization.uuid,
				);
			}
			this.onOrganizationChange();
		});

		// Load domains.
		this.domainSvc.getDomains().then((response) => {
			this.domains = response;
			this._domainFilterForRecentSelf.options = response;
			this._domainFilterForSelfVsPeer.options = response;
			this.selectedDomain = this.domains[0];
			this.onDomainChange();
		});
	}

	onOrganizationChange() {
		// Skip until everything is loaded.
		if (!this.groups) return;
		if (!this.users) return;
		if (!this.selectedOrganization) return;

		// Store the current organization UUID locally.
		localStorage.setItem('organizationUUID', this.selectedOrganization.uuid);

		// Update the group and filters.
		this.filteredGroups = this.groups.filter(
			(group) => group.organizationUUID === this.selectedOrganization.uuid,
		);
		this.selectedGroup = null;
		this.onGroupChange();
	}

	onDomainChange() {
		// Reset all domains to match top domain.
		this.selectedGrowthDomain = this.selectedDomain;
		this._domainFilterForRecentSelf.value = this.selectedDomain;
		this._domainFilterForSelfVsPeer.value = this.selectedDomain;
		this.loadReports();
	}

	onGrowthDomainChange() {
		this.reportSvc.updateDomainGrowth(this.selectedGrowthDomain);
	}

	getRecentSelfChartConfiguration(
		filters: any,
		modal: boolean = true,
	): ChartConfiguration {
		const configuration =
			this.reportSvc.getRecentSelfByGroupChartConfiguration(filters);
		if (!configuration) return null;
		if (!modal) {
			if (configuration.data.labels.length > 6) {
				this.viewAllForRecentSelf.next(true);
				configuration.data.labels.splice(6);
				configuration.data.datasets[0].data.splice(6);
			} else this.viewAllForRecentSelf.next(false);
		} // else configuration.options.bar.datasets.barThickness = 10;
		return configuration;
	}

	getSelfVsPeerChartConfiguration(
		filters: any,
		modal: boolean = true,
	): ChartConfiguration {
		const configuration =
			this.reportSvc.getSelfVsPeerByUserChartConfiguration(filters);
		if (!configuration) return null;
		if (!modal) {
			if (configuration.data.labels.length > 6) {
				this.viewAllForSelfVsPeer.next(true);
				configuration.data.labels.splice(6);
				configuration.data.datasets[0].data.splice(6);
				configuration.data.datasets[1].data.splice(6);
			} else this.viewAllForSelfVsPeer.next(false);
		} // else configuration.options.bar.datasets.barThickness = 10;
		return configuration;
	}

	loadReports(): void {
		// Skip until everything is loaded.
		if (!this.users) return;
		if (!this.groups) return;
		if (!this.selectedOrganization) return;
		if (!this.domains) return;

		// Show loader.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(
			loader,
			'pages/leader/dashboard/measure:_loadReports',
		);

		// Update dashboard filters.
		this.reportSvc.updateDashboardFilters(
			this.selectedOrganization,
			this.selectedDomain,
			this.selectedGroup,
			this.selectedUser,
			this.selectedTimespan,
			this.selectedGrowthDomain,
			this._domainFilterForRecentSelf.value,
			this._domainFilterForSelfVsPeer.value,
		);

		// Clear loader.
		this.loaderSvc.removeLoader(loader);
	}

	//pie chart
	pieChartLabel(series: any[], name: string): string {
		const item = series.filter((data) => data.name === name);
		if (item.length > 0) {
			return item[0].label;
		}
		return name;
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	loadGridData() {
		// Skip until everything is loaded.
		if (!this.users) return;
		if (!this.groups) return;
		if (!this.selectedOrganization) return;
		this.p = 1;
		this.assessmentSvc
			.getAssessmentHistory()
			.pipe(
				map((response: any) => {
					if (!!response) {
						response.map((assignment) => {
							const user = this.users?.find(
								(user) => user.uuid === assignment.uuid,
							);
							assignment.genericName =
								user !== undefined ? user.name : assignment.genericName;
						});
						if (this.selectedGroup === null) {
							this.userData = response.filter(
								(assessment) =>
									assessment.organizationUUID ===
									this.selectedOrganization.uuid,
							);
						} else {
							this.userData = response.filter(
								(assessment) =>
									assessment.groupUUID === this.selectedGroup.uuid &&
									assessment.organizationUUID ===
										this.selectedOrganization.uuid,
							);
						}
						const uniqueArray = Array.from(
							new Set(this.userData.map((item) => item.uuid)),
						).map((uuid) => {
							return this.userData.find((item) => item.uuid === uuid);
						});
						this.data = uniqueArray;
						this.data.map((user) => {
							if (user.genericName) {
								user.genericName = this.capitalizeFirstLetter(user.genericName);
							}
						});
					}
				}).bind(this),
			)
			.subscribe();
	}

	capitalizeFirstLetter(value) {
		return value
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	onGroupChange() {
		// Skip until everything is loaded.
		if (!this.users) return;
		if (!this.selectedOrganization) return;

		// Update the user filter.
		if (!this.selectedGroup)
			this.filteredUsers = this.users.filter(
				(user) => user.organizationUUID === this.selectedOrganization.uuid,
			);
		else
			this.filteredUsers = this.users.filter((user) =>
				this.selectedGroup.memberUUIDs.includes(user.uuid),
			);
		this.selectedUser = null;
		this.loadReports();
		this.loadGridData();
	}
}
