import { Component, OnInit } from '@angular/core';
import { AuthService, LanguageService } from '@services/public';
import { ActivatedRoute, Router } from '@angular/router';

const PAGE_PATH: string = 'pages.public.unsubscribe';

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit {
	private _email: string;

	// Page langauge.
	page: { [key: string]: string } = {
		cancel: '',
		unsubscribe: '',
	};

	constructor(
		private _authSvc: AuthService,
		private _activatedRouteSvc: ActivatedRoute,
		private _languageSvc: LanguageService,
		private _routerSvc: Router,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});
		this._activatedRouteSvc.queryParams.subscribe((params) => {
			this._email = params.email;
		});
	}

	cancel() {
		this._routerSvc.navigate(['login']);
	}

	unsubscribe() {
		this._authSvc
			.unsubscribeEmail(this._email)
			.subscribe(() => this._routerSvc.navigate(['login']));
	}
}
