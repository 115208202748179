<div class="member-dashboard-rewards-skill-builder-badges">
	<div class="title">
		{{ title }}
	</div>
	<div class="skill-builder-badges">
		<div
			*ngFor="let skillBuilderBadge of skillBuilderBadges"
			class="skill-builder-badge"
		>
			<div class="icon-progress">
				<img
					class="icon"
					[src]="skillBuilderBadge.icon"
					alt="skill builder icon"
				/>
				<svg viewBox="0 0 36 36" [attr.class]="skillBuilderBadge.class">
					<path
						class="grey-circle"
						d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
					/>
					<path
						class="progress-circle"
						[attr.stroke-dasharray]="skillBuilderBadge.progress"
						d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
					/>
				</svg>
			</div>
			<div class="name-level">
				<div class="name">
					{{ skillBuilderBadge.name }}
				</div>
				<div class="level">
					{{ labels.level | slice: 0 : 1 }} {{ skillBuilderBadge.level }}
				</div>
			</div>
		</div>
	</div>
</div>
