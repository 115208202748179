import { Component, OnInit } from '@angular/core';
import { AllChallengesService } from '@pages/member/challenges/services/all-challenges.service';
import { LanguageService } from '@services/public';

@Component({
	selector: 'app-challenge-dashboard-tab',
	templateUrl: './challenge-dashboard-tab.component.html',
	styleUrls: ['./challenge-dashboard-tab.component.scss'],
})
export class ChallengeDashboardTabComponent implements OnInit {
	mobileDashboardValues: any = {};

	// Language Translation
	pageLanguage: any;
	appLabels: any;
	basePath = `pages.member.dashboard.pages`;

	constructor(
		private allChallengesSvc: AllChallengesService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this.mobileDashboard();
		// Load Language
		this._languageSvc
			.get([this.basePath + `.challengeDashboardTab`])
			.then((value) => {
				this.pageLanguage = value[this.basePath + `.challengeDashboardTab`];
			});
		this._languageSvc.get([`labels`]).then((value) => {
			this.appLabels = value[`labels`];
		});
	}

	mobileDashboard() {
		this.allChallengesSvc.getMobileDashboard().subscribe((res) => {
			this.mobileDashboardValues = res;
		});
	}
}
