// Igbo

import { LocaleData } from 'ngx-bootstrap/chronos';

export const igLocale: LocaleData = {
	months: [
		'Jenụwarị',
		'Febụwarị',
		'Machi',
		'Eprel',
		'Mee',
		'Juni',
		'Julaị',
		'Ọgọọst',
		'Septemba',
		'Oktoba',
		'Novemba',
		'Disemba',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mar',
		'Epr',
		'Mee',
		'Jun',
		'Jul',
		'Ọg',
		'Sep',
		'Okt',
		'Nov',
		'Dis',
	],
	weekdays: [
		'Sọndee',
		'Mọnde',
		'Tọọsdee',
		'Wenesdee',
		'Tọsdee',
		'Fraịdee',
		'Satọdee',
	],
	weekdaysShort: ['Sọ', 'Mọ', 'Tọọ', 'Wen', 'Tọ', 'Fra', 'Sat'],
	weekdaysMin: ['Sọ', 'Mọ', 'Tọọ', 'Wen', 'Tọ', 'Fra', 'Sat'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Taa] LT',
		nextDay: '[Echi] LT',
		nextWeek: 'dddd [na] LT',
		lastDay: '[Ụnyaahụ] LT',
		lastWeek: '[Izu gara aga] dddd [na] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'n’ %s',
		past: '%s gara aga',
		s: 'sekọnd ndị ole na ole',
		m: 'otu nkeji',
		mm: '%d nkeji',
		h: 'otu elekere',
		hh: '%d elekere',
		d: 'otu ụbọchị',
		dd: '%d ụbọchị',
		M: 'otu ọnwa',
		MM: '%d ọnwa',
		y: 'otu afọ',
		yy: '%d afọ',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
