// Ewe
import { LocaleData } from 'ngx-bootstrap/chronos';

export const eeLocale: LocaleData = {
	months: [
		'Dzove',
		'Dzodze',
		'Tedoxe',
		'Afɔfiɛ',
		'Dama',
		'Masa',
		'Siamlɔm',
		'Deasiamime',
		'Anyɔnyɔ',
		'Kele',
		'Adeɛmekpɔxe',
		'Dzome',
	],
	monthsShort: [
		'Dzv',
		'Dzodz',
		'Ted',
		'Afɔ',
		'Dam',
		'Mas',
		'Sia',
		'Dea',
		'Any',
		'Kel',
		'Ade',
		'Dzom',
	],
	weekdays: ['Kɔsiɖa', 'Dzoɖa', 'Braɖa', 'Kuɖa', 'Yawoɖa', 'Fiɖa', 'Memleɖa'],
	weekdaysShort: ['Kɔs', 'Dzo', 'Bra', 'Kuɖ', 'Yaw', 'Fiɖ', 'Mem'],
	weekdaysMin: ['Kɔ', 'Dz', 'Br', 'Ku', 'Ya', 'Fi', 'Me'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Egbe] LT',
		nextDay: '[Etsɔ si gbɔna] LT',
		nextWeek: 'dddd [si gbɔna] LT',
		lastDay: '[Etsonā] LT',
		lastWeek: 'dddd [si gbɔna] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'le %s me',
		past: 'hafi %s',
		s: 'few seconds',
		m: 'ɖeke gaƒoƒo',
		mm: '%d gaƒoƒo',
		h: 'ɖeke gaƒoƒo',
		hh: '%d gaƒoƒo',
		d: 'ɖeke ŋkeke',
		dd: '%d ŋkeke',
		M: 'ɖeke zã',
		MM: '%d zã',
		y: 'ɖeke ƒe',
		yy: '%d ƒe',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
