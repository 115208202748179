// Khmer

import { LocaleData } from 'ngx-bootstrap/chronos';

export const kmLocale: LocaleData = {
	months: [
		'មករា',
		'កុម្ភៈ',
		'មីនា',
		'មេសា',
		'ឧសភា',
		'មិថុនា',
		'កក្កដា',
		'សីហា',
		'កញ្ញា',
		'តុលា',
		'វិច្ឆិកា',
		'ធ្នូ',
	],
	monthsShort: [
		'មក',
		'កុ',
		'មី',
		'មេស',
		'ឧស',
		'មិថ',
		'កក',
		'សេះ',
		'កញ',
		'តុ',
		'វិច',
		'ធ្ន',
	],
	weekdays: [
		'អាទិត្យ',
		'ច័ន្ទ',
		'អង្គារ',
		'ពុធ',
		'ព្រហស្បតិ៍',
		'សុក្រ',
		'សៅរ៍',
	],
	weekdaysShort: ['អាទ', 'ច័', 'អង្គ', 'ពុធ', 'ព្រហ', 'សុក', 'សៅ'],
	weekdaysMin: ['អាទ', 'ច័', 'អង', 'ពុ', 'ព្រ', 'សុ', 'សៅ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[ថ្ងៃនេះ] LT',
		nextDay: '[ថ្ងៃស្អែក] LT',
		nextWeek: 'dddd [ក្រោយ] LT',
		lastDay: '[ម្សិលមិញ] LT',
		lastWeek: '[សប្តាហ៍មុន] dddd [ក្រោយ] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s បន្ទាប់',
		past: '%s មុន',
		s: 'រយៈពេលប៉ុន្មានវិនាទី',
		m: 'មិន្នឹតមួយ',
		mm: '%d នាទី',
		h: 'មួយម៉ោង',
		hh: '%d ម៉ោង',
		d: 'មួយថ្ងៃ',
		dd: '%d ថ្ងៃ',
		M: 'មួយខែ',
		MM: '%d ខែ',
		y: 'មួយឆ្នាំ',
		yy: '%d ឆ្នាំ',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
