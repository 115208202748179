<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-12 col-lg-6 space-between">
				<span class="cursor-pointer txt-md">
					<i class="fas fa-arrow-left" (click)="navigation()"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{
					pageLanguage?.title
				}}</span>
				<button
					type="button"
					(click)="navigation()"
					class="close d-none d-blcok-sm"
					[attr.aria-label]="labels?.close"
				>
					<div aria-hidden="true" class="icon-md">&times;</div>
				</button>
			</div>
		</div>
	</div>
</div>

<div class="main-container">
	<div class="container-fluid px-0 pb-5-sm-c pn-5-md-c">
		<div class="col-12">
			<div class="row column-reverse-sm">
				<div class="col-md-12 col-lg-6 challenge-card challenge-card-accordion">
					<div class="card card-accordion acc-purple h-100">
						<div
							class="card-header align-items-center px-sm-2-c font-weight-bold"
						>
							<div
								class="col-sm-6"
								class="plus-icon"
								data-toggle="collapse"
								data-target="#collapseOne"
								(click)="expandAccGroup()"
								aria-controls="collapseOne"
							>
								<i
									[ngClass]="[
										accExpandedGroup == true
											? 'fa fa-chevron-up'
											: 'fa fa-chevron-down',
									]"
									aria-hidden="false"
								></i>
							</div>
							{{ labels?.group }}
							<span class="track-btn right-btn d-flex">
								<button
									type="button"
									class="btn btn-small btn-primary ml-2"
									(click)="joinGroup()"
								>
									{{ pageLanguage?.joinGroup }}
								</button>
							</span>
						</div>
						<div
							class="{{
								accExpandedGroup == true
									? 'card-body collapse collapse'
									: 'card-body collapse collapse show'
							}}"
							aria-labelledby="headingOne"
						>
							<ul class="list-group">
								<li
									class="list-group-item d-flex justify-content-between align-items-center font-weight-bold"
									*ngFor="let group of memberGroup; let i = index"
								>
									<div class="col-sm-6">
										<span class="d-flex flex-wrap flex-lg-nowrap">{{
											group.name
										}}</span>
									</div>
									<div
										class="col-sm-6 d-flex justify-content-end pr-0 btn-row-c"
									>
										<span *ngIf="group.member">
											<button
												_ngcontent-wfq-c304=""
												type="button"
												class="btn btn-md ml-3"
												(click)="openModal(template, group)"
											>
												<span class="pr-2"
													><img
														src="assets/img/leave-icon.svg"
														alt="{{ pageLanguage?.leaveGroup }}"
												/></span>
												{{ labels?.leave }}
											</button>
										</span>
										<span *ngIf="!group.member">
											<button
												_ngcontent-wfq-c304=""
												type="button"
												class="btn btn-md ml-3"
											>
												<span class="pr-2"
													><img
														src="assets/img/send-reminder-icon.svg"
														alt="{{ labels?.sendReminder }}"
												/></span>
												{{ labels?.sendReminder }}
											</button>
										</span>
									</div>
								</li>
								<li
									class="list-group-item d-flex justify-content-between align-items-center font-weight-bold"
									*ngIf="memberGroup == 0"
								>
									<p>
										{{ pageLanguage?.notAMemberOfAnyGroup }}
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #template>
	<div class="modal-body text-center modal-confirm">
		<div class="modal-body">
			<div class="msg-wrapper">
				<div class="delete-ico">
					<img
						src="assets/img/leave-group-circle.svg"
						alt="{{ pageLanguage?.leaveGroup }}"
					/>
				</div>
				<p class="mt-3 text-center delete-msg">
					{{ dialogMessage }}
				</p>
			</div>
		</div>
		<div class="pt-4 border-top">
			<button (click)="decline()" type="button" class="btn btn-md ml-3">
				{{ labels?.cancel }}
			</button>
			<button (click)="leave()" type="button" class="btn btn-md btn-primary">
				{{ labels?.leave }}
			</button>
		</div>
	</div>
</ng-template>
