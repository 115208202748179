<div *ngIf="options" class="component-walkthrough">
	<div
		class="backdrop-container {{ options.backdrop }} {{ fixed ? 'fixed' : '' }}"
	>
		<div
			class="backdrop bottom"
			[ngStyle]="{ top: element.bottom + 'px' }"
		></div>
		<div
			class="backdrop left"
			[ngStyle]="{
				bottom: 'calc(100% - ' + element.bottom + 'px)',
				right: 'calc(100% - ' + element.left + 'px)',
				top: element.top + 'px',
			}"
		></div>
		<div
			class="backdrop right"
			[ngStyle]="{
				bottom: 'calc(100% - ' + element.bottom + 'px)',
				left: element.right + 'px',
				top: element.top + 'px',
			}"
		></div>
		<div
			class="backdrop top"
			[ngStyle]="{ bottom: 'calc(100% - ' + element.top + 'px)' }"
		></div>
	</div>
	<div #popup class="popup" [ngStyle]="popupStyle">
		<div>{{ options.display }}</div>
		<div class="button-holder">
			<button *ngIf="options.back" type="button" (click)="options.back()">
				<i>&lt;</i>
				<span>{{ labels.back }}</span>
			</button>
			<button
				*ngIf="options.next && !hideNext"
				type="button"
				(click)="options.next()"
			>
				<span>{{ labels.next }}</span>
				<i>&gt;</i>
			</button>
		</div>
	</div>
	<div
		*ngIf="
			options.position.type === 'element' && options.position.position === 'top'
		"
		class="triangle top"
		[ngStyle]="triangleStyle"
	></div>
	<div
		*ngIf="
			options.position.type === 'element' &&
			options.position.position === 'bottom'
		"
		class="triangle bottom"
		[ngStyle]="triangleStyle"
	></div>
	<div
		*ngIf="
			options.position.type === 'element' &&
			options.position.position === 'left'
		"
		class="triangle left"
		[ngStyle]="triangleStyle"
	></div>
	<div
		*ngIf="
			options.position.type === 'element' &&
			options.position.position === 'right'
		"
		class="triangle right"
		[ngStyle]="triangleStyle"
	></div>
	<!-- eslint-disable-next-line -->
	<a
		*ngIf="hasClickElement"
		class="click-element"
		[ngStyle]="clickElementStyle"
		(click)="onClick()"
	></a>
</div>
