import { CalendarDate, CalendarEvent } from '@components';
import { SKILL_BUILDER_TYPES, SkillBuilderData } from '@services/member';

export enum GOAL_PAGE {
	DOMAIN = 'DOMAIN',
	SKILL = 'SKILL',
	ACTION = 'ACTION',
	CUSTOM_ACTION = 'CUSTOM_ACTION',
	YESTERDAY = 'YESTERDAY',
	TRACK = 'TRACK',
}

export const GOAL_PREVIOUS_PAGE = {};

// Start flow.
GOAL_PREVIOUS_PAGE[GOAL_PAGE.DOMAIN] = null;
GOAL_PREVIOUS_PAGE[GOAL_PAGE.SKILL] = GOAL_PAGE.DOMAIN;
GOAL_PREVIOUS_PAGE[GOAL_PAGE.ACTION] = GOAL_PAGE.SKILL;
GOAL_PREVIOUS_PAGE[GOAL_PAGE.CUSTOM_ACTION] = GOAL_PAGE.SKILL;

// Track flow.
GOAL_PREVIOUS_PAGE[GOAL_PAGE.YESTERDAY] = null;
GOAL_PREVIOUS_PAGE[GOAL_PAGE.TRACK] = GOAL_PAGE.YESTERDAY;

export enum GoalCompleted {
	NO = 'no',
	PROCRASTINATED = 'procrastinated',
	YES = 'yes',
}

export interface GoalData extends SkillBuilderData {
	type: SKILL_BUILDER_TYPES.GOAL;
	page?: GOAL_PAGE;
	skillBuilderType?: string;
	dailyCompleted?: boolean;
	yesterdayCompleted?: boolean;
	yesterday?: boolean;
	domainUID?: number;
	skillUID?: number;
	skill?: string;
	action?: string;
	completed?: GoalCompleted;
	help?: string;
}

export interface GoalReportLog {
	date: string;
	completed: GoalCompleted;
}

export interface GoalReportData {
	uuid: string;
	domainUID: number;
	skill: string;
	action: string;
	streak: number;
	logs: Array<GoalReportLog>;
	name?: string;
	calendarEvents?: Map<CalendarDate, CalendarEvent>;
}
