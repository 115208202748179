<div class="barChart boxMain card-white card-white-sm border-radius-md">
	<div class="boxHead card-title-main">
		<h5>{{ title }}</h5>
		<div
			class="filters col-auto d-flex mt-2 mt-md-0 mb-2 mb-lg-0 align-items-center px-0"
		>
			<button class="view-all" *ngIf="isViewAll.value" (click)="viewAll()">
				{{ view }}
			</button>
			<ng-container *ngFor="let filter of filters">
				<select class="" [(ngModel)]="filter.value" (change)="getChart()">
					<option *ngFor="let option of filter.options" [ngValue]="option">
						<a class="{{ filter.value === option ? 'active' : '' }}">
							{{ option.name }}
						</a>
					</option>
				</select>
			</ng-container>
			<span
				*ngIf="!!info"
				[ngbTooltip]="info"
				triggers="click"
				tooltipClass="my-custom-class"
				placement="left"
				class="pl-2"
			>
				<span class="info-circle">
					<img src="assets/img/info-ico-card.svg" alt="info" />
				</span>
			</span>
		</div>
	</div>
	<div class="chartArea">
		<canvas #barChart></canvas>
	</div>
</div>
