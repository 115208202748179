<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-12 col-lg-6 space-between">
				<span class="cursor-pointer txt-md">
					<i class="fas fa-arrow-left" (click)="backstep()"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{ inventory.title }}</span>
				<button
					type="button"
					(click)="closeTab()"
					class="close d-none d-blcok-sm"
					[attr.aria-label]="labels.close"
				>
					<div aria-hidden="true" class="icon-md">&times;</div>
				</button>
			</div>
		</div>
	</div>
</div>

<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container px-0 w-sm-100 w-50">
		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-12 col-lg-10 col-xl-8">
				<div class="row w-100 justify-content-center">
					<div class="col-sm-12">
						<h5 class="w-auto text-center mb-4 px-3 font-weight-bold">
							{{ inventory.description }}
						</h5>
						<div
							*ngFor="let question of inventory.questions"
							class="card card-white-sm mb-3 card-no-space"
						>
							<div class="cards-body">
								<p class="text-center para ft-bold">{{ question.display }}</p>
								<div
									*ngIf="
										question.type === 'list' || question.type === 'multilist'
									"
								>
									<div *ngFor="let answer of question.answers">
										<div class="form-check1 mb-3 px-3">
											<label
												class="form-check-label pl-0 d-flex align-items-center"
											>
												<input
													*ngIf="question.type == 'list'"
													class="form-check-input"
													[name]="question.uid"
													type="radio"
													[(ngModel)]="question.selected"
													[id]="
														question.uid.toString() + answer.value.toString()
													"
													[value]="answer.value"
												/>
												<input
													*ngIf="question.type == 'multilist'"
													class="form-check-input"
													[name]="question.uid"
													type="checkbox"
													[(ngModel)]="answer.selected"
													[id]="
														question.uid.toString() + answer.value.toString()
													"
													[value]="answer.value"
												/>
												<span class="letter">{{ answer.value }}</span>
												<div class="d-flex pl-2">
													<div class="options pr-2">
														<span>{{ answer.display }}</span>
													</div>
												</div>
											</label>
										</div>
									</div>
								</div>
								<div
									*ngIf="question.type === 'star'"
									class="row justify-content-center"
								>
									<div class="col-12">
										<div class="">
											<div class="cont d-flex justify-content-center">
												<div class="stars">
													<ngx-star-rating
														[(ngModel)]="question.selected"
														[id]="question.uid"
													></ngx-star-rating>
												</div>
											</div>
											<div class="col-12 px-3">
												<div class="dot-sm"></div>
												<div class="dotted-line">
													<i class="fas fa-chevron-right right-arrow"></i>
												</div>
												<div class="col-12 rating-label">
													<div class="lft">
														{{ question.answers[0]?.display | titlecase }}
													</div>
													<div class="rgt">
														{{ question.answers[1]?.display | titlecase }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="submitted" class="invalid-feedback">
							{{ page.errorAnswerAll }}
						</div>
					</div>
				</div>

				<div class="footer-inner-action">
					<button
						type="button"
						class="btn btn-primary btn-lg px-4"
						(click)="onSubmit()"
					>
						{{ labels.submit }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
