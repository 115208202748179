// Krio
import { LocaleData } from 'ngx-bootstrap/chronos';

export const kriLocale: LocaleData = {
	months: [
		'Januwari',
		'Februwari',
		'Machi',
		'Aprili',
		'Mei',
		'Juni',
		'Julai',
		'Agosti',
		'Septemba',
		'Oktoba',
		'Novemba',
		'Disemba',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mac',
		'Apr',
		'Mei',
		'Jun',
		'Jul',
		'Ago',
		'Sep',
		'Okt',
		'Nov',
		'Dis',
	],
	weekdays: [
		'Sande',
		'Mande',
		'Tiusde',
		'Wenesde',
		'Torsde',
		'Fraide',
		'Sataade',
	],
	weekdaysShort: ['San', 'Man', 'Tiu', 'Wen', 'Tor', 'Fra', 'Sat'],
	weekdaysMin: ['Sa', 'Ma', 'Ti', 'We', 'To', 'Fr', 'Sa'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Tude] LT',
		nextDay: '[Tomo] LT',
		nextWeek: 'dddd [na] LT',
		lastDay: '[Yestade] LT',
		lastWeek: '[Last] dddd [na] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'a few seconds',
		m: 'a minute',
		mm: '%d minutes',
		h: 'an hour',
		hh: '%d hours',
		d: 'a day',
		dd: '%d days',
		M: 'a month',
		MM: '%d months',
		y: 'a year',
		yy: '%d years',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
