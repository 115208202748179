import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService, LanguageService } from '@services/public';

import { Assessment, AssessmentStatus, AssessmentType } from '@services/leader';
import { Group, GroupService } from '@services/member';
import { AssessmentService } from '@services/member/assessment.service';

@Component({
	selector: 'app-assessment-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
})
export class MemberAssessmentListComponent implements OnInit, OnDestroy {
	modalRef?: BsModalRef;
	message?: string;
	groups: Array<Group> = [];
	groupSubscription: Subscription;
	selectedGroup: Group = null;
	assessments: Array<Assessment> = [];
	activeAssessments: Array<Assessment> = [];

	// Language Translation
	pageLanguage: any;
	appLabels: any;
	basePath = `pages.member.assessment`;

	constructor(
		private groupSvc: GroupService,
		private assessmentSvc: AssessmentService,
		private router: Router,
		private loaderSvc: LoaderService,
		private modalSvc: BsModalService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this.router.events.subscribe((x) => {
			if (x instanceof NavigationEnd) {
				window.scrollTo(0, 0);
			}
		});
		// Load Language
		this._languageSvc.get([this.basePath]).then((value) => {
			this.pageLanguage = value[this.basePath];
			// Load assessments.
			this.loadAssessmentList();
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.appLabels = value[`labels`];
		});
		// Load groups.
		this.groupSvc.groups.subscribe((next) => {
			this.groups = next.filter((group) => group.member);
			this.selectedGroup = null;
		});
		this.groupSvc.loadGroups();
	}

	loadAssessmentList() {
		// Load assessments.
		const assessmentLoader: unique symbol = Symbol();
		this.loaderSvc.addLoader(assessmentLoader);
		this.assessmentSvc
			.getAssessments()
			.pipe(
				map((response: any) => {
					if (!!response) {
						let dateToday = new Date();
						let activeAssessments = [];
						let scheduledAssessments = [];
						let tempAssessments = response.filter((assessment) => {
							if (this.daysBetweenDates(dateToday, assessment.startDate) > 31)
								return false;
							assessment.daysUntilStart = this.daysBetweenDates(
								dateToday,
								assessment.startDate,
							);
							const group = this.groups.find(
								(group) => group.uuid === assessment.groupUUID,
							);
							assessment.group = group;
							return true;
						});
						activeAssessments = this.sortAssessmentsByStartDate(
							tempAssessments.filter(
								(assessment) => assessment.status === AssessmentStatus.ACTIVE,
							),
						);
						scheduledAssessments = this.sortAssessmentsByStartDate(
							tempAssessments.filter(
								(assessment) =>
									assessment.status === AssessmentStatus.SCHEDULED,
							),
						);
						this.assessments = [...activeAssessments, ...scheduledAssessments];
						this.activeAssessments = this.assessments;
						this.loaderSvc.removeLoader(assessmentLoader);
					}
					this.assessments.forEach((ele) => {
						if (ele.groupUUID !== null) {
							ele.name = ele.name
								? this.capitalizeFirstLetter(ele.name)
								: ele.name;
							ele.group.name = ele.group?.name
								? this.capitalizeFirstLetter(ele.group.name)
								: ele.group?.name;
						}
						if (ele.type === AssessmentType.LEADER)
							ele.type = this.pageLanguage.page.leader;
						if (ele.type === AssessmentType.PEER)
							ele.type = this.pageLanguage.page.peer;
						if (ele.type === AssessmentType.SELF)
							ele.type = this.pageLanguage.page.self;
						ele.type = this.capitalizeFirstLetter(ele.type);
					});
				}).bind(this),
			)
			.subscribe();
	}

	sortAssessmentsByStartDate(assessments): Array<Assessment> {
		return assessments.sort((item1, item2) => {
			if (item1.daysUntilStart < item2.daysUntilStart) return -1;
			if (item2.daysUntilStart < item1.daysUntilStart) return 1;
			return 0;
		});
	}

	// returns negative number if startDate comes after dateToCompare (past date)
	// returns positive number if startDate comes before dateToCompare (future date)
	daysBetweenDates(startDate, dateToCompare) {
		let fullDay = 86400000;
		let dateToday: any = startDate;
		let dateStart: any = new Date(dateToCompare);
		return Math.round((dateToday - dateStart) / fullDay) * -1;
	}

	onGroupChange(group?: Group) {
		if (!group) {
			this.selectedGroup = null;
			this.assessments = this.activeAssessments;
		} else {
			this.selectedGroup = group;
			this.assessments = this.activeAssessments.filter(
				(assessment) => assessment.group?.uuid === group.uuid,
			);
		}
	}

	startAssessment(assessment: Assessment) {
		if (assessment.type.toLowerCase() === AssessmentType.PEER)
			this.router.navigate(['/member/assessment/peer', assessment.uuid]);
		else if (assessment.type.toLowerCase() === AssessmentType.LEADER)
			this.router.navigate(['/member/assessment/leader', assessment.uuid]);
		else this.router.navigate(['/member/assessment/self', assessment.uuid]);
	}
	capitalizeFirstLetter(value) {
		return value
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}
	decline() {
		this.message = 'Declined!';
		this.modalRef?.hide();
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-sm modal-dialog-centered',
		});
	}
	ngOnDestroy(): void {
		if (!!this.groupSubscription) this.groupSubscription.unsubscribe();
	}
	splitString(str: string, delim: string): Array<string> {
		return str.split(`${delim}`);
	}
}
