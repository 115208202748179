import { Component, OnInit } from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { config } from 'environment';
import {
	AuthService,
	TokenService,
	LogoutService,
	Role,
	LanguageService,
	STORAGE_KEYS,
} from '@services/public';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Auth, OAuthProvider, UserCredential } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { signInWithPopup } from '@firebase/auth';
import {
	SignInWithApple,
	SignInWithAppleOptions,
	SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountModalComponent } from '@pages/account/account-modal/account-modal.component';
import { application } from 'environment';
import { ProfileService } from '@services/member';

declare var AppleID;
const PAGE_PATH = `pages.public.login.page`;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class PublicLoginComponent implements OnInit {
	form: UntypedFormGroup;
	hidePassword: boolean = false;
	token: any;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		titleMobile: '',
		subtitle: '',
		email: '',
		emailRequired: '',
		password: '',
		passwordRequired: '',
		forgotPassword: '',
		login: '',
		loginEdLink: '',
		loginApple: '',
		loginGoogle: '',
		sso: '',
		ssoDeclined: '',
		registrationError: '',
	};

	constructor(
		private _authSvc: AuthService,
		private _tokenSvc: TokenService,
		private _toastSvc: ToastrService,
		private _profileSvc: ProfileService,
		private _router: Router,
		private _auth: Auth,
		private _logoutSvc: LogoutService,
		private _modalSvc: BsModalService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Initialize the login form.
		this.form = new UntypedFormGroup({
			email: new UntypedFormControl(null, Validators.required),
			password: new UntypedFormControl(null, Validators.required),
		});

		// Check if someone is already logged in.
		if (!!this._tokenSvc.refreshToken) {
			this._checkAccountRole();
			return;
		}

		// Initialize google sign-in.
		GoogleAuth.initialize({
			clientId:
				'529874557268-tr1023p0oak6hlg64thbgdi3mms6ot4d.apps.googleusercontent.com',
			scopes: ['profile', 'email'],
			grantOfflineAccess: true,
		});

		// Initialize push notification plugin.
		if (Capacitor.isPluginAvailable('PushNotifications')) {
			PushNotifications.requestPermissions().then((result) => {
				if (result.receive === 'granted') PushNotifications.register();
			});

			PushNotifications.addListener('registration', (token: Token) => {
				localStorage.setItem(STORAGE_KEYS.DEVICE_TOKEN, token.value);
			});

			PushNotifications.addListener('registrationError', (error: any) => {
				alert(this.page.registrationError + ' ' + JSON.stringify(error));
			});
		}
	}

	get f(): any {
		return this.form.controls;
	}

	onLoginSubmit(): void {
		// Validate form.
		this.form.markAllAsTouched();
		if (this.form.invalid) return;

		// Login user.
		this._authSvc
			.login(this.form.value.email, this.form.value.password, true)
			.pipe(
				map((response: any) => {
					if (!this._saveAuth(response)) {
						this._logoutSvc.logout();
						this.form.patchValue({ password: '' });
					}
				}).bind(this),
			)
			.subscribe();
	}

	onEdLinkClick(): void {
		window.location.href = `https://ed.link/sso/login?client_id=${encodeURIComponent(
			config.clientID,
		)}&redirect_uri=${encodeURIComponent(
			config.redirectURI,
		)}&response_type=code`;
	}

	private _parseJwt(token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(''),
		);

		return JSON.parse(jsonPayload);
	}

	private _generateNonce(length: number): string {
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	// Apple Login. Unused.
	public async appleLogin() {
		try {
			AppleID.auth.init({
				// The Service ID registered with Apple for use with SignInWithApple.
				clientId: config.apple.serviceID,
				scope: 'email name',
				// The URL to direct the user to after the login.
				redirectURI: config.apple.redirectURI,
				state: 'init',
				nonce: this._generateNonce(10),
				usePopup: true, //or false defaults to false
			});
			const data = await AppleID.auth.signIn();
			const token = this._parseJwt(data.authorization.id_token);
			// const code = this.parseJwt(data.authorization.grant_code)
			this._authSvc.appleLogin(token).subscribe((response) => {
				if (!this._saveAuth(response)) {
					this._logoutSvc.logout();
					this.form.patchValue({ password: '' });
				}
			});
		} catch (error) {
			// console.error(error);
		}
	}

	onAppleClick() {
		if (Capacitor.getPlatform() === 'web') this.appleSSOWeb();
		else this.appleSSONative();
	}

	async appleSSOWeb() {
		const provider = new OAuthProvider('apple.com');
		await signInWithPopup(this._auth, provider).then(
			(result: UserCredential) => {
				this.token = result['_tokenResponse']['oauthIdToken'];
			},
		);
		this._authSvc
			.appleLogin(this.token)
			.pipe(
				map((response: any) => {
					if (!this._saveAuth(response)) {
						this._logoutSvc.logout();
						this.form.patchValue({ password: '' });
					}
				}).bind(this),
			)
			.subscribe();
	}

	appleSSONative() {
		let options: SignInWithAppleOptions = {
			// The Service ID registered with Apple for use with SignInWithApple.
			clientId: config.apple.serviceID,
			// The URL to direct the user to after the login.
			redirectURI: config.apple.redirectURI,
			scopes: 'email name',
			state: 'init',
			// A unique value to prevent replay attacks (optional but recommended).
			nonce: this._generateNonce(10),
		};
		SignInWithApple.authorize(options)
			.then(async (result: SignInWithAppleResponse) => {
				this._authSvc
					.appleLogin(result.response.identityToken)
					.pipe(
						map((response: any) => {
							if (!this._saveAuth(response)) {
								this._logoutSvc.logout();
								this.form.patchValue({ password: '' });
							}
						}).bind(this),
					)
					.subscribe();
			})
			.catch();
	}

	async onGoogleClick() {
		let googleUser = await GoogleAuth.signIn();
		this._authSvc
			.googleAuth(googleUser.authentication.idToken, true)
			.pipe(
				map((response: any) => {
					if (!this._saveAuth(response)) {
						this._logoutSvc.logout();
						this.form.patchValue({ password: '' });
					}
				}).bind(this),
			)
			.subscribe();
	}

	private _saveAuth(res): boolean {
		if (!this._tokenSvc.login(res)) return false;
		this._authSvc.confirm().subscribe();
		const deviceToken = localStorage.getItem(STORAGE_KEYS.DEVICE_TOKEN);
		if (!!deviceToken) this._profileSvc.addDevice(deviceToken).subscribe();
		this._checkAccountRole();
		return true;
	}

	private _checkAccountRole() {
		this._tokenSvc.role.subscribe((role) => {
			if (role === Role.ACCOUNT) {
				// @ts-ignore
				if (application === 'jakapa.website') {
					this._modalSvc.show(AccountModalComponent, {
						class: 'modal-md modal-dialog-centered',
						backdrop: 'static',
						keyboard: false,
					});
				} else {
					this._toastSvc.error('Account inactive!');
				}
			} else {
				this._toastSvc.clear();
				this._router.navigate(['member/dashboard']);
			}
		});
	}
}
