<!-- Main content -->
<section class="content">
	<div class="tab-content" id="myTabContent">
		<div
			class="tab-pane fade show active"
			id="physics"
			role="tabpanel"
			aria-labelledby="physics-tab"
		>
			<div class="main-container pb-lg">
				<!-- copy from here... -->
				<div class="bck-clr pl-3 pr-3">
					<app-language-select></app-language-select>
					<div class="card-wrapper d-flex flex-wrap justify-content-between">
						<div class="small-card mb-3">
							<div
								class="card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
								[routerLink]="['/member/profile', { fromSetting: true }]"
							>
								<img
									width="26"
									height="26"
									src="../../../../assets/img/Profile-icon.svg"
									alt="icon"
								/>
								<span class="text-center pl-2">{{
									pageLanguage?.profile
								}}</span>
							</div>
						</div>
						<!-- <div class="small-card mb-3">
              <div
                class="card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
                [routerLink]="['/member/join', {fromSetting:true}]"
              >
                <img
                  width="26"
                  height="26"
                  src="../../../../assets/img/Add-Group-icon.svg"
                  alt="icon"
                />
                <span class="text-center pl-2">Join Group/Tag</span>
              </div>
            </div> -->
						<!-- <div class="small-card mb-3">
              <div
                class="card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
              >
                <img
                  width="26"
                  height="26"
                  src="../../../../assets/img/talk-bubbles-line.svg"
                  alt="icon"
                />
                <span class="text-center pl-2">Feedback</span>
              </div>
            </div> -->
						<div class="small-card mb-3">
							<div
								class="card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
								(click)="resources()"
							>
								<img
									width="26"
									height="26"
									src="../../../../assets/img/Resources-icon.svg"
									alt="icon"
								/>
								<span class="text-center pl-2">{{
									pageLanguage?.resources
								}}</span>
							</div>
						</div>
						<div class="small-card mb-3">
							<div
								class="card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
								(click)="openModal(template)"
							>
								<img
									width="26"
									height="26"
									src="../../../../assets/img/How-use-icon.svg"
									alt="icon"
								/>
								<span class="text-center pl-2"
									>{{ pageLanguage?.howToUse }} <br />{{
										pageLanguage?.theApp
									}}</span
								>
							</div>
						</div>
						<!--Logout Card-->
						<div class="small-card mb-3">
							<div
								class="card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
								(click)="logout()"
							>
								<img
									width="26"
									height="26"
									src="../../../../assets/img/Logout-icon.svg"
									alt="icon"
								/>
								<span class="text-center pl-2">{{ pageLanguage?.logout }}</span>
							</div>
						</div>
						<!--Certificates-->
						<div class="small-card mb-3">
							<div
								class="list-btn card-inner d-flex justify-content-center align-items-center pt-4 pb-4 pl-1 pr-1"
								(click)="toggleSlidingPanel()"
							>
								<div class="list-btn certificate-icon"></div>
								<span class="list-btn text-center pl-2">{{
									pageLanguage?.achievements
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- till here... -->
			</div>
		</div>
	</div>
</section>

<ng-template #template>
	<div class="modal-body px-0">
		<div class="modal-header px-0 pr-4 pt-0 pt-md-3 pb-0 pb-md-3">
			<h4 class="modal-title d-flex align-items-center justify-content-center">
				<label class="lbl-md pl-3 mb-0">{{ pageLanguage?.howToUseApp }}</label>
			</h4>

			<button
				type="button"
				class="btn-close close pull-right"
				[attr.aria-label]="pageLanguage?.close"
				(click)="decline()"
			>
				<span aria-hidden="true" class="visually-hidden">&times;</span>
			</button>
		</div>

		<div class="col-12 my-4 px-4 embed-responsive embed-responsive-16by9">
			<iframe
				class="embed-responsive-item"
				src="https://www.youtube.com/embed/eLOJPAjA91s?autoplay=0&loop=1&autopause=0&controls=1"
				width="420"
				height="360"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
			></iframe>
		</div>

		<div
			class="pt-0 pt-md-3 d-flex d-lg-none align-items-center justify-content-center"
		>
			<button (click)="decline()" type="button" class="btn btn-md">
				{{ pageLanguage?.close }}
			</button>
		</div>
	</div>
</ng-template>

<app-certificate-list
	#slidingPanel
	[isOpen]="isSlidingPanelOpen"
></app-certificate-list>
