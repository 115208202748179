<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container px-0 w-sm-100 w-50">
		<p class="mt-3 text-center success-msg px-5 title-main">
			{{ page.title }}
		</p>
		<h2 class="subtext subtext-md pt-1">
			{{ page.subTitle }}
		</h2>

		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center">
					<div class="col-12 mb-3">
						<form [formGroup]="form">
							<textarea
								class="form-control txtarea"
								id="action"
								rows="3"
								[placeholder]="page.placeholder"
								formControlName="action"
								[maxlength]="maxChars"
								[ngClass]="{
									'is-invalid': submitted && form.controls.action.errors,
								}"
							>
							</textarea>
							<div
								*ngIf="submitted && form.controls.action.errors"
								class="invalid-feedback"
							>
								<div *ngIf="form.controls.action.errors.required">
									{{ page.error1 }}
								</div>
								<div *ngIf="form.controls.action.errors.pattern">
									{{ page.error2 }}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-inner-action">
			<button
				type="button"
				(click)="nextOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ page.next }}
			</button>
		</div>
	</div>
</div>
