import { Component } from '@angular/core';
import { LoaderService } from '@services/public';
import { ReplaySubject } from 'rxjs';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
	isLoading: ReplaySubject<boolean>;

	constructor(private _loaderSvc: LoaderService) {
		this.isLoading = this._loaderSvc.isLoading;
	}
}
