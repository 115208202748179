import { SKILL_BUILDER_TYPES, SkillBuilderData } from '@services/member';

export enum MESSAGE_PAGE {
	NAME = 'NAME',
	VALUES = 'VALUES',
	POSITIVE_MESSAGE = 'POSITIVE_MESSAGE',
	DELIVERED_MESSAGE = 'DELIVERED_MESSAGE',
	IMPACT = 'IMPACT',
}

export const MESSAGE_PREVIOUS_PAGE = {};

// Start flow.
MESSAGE_PREVIOUS_PAGE[MESSAGE_PAGE.NAME] = null;
MESSAGE_PREVIOUS_PAGE[MESSAGE_PAGE.VALUES] = MESSAGE_PAGE.NAME;
MESSAGE_PREVIOUS_PAGE[MESSAGE_PAGE.POSITIVE_MESSAGE] = MESSAGE_PAGE.VALUES;

// Track flow.
MESSAGE_PREVIOUS_PAGE[MESSAGE_PAGE.DELIVERED_MESSAGE] = null;
MESSAGE_PREVIOUS_PAGE[MESSAGE_PAGE.IMPACT] = MESSAGE_PAGE.DELIVERED_MESSAGE;

export interface MessageData extends SkillBuilderData {
	type: SKILL_BUILDER_TYPES.MESSAGE;
	page?: MESSAGE_PAGE;
	name?: string;
	values?: Array<string>;
	message?: string;
	logUUID?: string;
	impact?: string;
}

export interface MessageReportMember {
	name: string;
	messages: number;
	positivePercentage: number;
}

export interface MessageReportPie {
	positive: number;
	neutral: number;
	negative: number;
}

export interface MessageReportData {
	positiveMessagesDelivered: number;
	members: Array<MessageReportMember>;
	pie?: MessageReportPie;
}
