import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	ValidationErrors,
} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { map } from 'rxjs/operators';
import { PASSWORD_PATTERN, keyPressOnlyAlphabets } from '@util/common';
import {
	LoaderService,
	LogoutService,
	StorageService,
	LanguageService,
} from '@services/public';
import { ProfileService } from '@services/member/profile.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
	SignInWithApple,
	SignInWithAppleOptions,
	SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import { Platform } from '@angular/cdk/platform';
import { Capacitor } from '@capacitor/core';
import { config } from 'environment';
declare var AppleID;
const PATH = `pages.member.profile.page`;

@Component({
	selector: 'app-member-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class MemberProfileComponent implements OnInit, OnDestroy {
	keyPressOnlyAlphabets: typeof keyPressOnlyAlphabets = keyPressOnlyAlphabets; // Expose this function to the template.
	@ViewChild('appleButtonHolder', { static: false })
	appleButtonHolder: ElementRef;
	form: UntypedFormGroup;
	hideCurrentPassword = true;
	hideNewPassword = true;
	hideConfirmPassword = true;
	existingEmails = [];
	modalRef?: BsModalRef;
	message?: string;
	private _subscriptions: Subscription = new Subscription();
	isPlatform: boolean = false;
	appleLinked: boolean;
	pageLanguage: any;

	constructor(
		private profileSvc: ProfileService,
		private router: Router,
		private logoutSvc: LogoutService,
		private modalSvc: BsModalService,
		private activatedRoute: ActivatedRoute,
		private loaderSvc: LoaderService,
		private _storageSvc: StorageService,
		private platform: Platform,
		private _languageSvc: LanguageService,
	) {
		if (this.platform.IOS) {
			this.isPlatform = true;
		}
	}

	noWhitespaceValidator1(control: UntypedFormControl): ValidationErrors | null {
		const isWhitespace = (control.value || '').trim().length === 0;
		return isWhitespace ? { whitespace: true } : null;
	}

	ngOnInit() {
		this.form = new UntypedFormGroup(
			{
				firstName: new UntypedFormControl(null, [
					Validators.required,
					Validators.maxLength(40),
					this.noWhitespaceValidator1,
				]),
				lastName: new UntypedFormControl(null, [
					Validators.required,
					Validators.maxLength(40),
					this.noWhitespaceValidator1,
				]),
				email: new UntypedFormControl({ value: null, disabled: true }),
				currentPassword: new UntypedFormControl(null),
				newPassword: new UntypedFormControl(null, [
					Validators.pattern(PASSWORD_PATTERN),
				]),
				confirmPassword: new UntypedFormControl(null),
			},
			this.matchPasswords,
		);

		// Load language
		this._languageSvc
			.get([PATH])
			.then((value) => (this.pageLanguage = value[PATH]));

		// Load profile.
		this._subscriptions.add(
			this.profileSvc.profile.subscribe((next) => {
				this.existingEmails = next.emails;
				this.form.reset();
				this.form.patchValue(next);
				this.f.email.patchValue(
					next.emails.find((email) => email.primary).email,
				);
				this.appleLinked = next.emails.some(
					(email) => email.ssoProvider === 'apple',
				);
				this.updateLinkStatus();
			}),
		);
		this.profileSvc.loadProfile();
		this.initializeAppleSignInWithWeb();
	}

	updateLinkStatus(): void {
		if (this.appleLinked)
			this.appleButtonHolder.nativeElement.classList.add('linked');
	}

	get f(): any {
		return this.form.controls;
	}

	private matchPasswords(form: UntypedFormGroup): any {
		if (form.value.newPassword === form.value.confirmPassword) return null;
		return { mismatch: true };
	}

	validateForm() {
		if (
			!this.form.value.currentPassword &&
			!this.form.value.newPassword &&
			!this.form.value.confirmPassword
		) {
			this.f.currentPassword.setErrors(null);
			this.f.newPassword.setErrors(null);
			this.f.confirmPassword.setErrors(null);
		} else {
			this.f.currentPassword.setErrors(
				!this.form.value.currentPassword && this.f.currentPassword.touched
					? { required: true }
					: this.f.currentPassword.errors
						? this.f.currentPassword.errors
						: null,
			);
			this.f.newPassword.setErrors(
				!this.form.value.newPassword && this.f.newPassword.touched
					? { required: true }
					: this.f.newPassword.errors
						? this.f.newPassword.errors
						: null,
			);
			this.f.confirmPassword.setErrors(
				!this.form.value.confirmPassword && this.f.confirmPassword.touched
					? { required: true }
					: this.f.confirmPassword.errors
						? this.f.confirmPassword.errors
						: null,
			);
			this.f.currentPassword.markAllAsTouched();
			this.f.newPassword.markAllAsTouched();
			this.f.confirmPassword.markAllAsTouched();
		}
	}

	updateProfile(): void {
		// Validate form.
		this.validateForm();
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}

		// Compile profile updates.
		const profile = {};
		for (const control in this.form.controls)
			if (
				this.form.controls[control].dirty &&
				!!this.form.controls[control].value
			)
				profile[control] = this.form.controls[control].value;

		// Update profile.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.profileSvc
			.updateProfile(profile)
			.pipe(
				map((response: any) => {
					if (response.success) {
						this.loaderSvc.removeLoader(loader);
						Swal.fire({
							title: this.pageLanguage?.updateSuccess,
							icon: 'success',
							confirmButtonColor: '#456790',
							confirmButtonText: this.pageLanguage.ok,
							didDestroy: () => this.profileSvc.loadProfile(),
						});
					}
				}).bind(this),
			)
			.subscribe();
	}

	changePrimaryEmail(event) {
		this.f.email.patchValue(event.emails.find((email) => email.primary).email);
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-sm modal-dialog-centered',
		});
	}

	deleteProfile() {
		this.profileSvc
			.deleteProfile()
			.pipe(
				map((response: any) => {
					if (response.success) {
						this.modalRef.hide();
						this._storageSvc.clearMainStorage();
						this.logoutSvc.logout();
						this.router.navigate(['/']);
					}
				}).bind(this),
			)
			.subscribe();
	}

	decline(): void {
		this.message = this.pageLanguage?.declined;
		this.modalRef?.hide();
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	navigation() {
		if (this.activatedRoute.snapshot.paramMap.get('fromSetting')) {
			this.router.navigate(['/member/settings']);
		} else {
			this.router.navigate(['/member/dashboard']);
		}
	}

	generateNonce(length: number): string {
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	// Link Apple Account
	linkApple() {
		if (Capacitor.getPlatform() === 'web') {
			this.signInWithWeb();
		} else {
			this.signInWithNative();
		}
	}

	initializeAppleSignInWithWeb(): void {
		AppleID.auth.init({
			clientId: config.apple.serviceID,
			scope: 'email name',
			redirectURI: config.apple.redirectURI,
			state: 'init',
			nonce: this.generateNonce(10),
			usePopup: true, //or false defaults to false
		});
	}

	// capacitor apple sign in on web
	async signInWithWeb() {
		const data = await AppleID.auth.signIn();
		const {
			authorization: { id_token },
		} = data;
		this.profileSvc.linkApple(id_token).subscribe((response) => {
			if (response.success) {
				Swal.fire({
					title: this.pageLanguage?.appleLinkSuccess,
					icon: 'success',
					confirmButtonColor: '#456790',
					confirmButtonText: this.pageLanguage.ok,
					didDestroy: () => this.profileSvc.loadProfile(),
				});
			} else {
				Swal.fire({
					title: this.pageLanguage.appleLinkFail,
					text: response.errors[0].message,
					icon: 'error',
					confirmButtonColor: '#456790',
					confirmButtonText: this.pageLanguage.ok,
				});
			}
		});
	}

	// Native apple sign in on ios
	signInWithNative() {
		let options: SignInWithAppleOptions = {
			clientId: config.apple.serviceID,
			redirectURI: config.apple.redirectURI,
			scopes: 'email name',
			state: 'init',
			nonce: this.generateNonce(10),
		};
		SignInWithApple.authorize(options)
			.then(async (result: SignInWithAppleResponse) => {
				try {
					this.profileSvc.linkApple(result.response.identityToken).subscribe(
						(response) => {
							if (response.success) {
								Swal.fire({
									title: this.pageLanguage.appleLinkSuccess,
									icon: 'success',
									confirmButtonColor: '#456790',
									confirmButtonText: this.pageLanguage.ok,
									didDestroy: () => this.profileSvc.loadProfile(),
								});
							} else {
								Swal.fire({
									title: this.pageLanguage.appleLinkFail,
									text: response.errors[0].message,
									icon: 'error',
									confirmButtonColor: '#456790',
									confirmButtonText: this.pageLanguage.ok,
								});
							}
						},
						(error) => {
							// Handle subscription error
							Swal.fire({
								title: this.pageLanguage.appleLinkFail,
								icon: 'error',
								confirmButtonColor: '#456790',
								confirmButtonText: this.pageLanguage.ok,
							});
						},
					);
				} catch (error) {
					// Handle error in try block
					Swal.fire({
						title: this.pageLanguage.appleLinkFail,
						icon: 'error',
						confirmButtonColor: '#456790',
						confirmButtonText: this.pageLanguage.ok,
					});
				}
			})
			.catch((error) => {
				// Handle error in the authorize call
				Swal.fire({
					title: this.pageLanguage.appleLinkFail,
					icon: 'error',
					confirmButtonColor: '#456790',
					confirmButtonText: this.pageLanguage.ok,
				});
			});
	}
}
