// Bhojpuri
import { LocaleData } from 'ngx-bootstrap/chronos';

export const bhoLocale: LocaleData = {
	months: [
		'जनवरी',
		'फरवरी',
		'मार्च',
		'अप्रैल',
		'मई',
		'जून',
		'जुलाई',
		'अगस्त',
		'सितंबर',
		'अक्टूबर',
		'नवंबर',
		'दिसंबर',
	],
	monthsShort: [
		'जन',
		'फर',
		'मार्च',
		'अप्रै',
		'मई',
		'जून',
		'जुलाई',
		'अग',
		'सितं',
		'अक्टू',
		'नवं',
		'दिसं',
	],
	weekdays: [
		'रविवार',
		'सोमवार',
		'मंगलवार',
		'बुधवार',
		'बिहिवार',
		'शुक्रवार',
		'शनिवार',
	],
	weekdaysShort: ['रवि', 'सोम', 'मंगल', 'बुध', 'बिहि', 'शुक्र', 'शनि'],
	weekdaysMin: ['र', 'सो', 'मं', 'बु', 'बिहि', 'शु', 'श'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[आज] LT',
		nextDay: '[काल्ह] LT',
		nextWeek: 'dddd [के दिन] LT',
		lastDay: '[बीता] LT',
		lastWeek: '[बीता] dddd [के दिन] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s बाद',
		past: '%s पहिले',
		s: 'कइके सेकंड',
		m: 'एक मिनट',
		mm: '%d मिनट',
		h: 'एक घंटा',
		hh: '%d घंटा',
		d: 'एक दिन',
		dd: '%d दिन',
		M: 'एक महीना',
		MM: '%d महीना',
		y: 'एक साल',
		yy: '%d साल',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
