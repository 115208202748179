import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
	GroupService,
	Group,
	Organization,
	ALL_GROUPS,
	NO_ORGANIZATIONS,
} from '@services/member';
import { OrganizationService } from '@services/member';
import { LanguageService, LoaderService } from '@services/public';
import { map } from 'rxjs/operators';
import { ChartConfiguration } from 'chart.js';
import { User, UserService } from '@services/viewer';
import { Filter } from '@util';
import { SkillBuilderService } from '@services/researcher';
const PAGE_PATH = `pages.researcher.dashboard.trainDashboard`;
// Constants.
const SKILL_BUILDERS = [
	'abstract',
	'adventure',
	'brain',
	'decision',
	'goal',
	'journal',
	'message',
	'stress',
	'wwyd',
];

@Component({
	selector: 'app-train-dashboard',
	templateUrl: './train-dashboard.component.html',
	styleUrls: ['./train-dashboard.component.scss'],
})
export class TrainDashboardComponent implements OnInit, OnDestroy {
	// Page filters.
	organizationFilter: Filter<Organization> = {
		key: 'organizationFilter',
		value: null,
		options: null,
	};
	timespanFilter: Filter<any> = {
		key: 'timespanFilter',
		value: null,
		options: [
			{ name: 'All Time', value: 'all time' },
			{ name: '30 Days', value: '30 days' },
			{ name: '90 Days', value: '90 days' },
			{ name: '180 Days', value: '180 days' },
			{ name: '1 Year', value: '1 year' },
			{ name: '5 Years', value: '5 years' },
		],
	};
	skillBuilderFilter: Filter<any> = {
		key: 'skillBuilderFilter',
		value: null,
		options: null,
	};
	groupFilter: Filter<Group> = {
		key: 'groupFilter',
		value: null,
		options: null,
	};
	private _groupFilterForSkill: Filter<Group> = {
		key: 'groupFilterForSkill',
		value: null,
		options: null,
	};
	private _groupFilterForActivity: Filter<Group> = {
		key: 'groupFilterForActivity',
		value: null,
		options: null,
	};
	private _groupFilterForGoal: Filter<Group> = {
		key: 'groupFilterForGoal',
		value: null,
		options: null,
	};
	private _groupFilterForImpact: Filter<Group> = {
		key: 'groupFilterForImpact',
		value: null,
		options: null,
	};
	filtersForSkill: Array<Filter<Group>> = [this._groupFilterForSkill];
	filtersForActivity: Array<Filter<Group>> = [this._groupFilterForActivity];
	filtersForGoal: Array<Filter<Group>> = [this._groupFilterForGoal];
	filtersForImpact: Array<Filter<Group>> = [this._groupFilterForImpact];

	// Chart refresh subjects.
	refreshForSkill: Subject<boolean> = new Subject<boolean>();
	refreshForActivity: Subject<boolean> = new Subject<boolean>();
	refreshForGoal: Subject<boolean> = new Subject<boolean>();
	refreshForImpact: Subject<boolean> = new Subject<boolean>();

	// Chart view all subjects.
	viewAllForSkill: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	);
	viewAllForActivity: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	);
	viewAllForGoal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	);
	viewAllForImpact: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	);

	// Local data storage from API calls.
	groups: Array<Group> | null = null;
	users: Array<User> | null = null;
	stats: any = null;
	organizationStats: any = {
		completed: 0,
		streak: 0,
		positive: 0,
		goal: 0,
	};
	userStats: Array<any> = [];

	// Subscription tracker.
	private _subscriptions: Subscription = new Subscription();

	// Skill Builder Engagement.
	skillBuilderEngagementPage: number = 1;
	skillBuilderEngagementData: Array<any> = [];
	// Page language.
	page: { [key: string]: string } = {};
	constructor(
		private loaderSvc: LoaderService,
		private organizationSvc: OrganizationService,
		private groupSvc: GroupService,
		private userSvc: UserService,
		private _languageSvc: LanguageService,
		private skillBuilderSvc: SkillBuilderService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		// Load page filters.
		this.loadOrganizations();
		this.timespanFilter.value = this.timespanFilter.options[0];
		this.loadSkillBuilders();
		this.loadGroups();

		// Load user data.
		this._subscriptions.add(
			this.userSvc.users.subscribe((users) => {
				this.users = users;
				this.refreshCharts();
			}),
		);

		// Get skill builder stats.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(
			loader,
			`pages/researcher/dashboard/train-dashboard:ngOnInit:skill-builder-stats`,
		);
		this.skillBuilderSvc
			.getStats()
			.pipe(
				map((response) => {
					if (!response.errors) {
						this.stats = response;
						this.filterOrganizationStats();
					}
					this.loaderSvc.removeLoader(loader);
				}),
			)
			.subscribe();
	}

	loadOrganizations(): void {
		this._subscriptions.add(
			this.organizationSvc.organizations.subscribe((organizations) => {
				this.organizationFilter.options = organizations;
				if (this.organizationFilter.options.length === 0)
					this.organizationFilter.options = [NO_ORGANIZATIONS];
				const organizationUUID = localStorage.getItem('organizationUUID');
				if (!organizationUUID) {
					this.organizationFilter.value = this.organizationFilter.options[0];
					localStorage.setItem(
						'organizationUUID',
						this.organizationFilter.value.uuid,
					);
				} else {
					const organization = this.organizationFilter.options.find(
						(organization) => organization.uuid === organizationUUID,
					);
					this.organizationFilter.value = !organization
						? this.organizationFilter.options[0]
						: organization;
					localStorage.setItem(
						'organizationUUID',
						this.organizationFilter.value.uuid,
					);
				}
				this.onOrganizationChange();
			}),
		);
	}

	loadGroups(): void {
		this._subscriptions.add(
			this.groupSvc.groups.subscribe((groups) => {
				// Filter out groups without a role and groups with no members.
				this.groups = groups.filter(
					(group) => !!group.role && group.memberUUIDs.length > 0,
				);

				// Make sure organizations are loaded.
				if (!this.organizationFilter.value) return;

				this.loadGroupFilters();
			}),
		);
	}

	loadGroupFilters(): void {
		// Skip until everything is loaded and make sure an organization exists.
		if (!this.organizationFilter.value || !this.organizationFilter.value.uuid)
			return;

		// Filter group options by organization.
		this.groupFilter.options = [
			ALL_GROUPS,
			...this.groups.filter(
				(group) =>
					group.organizationUUID === this.organizationFilter.value.uuid,
			),
		];
		this._groupFilterForSkill.options = this.groupFilter.options;
		this._groupFilterForActivity.options = this.groupFilter.options;
		this._groupFilterForGoal.options = this.groupFilter.options;
		this._groupFilterForImpact.options = this.groupFilter.options;

		// Handle main group filter.
		if (!this.groupFilter.value) {
			this.groupFilter.value = this.groupFilter.options[0];
			this.onGroupChange();
			return;
		} else {
			const group = this.groupFilter.options.find(
				(group) => group.uuid === this.groupFilter.value.uuid,
			);
			if (!group) {
				this.groupFilter.value = this.groupFilter.options[0];
				this.onGroupChange();
				return;
			}
			this.groupFilter.value = group;
		}

		// Handle sub group filters.
		for (const chart of [
			{ filter: this._groupFilterForSkill, refresh: this.refreshForSkill },
			{
				filter: this._groupFilterForActivity,
				refresh: this.refreshForActivity,
			},
			{ filter: this._groupFilterForGoal, refresh: this.refreshForGoal },
			{ filter: this._groupFilterForImpact, refresh: this.refreshForImpact },
		]) {
			if (!chart.filter.value) {
				chart.filter.value = chart.filter.options[0];
				chart.refresh.next(true);
			} else {
				const group = chart.filter.options.find(
					(group) => group.uuid === chart.filter.value.uuid,
				);
				if (!group) {
					chart.filter.value = this.groupFilter.value;
					chart.refresh.next(true);
				} else chart.filter.value = group;
			}
		}
	}

	loadSkillBuilders(): void {
		this._languageSvc
			.get(
				SKILL_BUILDERS.map(
					(skillBuilder) => `skillBuilders.${skillBuilder}.name`,
				),
			)
			.then((value) => {
				this.skillBuilderFilter.options = [
					{
						type: null,
						name: this.page?.allSkillBuilder,
						parameter: null,
					},
				];
				for (const skillBuilder of SKILL_BUILDERS)
					this.skillBuilderFilter.options.push({
						type: skillBuilder,
						name: value[`skillBuilders.${skillBuilder}.name`],
						parameter: `${skillBuilder}Completed`,
					});
				this.skillBuilderFilter.value = this.skillBuilderFilter.options[0];
				this.refreshSkillBuilderEngagement();
			});
	}

	filterOrganizationStats(): void {
		// Skip until everything is loaded.
		if (!this.stats) return;
		if (!this.organizationFilter.value) return;

		// Make sure an organization exists.
		if (!this.organizationFilter.value.uuid) {
			this.organizationStats = {
				completed: 0,
				streak: 0,
				positive: 0,
				goal: 0,
			};
			this.userStats = [];
			return;
		}

		// Get current organization stats.
		const organization = this.stats.organizations.find(
			(organization) =>
				organization.uuid === this.organizationFilter.value.uuid,
		);
		this.organizationStats = organization || {
			completed: 0,
			streak: 0,
			positive: 0,
			goal: 0,
		};
		this.organizationStats.streak = Math.round(this.organizationStats.streak);
		this.organizationStats.goal = Math.round(this.organizationStats.goal);

		// Filter user stats.
		this.userStats = this.stats.users.filter(
			(user) => user.organizationUUID === this.organizationFilter.value.uuid,
		);
		this.refreshCharts();
	}

	onOrganizationChange(): void {
		// Skip until everything is loaded.
		if (!this.groups) return;

		// Skip if no organizations exist.
		if (!this.organizationFilter.value.uuid) return;

		// Store the current organization UUID locally.
		localStorage.setItem(
			'organizationUUID',
			this.organizationFilter.value.uuid,
		);

		this.filterOrganizationStats();
		this.loadGroupFilters();
	}

	onGroupChange() {
		this._groupFilterForSkill.value = this.groupFilter.value;
		this._groupFilterForActivity.value = this.groupFilter.value;
		this._groupFilterForGoal.value = this.groupFilter.value;
		this._groupFilterForImpact.value = this.groupFilter.value;
		this.refreshCharts();
	}

	refreshCharts() {
		// Skip until everything is loaded.
		if (!this.groups) return;
		if (!this.users) return;

		this.refreshSkillBuilderEngagement();
		this.refreshForSkill.next(true);
		this.refreshForActivity.next(true);
		this.refreshForGoal.next(true);
		this.refreshForImpact.next(true);
	}

	refreshSkillBuilderEngagement() {
		if (!this.skillBuilderFilter.value) return;
		if (!this.groupFilter.value) return;
		if (!this.users) return;

		this.skillBuilderEngagementPage = 1;
		this.skillBuilderEngagementData = [];
		this.userStats.forEach((user) => {
			// Limit by group.
			if (
				!!this.groupFilter.value.uuid &&
				user.groupUUID !== this.groupFilter.value.uuid
			)
				return;

			// Limit users to one entry per skill builder type.
			if (
				this.skillBuilderEngagementData.findIndex(
					(userData) => userData.uuid === user.uuid,
				) !== -1
			)
				return;

			// Get the user name if available.
			let userName = user.genericName;
			const userData = this.users.find(
				(userData) => userData.uuid === user.uuid,
			);
			if (!!userData) userName = userData.name;

			// Build the table records.
			if (!this.skillBuilderFilter.value.type) {
				this.skillBuilderFilter.options.forEach((skillBuilder) => {
					if (
						!!user[skillBuilder.parameter] &&
						user[skillBuilder.parameter] !== 0
					) {
						this.skillBuilderEngagementData.push({
							uuid: user.uuid,
							name: userName,
							skillBuilder: skillBuilder.name,
							completed: user[skillBuilder.parameter],
						});
					}
				});
			} else {
				if (!!user[this.skillBuilderFilter.value.parameter]) {
					this.skillBuilderEngagementData.push({
						uuid: user.uuid,
						name: userName,
						skillBuilder: this.skillBuilderFilter.value.name,
						completed: user[this.skillBuilderFilter.value.parameter],
					});
				}
			}
		});
	}

	private get EMPTY_CHART_CONFIGURATION(): ChartConfiguration {
		return {
			type: 'bar',
			data: {
				datasets: [
					{
						label: '',
						data: [],
						borderRadius: 5,
					},
				],
			},
			options: {
				plugins: {
					legend: {
						display: false,
						position: 'top',
						align: 'end',
						labels: {
							useBorderRadius: true,
							borderRadius: 7.5,
							boxWidth: 15,
							boxHeight: 15,
						},
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				indexAxis: 'y',
				scales: {
					y: {
						grid: {
							display: false,
						},
					},
					x: {
						min: 0,
						ticks: {
							stepSize: 10,
						},
						title: {
							display: true,
							color: '#757575',
						},
					},
				},
				bar: {
					datasets: {
						barThickness: 12,
					},
				},
			},
		};
	}

	getChartConfigurationForSkill(
		filters: any,
		modal: boolean = true,
	): ChartConfiguration {
		const graphData = [];
		const { groupFilterForSkill } = filters;

		// Compile chart data.
		if (!groupFilterForSkill || !groupFilterForSkill.uuid) {
			const groups = this.groupFilter.options.map((group) => {
				return { uuid: group.uuid, name: group.name, score: 0 };
			});
			for (const user of this.userStats) {
				const group = groups.find((group) => group.uuid === user.groupUUID);
				if (!!group) group.score += user.completed;
			}
			for (const group of groups)
				graphData.push({ label: group.name, score: group.score });
		} else {
			for (const user of this.userStats) {
				if (user.groupUUID === groupFilterForSkill.uuid) {
					const userData = this.users.find(
						(userData) => userData.uuid === user.uuid,
					);
					if (!!userData)
						graphData.push({
							label: userData.name,
							score: user.completed,
						});
				}
			}
		}

		// Sort by descending score.
		graphData.sort((a, b) => b.score - a.score);

		// Build configuration.
		const configuration: ChartConfiguration = this.EMPTY_CHART_CONFIGURATION;
		if (!modal) {
			if (graphData.length > 6) {
				this.viewAllForSkill.next(true);
				graphData.splice(6);
			} else this.viewAllForSkill.next(false);
		} else configuration.options.bar.datasets.barThickness = 10;
		configuration.data.labels = graphData.map((data) => data.label);
		configuration.data.datasets[0].data = graphData.map((data) => data.score);
		configuration.data.datasets[0].backgroundColor = '#74A2FF';
		configuration.options.scales.x.max = Math.max(
			...graphData.map((data) => data.score),
			100,
		);
		(<any>configuration.options.scales.x).title.text =
			this.page?.numSkillBuilders;

		return configuration;
	}

	getChartConfigurationForActivity(
		filters: any,
		modal: boolean = true,
	): ChartConfiguration {
		const graphData = [];
		const { groupFilterForActivity } = filters;

		// Compile chart data.
		if (!groupFilterForActivity || !groupFilterForActivity.uuid) {
			const groups = this.groupFilter.options.map((group) => {
				return { uuid: group.uuid, name: group.name, scores: [] };
			});
			for (const user of this.userStats) {
				const group = groups.find((group) => group.uuid === user.groupUUID);
				if (!!group && user.completed > 0) group.scores.push(user.streak);
			}
			for (const group of groups) {
				let score = 0;
				if (group.scores.length > 0)
					score = Math.round(
						group.scores.reduce((a, b) => a + b, 0) / group.scores.length,
					);
				graphData.push({ label: group.name, score });
			}
		} else {
			for (const user of this.userStats) {
				if (user.groupUUID === groupFilterForActivity.uuid) {
					const userData = this.users.find(
						(userData) => userData.uuid === user.uuid,
					);
					if (!!userData)
						graphData.push({ label: userData.name, score: user.streak });
				}
			}
		}

		// Sort by descending score.
		graphData.sort((a, b) => b.score - a.score);

		// Build configuration.
		const configuration: ChartConfiguration = this.EMPTY_CHART_CONFIGURATION;
		if (!modal) {
			if (graphData.length > 6) {
				this.viewAllForActivity.next(true);
				graphData.splice(6);
			} else this.viewAllForActivity.next(false);
		} else configuration.options.bar.datasets.barThickness = 10;
		configuration.data.labels = graphData.map((data) => data.label);
		configuration.data.datasets[0].data = graphData.map((data) => data.score);
		configuration.data.datasets[0].backgroundColor = '#3CE986';
		configuration.options.scales.x.max = Math.max(
			...graphData.map((data) => data.score),
			100,
		);
		(<any>configuration.options.scales.x).title.text = this.page?.avgStreak;

		return configuration;
	}

	getChartConfigurationForGoal(
		filters: any,
		modal: boolean = true,
	): ChartConfiguration {
		const graphData = [];
		const { groupFilterForGoal } = filters;

		// Compile chart data.
		if (!groupFilterForGoal || !groupFilterForGoal.uuid) {
			const groups = this.groupFilter.options.map((group) => {
				return { uuid: group.uuid, name: group.name, scores: [] };
			});
			for (const user of this.userStats) {
				const group = groups.find((group) => group.uuid === user.groupUUID);
				if (!!group && user.completed > 0) group.scores.push(user.goal);
			}
			for (const group of groups) {
				let score = 0;
				if (group.scores.length > 0)
					score = Math.round(
						group.scores.reduce((a, b) => a + b, 0) / group.scores.length,
					);
				graphData.push({ label: group.name, score });
			}
		} else {
			for (const user of this.userStats) {
				if (user.groupUUID === groupFilterForGoal.uuid) {
					const userData = this.users.find(
						(userData) => userData.uuid === user.uuid,
					);
					if (!!userData)
						graphData.push({
							label: userData.name,
							score: Math.round(user.goal),
						});
				}
			}
		}

		// Sort by descending score.
		graphData.sort((a, b) => b.score - a.score);

		// Build configuration.
		const configuration: ChartConfiguration = this.EMPTY_CHART_CONFIGURATION;
		if (!modal) {
			if (graphData.length > 6) {
				this.viewAllForGoal.next(true);
				graphData.splice(6);
			} else this.viewAllForGoal.next(false);
		} else configuration.options.bar.datasets.barThickness = 10;
		configuration.data.labels = graphData.map((data) => data.label);
		configuration.data.datasets[0].data = graphData.map((data) => data.score);
		configuration.data.datasets[0].backgroundColor = '#3CE986';
		configuration.options.scales.x.max = Math.max(
			...graphData.map((data) => data.score),
			100,
		);
		(<any>configuration.options.scales.x).title.text = this.page?.percentTime;

		return configuration;
	}

	getChartConfigurationForImpact(
		filters: any,
		modal: boolean = true,
	): ChartConfiguration {
		const graphData = [];
		const { groupFilterForImpact } = filters;

		// Compile chart data.
		if (!groupFilterForImpact || !groupFilterForImpact.uuid) {
			const groups = this.groupFilter.options.map((group) => {
				return { uuid: group.uuid, name: group.name, score: 0 };
			});
			for (const user of this.userStats) {
				const group = groups.find((group) => group.uuid === user.groupUUID);
				if (!!group) group.score += user.positive;
			}
			for (const group of groups)
				graphData.push({ label: group.name, score: group.score });
		} else {
			for (const user of this.userStats) {
				if (user.groupUUID === groupFilterForImpact.uuid) {
					const userData = this.users.find(
						(userData) => userData.uuid === user.uuid,
					);
					if (!!userData)
						graphData.push({ label: userData.name, score: user.positive });
				}
			}
		}

		// Sort by descending score.
		graphData.sort((a, b) => b.score - a.score);

		// Build configuration.
		const configuration: ChartConfiguration = this.EMPTY_CHART_CONFIGURATION;
		if (!modal) {
			if (graphData.length > 6) {
				this.viewAllForImpact.next(true);
				graphData.splice(6);
			} else this.viewAllForImpact.next(false);
		} else configuration.options.bar.datasets.barThickness = 10;
		configuration.data.labels = graphData.map((data) => data.label);
		configuration.data.datasets[0].data = graphData.map((data) => data.score);
		configuration.data.datasets[0].backgroundColor = '#74A2FF';
		configuration.options.scales.x.max = Math.max(
			...graphData.map((data) => data.score),
			100,
		);
		(<any>configuration.options.scales.x).title.text = this.page?.numInstances;

		return configuration;
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
