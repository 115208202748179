import { Component, OnInit } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { EMAIL_PATTERN } from '@util/common';
import { LoaderService, AuthService, LanguageService } from '@services/public';

const PATH = `pages.public.forgot`;
@Component({
	selector: 'app-public-password-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['./forgot.component.scss'],
})
export class PublicPasswordForgotComponent implements OnInit {
	public form: UntypedFormGroup;
	pageLanguage: any;

	constructor(
		private authSvc: AuthService,
		private router: Router,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Load Language
		this._languageSvc.get([PATH]).then((value) => {
			this.pageLanguage = value[PATH];
		});
		this.form = new UntypedFormGroup({
			email: new UntypedFormControl(null, [
				Validators.required,
				Validators.pattern(EMAIL_PATTERN),
			]),
		});
	}

	get f() {
		return this.form.controls;
	}

	forgotPassword() {
		// Validate form.
		this.form.markAllAsTouched();
		if (this.form.invalid) return;

		// Send email.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.authSvc
			.forgotPassword(this.form.value.email)
			.pipe(
				map((response: any) => {
					if (!!response.success) {
						this.form.reset();
						this.loaderSvc.removeLoader(loader);
						Swal.fire({
							title: this.pageLanguage?.emailSent,
							icon: 'success',
							confirmButtonColor: '#456790',
							confirmButtonText: this.pageLanguage?.ok,
							didDestroy: () => this.router.navigate(['/login']),
						});
					}
				}).bind(this),
			)
			.subscribe();
	}
}
