import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { LanguageService } from '@services/public';
import { APISuccess, APIErrors, isAPISuccess } from '@util';
import { ADVENTURE_IMPACT, AdventureData } from '../common';
import { Router } from '@angular/router';
import { SelectListOption } from '@components';
import { ReplaySubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdventureService } from '@services/member';

const PAGE_PATH: string = 'skillBuilders.adventure.pages.impact';

@Component({
	selector: 'app-member-skill-builder-adventure-impact',
	templateUrl: './impact.component.html',
	styleUrls: ['./impact.component.scss'],
})
export class MemberSkillBuilderAdventureImpactComponent implements OnInit {
	BUTTON_CLASS: { [key: string]: string } = {
		SELECTED: 'btn-secondary',
		NOT_SELECTED: 'btn-outline btn-outline-danger',
	};
	@Input() data: AdventureData;
	@ViewChild('reminder', { static: false }) reminderTemplate: TemplateRef<void>;
	choice: boolean = null;
	submitted = false;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	selected: ADVENTURE_IMPACT = null;
	modalRef: BsModalRef;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		no: '',
		yes: '',
		error: '',
		title2: '',
		submit: '',
		ok: '',
		reminder: '',
	};

	constructor(
		private _adventureSvc: AdventureService,
		private _languageSvc: LanguageService,
		private _modalSvc: BsModalService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				if (key === 'title') {
					this._languageSvc
						.template(this.page[key], { adventure: this.data?.adventure })
						.then((value) => (this.page[key] = value));
				} else {
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value));
				}
			}
		});

		const path: string = 'skillBuilders.adventure.impact';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] === 'object' && value[path] !== null)
				this.options.next(
					Object.values(value[path]).map((value: SelectListOption) => {
						if (value.value === this.data?.impact)
							return { ...value, selected: true };
						return value;
					}),
				);
		});
	}

	onChange(option: SelectListOption): void {
		this.selected = option.value;
	}

	submitOnClick() {
		this.submitted = true;
		if (this.choice === null) return;
		if (!this.choice) {
			this.modalRef = this._modalSvc.show(this.reminderTemplate, {
				class: 'modal-sm modal-dialog-centered',
				backdrop: 'static',
				keyboard: true,
			});
			return;
		}

		if (!this.selected) return;
		this.data.impact = this.selected;
		this._adventureSvc
			.updateLog(this.data)
			.subscribe((res: APISuccess | APIErrors) => {
				if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
			});
	}

	closeReminder(): void {
		this.modalRef?.hide();
		this._router.navigate(['member/dashboard']);
	}
}
