import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { AssessmentScores } from '../assessment-scores.service';
import { Group } from '@services/member';
interface labels {
	score?: string;
	skillLevel?: string;
}
@Injectable({
	providedIn: 'root',
})
export class SelfByGroupService {
	constructor() {}

	private get EMPTY_REPORT(): ChartConfiguration {
		return {
			type: 'bar',
			data: {
				labels: [],
				datasets: [
					{
						label: 'Score',
						data: [],
						borderRadius: 5,
						backgroundColor: '#3CE986',
					},
				],
			},
			options: {
				plugins: {
					legend: {
						display: true,
						position: 'top',
						align: 'end',
						labels: {
							useBorderRadius: true,
							borderRadius: 5.5,
							boxWidth: 10,
							boxHeight: 10,
						},
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				indexAxis: 'y',
				scales: {
					y: {
						grid: {
							display: false,
						},
					},
					x: {
						min: 0,
						max: 5,
						ticks: {
							stepSize: 0.5,
						},
						title: {
							display: true,
							text: 'Skill Level',
							color: '#757575',
						},
					},
				},
			},
		};
	}

	generateReport(
		scores: AssessmentScores,
		groups: Array<Group>,
		chartLabels: labels,
	): ChartConfiguration {
		const labels: Array<string> = [];
		const selfData: Array<number> = [];

		// Filter to the most recent self data.
		const compiledScores = {};
		scores.self.forEach(({ userUUID, skillUID, date, score }) => {
			groups.forEach((group) => {
				if (!group.memberUUIDs.includes(userUUID)) return;
				if (!compiledScores[group.uuid]) compiledScores[group.uuid] = {};
				if (!compiledScores[group.uuid][userUUID])
					compiledScores[group.uuid][userUUID] = {};
				if (!compiledScores[group.uuid][userUUID][skillUID])
					compiledScores[group.uuid][userUUID][skillUID] = {};
				if (!compiledScores[group.uuid][userUUID][skillUID].self)
					compiledScores[group.uuid][userUUID][skillUID].self = { date, score };
				else if (
					new Date(date) >
					new Date(compiledScores[group.uuid][userUUID][skillUID].self.date)
				)
					compiledScores[group.uuid][userUUID][skillUID].self = { date, score };
			});
		});

		// Calculate each groups' score.
		const averageGroupScores = [];
		for (const groupUUID in compiledScores) {
			const groupScores = [];
			for (const userUUID in compiledScores[groupUUID]) {
				const userScores = [];
				for (const skillUUID in compiledScores[groupUUID][userUUID]) {
					userScores.push(
						compiledScores[groupUUID][userUUID][skillUUID].self.score,
					);
				}
				groupScores.push(
					userScores.reduce((a, b) => a + +b, 0) / userScores.length,
				);
			}
			averageGroupScores.push({
				uuid: groupUUID,
				score: groupScores.reduce((a, b) => a + +b, 0) / groupScores.length,
			});
		}

		// Sort by group score descending.
		averageGroupScores.sort((a, b) => b.score - a.score);

		// Calculate labels and scores.
		for (const groupScore of averageGroupScores) {
			const group: Group = groups.find(
				(group) => group.uuid === groupScore.uuid,
			);
			if (!!group) {
				labels.push(group.name);
				selfData.push(+groupScore.score.toFixed(1));
			}
		}

		// Update chart configuration.
		let report: ChartConfiguration = this.EMPTY_REPORT;
		report.data.labels = labels;
		report.data.datasets[0].data = selfData;
		report.data.datasets[0].label = chartLabels.score;
		report.options = {
			...report.options,
			scales: {
				...report.options.scales,
				x: {
					...report.options.scales.x,
					title: {
						display: true,
						text: chartLabels.skillLevel,
						color: '#757575',
					},
				},
			} as any,
		};
		return report;
	}
}
