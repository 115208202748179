// Kurdish (Sorani)
import { LocaleData } from 'ngx-bootstrap/chronos';

export const ckbLocale: LocaleData = {
	months: [
		'تەموز',
		'شوبات',
		'ئازار',
		'یەکەم',
		'بەیانی',
		'چوارەشەمەیە',
		'تشرینی یەکەمی',
		'تشرینی دووەمی',
		'کانونی یەکەمی',
		'کانونی دووەمی',
		'شوبات',
		'ئازار',
	],
	monthsShort: [
		'تەم',
		'شوب',
		'ئاز',
		'یەک',
		'بەی',
		'چو',
		'تشر',
		'تشر',
		'کانو',
		'کانو',
		'شوب',
		'ئاز',
	],
	weekdays: [
		'یەکشەممە',
		'دووشەممە',
		'سێشەممە',
		'چوارشەممە',
		'پێنجشەممە',
		'هەینی',
		'شەممە',
	],
	weekdaysShort: ['یەک', 'دوو', 'سێ', 'چو', 'پێن', 'هەی', 'شەم'],
	weekdaysMin: ['یە', 'دو', 'س', 'چو', 'پ', 'ه', 'ش'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[ئەم ڕووداوە] LT',
		nextDay: '[سبەین] LT',
		nextWeek: 'dddd [ی] LT',
		lastDay: '[دووڕووداوە] LT',
		lastWeek: '[ئەو هەفتەیە] dddd [ی] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s د',
		past: '%s پێش',
		s: 'چند چرکە',
		m: 'یەک خولەک',
		mm: '%d خولەک',
		h: 'یەک ساەت',
		hh: '%d ساەت',
		d: 'یەک ڕووداوە',
		dd: '%d ڕووداوە',
		M: 'یەک مانگ',
		MM: '%d مانگ',
		y: 'یەک ساڵ',
		yy: '%d ساڵ',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
