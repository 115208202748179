// Hausa

import { LocaleData } from 'ngx-bootstrap/chronos';

export const haLocale: LocaleData = {
	months: [
		'Janairu',
		'Fabrairu',
		'Maris',
		'Afrilu',
		'Mayu',
		'Yuni',
		'Yuli',
		'Agusta',
		'Satumba',
		'Oktoba',
		'Nuwamba',
		'Disamba',
	],
	monthsShort: [
		'Jan',
		'Fab',
		'Mar',
		'Afr',
		'May',
		'Yun',
		'Yul',
		'Agu',
		'Sat',
		'Okt',
		'Nuw',
		'Dis',
	],
	weekdays: [
		'Lahadi',
		'Litinin',
		'Talata',
		'Laraba',
		'Alhamis',
		"Jumma'a",
		'Asabar',
	],
	weekdaysShort: ['Lah', 'Lit', 'Tal', 'Lar', 'Alh', 'Jum', 'Asa'],
	weekdaysMin: ['La', 'Li', 'Ta', 'La', 'Al', 'Ju', 'As'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Yau] LT',
		nextDay: '[Gobe] LT',
		nextWeek: 'dddd [a] LT',
		lastDay: '[Jiya] LT',
		lastWeek: '[Jiya] dddd [a] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'a %s',
		past: '%s da',
		s: 'sekondi kaɗan',
		m: 'daka minti ɗaya',
		mm: '%d minti',
		h: 'sa’a ɗaya',
		hh: '%d sa’oi',
		d: 'rana ɗaya',
		dd: '%d ranaku',
		M: 'watan ɗaya',
		MM: '%d watanni',
		y: 'shekara ɗaya',
		yy: '%d shekaru',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
