import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import {
	LoaderService,
	DomainService,
	AssessmentService,
	Skill,
	Option,
	LanguageService,
} from '@services/public';

@Component({
	selector: 'app-public-assessment-external',
	templateUrl: './external.component.html',
	styleUrls: ['./external.component.scss'],
})
export class PublicAssessmentExternalComponent implements OnInit {
	// Assessment
	code: string = null;
	options: Array<Option> = [];
	instructions: string = '';
	userName: string = '';
	skills: Array<Skill> = [];
	scores: Array<number> = [];

	// Form control
	form: UntypedFormGroup = new UntypedFormGroup({});
	currentSkillIndex: number = 0;

	allSkills: Array<Skill> = [];
	submitted: boolean = false;
	submittedMessage: string = '';

	pageLanguage: any;
	labels: any;
	path: string = `pages.public.assessment.external`;

	constructor(
		private toastSvc: ToastrService,
		private domainSvc: DomainService,
		private assessmentSvc: AssessmentService,
		private route: ActivatedRoute,
		private router: Router,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this.router.events.subscribe((x) => {
			if (x instanceof NavigationEnd) {
				window.scrollTo(0, 0);
			}
		});
		// Store code.
		this.code = this.route.snapshot.paramMap.get('code');
		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
			this.submittedMessage = this.pageLanguage.submittedMessage;
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});
		// Load instructions and options.
		const path: string = 'assessments.external';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] !== 'object' || value[path] === null) return;
			this.options = Object.values(value[path].options);
			this.instructions = value[path].instructions;
		});

		// Load skills.
		this.domainSvc.getSkills().then((response) => {
			this.allSkills = response;
			this.loadAssessment();
		});
	}

	loadAssessment(): void {
		// Load assessment.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.getExternal(this.code)
			.pipe(
				map((response: any) => {
					if (!!response.userName) {
						this.userName = response.userName;
						this.form.addControl(
							this.userName,
							new UntypedFormControl('', Validators.required),
						);
					}
					if (!!response.skillUIDs)
						this.skills = this.allSkills.filter((skill) =>
							response.skillUIDs.includes(skill.uid),
						);
					if (!!response.submitted) {
						this.submittedMessage = this.pageLanguage.alreadySubmitted;
						this.submitted = true;
					}
					if (!response.errors) this.loaderSvc.removeLoader(loader);
				}).bind(this),
			)
			.subscribe();
	}

	prev(): void {
		// Store score.
		if (this.form.controls[this.userName].value !== null)
			this.scores[this.currentSkillIndex] =
				this.form.controls[this.userName].value;

		// Update form.
		this.currentSkillIndex--;
		this.form.reset();
		if (typeof this.scores[this.currentSkillIndex] !== 'undefined')
			this.form.controls[this.userName].patchValue(
				this.scores[this.currentSkillIndex],
			);
	}

	next(): void {
		// Make sure question is answered.
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			this.toastSvc.error(this.pageLanguage.answerAllQuestions);
			return;
		}

		// Store score.
		this.scores[this.currentSkillIndex] =
			this.form.controls[this.userName].value;

		// Update form.
		this.currentSkillIndex++;
		this.form.reset();
		if (typeof this.scores[this.currentSkillIndex] !== 'undefined') {
			this.form.controls[this.userName].patchValue(
				this.scores[this.currentSkillIndex],
			);
		}

		// Scroll to the top.
		setTimeout(() => window.scrollTo(0, 0));
	}

	finish(): void {
		// Make sure question is answered.
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			this.toastSvc.error(this.pageLanguage.answerAllQuestions);
			return;
		}

		// Store score.
		this.scores[this.currentSkillIndex] =
			this.form.controls[this.userName].value;

		// Compile scores.
		const skillScores = [];
		for (let i = 0; i < this.skills.length; i++)
			if (!!this.scores[i])
				skillScores.push({ uid: this.skills[i].uid, score: this.scores[i] });

		// Submit assessment.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.submitExternal(this.code, skillScores)
			.pipe(
				map((response: any) => {
					if (response.success) {
						this.loaderSvc.removeLoader(loader);
						this.submitted = true;
						this.toastSvc.success(this.pageLanguage.submitSuccess);
					}
				}).bind(this),
			)
			.subscribe();
	}
}
