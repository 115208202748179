import { Component, Input, OnInit } from '@angular/core';
import { DecisionData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { DecisionService } from '@services/member';
import { Router } from '@angular/router';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.decision.pages.rightChoiceYes';

@Component({
	selector: 'app-member-skill-builder-decision-right-choice-yes',
	templateUrl: './right-choice-yes.component.html',
	styleUrls: ['./right-choice-yes.component.scss'],
})
export class MemberSkillBuilderDecisionRightChoiceYesComponent
	implements OnInit
{
	@Input() data: DecisionData;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		text1: '',
		submit: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _decisionSvc: DecisionService,
		private _router: Router,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
	}

	nextOnClick(): void {
		this._decisionSvc
			.updateLog(this.data)
			.subscribe((res: APISuccess | APIErrors) => {
				if (isAPISuccess(res)) {
					this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, null);
					this._router.navigate(['member/dashboard']);
				}
			});
	}
}
