import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { REFLECTION_PAGE, ReflectionData } from '../common';
import { ReplaySubject, Subscription } from 'rxjs';
import {
	SelectListCustom,
	SelectListNext,
	SelectListOption,
} from '@components';
import {
	Domain2Service,
	LanguageService,
	MAIN_KEYS,
	MAIN_KEY_PREFIX,
	Skill2,
	StorageService,
	TEMP_KEYS,
} from '@services/public';

const PAGE_PATH: string = 'skillBuilders.reflection.pages.skill';

@Component({
	selector: 'app-member-skill-builder-reflection-skill',
	templateUrl: './skill.component.html',
	styleUrls: ['./skill.component.scss'],
})
export class MemberSkillBuilderReflectionSkillComponent
	implements OnInit, OnDestroy
{
	@Input() data: ReflectionData;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	defaultOptions: Array<SelectListOption> = [];
	customOptions: Array<SelectListOption> = [];
	custom: SelectListCustom = {
		add: this.addCustom.bind(this),
		delete: this.deleteCustom.bind(this),
	};
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
	};

	constructor(
		private _domainSvc: Domain2Service,
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			for (const key in value[PAGE_PATH]) {
				switch (key) {
					case 'subTitle': {
						if (this.data.domainUID === 0) break;
						this._languageSvc
							.template(value[PAGE_PATH][key], {
								domain: this._domainSvc
									.getDomain(this.data.domainUID)
									.name.toLowerCase(),
							})
							.then((value) => (this.page.subTitle = value));
						break;
					}
					case 'subTitleOnboarding': {
						if (this.data.domainUID !== 0) break;
						this._languageSvc
							.template(value[PAGE_PATH][key])
							.then((value) => (this.page.subTitle = value));
						break;
					}
					default: {
						this._languageSvc
							.template(value[PAGE_PATH][key])
							.then((value) => (this.page[key] = value));
					}
				}
			}
		});

		// Get options list.
		if (this.data.domainUID === 0) {
			// Get onboarding skills.
			this.options.next(
				this.data.onboardingSkillUIDs.map((skillUID) => {
					const skill: Skill2 = this._domainSvc.getSkill(skillUID);
					const option: SelectListOption = {
						display: skill.name,
						value: skill.uid,
					};
					if (this.data.skillUID === skill.uid) option.selected = true;
					return option;
				}),
			);
		} else {
			// Get domain skills.
			this.defaultOptions = this._domainSvc
				.getSkills(this.data.domainUID)
				.map((skill) => {
					return { display: skill.name, value: skill.uid };
				});
			this._subscriptions.add(
				this._storageSvc.updates.subscribe((update) => {
					if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL) {
						this.customOptions = [];
						if (Array.isArray(update.value[MAIN_KEYS.GOAL_SKILLS])) {
							for (const option of update.value[MAIN_KEYS.GOAL_SKILLS]) {
								if (typeof option !== 'string') continue;
								this.customOptions.push({ display: option, custom: true });
							}
						}
						this._mergeAndSelectOptions();
					}
					if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR) {
						this.customOptions = [];
						this._mergeAndSelectOptions();
					}
					if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.GOAL_SKILLS) {
						this.customOptions = [];
						if (Array.isArray(update.value)) {
							for (const option of update.value) {
								if (typeof option !== 'string') continue;
								this.customOptions.push({ display: option, custom: true });
							}
						}
						this._mergeAndSelectOptions();
					}
				}),
			);
			let options: Array<string> = this._storageSvc.getMainStorage(
				MAIN_KEYS.GOAL_SKILLS,
			);
			if (Array.isArray(options)) {
				for (const option of options) {
					if (typeof option !== 'string') continue;
					this.customOptions.push({ display: option, custom: true });
				}
			}

			this._mergeAndSelectOptions();
		}
	}

	private _mergeAndSelectOptions(): void {
		this.options.next(
			[...this.customOptions, ...this.defaultOptions].map(
				(option: SelectListOption) => {
					if (option.display === this.data.skill) option.selected = true;
					return option;
				},
			),
		);
	}

	addCustom(customOption: string): void {
		if (
			this.customOptions.findIndex(
				(option) => option.display === customOption,
			) !== -1
		)
			return;
		if (
			this.defaultOptions.findIndex(
				(option) => option.display === customOption,
			) !== -1
		)
			return;
		this.customOptions.push({ display: customOption, custom: true });
		this._storageSvc.setMainStorage(
			MAIN_KEYS.GOAL_SKILLS,
			this.customOptions.map((option) => option.display),
		);
	}

	deleteCustom(option: SelectListOption): void {
		this.customOptions = this.customOptions.filter(
			(customOption) => customOption.display !== option.display,
		);
		if (this.customOptions.length === 0)
			this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_SKILLS, null);
		else
			this._storageSvc.setMainStorage(
				MAIN_KEYS.GOAL_SKILLS,
				this.customOptions.map((option) => option.display),
			);
	}

	nextOnClick(option: SelectListOption): void {
		this._storageSvc.setTempStorage(TEMP_KEYS.REFLECTION, {
			...this.data,
			page: !option.value
				? REFLECTION_PAGE.CUSTOM_ACTION
				: REFLECTION_PAGE.ACTION,
			skillUID: option.value ?? null,
			skill: option.display,
		});
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
