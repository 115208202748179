import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { AssessmentScores } from '../assessment-scores.service';
import { Domain } from '@services/public';
interface labels {
	self?: string;
	peer?: string;
	skillLevel?: string;
}
@Injectable({
	providedIn: 'root',
})
export class SelfVsPeerBySkillService {
	constructor() {}

	private get EMPTY_REPORT(): ChartConfiguration {
		return {
			type: 'bar',
			data: {
				labels: [],
				datasets: [
					{
						label: 'Self',
						data: [],
						borderRadius: 5,
						backgroundColor: '#3CE986',
					},
					{
						label: 'Peer',
						data: [],
						borderRadius: 5,
						backgroundColor: '#74A2FF',
					},
				],
			},
			options: {
				plugins: {
					legend: {
						display: true,
						position: 'top',
						align: 'end',
						labels: {
							useBorderRadius: true,
							borderRadius: 5.5,
							boxWidth: 10,
							boxHeight: 10,
						},
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				indexAxis: 'y',
				scales: {
					y: {
						grid: {
							display: false,
						},
					},
					x: {
						min: 0,
						max: 5,
						ticks: {
							stepSize: 0.5,
						},
						title: {
							display: true,
							text: 'Skill Level',
							color: '#757575',
						},
					},
				},
			},
		};
	}

	generateReport(
		scores: AssessmentScores,
		domain: Domain,
		chartLabels: labels,
	): ChartConfiguration {
		const labels: Array<string> = [];
		const selfData: Array<number> = [];
		const peerData: Array<number> = [];

		// Get skill UIDs and names.
		const compiledScores = {};
		domain.skills.forEach((skill) => {
			compiledScores[skill.uid] = {};
			compiledScores[skill.uid].name = skill.name;
			compiledScores[skill.uid].users = {};
		});

		// Filter to the most recent self data per user.
		scores.self.forEach(({ userUUID, skillUID, date, score }) => {
			if (!compiledScores[skillUID].users[userUUID])
				compiledScores[skillUID].users[userUUID] = {};
			if (!compiledScores[skillUID].users[userUUID].self)
				compiledScores[skillUID].users[userUUID].self = { date, score };
			else if (
				new Date(date) >
				new Date(compiledScores[skillUID].users[userUUID].self.date)
			)
				compiledScores[skillUID].users[userUUID].self = { date, score };
		});

		// Sort the historical peer data per user.
		scores.peer.forEach(({ userUUID, skillUID, score }) => {
			if (!compiledScores[skillUID].users[userUUID])
				compiledScores[skillUID].users[userUUID] = {};
			if (!compiledScores[skillUID].users[userUUID].peer)
				compiledScores[skillUID].users[userUUID].peer = [score];
			else compiledScores[skillUID].users[userUUID].peer.push(score);
		});

		// Calculate labels and scores.
		for (const skillUID in compiledScores) {
			labels.push(compiledScores[skillUID].name);
			const selfScores = Object.values(compiledScores[skillUID].users)
				.map((user: any) => (!user.self ? null : user.self.score))
				.filter((score) => score !== null);
			if (!selfScores.length) selfData.push(null);
			else
				selfData.push(
					+(selfScores.reduce((a, b) => a + b, 0) / selfScores.length).toFixed(
						1,
					),
				);
			const peerScores = Object.values(compiledScores[skillUID].users)
				.map((user: any) =>
					!user.peer
						? null
						: user.peer.reduce((a, b) => a + b, 0) / user.peer.length,
				)
				.filter((score) => score !== null);
			if (!peerScores.length) peerData.push(null);
			else
				peerData.push(
					+(peerScores.reduce((a, b) => a + b, 0) / peerScores.length).toFixed(
						1,
					),
				);
		}

		// Update chart configuration.
		let report: ChartConfiguration = this.EMPTY_REPORT;
		report.data.labels = labels;
		report.data.datasets[0].data = selfData;
		report.data.datasets[0].label = chartLabels.self;
		report.data.datasets[1].data = peerData;
		report.data.datasets[1].label = chartLabels.peer;
		report.options = {
			...report.options,
			scales: {
				...report.options.scales,
				x: {
					...report.options.scales.x,
					title: {
						display: true,
						text: chartLabels.skillLevel,
						color: '#757575',
					},
				},
			} as any,
		};
		return report;
	}
}
