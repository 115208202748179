<div class="member-dashboard-rewards-challenge-badges">
	<div class="title-legend">
		<div class="title">{{ page.title }}</div>
		<div class="legend">
			{{ page.legend }}
			<ul>
				<li>&lt; 60</li>
				<li>&gt;= 60</li>
				<li>&gt;= 80</li>
				<li>&gt;= 100</li>
			</ul>
		</div>
	</div>
	<div class="challenge-badges">
		<div *ngFor="let challenge of challengeBadges" class="challenge-badge">
			<img class="challenge-icon" [src]="challenge.icon" alt="Badge" />
			<a class="flag-tooltip previous" [attr.aria-label]="page.previousLevel">
				<span class="flag">
					{{ labels.level | slice: 0 : 1
					}}{{ challenge.level > 0 ? challenge.level - 1 : 0 }}
				</span>
			</a>
			<div
				class="badge-levels"
				[ngStyle]="{ background: 'conic-gradient(' + challenge.gradient + ')' }"
			>
				<div class="donut-line fixed top"></div>
				<div class="donut-line fixed bottom"></div>
			</div>
			<a class="flag-tooltip current" [attr.aria-label]="page.currentLevel">
				<span class="flag">
					{{ labels.level | slice: 0 : 1 }}{{ challenge.level }}
				</span>
			</a>
			<a class="ribbon-tooltip" [attr.aria-label]="challenge.name">
				<div class="ribbon">
					{{ challenge.name }}
				</div>
			</a>
		</div>
	</div>
	<div class="legend bottom">
		{{ page.legend }}
		<ul>
			<li>&lt; 60</li>
			<li>&gt;= 60</li>
			<li>&gt;= 80</li>
			<li>&gt;= 100</li>
		</ul>
	</div>
</div>
