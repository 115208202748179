import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
	LanguageService,
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	VOLATILE_KEYS,
} from '@services/public';
import { Subscription } from 'rxjs';
import {
	ADVENTURE_PAGE,
	ADVENTURE_PREVIOUS_PAGE,
	AdventureData,
} from './common';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import { APIErrors, isAPIErrors } from '@util';

const PAGE_PATH: string = 'skillBuilders.adventure.pages.main';

@Component({
	selector: 'app-member-skill-builder-adventure',
	templateUrl: './adventure.component.html',
	styleUrls: ['./adventure.component.scss'],
})
export class MemberSkillBuilderAdventureComponent implements OnInit, OnDestroy {
	ADVENTURE_PAGE: typeof ADVENTURE_PAGE = ADVENTURE_PAGE;
	data: AdventureData;
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _router: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Get adventure data.
		this.data = this._storageSvc.getTempStorage(TEMP_KEYS.ADVENTURE);
		const goalPlanUUID = this._storageSvc.getVolatileStorage(
			VOLATILE_KEYS.GOAL_PLAN_UUID,
			true,
		);
		if (!this.data) {
			this._skillBuilderSvc
				.getStatus(SKILL_BUILDER_TYPES.ADVENTURE, goalPlanUUID)
				.subscribe((res: AdventureData | APIErrors) => {
					if (!isAPIErrors(res) && !this.data) {
						this.data = {
							...res,
							page: res.start ? ADVENTURE_PAGE.CHOOSE : ADVENTURE_PAGE.IMPACT,
						};

						this._setStorageSubscription();
					}
				});
		} else this._setStorageSubscription();
	}

	private _setStorageSubscription() {
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
					this.data = update.value[TEMP_KEYS.ADVENTURE];
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) this.data = null;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ADVENTURE)
					this.data = update.value;
				if (!this.data && !this._router.getCurrentNavigation())
					this._router.navigate(['member/dashboard']);
			}),
		);
	}

	back() {
		const page = ADVENTURE_PREVIOUS_PAGE[this.data.page];
		if (!page) {
			this._router.navigate(['member/dashboard']);
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.ADVENTURE, {
			...this.data,
			page,
		});
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this._storageSvc.setTempStorage(TEMP_KEYS.ADVENTURE, null);
	}
}
