// Luganda
import { LocaleData } from 'ngx-bootstrap/chronos';

export const lgLocale: LocaleData = {
	months: [
		'Janwali',
		'Febwali',
		'Maati',
		'Apreeli',
		'Meesi',
		'Juni',
		'Julaayi',
		'Ogwos',
		'Sebeetemba',
		'Okitobba',
		'Novemba',
		'Desemba',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Maa',
		'Apr',
		'Mee',
		'Jun',
		'Jul',
		'Ogw',
		'Seb',
		'Oki',
		'Nov',
		'Des',
	],
	weekdays: [
		'Sande',
		'Mmande',
		'Bukiikakkono',
		'Buda',
		'Bwayise',
		'Lwokubiri',
		'Lwakusatu',
	],
	weekdaysShort: ['San', 'Mma', 'Bik', 'Bud', 'Bwa', 'Lw', 'Lw'],
	weekdaysMin: ['Sa', 'Mm', 'Bi', 'Bu', 'Bw', 'Lw', 'Lw'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Leero] LT',
		nextDay: '[Kyejjuko] LT',
		nextWeek: 'dddd [ebukya] LT',
		lastDay: '[Ejjo] LT',
		lastWeek: '[Ekitanga ekijje] dddd [e] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'mu %s',
		past: '%s ejja',
		s: 'ebbanga enkya',
		m: 'akabanda k’akatale',
		mm: '%d akabanda k’akatale',
		h: 'saawa emu',
		hh: '%d saawa',
		d: 'olunaku emu',
		dd: '%d ennaku',
		M: 'omwezi emu',
		MM: '%d emwezi',
		y: 'omwaka emu',
		yy: '%d emyaka',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
