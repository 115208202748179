<div class="btn-row d-flex mt-2 mt-md-0">
	<button
		type="button"
		class="btn btn-small btin-icon btn-secondary btn-secondary-sm"
		(click)="openModal(template)"
	>
		<span class="btn-ico">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="25"
				fill="currentColor"
				class="bi bi-plus"
				viewBox="0 0 16 16"
			>
				<path
					d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
				/>
			</svg>
		</span>
		{{ pageLanguage?.addNew }}
	</button>
</div>
<ng-template #template>
	<div class="modal-header">
		<h5 class="mb-0">{{ pageLanguage?.title }}</h5>
		<button
			#closebutton
			type="button"
			class="close"
			data-dismiss="modal"
			(click)="decline()"
			[attr.aria-label]="labels?.close"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body px-0">
		<div class="col-12 my-4 px-4">
			<div class="mb-3" [formGroup]="addEmailForm" class="d-flex">
				<input
					type="email"
					formControlName="email"
					class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
					[(ngModel)]="addEmail"
					class="form-control btm-bordered"
					id="exampleFormControlInput1"
					placeholder="pageLanguage?.placeholder"
				/>
				<span class="required-asterisk">*</span>
			</div>
			<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
				<div *ngIf="f.email.errors.required">{{ labels?.emailRequired }}</div>
				<div *ngIf="f.email.errors.email">
					{{ pageLanguage?.emailMustBeValid }}
				</div>
			</div>
		</div>
		<div class="pt-4 justify-content-center d-flex">
			<button type="button" class="btn btn-md" (click)="decline()">
				{{ labels?.cancel }}
			</button>
			<button
				type="button"
				class="btn btn-md btn-primary"
				(click)="onAddEmail()"
			>
				{{ pageLanguage?.saveVerify }}
			</button>
		</div>
	</div>
</ng-template>
