import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { ExposedPromise, JClone } from 'jakapa-utilities';

export interface Domain2 {
	uid: number;
	name: string;
	logo: string;
	key: string;
	skills: { [key: string]: Skill2 };
}

export interface Skill2 {
	uid: number;
	name: string;
	domain: Domain2;
}

/**
 * @deprecated
 */
export interface Domain {
	uid: number;
	name: string;
	skills: Skill[];
}

/**
 * @deprecated
 */
export interface Skill {
	uid: number;
	name: string;
	definition: string;
	description: string;
	questions: Array<string>;
}

const DOMAIN_AND_SKILL_UIDS: { [key: number]: Array<number> } = {
	1: [101, 102, 103, 104, 105, 106, 107, 108, 109],
	2: [201, 202, 203, 204, 205, 206],
	3: [301, 302, 303, 304, 305, 306, 307],
	4: [401, 402, 403, 404, 405],
	5: [501, 502, 503, 504, 505],
};
const DOMAIN_LOGOS: { [key: number]: string } = {
	1: 'assets/img/self-management.svg',
	2: 'assets/img/innovation.svg',
	3: 'assets/img/emotional-resilience.svg',
	4: 'assets/img/social-engagement.svg',
	5: 'assets/img/cooperation.svg',
};
const DOMAIN_KEYS: { [key: number]: string } = {
	1: 'self-management',
	2: 'innovation',
	3: 'emotional-resilience',
	4: 'social-engagement',
	5: 'cooperation',
};

@Injectable({
	providedIn: 'root',
})
export class Domain2Service {
	private _domains: { [key: string]: Domain2 } = {};
	private _skills: { [key: string]: Skill2 } = {};
	private _loaded: ExposedPromise<void> = new ExposedPromise<void>();

	constructor(private _languageSvc: LanguageService) {
		// Build domain and skill objects and retrieve name paths.
		const paths: Array<string> = [];
		for (const domainUID in DOMAIN_AND_SKILL_UIDS) {
			const domain: Domain2 = {
				uid: +domainUID,
				name: '',
				logo: DOMAIN_LOGOS[domainUID],
				key: DOMAIN_KEYS[domainUID],
				skills: {},
			};
			paths.push(`domains2.${domainUID}.name`);
			for (const skillUID of DOMAIN_AND_SKILL_UIDS[domainUID]) {
				const skill: Skill2 = { uid: skillUID, name: '', domain };
				domain.skills[skillUID] = skill;
				this._skills[skillUID] = skill;
				paths.push(`domains2.${domainUID}.skills.${skillUID}.name`);
			}
			this._domains[domainUID] = domain;
		}

		// Load domain and skill names.
		this._languageSvc.get(paths).then((value) => {
			for (const domain of Object.values(this._domains)) {
				const path: string = `domains2.${domain.uid}.name`;
				if (typeof value[path] === 'string') domain.name = value[path];
				for (const skill of Object.values(domain.skills)) {
					const path: string = `domains2.${domain.uid}.skills.${skill.uid}.name`;
					if (typeof value[path] === 'string') skill.name = value[path];
				}
			}
			this._loaded.resolve();
		});
	}

	/** Returns a promise that resolves once all names have been loaded in.
	 *
	 * @returns {Promise<void>}
	 */
	get loaded(): Promise<void> {
		return this._loaded.toPromise();
	}

	/** Returns the domain object with the matching UID.
	 *
	 * @param {number | string} uid The UID of the domain to retrieve.
	 * @returns {Domain2}
	 */
	getDomain(uid: number | string): Domain2 {
		return JClone(this._domains[uid]);
	}

	/** Returns an array of all domain objects.
	 *
	 * @returns {Array<Domain2>}
	 */
	getDomains(): Array<Domain2> {
		return JClone(Object.values(this._domains));
	}

	/** Returns the skill object with the matching UID.
	 *
	 * @param {number | string} uid The UID of the skill to retrieve.
	 * @returns {Skill2}
	 */
	getSkill(uid: number | string): Skill2 {
		return JClone(this._skills[uid]);
	}

	/** Returns an array of all skill objects.
	 *
	 * @param {number | string} domainUID
	 *  Optional. Filters the array by a specific domain UID.
	 * @returns {Array<Skill2>}
	 */
	getSkills(domainUID?: number | string): Array<Skill2> {
		if (!domainUID) return JClone(Object.values(this._skills));
		return JClone(Object.values(this._domains[domainUID].skills));
	}

	/** Returns the domain or skill object with the matching UID.
	 *
	 * @param {number | string} uid The UID of the domain or skill to retrieve.
	 * @returns {Domain2 | Skill2}
	 */
	getDomainOrSkill(uid: number | string): Domain2 | Skill2 {
		if (+uid < 100) return JClone(this._domains[uid]);
		return JClone(this._skills[uid]);
	}
}
