<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container px-0 w-sm-100 w-50">
		<h4 class="w-auto text-center mb-0 px-3">
			{{ title }}
		</h4>

		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center c-form" [formGroup]="form">
					<div class="col-12 mb-3">
						<textarea
							class="form-control txtarea"
							id="textInput"
							rows="3"
							[placeholder]="placeholder"
							formControlName="textInput"
							[ngClass]="{ 'is-invalid': submitted && !textInput.valid }"
						></textarea>
						<div
							*ngIf="submitted && !!textInput.errors"
							class="invalid-feedback"
						>
							<div *ngIf="!!textInput.errors.required">
								{{ errorRequired }}
							</div>
							<div *ngIf="!!textInput.errors.pattern">
								{{ labels.errorNoHTML }}
							</div>
						</div>
					</div>
					<div class="col-12 mb-3 c-form">
						<div class="form-group" *ngIf="!!disclaimer">
							<div class="form-check">
								<input
									class="form-check-input"
									formControlName="disclaimer"
									type="checkbox"
									id="disclaimer"
								/>
								<label class="form-check-label" for="disclaimer">
									{{ disclaimer.display }}
								</label>
							</div>
							<div
								*ngIf="
									submitted && form.controls['disclaimer'].errors?.required
								"
								class="invalid-feedback"
							>
								{{ disclaimer.error || labels.errorDisclaimer }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-inner-action">
			<button
				*ngIf="!!next?.onClick"
				type="button"
				(click)="nextOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ next.display || labels.next }}
			</button>
		</div>
	</div>
</div>
