<app-skill-builder-select-list
	[mainTitle]="page.title"
	[options]="options"
	[next]="next"
>
</app-skill-builder-select-list>

<ng-template #no>
	<div class="modal-body text-center modal-confirm">
		<p class="info-text pt-3">
			{{ page.titleNo }}
		</p>
		<p></p>
		<div class="col-12 my-4 px-4" [formGroup]="formNo">
			<textarea
				class="form-control txtarea-bordered"
				id="answerNo"
				rows="3"
				[placeholder]="page.placeholder"
				formControlName="answerNo"
				class="form-control"
				[maxlength]="maxChars"
				[ngClass]="{
					'is-invalid': submitted && formNo.controls.answerNo.errors,
				}"
			>
			</textarea>
			<div
				*ngIf="submitted && formNo.controls.answerNo.errors"
				class="invalid-feedback"
			>
				<div *ngIf="formNo.controls.answerNo.errors.required">
					{{ page.error1 }}
				</div>
				<div *ngIf="formNo.controls.answerNo.errors.pattern">
					{{ page.error2 }}
				</div>
			</div>
		</div>
		<div class="pt-4">
			<button (click)="cancelOnClick()" type="button" class="btn btn-md ml-3">
				{{ page.cancel }}
			</button>
			<button
				(click)="submitOnClick()"
				type="button"
				class="btn btn-md btn-primary"
			>
				{{ page.submit }}
			</button>
		</div>
	</div>
</ng-template>

<ng-template #yes>
	<div class="modal-body text-center pt-5">
		<div class="d-flex align-items-center justify-content-center">
			<div class="success-checkmark">
				<i class="fas fa-check"></i>
			</div>
		</div>
		<p class="mt-3 text-center info-text">
			<span [innerHTML]="page.titleYes"></span>
		</p>
		<div class="col-12 my-4 px-4" [formGroup]="formYes">
			<textarea
				class="form-control txtarea-bordered"
				id="answerYes"
				rows="3"
				[placeholder]="page.placeholder"
				formControlName="answerYes"
				class="form-control"
				[maxlength]="maxChars"
				[ngClass]="{
					'is-invalid': submitted && formYes.controls.answerYes.errors,
				}"
			>
			</textarea>
			<div
				*ngIf="submitted && formYes.controls.answerYes.errors"
				class="invalid-feedback"
			>
				<div *ngIf="formYes.controls.answerYes.errors.required">
					{{ page.error1 }}
				</div>
				<div *ngIf="formYes.controls.answerYes.errors.pattern">
					{{ page.error2 }}
				</div>
			</div>
		</div>
		<div class="pt-4">
			<button (click)="cancelOnClick()" type="button" class="btn btn-md ml-3">
				{{ page.cancel }}
			</button>
			<button
				(click)="submitOnClick()"
				type="button"
				class="btn btn-md btn-primary"
			>
				{{ page.submit }}
			</button>
		</div>
	</div>
</ng-template>
