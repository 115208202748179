import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoogleAuthProvider, OAuthCredential } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { LOGOUT_EVENT, LogoutFunction, LogoutService } from './logout.service';
import { config } from 'environment';
import { STORAGE_KEYS } from './storage.service';

export const PUBLIC_HEADER_KEY = 'X-Public-View';
export const PUBLIC_HEADERS = new HttpHeaders().set(PUBLIC_HEADER_KEY, '');

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private _timezone = 'America/Chicago';

	constructor(
		public afAuth: AngularFireAuth,
		public router: Router,
		private tokenSvc: TokenService,
		private logoutSvc: LogoutService,
		private http: HttpClient,
	) {
		// Bind logout service.
		this.logoutSvc.subscribe(LOGOUT_EVENT.API, this._logout);

		this.afAuth.authState.subscribe((user) => {
			if (user) {
				localStorage.setItem('user', JSON.stringify(user));
				JSON.parse(localStorage.getItem('user')!);
			} else {
				localStorage.setItem('user', 'null');
				JSON.parse(localStorage.getItem('user')!);
			}
		});

		// Get current time offset.
		const offset = new Date().getTimezoneOffset() / 60;
		this._timezone = 'UTC' + (offset >= 0 ? '+' : '') + offset.toString();
	}

	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem('user')!);
		return user !== null && user.emailVerified !== false ? true : false;
	}

	login(email: string, password: string, remember: boolean): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/authentication/login`,

			{ email, password, remember, timezone: this._timezone },
			{ headers: PUBLIC_HEADERS },
		);
	}

	googleAuth(token: string, remember: boolean): Observable<any> {
		return this.http.post(
			`${config.apiBase}public/authentication/google`,
			{ token, remember, timezone: this._timezone },
			{ headers: PUBLIC_HEADERS },
		);
	}

	loginWithGoogle() {
		this.afAuth
			.signInWithPopup(new GoogleAuthProvider())
			.then((result) => {
				const credential: OAuthCredential | null =
					GoogleAuthProvider.credentialFromResult(<any>result);
				if (!credential || !credential.idToken) return;
				this.googleAuth(credential?.idToken, true).subscribe((response) => {
					if (this.tokenSvc.login(response)) {
						this.confirm().subscribe();
						this.router.navigate(['/member/dashboard']);
					} else {
						this.logoutSvc.logout();
					}
					if (response) {
						this.afAuth.authState.subscribe((user) => {
							if (user) {
								localStorage.setItem('user', JSON.stringify(user));
								JSON.parse(localStorage.getItem('user')!);
							}
							this.router.navigate(['dashboard']);
						});
					}
				});
			})
			.catch((error) => {
				window.alert(error);
			});
	}

	signOut() {
		return this.afAuth.signOut().then(() => {
			localStorage.removeItem('user');
			this.router.navigate(['sign-in']);
		});
	}

	appleLogin(appleToken: string): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/authentication/apple`,
			{ token: appleToken, remember: true, timezone: this._timezone },
			{ headers: PUBLIC_HEADERS },
		);
	}

	edlink(code: string, remember: boolean): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/authentication/edlink`,
			{ code, remember, timezone: this._timezone },
			{ headers: PUBLIC_HEADERS },
		);
	}

	forgotPassword(email: string): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/password/forgot`,
			{ email },
			{ headers: PUBLIC_HEADERS },
		);
	}

	resetPassword(code: string, password: string): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/password/reset`,
			{ code, password },
			{ headers: PUBLIC_HEADERS },
		);
	}

	register(registration: any): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/register`,
			registration,
			{ headers: PUBLIC_HEADERS },
		);
	}

	verifyEmail(code: string): Observable<any> {
		return this.http.post<any>(
			`${config.apiBase}public/email/verify`,
			{ code },
			{ headers: PUBLIC_HEADERS },
		);
	}

	unsubscribeEmail(email: string): Observable<any> {
		return this.http.put<any>(
			`${config.apiBase}public/email/unsubscribe`,
			{ email },
			{ headers: PUBLIC_HEADERS },
		);
	}

	refreshTokens(): Observable<any> {
		return this.http.post(
			`${config.apiBase}public/authentication/refresh`,
			{ refreshToken: this.tokenSvc.refreshToken, timezone: this._timezone },
			{ headers: PUBLIC_HEADERS },
		);
	}

	confirm(): Observable<any> {
		return this.http.post(
			`${config.apiBase}public/authentication/confirm`,
			{ refreshToken: this.tokenSvc.refreshToken },
			{ headers: PUBLIC_HEADERS },
		);
	}

	private _logout: LogoutFunction = () => {
		this.http
			.post<any>(
				`${config.apiBase}public/logout`,
				{
					refreshToken: this.tokenSvc.refreshToken,
					deviceToken: localStorage.getItem(STORAGE_KEYS.DEVICE_TOKEN),
				},
				{ headers: PUBLIC_HEADERS },
			)
			.subscribe();
	};
}
