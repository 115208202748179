import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@services/public';
import {
	BLANK_SKILL_BUILDER,
	SKILL_BUILDER_FILTERS,
	SKILL_BUILDER_TYPES,
	SkillBuilder,
	SkillBuilderService,
} from '@services/member';
import { Filter } from '@util';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.report';

@Component({
	selector: 'app-member-skill-builder-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderReportComponent implements OnInit {
	SKILL_BUILDER_TYPES: typeof SKILL_BUILDER_TYPES = SKILL_BUILDER_TYPES;
	skillBuilderFilter: Filter<SkillBuilder> = {
		key: 'skillBuilders',
		value: { ...BLANK_SKILL_BUILDER },
		options: [],
	};
	displayNoData: boolean = false;

	// Page langauge.
	page: { [key: string]: string } = {
		noData: '',
		goToSkillBuilders: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _router: Router,
		private _skillBuildersSvc: SkillBuilderService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			this.skillBuilderFilter.value.name = this.page.selectChallenge;
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		await this._skillBuildersSvc.loaded;

		this.skillBuilderFilter.options = [
			this.skillBuilderFilter.value,
			...this._skillBuildersSvc.getSkillBuilders(SKILL_BUILDER_FILTERS.REPORT),
		];
	}

	skillBuilderOnChange(): void {
		window.scrollTo(0, 0);
		this.displayNoData = false;
	}

	noData(): void {
		this.displayNoData = true;
	}

	goToSkillBuilders() {
		// Reloads the dashboard.
		this._router.navigate(['']);
	}
}
