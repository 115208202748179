import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ABSTRACT_PAGE, AbstractData } from '../common';
import { TextBoxDisclaimer, TextBoxNext } from '@components';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.idea';

@Component({
	selector: 'app-member-skill-builder-abstract-idea',
	templateUrl: './idea.component.html',
	styleUrls: ['./idea.component.scss'],
})
export class MemberSkillBuilderAbstractIdeaComponent implements OnInit {
	@Input() data: AbstractData;
	disclaimer: TextBoxDisclaimer = { display: '' };
	next: TextBoxNext = { onClick: this.nextOnClick.bind(this) };

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		errorRequired: '',
		disclaimer: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				switch (key) {
					case 'disclaimer':
						this._languageSvc.template(this.page[key]).then((value) => {
							this.page[key] = value;
							this.disclaimer.display = value;
						});
						break;
					default:
						this._languageSvc
							.template(this.page[key])
							.then((value) => (this.page[key] = value));
				}
			}
		});
	}

	nextOnClick(idea: string): void {
		this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, {
			...this.data,
			page: ABSTRACT_PAGE.POSITIVE_REASONS,
			idea,
		});
	}
}
