<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container px-0 w-sm-100 w-50">
		<h4 class="w-auto text-center mb-0 px-3">
			{{ page.title }}
		</h4>

		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center">
					<div class="col-12 mb-3" [formGroup]="form">
						<textarea
							class="form-control txtarea"
							id="dilemma"
							rows="3"
							[placeholder]="page.placeholder"
							formControlName="dilemma"
							[maxlength]="maxChars"
							[ngClass]="{
								'is-invalid': submitted && form.controls.dilemma.errors,
							}"
						></textarea>
						<div
							*ngIf="submitted && form.controls.dilemma.errors"
							class="invalid-feedback"
						>
							<div *ngIf="form.controls.dilemma.errors.required">
								{{ page.error1 }}
							</div>
							<div *ngIf="form.controls.dilemma.errors.pattern">
								{{ page.error2 }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-inner-action">
			<button
				type="button"
				(click)="nextOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ page.next }}
			</button>
		</div>
	</div>
</div>
