import { LocaleData } from 'ngx-bootstrap/chronos';
import { OrdinalDateFn } from 'ngx-bootstrap/chronos/locale/locale.class';

const swOrdinal: OrdinalDateFn = (number: number) => {
	// Example implementation; you can customize this based on your needs
	return `${number}`; // Swahili may not use ordinal suffixes, so just return the number
};

export const swLocale: LocaleData = {
	months: [
		'Januari',
		'Februari',
		'Machi',
		'Aprili',
		'Mei',
		'Juni',
		'Julai',
		'Agosti',
		'Septemba',
		'Oktoba',
		'Novemba',
		'Desemba',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'Mei',
		'Jun',
		'Jul',
		'Ago',
		'Sep',
		'Okt',
		'Nov',
		'Des',
	],
	weekdays: [
		'Jumapili',
		'Jumatatu',
		'Jumanne',
		'Jumatano',
		'Alhamisi',
		'Ijumaa',
		'Jumamosi',
	],
	weekdaysShort: ['Jpl', 'Jtt', 'Jmn', 'Jtn', 'Alh', 'Iju', 'Jmo'],
	weekdaysMin: ['Jp', 'Jt', 'Jn', 'Jm', 'Al', 'Ij', 'Jm'],
	longDateFormat: {
		L: 'MM/DD/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Leo saa] LT',
		nextDay: '[Kesho saa] LT',
		nextWeek: 'dddd [saa] LT',
		lastDay: '[Jana] LT',
		lastWeek: '[Wiki iliyopita] dddd [saa] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'katika %s',
		past: '%s iliyopita',
		s: 'sekunde chache',
		m: 'dakika moja',
		mm: '%d dakika',
		h: 'saa moja',
		hh: '%d saa',
		d: 'siku moja',
		dd: '%d siku',
		M: 'mwezi mmoja',
		MM: '%d miezi',
		y: 'mwaka mmoja',
		yy: '%d miaka',
	},
	ordinal: swOrdinal, // Set the custom ordinal function
	week: {
		dow: 1,
		doy: 4,
	},
};
