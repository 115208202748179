<ng-template [ngIf]="data.domainUID === 0">
	<app-skill-builder-select-list
		[mainTitle]="page.title"
		[subTitle]="page.subTitle"
		[options]="options"
		[next]="next"
	>
	</app-skill-builder-select-list>
</ng-template>
<ng-template [ngIf]="data.domainUID !== 0">
	<app-skill-builder-select-list
		[mainTitle]="page.title"
		[subTitle]="page.subTitle"
		[options]="options"
		[custom]="custom"
		[next]="next"
	>
	</app-skill-builder-select-list>
</ng-template>
