import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	Type,
} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PublicDomService {
	constructor(
		private _componentFactoryResolver: ComponentFactoryResolver,
		private _applicationRef: ApplicationRef,
		private _injector: Injector,
	) {}

	/**
	 * Injects a component into the DOM and returns a destroy function.
	 * @param {Component} componentType The component type to inject.
	 * @returns {Function} A function to destroy the component.
	 */
	injectComponent<T>(componentType: Type<T>): ComponentRef<T> {
		const componentRef: ComponentRef<T> = this._componentFactoryResolver
			.resolveComponentFactory<T>(componentType)
			.create(this._injector);
		this._applicationRef.attachView(componentRef.hostView);
		document.body.appendChild(
			(<EmbeddedViewRef<T>>componentRef.hostView).rootNodes[0],
		);
		componentRef.onDestroy(() => {
			this._applicationRef.detachView(componentRef.hostView);
		});
		return componentRef;
	}
}
