import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDAction, WWYDData, WWYD_PAGE } from '../common';
import { SelectListOption } from '@components';
import { ReplaySubject } from 'rxjs';
import { ExposedPromise, JClone } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.ethics';

@Component({
	selector: 'app-member-skill-builder-wwyd-ethics',
	templateUrl: './ethics.component.html',
	styleUrls: ['./ethics.component.scss'],
})
export class MemberSkillBuilderWwydEthicsComponent implements OnInit {
	@Input() data: WWYDData;
	submitted: boolean = false;
	private _options: Array<SelectListOption> = [];
	private _optionsLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		next: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Get options list.
		const path: string = 'skillBuilders.wwyd.ethics';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] === 'object' && value[path] !== null)
				this._options = Object.values(value[path]);
			this._optionsLoaded.resolve();
		});
	}

	getOptions(action: WWYDAction): ReplaySubject<Array<SelectListOption>> {
		const options = new ReplaySubject<Array<SelectListOption>>(1);
		this._optionsLoaded.then(() => {
			options.next(
				JClone(this._options).map((option) => {
					if (option.value === action.ethical) option.selected = true;
					return option;
				}),
			);
		});
		return options;
	}

	onChange(action: WWYDAction, option: SelectListOption) {
		action.ethical = option.value;
	}

	nextOnClick(): void {
		this.submitted = true;
		for (const action of this.data.actions) if (!action.ethical) return;
		this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
			...this.data,
			page: WWYD_PAGE.BENEFITS,
		});
	}
}
