<div class="card card-rounded">
	<div class="card-body px-lg pb-0">
		<div class="card-header text-center font-20 pb-0">
			{{ title }}
		</div>
		<div class="row">
			<div class="col-12 donut-wrapper position-relative">
				<div class="title-center">{{ chartTitle }}</div>
				<ngx-charts-pie-chart
					[view]="[380, 280]"
					[scheme]="{ domain: ['#3CE986', '#DE91FF', '#FF8888'] }"
					[results]="data"
					[legend]="true"
					[labels]="true"
					[doughnut]="true"
					[legendPosition]="'bottom'"
					[legendTitle]="''"
					[labelFormatting]="pieChartLabel.bind(this)"
					[animations]="false"
				>
				</ngx-charts-pie-chart>
			</div>
		</div>
	</div>
</div>
