// Dhivehi
import { LocaleData } from 'ngx-bootstrap/chronos';

export const dvLocale: LocaleData = {
	months: [
		'ޖެނުއަރީ',
		'ފެބުރުއަރީ',
		'މާރޗް',
		'އޭޕްރިލް',
		'މެއި',
		'ޖޫން',
		'ޖުލައި',
		'އޯގަސްޓް',
		'ސެޕްޓެމްބަރު',
		'އޮކްޓޫބަރު',
		'ނޮވެމްބަރު',
		'ޑިސެމްބަރު',
	],
	monthsShort: [
		'ޖެން',
		'ފެބް',
		'މާރޗް',
		'އޭޕް',
		'މެއި',
		'ޖޫން',
		'ޖުލައި',
		'އޯގަސް',
		'ސެޕް',
		'އޮކް',
		'ނޮވެ',
		'ޑިސް',
	],
	weekdays: [
		'އާދީއްތަ',
		'ހޯމަދު',
		'އަންގާރަ',
		'ބުދަ',
		'ބުރާރަތު',
		'ހުކުރު',
		'ހޮނިހިރު',
	],
	weekdaysShort: ['އާދީ', 'ހޯމަ', 'އަންގާ', 'ބުދަ', 'ބުރާ', 'ހުކު', 'ހޮނި'],
	weekdaysMin: ['އާ', 'ހޯ', 'އަ', 'ބު', 'ބު', 'ހު', 'ހޮ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[މިއަދު] LT',
		nextDay: '[މާދަމާތީ] LT',
		nextWeek: 'dddd LT',
		lastDay: '[އިއްޔެ] LT',
		lastWeek: '[ފާއިތުވި] dddd LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'ތެރޭގައި %s',
		past: 'ކުރިން %s',
		s: 'ސިކުންތު',
		m: 'މިނިޓުން',
		mm: '%d މިނިޓު',
		h: 'ގަޑިއިރު',
		hh: '%d ގަޑިއިރު',
		d: 'ދުވަހު',
		dd: '%d ދުވަހު',
		M: 'މަސް',
		MM: '%d މަސް',
		y: 'އަހަރު',
		yy: '%d އަހަރު',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 7,
		doy: 12,
	},
};
