<div class="main-container pb-lg">
	<tabset class="tab-c">
		<tab heading="{{ page?.measure }}" (selectTab)="tab = 'Measure'">
			<div
				*ngIf="tab === 'Measure'"
				class="dashContArea dashContAreaStudent px-5 mt-4"
			>
				<app-measure-dashboard></app-measure-dashboard>
			</div>
		</tab>
		<tab heading="{{ page?.train }}" (selectTab)="tab = 'Train'">
			<div
				*ngIf="tab === 'Train'"
				class="dashContArea dashContAreaStudent px-5 mt-2"
			>
				<app-train-dashboard></app-train-dashboard>
			</div>
		</tab>
		<tab heading="{{ page?.track }}" (selectTab)="tab = 'Track'">
			<div
				*ngIf="tab === 'Track'"
				class="dashContArea dashContAreaStudent px-5 mt-2"
			>
				<app-track-dashboard></app-track-dashboard>
			</div>
		</tab>
		<!-- <tab heading="User Management" (selectTab)="tab = 'User Management'">
      <div *ngIf="tab === 'User Management'" class="dashContArea dashContAreaStudent px-5 mt-2">
        <app-user-management></app-user-management>
      </div>
    </tab>
    <tab heading="Group Management" (selectTab)="tab = 'Group Management'">
      <div *ngIf="tab === 'Group Management'" class="dashContArea dashContAreaStudent px-5 mt-2">
        <app-group-management></app-group-management>
      </div>
    </tab>-->
		<tab heading="{{ page?.reward }}" (selectTab)="tab = 'Reward'">
			<div
				*ngIf="tab === 'Reward'"
				class="dashContArea dashContAreaStudent px-5 mt-2"
			>
				<app-leader-reward></app-leader-reward>
			</div>
		</tab>
	</tabset>
</div>
