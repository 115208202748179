// Lao
import { LocaleData } from 'ngx-bootstrap/chronos';

export const loLocale: LocaleData = {
	months: [
		'ມັງກອນ',
		'ກຸມພາ',
		'ມີນາ',
		'ເມສາ',
		'ພະຈິກ',
		'ເຣມະ',
		'ກຣະມດາ',
		'ສິງຫາ',
		'ກັນຍາ',
		'ຕຸລາ',
		'ພະຈິກ',
		'ທັນວາ',
	],
	monthsShort: [
		'ມັງ',
		'ກຸມ',
		'ມີນ',
		'ເມສ',
		'ພະຈິ',
		'ເຣມ',
		'ກຣະ',
		'ສິງ',
		'ກັນ',
		'ຕຸລ',
		'ພະຈິ',
		'ທັນ',
	],
	weekdays: ['ອາທິດ', 'ຈັນ', 'ອັງຄານ', 'ພະລະສົງ', 'ພະລະສົງ', 'ສຸກ', 'ເສັຽ'],
	weekdaysShort: ['ອາທ', 'ຈັນ', 'ອັງ', 'ພະລ', 'ພະລ', 'ສຸກ', 'ເສັຽ'],
	weekdaysMin: ['ອາ', 'ຈັ', 'ອັ', 'ພະ', 'ພະ', 'ສຸ', 'ເສ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[ມື້ນີ້] LT',
		nextDay: '[ມື້ອາທິດ] LT',
		nextWeek: 'dddd [ໃນອາທິດຕໍ່ໄປ] LT',
		lastDay: '[ມື້ຄໍາ] LT',
		lastWeek: '[ອາທິດທີ່ແລ້ວ] dddd [ທີ່ແລ້ວ] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'ໃນ %s',
		past: '%s ກ່ຽວກັບ',
		s: 'ເວລາບາງເລື່ອຍ',
		m: '1 ນາທີ',
		mm: '%d ນາທີ',
		h: '1 ຊົ່ວໂມງ',
		hh: '%d ຊົ່ວໂມງ',
		d: '1 ວັນ',
		dd: '%d ວັນ',
		M: '1 ເດືອນ',
		MM: '%d ປະເທດ',
		y: '1 ປີ',
		yy: '%d ປີ',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
