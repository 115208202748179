import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	TemplateRef,
	ViewChild,
	OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	Role,
	TokenService,
	ROLES_ORDER,
	LogoutService,
	LanguageService,
} from '@services/public';
import { MemberGroupAddComponent } from '@pages/member/group/add/add.component';
import { Subscription } from 'rxjs';
// import { MemberGroupAddComponent } from '@pages/member';

const PAGE_PATH = `pages.member.menu`;

// Common menu options
const MENU_HOME = {
	name: '',
	path: ['/member/dashboard'],
	image: 'assets/img/Home-icon.svg',
};

const MENU_LEADER = {
	name: '',
	path: ['/leader/dashboard'],
	image: 'assets/img/Home-icon.svg',
};
const MENU_ASSESSMENTS = {
	name: '',
	path: ['/member/assessment/list'],
	image: 'assets/img/Assessment-icon.svg',
};
const MENU_LEADER_ASSESSMENTS = {
	name: '',
	path: ['/leader/assessment/list'],
	image: 'assets/img/Assessment-icon.svg',
};
const MENU_RESOURCES = {
	name: '',
	image: 'assets/img/Resources-icon.svg',
};
const MENU_VIDEO = {
	name: '',
	image: 'assets/img/How-use-icon.svg',
};
const MENU_PROFILE = {
	name: '',
	path: ['member/profile'],
	image: 'assets/img/Profile-icon.svg',
};
const MENU_LOGOUT = {
	name: '',
	path: ['/login'],
	image: 'assets/img/Logout-icon.svg',
};

// User menu options
const MENU_CHALLENGES = {
	name: '',
	path: ['/member/challenges'],
	image: 'assets/img/Group-798.svg',
};

const MENU_JOIN = {
	name: 'Join Group / Tag',
	path: ['member/join'],
	image: 'assets/img/Add-Group-icon.svg',
};

// Leader menu options
const MENU_REPORTS = {
	name: 'Reports',
	path: ['/leader/report'],
	image: 'assets/img/pie-chart.svg',
};

@Component({
	selector: 'app-member-main-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MemberMainMenuComponent implements OnInit, OnDestroy {
	@Output() toggleMenu: EventEmitter<any> = new EventEmitter<any>();
	modalRef?: BsModalRef;
	message?: string;
	@ViewChild('template') template: TemplateRef<any>;
	menu: Array<any> = [];
	page: { [key: string]: string } = {
		assessments: '',
		confirmed: '',
		close: '',
		declined: '',
		home: '',
		howToUse: '',
		joinGroupTag: '',
		leaderAssessments: '',
		leaderDashboard: '',
		logout: '',
		profile: '',
		reports: '',
		resources: '',
		skillBuilders: '',
	};

	private _subscriptions: Subscription = new Subscription();

	constructor(
		private router: Router,
		private logoutSvc: LogoutService,
		private tokenSvc: TokenService,
		private modalSvc: BsModalService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
			this._subscriptions.add(
				this.tokenSvc.role.subscribe((role) => this.updateMenu(role)),
			);
		});
	}
	updateMenu(role: any): void {
		MENU_HOME.name = this.page.home;
		MENU_ASSESSMENTS.name = this.page.assessments;
		MENU_CHALLENGES.name = this.page.skillBuilders;
		MENU_LEADER.name = this.page.leaderDashboard;
		MENU_LEADER_ASSESSMENTS.name = this.page.leaderAssessments;
		MENU_JOIN.name = this.page.joinGroupTag;
		MENU_LOGOUT.name = this.page.logout;
		MENU_PROFILE.name = this.page.profile;
		MENU_REPORTS.name = this.page.reports;
		MENU_RESOURCES.name = this.page.resources;
		MENU_VIDEO.name = this.page.howToUse;
		if (role === Role.ACCOUNT) {
			this.menu.push(MENU_LOGOUT);
			return;
		}
		this.menu = [MENU_HOME, MENU_ASSESSMENTS];
		if (ROLES_ORDER.indexOf(role) > ROLES_ORDER.indexOf(Role.MEMBER)) {
			this.menu.push(MENU_LEADER);
		}
		if (role === Role.LEADER || role === Role.ADMIN)
			this.menu.push(MENU_LEADER_ASSESSMENTS, MENU_REPORTS);
		this.menu.push(
			MENU_CHALLENGES,
			//MENU_JOIN,
			MENU_RESOURCES,
			MENU_PROFILE,
			MENU_VIDEO,
			MENU_LOGOUT,
		);
	}
	reloadPage() {
		setTimeout(() => {
			location.reload();
		}, 100);
	}
	public onOptionClick(option: any): void {
		this.toggleMenu.emit();
		if (option === MENU_RESOURCES) window.open('https://jakapa.com/resources');
		if (option === MENU_VIDEO) this.openModal(this.template);
		if (option === MENU_LOGOUT) this.logout();
		if (!!option.path) {
			if (this.router.url === option.path.join('/'))
				this.router
					.navigateByUrl('/dummy', { skipLocationChange: true })
					.then(() => this.router.navigate(option.path));
			else {
				this.router.navigate(option.path);
			}
		}
	}

	openModal(template: any) {
		this.modalRef = this.modalSvc.show(template, {
			class: 'modal-md modal-dialog-centered',
			backdrop: 'static',
			keyboard: true,
		});
	}
	confirm(): void {
		this.message = this.page.confirmed;
		this.modalRef?.hide();
	}

	decline(): void {
		this.message = this.page.declined;
		this.modalRef?.hide();
	}

	logout(): void {
		this.logoutSvc.logout();
	}

	addGroup(): void {
		this.modalSvc.show(MemberGroupAddComponent, {
			class: 'modal-md-c modal-dialog-centered',
		});
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
