<div class="assessmentListMain">
	<div class="tabHeadMain px-5">
		<div class="container">
			<div class="tabHead">
				<span routerLink="/member/dashboard" class="back-arrow"
					><i class="fas fa-arrow-left"></i></span
				>{{ pageLanguage?.list.title }}
			</div>
		</div>
	</div>
	<div class="container-fluid pb-5-sm-c">
		<div class="row">
			<div class="col-xs-12 col-sm-2 col-md-2 lftPanalMain">
				<div class="lftPanal">
					<ul>
						<li (click)="onGroupChange()" class="lftPnlheading">
							{{ appLabels?.allGroups | titlecase }}
						</li>
						<ng-container *ngFor="let group of groups">
							<li (click)="onGroupChange(group)">
								<a class="{{ selectedGroup === group ? 'active' : '' }}">{{
									group.name
								}}</a>
							</li>
						</ng-container>
					</ul>
				</div>
			</div>
			<div class="col-xs-12 col-sm-10 col-md-10 rytPanalMain">
				<div class="rytPanal">
					<div class="rytHeadMain"></div>

					<div id="no-more-tables">
						<table class="table-c">
							<thead>
								<tr>
									<th>{{ appLabels?.assessmentName }}</th>
									<th>{{ appLabels?.assessmentType }}</th>
									<th>{{ appLabels?.groupName }}</th>
									<th>{{ appLabels?.endDate }}</th>
									<th>{{ appLabels?.actions }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let assessment of assessments">
									<td [attr.data-title]="appLabels?.assessmentName">
										{{ assessment.name }}
									</td>
									<td [attr.data-title]="appLabels?.assessmentType">
										{{ assessment.type }}
									</td>
									<td [attr.data-title]="appLabels?.groupName">
										{{
											assessment.group?.name === null
												? '-'
												: assessment.group.name
										}}
									</td>
									<td [attr.data-title]="appLabels?.endDate">
										{{
											assessment.endDate === null
												? '-'
												: (assessment.endDate | date: 'M/d/yyyy')
										}}
									</td>
									<td [attr.data-title]="appLabels?.actions" class="btn-c">
										<span class="track-btn right-btn">
											<button
												*ngIf="assessment.status === 'ACTIVE'"
												type="button"
												(click)="startAssessment(assessment)"
												class="btn btn-small btin-icon btn-primary"
											>
												{{ appLabels?.start }}
												<img
													src="./../../../../assets/img/new/play-filled.svg"
													alt="play-filled"
													class="play-icon flex-shrink-0"
												/>
											</button>
											<div *ngIf="assessment.status === 'SCHEDULED'">
												<label class="font-weight-normal txt-sm"
													>{{ appLabels?.startsOn }}:
													{{ assessment.startDate | date: 'M/d/yyyy' }}</label
												>
											</div>
										</span>
									</td>
								</tr>
								<ng-container *ngIf="!assessments.length">
									<tr>
										<td class="text-center pl-0-c" colspan="5">
											{{ appLabels?.noAssessmentsFound }}
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
