import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '@services/public';
import { APIErrors, APISuccess } from '@util';
import { config } from 'environment';
import { ExposedPromise, JClone } from 'jakapa-utilities';
import { Observable } from 'rxjs';

export enum SKILL_BUILDER_TYPES {
	ABSTRACT = 'abstract',
	ADVENTURE = 'adventure',
	BRAIN = 'brain',
	DECISION = 'decision',
	GOAL = 'goal',
	JOURNAL = 'journal',
	MESSAGE = 'message',
	REFLECTION = 'reflection',
	STRESS = 'stress',
	WWYD = 'wwyd',
}
const _SBT: typeof SKILL_BUILDER_TYPES = SKILL_BUILDER_TYPES;

export enum SKILL_BUILDER_FILTERS {
	DAILY = 'daily',
	REPEATABLE = 'repeatable',
	REPORT = 'report',
}
const _SBF: typeof SKILL_BUILDER_FILTERS = SKILL_BUILDER_FILTERS;

export interface SkillBuilderData {
	type: SKILL_BUILDER_TYPES;
	start: boolean;
	goalPlanUUID?: string;
}

export interface SkillBuilder {
	type: SKILL_BUILDER_TYPES;
	name: string;
	icon: string;
}

export const BLANK_SKILL_BUILDER: SkillBuilder = {
	type: null,
	name: '',
	icon: '',
};

const _SB: {
	[key in SKILL_BUILDER_TYPES]: SkillBuilder & {
		[key in SKILL_BUILDER_FILTERS]?: true;
	};
} = {
	[_SBT.ABSTRACT]: {
		type: _SBT.ABSTRACT,
		name: '',
		icon: 'assets/img/Abstract-Thinking.svg',
		[_SBF.REPEATABLE]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.ADVENTURE]: {
		type: _SBT.ADVENTURE,
		name: '',
		icon: 'assets/img/Go-Adventure.svg',
		[_SBF.REPEATABLE]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.BRAIN]: {
		type: _SBT.BRAIN,
		name: '',
		icon: 'assets/img/Build-Positive-Brain.svg',
		[_SBF.DAILY]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.DECISION]: {
		type: _SBT.DECISION,
		name: '',
		icon: 'assets/img/Practice-Decision-Making.svg',
		[_SBF.REPEATABLE]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.GOAL]: {
		type: _SBT.GOAL,
		name: '',
		icon: 'assets/img/Achieve-Goal.svg',
		[_SBF.DAILY]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.JOURNAL]: {
		type: _SBT.JOURNAL,
		name: '',
		icon: 'assets/img/Did-Felt-Journal.svg',
		[_SBF.DAILY]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.MESSAGE]: {
		type: _SBT.MESSAGE,
		name: '',
		icon: 'assets/img/Manage-Message.svg',
		[_SBF.REPEATABLE]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.REFLECTION]: {
		type: _SBT.REFLECTION,
		name: '',
		icon: '',
	},
	[_SBT.STRESS]: {
		type: _SBT.STRESS,
		name: '',
		icon: 'assets/img/Make-Stress-Plan.svg',
		[_SBF.DAILY]: true,
		[_SBF.REPORT]: true,
	},
	[_SBT.WWYD]: {
		type: _SBT.WWYD,
		name: '',
		icon: 'assets/img/What-You-Do.svg',
		[_SBF.REPEATABLE]: true,
		[_SBF.REPORT]: true,
	},
};

@Injectable({
	providedIn: 'root',
})
export class SkillBuilderService {
	private _loaded: ExposedPromise<void> = new ExposedPromise<void>();

	constructor(
		private _http: HttpClient,
		private _languageSvc: LanguageService,
	) {
		// Load domain and skill names.
		this._languageSvc
			.get(
				Object.keys(_SB).map(
					(skillBuilder) => `skillBuilders.${skillBuilder}.name`,
				),
			)
			.then((value) => {
				for (const skillBuilder of Object.values(_SB)) {
					const path = `skillBuilders.${skillBuilder.type}.name`;
					if (typeof value[path] === 'string') skillBuilder.name = value[path];
				}
				this._loaded.resolve();
			});
	}

	/** Returns a promise that resolves once all names have been loaded in.
	 *
	 * @returns {Promise<void>}
	 */
	get loaded(): Promise<void> {
		return this._loaded.toPromise();
	}

	getName(type: SKILL_BUILDER_TYPES): string {
		return _SB[type].name;
	}

	getSkillBuilders(filter?: SKILL_BUILDER_FILTERS): Array<SkillBuilder> {
		return JClone(
			Object.values(_SB).filter(
				(skillBuilder) => !filter || skillBuilder[filter],
			),
		);
	}

	end(
		skillBuilderType: string,
		goalPlanUUID?: string,
	): Observable<APISuccess | APIErrors> {
		return this._http.put<APISuccess | APIErrors>(
			`${config.apiBase}member/skill-builder/end`,
			{ type: skillBuilderType, goalPlanUUID: goalPlanUUID ?? null },
		);
	}

	getList(): Observable<any> {
		return this._http.get<any>(`${config.apiBase}member/skill-builder/list`);
	}

	getStatus(
		skillBuilderType: string,
		goalPlanUUID?: string,
	): Observable<SkillBuilderData | APIErrors> {
		return this._http.get<SkillBuilderData | APIErrors>(
			`${config.apiBase}member/skill-builder/status?type=${
				skillBuilderType
			}&goalPlanUUID=${encodeURIComponent(goalPlanUUID)}`,
		);
	}
}
