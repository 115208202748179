<section class="content">
	<div class="tab-content" id="myTabContent">
		<div
			class="tab-pane fade show active"
			id="physics"
			role="tabpanel"
			aria-labelledby="physics-tab"
		>
			<div
				class="col-12 mt-2 d-flex justify-content-center justify-content-md-end"
			>
				<div class="row w-sm-100 mb-3">
					<select
						class="w-sm-100"
						[(ngModel)]="skillBuilderFilter.value"
						(change)="skillBuilderOnChange()"
					>
						<option
							*ngFor="let skillBuilder of skillBuilderFilter.options"
							[ngValue]="skillBuilder"
						>
							{{ skillBuilder.name }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>

	<!-- Reports -->
	<app-challenge-dashboard-tab
		*ngIf="!skillBuilderFilter.value.type"
	></app-challenge-dashboard-tab>
	<ng-container [ngSwitch]="skillBuilderFilter.value.type">
		<app-member-skill-builder-abstract-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.ABSTRACT"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-abstract-report>
		<app-member-skill-builder-adventure-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.ADVENTURE"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-adventure-report>
		<app-member-skill-builder-brain-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.BRAIN"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-brain-report>
		<app-member-skill-builder-decision-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.DECISION"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-decision-report>
		<app-member-skill-builder-goal-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.GOAL"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-goal-report>
		<app-member-skill-builder-journal-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.JOURNAL"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-journal-report>
		<app-member-skill-builder-message-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.MESSAGE"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-message-report>
		<app-member-skill-builder-stress-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.STRESS"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-stress-report>
		<app-member-skill-builder-wwyd-report
			*ngSwitchCase="SKILL_BUILDER_TYPES.WWYD"
			[noData]="noData.bind(this)"
		></app-member-skill-builder-wwyd-report>
	</ng-container>

	<!-- No Data -->
	<div *ngIf="displayNoData" class="main-container w-100">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="card d-flex align-items-center justify-content-center">
						<div
							class="card-body d-flex align-items-center justify-content-center flex-column col-sm-12 col-md-8"
						>
							<div class="text-center">
								<img
									src="assets/img/no-data.svg"
									[alt]="page.noData"
									class="w-75"
								/>
							</div>
							<div class="title-main text-center py-4">
								{{ page.noData }}
							</div>
							<div class="py-1">
								<div class="btn-row d-flex">
									<button
										type="button"
										(click)="goToSkillBuilders()"
										class="btn btn-small btin-icon btn-secondary px-3"
									>
										{{ page.goToSkillBuilders }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
