<app-skill-builder-text-list
	[mainTitle]="data?.affect"
	[subTitle]="page.subTitle"
	[textInputs]="actions"
	[disclaimer]="disclaimer"
	[next]="next"
	[validationError]="page.error1"
	[parentLoaded]="loaded.toPromise()"
>
</app-skill-builder-text-list>
