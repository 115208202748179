import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

import {
	Skill,
	Option,
	LoaderService,
	DomainService,
	LanguageService,
	StorageService,
	TEMP_KEYS,
	LogoutService,
	LogoutFunction,
	LOGOUT_EVENT,
} from '@services/public';
import { AssessmentService, MemberOnboardingService } from '@services/member';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-member-assessment-self',
	templateUrl: './self.component.html',
	styleUrls: ['./self.component.scss'],
})
export class MemberAssessmentSelfComponent implements OnInit, OnDestroy {
	private readonly _key: symbol = Symbol();

	// Assessment
	uuid: string = null;
	options: Array<Option> = [];
	instructions: string = '';
	skills: Array<Skill> = [];
	scores: Array<any> = [];
	assessmentComplete: boolean = false;
	@Output() reports: boolean = true;

	// Form control
	form: UntypedFormGroup = new UntypedFormGroup({});
	currentSkillIndex: number = 0;

	allSkills: Array<Skill> = [];

	// Language Translation
	pageLanguage: any;
	appLabels: any;
	basePath = `pages.member.assessment`;

	private _logoutKey: symbol = Symbol();
	private _subscriptions: Subscription = new Subscription();

	constructor(
		private toastSvc: ToastrService,
		private domainSvc: DomainService,
		private assessmentSvc: AssessmentService,
		private route: ActivatedRoute,
		private router: Router,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
		private location: Location,
		private _storageSvc: StorageService,
		private _onboardingSvc: MemberOnboardingService,
		private _logoutSvc: LogoutService,
	) {
		// Bind the logout service.
		this._logoutSvc.subscribe(
			LOGOUT_EVENT.PRE_API,
			this._beforeLogout,
			this._logoutKey,
		);
	}

	ngOnInit(): void {
		// Store uuid.
		this._subscriptions.add(
			this.router.events.subscribe((x) => {
				if (x instanceof NavigationEnd) {
					window.scrollTo(0, 0);
				} else {
					if (x instanceof NavigationStart && !this.assessmentComplete) {
						this._storageSvc.setTempStorage(
							TEMP_KEYS.IS_FIRST_SELF_ASSESSMENT,
							null,
						);
					}
				}
			}),
		);
		this.uuid = this.route.snapshot.paramMap.get('uuid');

		// Load Language
		this._languageSvc.get([this.basePath]).then((value) => {
			this.pageLanguage = value[this.basePath];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.appLabels = value[`labels`];
		});

		// Load instructions and options.
		const path: string = 'assessments.self';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] !== 'object' || value[path] === null) return;
			this.options = Object.values(value[path].options);
			this.instructions = value[path].instructions;
		});

		// Load skills.
		this.domainSvc.getSkills().then((response) => {
			this.allSkills = response;
			this.loadAssessment();
		});
	}

	loadAssessment(): void {
		// Load assessment.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.getSelf(this.uuid)
			.pipe(
				map((response: any) => {
					if (!!response.skillUIDs) {
						this.skills = this.allSkills.filter((skill) =>
							response.skillUIDs.includes(skill.uid),
						);
						this.skills[this.currentSkillIndex].questions.forEach(
							(question) => {
								this.form.addControl(
									question,
									new UntypedFormControl('', Validators.required),
								);
							},
						);
					}
					this.loaderSvc.removeLoader(loader);
				}).bind(this),
			)
			.subscribe();
	}

	prev(): void {
		// Store score.
		this.scores[this.currentSkillIndex] = {};
		for (const control in this.form.value)
			this.scores[this.currentSkillIndex][control] = this.form.value[control];

		// Update form.
		this.skills[this.currentSkillIndex].questions.forEach((question) => {
			this.form.removeControl(question);
		});
		this.currentSkillIndex--;
		this.form.reset();
		this.skills[this.currentSkillIndex].questions.forEach((question) => {
			this.form.addControl(
				question,
				new UntypedFormControl('', Validators.required),
			);
		});
		if (typeof this.scores[this.currentSkillIndex] !== 'undefined')
			for (const control in this.form.value)
				this.form.controls[control].patchValue(
					this.scores[this.currentSkillIndex][control],
				);
	}

	next(): void {
		// Make sure question is answered.
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			this.toastSvc.error(this.pageLanguage.page.mustFullyAnswer);
			return;
		}

		// Store score.
		this.scores[this.currentSkillIndex] = {};
		for (const control in this.form.value)
			this.scores[this.currentSkillIndex][control] = this.form.value[control];

		// Update form.
		this.skills[this.currentSkillIndex].questions.forEach((question) => {
			this.form.removeControl(question);
		});
		this.currentSkillIndex++;
		this.form.reset();
		this.skills[this.currentSkillIndex].questions.forEach((question) => {
			this.form.addControl(
				question,
				new UntypedFormControl('', Validators.required),
			);
		});
		if (typeof this.scores[this.currentSkillIndex] !== 'undefined') {
			for (const control in this.form.value) {
				this.form.controls[control].patchValue(
					this.scores[this.currentSkillIndex][control],
				);
			}
		}

		// Scroll to the top.
		setTimeout(() => window.scrollTo(0, 0));
	}

	backstep(): void {
		this.location.back();
	}

	finish(): void {
		// Make sure question is answered.
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			this.toastSvc.error(this.pageLanguage.page.mustFullyAnswer);
			return;
		}

		// Store score.
		this.scores[this.currentSkillIndex] = {};
		for (const control in this.form.value)
			this.scores[this.currentSkillIndex][control] = this.form.value[control];

		// Compile scores.
		const skillScores = [];
		for (let i = 0; i < this.skills.length; i++)
			skillScores.push({
				uid: this.skills[i].uid,
				score: Math.round(
					Object.values(this.scores[i]).reduce<number>(
						(p: number, c: number) => p + c,
						0,
					) / Object.values(this.scores[i]).length,
				),
			});

		// Submit assessment.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.assessmentSvc
			.submitSelf(this.uuid, skillScores)
			.pipe(
				map((response: any) => {
					if (!!response.success) {
						this.loaderSvc.removeLoader(loader);
						this.toastSvc.success(this.pageLanguage.page.submitSuccess);
						if (
							!!this._storageSvc.getTempStorage(
								TEMP_KEYS.IS_FIRST_SELF_ASSESSMENT,
							)
						) {
							this.assessmentComplete = true;
						}
						if (!this._onboardingSvc.isRunning) {
							this.router.navigate(['/member/dashboard/reports']);
						}
					}
				}).bind(this),
			)
			.subscribe();
	}

	private _beforeLogout: LogoutFunction = () => {
		this._storageSvc.setTempStorage(TEMP_KEYS.IS_FIRST_SELF_ASSESSMENT, null);
		this._subscriptions.unsubscribe();
	};

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();

		// Unbind the logout service.
		this._logoutSvc.unsubscribe(this._logoutKey);
	}
}
