import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { LanguageService } from '@services/public';

const PAGE_PATH: string = 'components.grid';

@Component({
	selector: 'app-jkp-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
	isDesc: boolean = true;
	column: string = '';
	table_header_highlight: any = '';
	@Input() tableData: any = [];
	@Input() headerData: any = [];
	@Input() totalItems: any;
	@ViewChild('dataTableHeader') public dataTableHeader: ElementRef;
	@ViewChild('dataTableBody') public dataTableBody: ElementRef;
	@Output() onRowClicked = new EventEmitter<any>();
	@Output() onpaginationClicked = new EventEmitter<any>();
	@Output() sortByColumn = new EventEmitter<any>();
	@Input() p: any;

	// Page langauge.
	page: { [key: string]: string } = {
		noRecord: '',
	};

	constructor(private _languageSvc: LanguageService) {}

	ngOnInit() {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
	}

	sort(property) {
		this.table_header_highlight = property;
		this.isDesc = !this.isDesc;
		let sortObj: any = {};
		if (this.isDesc) {
			sortObj.sortOrder = 'ASC';
		} else {
			sortObj.sortOrder = 'DESC';
		}
		sortObj.sortColumn = property;
		this.column = property;
		this.sortByColumn.emit(sortObj);
	}

	parallelScroll($event) {
		const ele = $event.srcElement;
		// Adjust for scrolling: width
		const currentLeftOffset = ele.scrollLeft;
		// Adjust for scrolling: height
		const clientHeight = ele.clientHeight;
		const maxHeight = ele.scrollHeight;
		if (clientHeight < maxHeight) {
			// Account for horizontal scrollbar @17px when scrollbars are active
			this.dataTableHeader.nativeElement.style.paddingRight = '17px';
		}
		this.syncDataTableHeader(currentLeftOffset);
	}

	syncDataTableHeader(offset) {
		this.dataTableHeader.nativeElement.scrollLeft = offset;
	}

	rowClicked(row, action) {
		this.onRowClicked.emit({ row, action });
	}

	handlePageChange(event: any): void {
		this.onpaginationClicked.emit(event);
		this.p = event;
	}
}
