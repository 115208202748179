import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { JOURNAL_PAGE, JournalData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.journal.pages.yesterday';

@Component({
	selector: 'app-member-skill-builder-journal-yesterday',
	templateUrl: './yesterday.component.html',
	styleUrls: ['./yesterday.component.scss'],
})
export class MemberSkillBuilderJournalYesterdayComponent implements OnInit {
	@Input() data: JournalData;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
		yesterday: '',
		today: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<void>> = [];
			for (const key in this.page) {
				promises.push(
					this._languageSvc.template(this.page[key]).then((value) => {
						this.page[key] = value;
					}),
				);
			}
			Promise.all(promises).then((_value) => this._languageLoaded.resolve());
		});

		await this._languageLoaded;

		// Get the options list.
		this.options.next([
			{
				display: this.page.yesterday,
				value: true,
			},
			{
				display: this.page.today,
				value: false,
			},
		]);
	}

	nextOnClick(option: SelectListOption): void {
		this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, {
			...this.data,
			page: JOURNAL_PAGE.HIGHLIGHT,
			yesterday: option.value,
		});
	}
}
