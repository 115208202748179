import { LocaleData } from 'ngx-bootstrap/chronos';
import { OrdinalDateFn } from 'ngx-bootstrap/chronos/locale/locale.class';

const amOrdinal: OrdinalDateFn = (number: number) => {
	// Example implementation; Amharic may not use traditional ordinal suffixes
	// Customize as needed
	return `${number}`; // Just return the number as a string
};

export const amLocale: LocaleData = {
	months: [
		'ጃንዩወሪ',
		'ፌብሩወሪ',
		'ማርች',
		'ኤፕሪል',
		'ሜይ',
		'ጁን',
		'ጁላይ',
		'ኦግስት',
		'ሴፕቴምበር',
		'ኦክቶበር',
		'ኖቬምበር',
		'ዲሴምበር',
	],
	monthsShort: [
		'ጃን',
		'ፌብ',
		'ማር',
		'ኤፕ',
		'ሜይ',
		'ጁን',
		'ጁላይ',
		'ኦግስት',
		'ሴፕ',
		'ኦክቶ',
		'ኖቬ',
		'ዲሴም',
	],
	weekdays: ['እሑድ', 'ሰኞ', 'ማክሰኞ', 'ረቡዕ', 'ሐሙስ', 'አርብ', 'ቅዳሜ'],
	weekdaysShort: ['እሑድ', 'ሰኞ', 'ማክ', 'ረቡዕ', 'ሐሙስ', 'አርብ', 'ቅዳሜ'],
	weekdaysMin: ['እሑድ', 'ሰኞ', 'ማክ', 'ረቡዕ', 'ሐሙስ', 'አርብ', 'ቅዳሜ'],
	longDateFormat: {
		L: 'MM/DD/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[ዛሬ] LT',
		nextDay: '[ነገ] LT',
		nextWeek: 'dddd [በሰአት] LT',
		lastDay: '[ከመቶቹት] LT',
		lastWeek: '[ባለፉት] dddd [በሰአት] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s ውስጥ',
		past: '%s በፊት',
		s: 'ጊዜ ጥቂት',
		m: 'አንድ ደቂቀት',
		mm: '%d ደቂቀት',
		h: 'አንድ ሰዓት',
		hh: '%d ሰዓት',
		d: 'አንድ ቀን',
		dd: '%d ቀን',
		M: 'አንድ ወር',
		MM: '%d ወር',
		y: 'አንድ እስከ',
		yy: '%d እስከ',
	},
	ordinal: amOrdinal, // Use the custom ordinal function
	week: {
		dow: 1, // Week starts on Monday
		doy: 4, // Week 1 is the week with January 4th
	},
};
