<div #languageDropdown class="language">
	<span class="globe"></span>
	<select
		id="languages"
		[(ngModel)]="languageFilter.value"
		(change)="onLanguageSelect()"
	>
		<option
			*ngFor="let language of languageFilter.options"
			[ngValue]="language"
		>
			{{ language.display }}
		</option>
	</select>
</div>
