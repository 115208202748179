<div
	*ngIf="display"
	class="main-container w-100 d-flex align-items-center justify-content-center"
>
	<div class="container-fluid pb-5-sm-c pb-5-md-c">
		<div class="col-12 px-0">
			<div class="col-12 d-flex justify-content-center align-items-center px-0">
				<div class="col-sm-12 col-lg-6 px-0">
					<div class="col-12 px-0">
						<app-skill-builder-pie-chart
							[title]="page.pieTitle"
							[data]="pieData"
							class="w-100 mb-3"
						></app-skill-builder-pie-chart>
					</div>

					<div class="col-12 px-0">
						<div class="card card-rounded">
							<div class="card-body pt-3 d-flex align-items-center">
								<div class="col-12 d-flex align-items-center px-0">
									<div
										class="count-box d-flex align-items-center justify-content-center"
									>
										{{ this.adventures }}
									</div>
									<div class="analysis-txt">
										{{ page.adventures }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
