// Bambara
import { LocaleData } from 'ngx-bootstrap/chronos';

export const bmLocale: LocaleData = {
	months: [
		'Zanwuye',
		'Fewuraye',
		'Marsi',
		'Awirili',
		'Mè',
		'Zuwɛn',
		'Zuluyɛ',
		'Ut',
		'Sɛtanburu',
		'ɔkutɔburu',
		'Nowanburu',
		'Desanburu',
	],
	monthsShort: [
		'Zan',
		'Few',
		'Mar',
		'Awi',
		'Mè',
		'Zuw',
		'Zul',
		'Ut',
		'Sɛt',
		'ɔkt',
		'Now',
		'Des',
	],
	weekdays: ['Kari', 'Ntɛnɛ', 'Tarata', 'Araba', 'Alamisa', 'Juma', 'Sibiri'],
	weekdaysShort: ['Kar', 'Ntɛ', 'Tar', 'Ara', 'Ala', 'Jum', 'Sib'],
	weekdaysMin: ['Ka', 'Nt', 'Ta', 'Ar', 'Al', 'Ju', 'Si'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Bi] LT',
		nextDay: '[Sini] LT',
		nextWeek: 'dddd [don] LT',
		lastDay: '[Kunu] LT',
		lastWeek: '[Den] dddd [don] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s kɔfɛ',
		past: '%s bɔ',
		s: 'sekondi few',
		m: 'miniti kelen',
		mm: '%d miniti',
		h: 'lɛrɛ kelen',
		hh: '%d lɛrɛ',
		d: 'tile kelen',
		dd: '%d tile',
		M: 'kalo kelen',
		MM: '%d kalo',
		y: 'san kelen',
		yy: '%d san',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
