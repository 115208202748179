<div
	class="main-container w-100 d-flex align-items-center justify-content-center pb-lg"
>
	<div class="container px-0 w-sm-100 w-50">
		<div
			class="d-flex justify-content-center small text-center my-3 sm-center-txt"
		>
			{{ page.title }}
		</div>

		<div class="row">
			<div class="col-12 heading-1">
				<div class="col-12">
					<form [formGroup]="form">
						<div *ngFor="let i of actionKeys" formGroupName="actions">
							<div class="row" formGroupName="{{ i }}">
								<div class="col-sm-12 col-lg-12 col-xl-6 mt-3 mt-lg-0">
									<div class="card card-white card-white-md">
										<div class="card-body px-2">
											<h5>
												{{ page.positiveOutcome[i] }}
											</h5>
											<div class="d-flex flex-column">
												<ngx-star-rating
													formControlName="positiveImpact"
													id="positiveImpact{{ i }}"
												>
												</ngx-star-rating>
												<div class="col-12 px-3">
													<div class="dot-sm"></div>
													<div class="dotted-line">
														<i class="fas fa-chevron-right right-arrow"></i>
													</div>
													<div class="col-12 rating-label rating-label-3">
														<div class="lft">{{ page.noImpact }}</div>
														<div class="rgt">{{ page.positiveImpact }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										*ngIf="
											submitted &&
											!!getActionControls(i).positiveImpact.errors?.required
										"
										class="invalid-feedback"
									>
										{{ page.error1 }}
									</div>
								</div>
								<div class="col-sm-12 col-lg-12 col-xl-6 mt-3 mt-lg-0">
									<div class="card card-white card-white-md">
										<div class="card-body px-2">
											<h5>
												{{ page.negativeOutcome[i] }}
											</h5>
											<div class="d-flex flex-column">
												<ngx-star-rating
													formControlName="negativeImpact"
													id="negativeImpact{{ i }}"
												>
												</ngx-star-rating>
												<div class="col-12 px-3">
													<div class="dot-sm positive"></div>
													<div class="dotted-line">
														<i class="fas fa-chevron-right right-arrow neutral">
														</i>
													</div>
													<div class="col-12 rating-label rating-label-3">
														<div class="lft">{{ page.noImpact }}</div>
														<div class="rgt">{{ page.negativeImpact }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										*ngIf="
											submitted &&
											!!getActionControls(i).negativeImpact.errors?.required
										"
										class="invalid-feedback"
									>
										{{ page.error1 }}
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="footer-inner-action">
			<button
				type="button"
				(click)="nextOnClick()"
				class="btn btn-primary btn-lg px-4"
			>
				{{ page.next }}
			</button>
		</div>
	</div>
</div>
