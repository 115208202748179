import { Component, OnInit } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

import { GroupService } from '@services/member';
import { LoaderService, LanguageService } from '@services/public';

@Component({
	selector: 'app-member-group-add',
	templateUrl: './add.component.html',
	styleUrls: ['./add.component.scss'],
})
export class MemberGroupAddComponent implements OnInit {
	form: UntypedFormGroup;
	pageLanguage: any;
	labels: any;
	path: string = `pages.member.group.add`;

	constructor(
		private groupSvc: GroupService,
		private modalSvc: BsModalService,
		private toastSvc: ToastrService,
		private loaderSvc: LoaderService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});

		this.form = new UntypedFormGroup({
			groupCode: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(40),
			]),
		});
	}

	get f(): any {
		return this.form.controls;
	}

	public onSubmit(): void {
		// Validate form.
		this.form.markAllAsTouched();
		if (this.form.invalid) return;
		// Register group.
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.groupSvc
			.registerGroup(this.form.value.groupCode)
			.pipe(
				map((response: any) => {
					if (!!response.success) {
						this.loaderSvc.removeLoader(loader);
						this.closeModal();
						this.toastSvc.success('Registered to the group successfully');
						this.groupSvc.loadGroups();
					}
				}).bind(this),
			)
			.subscribe();
	}

	closeModal(): void {
		this.modalSvc.hide();
	}
}
