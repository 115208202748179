<!-- Skill builder header -->
<!-- TODO: Refactor SCSS -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
				<span class="cursor-pointer txt-md">
					<i class="fas fa-arrow-left" (click)="back()"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
			</div>
		</div>
	</div>
</div>

<!-- Skill builder start flow -->
<ng-container *ngIf="data?.page === GOAL_PAGE.DOMAIN">
	<app-member-skill-builder-goal-domain [data]="data">
	</app-member-skill-builder-goal-domain>
</ng-container>
<ng-container *ngIf="data?.page === GOAL_PAGE.SKILL">
	<app-member-skill-builder-goal-skill [data]="data">
	</app-member-skill-builder-goal-skill>
</ng-container>
<ng-container *ngIf="data?.page === GOAL_PAGE.ACTION">
	<app-member-skill-builder-goal-action [data]="data">
	</app-member-skill-builder-goal-action>
</ng-container>
<ng-container *ngIf="data?.page === GOAL_PAGE.CUSTOM_ACTION">
	<app-member-skill-builder-goal-custom-action [data]="data">
	</app-member-skill-builder-goal-custom-action>
</ng-container>

<!-- Skill builder track flow -->
<ng-container *ngIf="data?.page === GOAL_PAGE.YESTERDAY">
	<app-member-skill-builder-goal-yesterday [data]="data">
	</app-member-skill-builder-goal-yesterday>
</ng-container>
<ng-container *ngIf="data?.page === GOAL_PAGE.TRACK">
	<app-member-skill-builder-goal-track [data]="data">
	</app-member-skill-builder-goal-track>
</ng-container>

<ng-template #dailyCompletedTemplate>
	<div class="modal-body modal-center">
		<p class="mt-3 text-center success-msg px-5 title-main">
			{{ page.reminder }}
		</p>
		<div class="pt-4 d-flex align-items-center justify-content-center">
			<button
				(click)="closePopup()"
				type="button"
				class="btn btn-md btn-bordered-purple"
			>
				{{ page.ok }}
			</button>
		</div>
	</div>
</ng-template>
