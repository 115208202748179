import { SKILL_BUILDER_TYPES, SkillBuilderData } from '@services/member';

export enum WWYD_PAGE {
	DILEMMA = 'DILEMMA',
	CUSTOM_DILEMMA = 'CUSTOM_DILEMMA',
	FIRST_CHOICE = 'FIRST_CHOICE',
	LIST_ACTIONS = 'LIST_ACTIONS',
	ETHICS = 'ETHICS',
	BENEFITS = 'BENEFITS',
	CHOOSE_ACTION = 'CHOOSE_ACTION',
	FINAL_CHOICE = 'FINAL_CHOICE',
}

export const WWYD_PREVIOUS_PAGE = {};

// Default start flow.
WWYD_PREVIOUS_PAGE[WWYD_PAGE.DILEMMA] = null;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.CUSTOM_DILEMMA] = WWYD_PAGE.DILEMMA;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.FIRST_CHOICE] = WWYD_PAGE.DILEMMA;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.LIST_ACTIONS] = WWYD_PAGE.FIRST_CHOICE;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.ETHICS] = WWYD_PAGE.LIST_ACTIONS;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.BENEFITS] = WWYD_PAGE.ETHICS;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.CHOOSE_ACTION] = WWYD_PAGE.BENEFITS;
WWYD_PREVIOUS_PAGE[WWYD_PAGE.FINAL_CHOICE] = WWYD_PAGE.CHOOSE_ACTION;

export enum WWYD_ETHICAL {
	VERY_ETHICAL = 'very-ethical',
	SOMEWHAT_ETHICAL = 'somewhat-ethical',
	SOMEWHAT_UNETHICAL = 'somewhat-unethical',
	VERY_UNETHICAL = 'very-unethical',
}

export enum WWYD_BENEFICIAL {
	VERY_BENEFICIAL = 'very-beneficial',
	SOMEWHAT_BENEFICIAL = 'somewhat-beneficial',
	SOMEWHAT_DETRIMENTAL = 'somewhat-detrimental',
	VERY_DETRIMENTAL = 'very-detrimental',
}

export interface WWYDDilemma {
	display: string;
	description: string;
	action?: string;
	custom?: boolean;
}

export interface WWYDAction {
	display: string;
	ethical?: WWYD_ETHICAL;
	beneficial?: WWYD_BENEFICIAL;
}

export interface WWYDData extends SkillBuilderData {
	type: SKILL_BUILDER_TYPES.WWYD;
	page?: WWYD_PAGE;
	dilemma?: WWYDDilemma;
	firstChoice?: boolean;
	actions?: Array<WWYDAction>;
	chosenAction?: string;
	finalChoice?: boolean;
}

export interface WWYDReportData {
	totalDilemmas: number;
}
