import { Component, Input, OnInit } from '@angular/core';
import { MESSAGE_PAGE, MessageData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { TextListNext, TextListTextInput } from '@components';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.message.pages.values';

@Component({
	selector: 'app-member-skill-builder-message-values',
	templateUrl: './values.component.html',
	styleUrls: ['./values.component.scss'],
})
export class MemberSkillBuilderMessageValuesComponent implements OnInit {
	@Input() data: MessageData;
	values: Array<TextListTextInput> = [
		{ placeholder: '' },
		{ placeholder: '' },
		{ placeholder: '' },
	];
	subTitle: string;
	next: TextListNext = { onClick: this.nextOnClick.bind(this) };
	loaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		placeholder: '',
		error: '',
	};

	constructor(
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Load message data.
		const values: Array<string> = <Array<string>>this.data?.values;
		if (!!values)
			for (let i = 0; i < values.length; i++) this.values[i].value = values[i];

		this.subTitle = `<span class="font-lg txt-green">${this.data.name}</span>`;

		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<void>> = [];
			for (const key in this.page) {
				promises.push(
					this._languageSvc.template(this.page[key]).then((value) => {
						this.page[key] = value;
						if (key === 'placeholder') {
							this.values = this.values.map((item) => ({
								...item,
								placeholder: value,
							}));
						}
					}),
				);
			}
			Promise.all(promises).then((_value) => this.loaded.resolve());
		});
	}

	nextOnClick(values: Array<string>): void {
		this._storageSvc.setTempStorage(TEMP_KEYS.MESSAGE, {
			...this.data,
			page: MESSAGE_PAGE.POSITIVE_MESSAGE,
			values: values.map((value) => {
				return value;
			}),
		});
	}
}
