<div class="leaderboard">
	<div class="header">
		<div class="title">
			{{ page.title }}
		</div>
		<select
			class="organization-select"
			[(ngModel)]="organizationFilter.value"
			(change)="organizationOnSelect()"
		>
			<option
				*ngFor="let organization of organizationFilter.options"
				[ngValue]="organization"
			>
				{{ organization.name }}
			</option>
		</select>
	</div>
	<div class="top-three">
		<div *ngFor="let card of leaderboard | slice: 0 : 3" [ngClass]="card.class">
			<div class="top-three-rank-avatar">
				<span class="top-three-rank">{{ card.rank }}</span>
				<div
					class="top-three-avatar"
					[ngStyle]="getAvatarStyle(card.avatar, card.size, card.userUUID)"
					alt="user icon"
				></div>
			</div>
			<div class="top-three-name-points-container">
				<div class="top-three-name-points">
					<div class="top-three-name">
						{{ card.name || card.genericName }}
					</div>
					<div class="top-three-points">
						{{ card.points }}{{ page.points | lowercase | slice: 0 : 1 }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bottom-five">
		<div *ngFor="let card of leaderboard | slice: 3">
			<div *ngIf="card.rank.length > 1 || card.rank === '9'" class="pinline">
				<span>
					<i> &#xf103; </i>
				</span>
			</div>
			<div [ngClass]="card.class">
				<div class="bottom-five-rank-container">
					<div class="bottom-five-rank">{{ card.rank }}</div>
				</div>
				<div class="bottom-five-avatar-name-stats">
					<div
						class="bottom-five-avatar"
						[ngStyle]="getAvatarStyle(card.avatar)"
						alt="user icon"
					></div>
					<div class="bottom-five-name-stats">
						<div class="bottom-five-name">
							{{ card.name || card.genericName }}
						</div>
						<div class="bottom-five-stats">
							{{ page.streakDays }}: <span>{{ card.streak }}</span>
						</div>
						<div class="bottom-five-stats">
							{{ page.averageScore }}: <span>{{ card.score }}%</span>
						</div>
					</div>
				</div>
				<div class="bottom-five-points">
					{{ card.points }}{{ page.points | lowercase | slice: 0 : 1 }}
				</div>
			</div>
		</div>
	</div>
</div>
