import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	LeaderboardCard,
	Organization,
	OrganizationService,
	RANK_DELTAS,
	RewardsService,
} from '@services/member';
import { LanguageService } from '@services/public';
import {
	APIErrors,
	AVATARS,
	Filter,
	getAvatarCoordinates,
	isAPIErrors,
} from '@util';
import { ExposedPromise } from 'jakapa-utilities';
import { Subscription } from 'rxjs';

const PAGE_PATH: string = 'components.leaderboard.page';
const ALL_JAKAPA_USERS: Organization = {
	uuid: null,
	name: '',
};
export const BLANK_USER: LeaderboardUser = {
	uuid: null,
	name: '',
	avatar: AVATARS.AVATAR1,
	rank: '0',
	delta: RANK_DELTAS.SAME,
	points: '0',
};

export interface LeaderboardUser {
	uuid: string;
	name: string;
	avatar: AVATARS;
	rank: string;
	delta: RANK_DELTAS;
	points: string;
}

@Component({
	selector: 'app-leaderboard',
	templateUrl: './leaderboard.component.html',
	styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnInit, OnDestroy {
	@Input() user: LeaderboardUser;

	organizationFilter: Filter<Organization> = {
		key: 'organization',
		value: ALL_JAKAPA_USERS,
		options: [ALL_JAKAPA_USERS],
	};
	leaderboard: Array<LeaderboardCard> = [];

	private _subscriptions: Subscription = new Subscription();
	private _organizationsLoaded: ExposedPromise<void> =
		new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		allJakapaUsers: '',
		streakDays: '',
		averageScore: '',
		points: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _organizationSvc: OrganizationService,
		private _rewardsSvc: RewardsService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				switch (key) {
					case 'allJakapaUsers':
						this._languageSvc.template(this.page[key]).then((value) => {
							this.page[key] = value;
							ALL_JAKAPA_USERS.name = value;
						});
						break;
					default:
						this._languageSvc
							.template(this.page[key])
							.then((value) => (this.page[key] = value));
				}
			}
		});

		// Get organizations.
		this._subscriptions.add(
			this._organizationSvc.organizations.subscribe((organizations) => {
				this.organizationFilter.options = [ALL_JAKAPA_USERS, ...organizations];
				this._organizationsLoaded.resolve();
			}),
		);

		// Load the leaderboard for all JAKAPA users.
		this.organizationOnSelect();
	}

	organizationOnSelect(): void {
		this._rewardsSvc
			.loadLeaderboardData(this.organizationFilter.value.uuid, this.user.uuid)
			.subscribe((response: Array<LeaderboardCard> | APIErrors) => {
				if (isAPIErrors(response)) return;

				// Sort response by rank.
				response.sort((a, b) => {
					const aRank = BigInt(a.rank);
					const bRank = BigInt(b.rank);
					if (aRank < bRank) return -1;
					if (aRank > bRank) return 1;
					return 0;
				});

				for (let i: number = 0; i < response.length; i++) {
					// Convert score to an integer.
					response[i].score = parseInt(response[i].score).toString();

					// Add card classes.
					if (i < 3) {
						response[i].class = `top-three-card ${response[i].rankDelta}`;
					} else {
						response[i].class = `bottom-five-card ${response[i].rankDelta}`;
					}

					// Edit properties for current user.
					if (this.user.uuid === response[i].userUUID) {
						response[i].name = this.user.name;
						response[i].avatar = this.user.avatar;
						response[i].class += ' self';
						this.user.rank = response[i].rank;
						this.user.delta = response[i].rankDelta;
						this.user.points = response[i].points;
					}
				}

				// Create ADA friendly top results where the middle item is still read
				// first when using a screenreader
				if (!!response[0]) {
					response[0].class += ' middle-user';
					response[0].size = 80;
				}
				if (!!response[1]) response[1].class += ' left-user';
				this.leaderboard = response;
			});
	}

	getAvatarStyle(avatar: AVATARS, size?: number, uuid?: string) {
		if (!avatar) avatar = AVATARS.AVATAR1;
		let { x, y } = getAvatarCoordinates(avatar, size);

		// Adjust for self border in top three.
		if (uuid === this.user.uuid) {
			x += 4;
			y += 4;
		}
		return { 'background-position': `${-x}px ${-y}px` };
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}
}
