import { Component, Input, OnInit } from '@angular/core';

export interface PieChartData {
	name: string;
	value: number;
	label: string;
}

@Component({
	selector: 'app-skill-builder-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss'],
})
export class SkillBuilderPieChartComponent implements OnInit {
	@Input() title: string;
	@Input() chartTitle: string = '';
	@Input() data: Array<PieChartData>;

	constructor() {}

	ngOnInit(): void {}

	pieChartLabel(name: string): string {
		const item = this.data.find((data) => data.name === name);
		if (!item) return name;
		return item.label;
	}
}
