import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'environment';

export interface User {
	uuid: string;
	name: string;
}

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	constructor(private http: HttpClient) {}

	createUser(reqBody) {
		return this.http.post<any>(
			`${config.apiBase}leader/management/user/add`,
			reqBody,
		);
	}

	bulkUploadUsers(reqBody) {
		return this.http.post<any>(
			`${config.apiBase}leader/management/user/bulk`,
			reqBody,
		);
	}

	acceptUserGroupRequest(requredObj) {
		return this.http.post<any>(
			`${config.apiBase}leader/management/group/request/accept`,
			requredObj,
		);
	}

	declineUserGroupRequest(userUUID) {
		return this.http.delete<any>(
			`${config.apiBase}leader/management/group/request/decline`,
			{
				body: { uuid: userUUID },
			},
		);
	}

	deleteUserGroupRequest(userUUID) {
		return this.http.delete<any>(
			`${config.apiBase}leader/management/group/request/delete`,
			{
				body: { uuid: userUUID },
			},
		);
	}

	deleteUserRequest(userUUID) {
		return this.http.delete<any>(
			`${config.apiBase}leader/management/user/request/delete`,
			{
				body: { uuid: userUUID },
			},
		);
	}
}
