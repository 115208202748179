<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-6 space-between">
				<span class="cursor-pointer txt-md">
					<i (click)="navigation()" class="fas fa-arrow-left"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{
					this.pageLanguage?.profile
				}}</span>
				<button
					type="button"
					(click)="navigation()"
					class="close d-none d-blcok-sm"
					[attr.aria-label]="this.pageLanguage?.close"
				>
					<div aria-hidden="true" class="icon-md">&times;</div>
				</button>
			</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-center plain-rounded-card px-4">
	<div class="card card-white col-md-12 col-lg-6 mt-4 mb-lg">
		<div class="card-body align-items-start">
			<div class="col-12 title-holder">
				<h3 class="title-main">{{ this.pageLanguage?.updateInfo }}</h3>
				<div #appleButtonHolder class="circle-md circle-border">
					<div
						#appleButton
						class="apple-connect"
						(click)="linkApple()"
						alt="connect apple account"
					></div>
				</div>
			</div>
			<div class="w-100">
				<form [formGroup]="form" (ngSubmit)="updateProfile()">
					<div class="formInner">
						<div class="row formInnerCol">
							<div class="flex-column col-6">
								<label>{{ this.pageLanguage?.firstName }}</label>
								<div class="input-group">
									<input
										(keypress)="keyPressOnlyAlphabets($event)"
										formControlName="firstName"
										type="text"
										class="form-control"
										placeholder="{{ this.pageLanguage?.firstName }}"
										[ngClass]="{
											'danger-class': f.firstName.errors && f.firstName.touched,
										}"
									/>
									<div
										*ngIf="f.firstName.errors && f.firstName.touched"
										class="invalid-feedback"
									>
										<div
											*ngIf="
												f.firstName.errors.required ||
												f.firstName.errors?.['whitespace']
											"
										>
											{{ this.pageLanguage?.firstNameRequired }}
										</div>
										<div
											*ngIf="
												!f.firstName.errors.required &&
												f.firstName.errors.maxlength?.actualLength > 40
											"
										>
											{{ this.pageLanguage?.firstNameLength }}
										</div>
									</div>
								</div>
							</div>

							<div class="flex-column col-6">
								<label>{{ this.pageLanguage?.lastName }}</label>
								<div class="input-group">
									<input
										(keypress)="keyPressOnlyAlphabets($event)"
										formControlName="lastName"
										type="text"
										class="form-control"
										placeholder="{{ this.pageLanguage?.lastName }}"
										[ngClass]="{
											'danger-class': f.lastName.errors && f.lastName.touched,
										}"
									/>
									<div
										*ngIf="f.lastName.errors && f.lastName.touched"
										class="invalid-feedback"
									>
										<div
											*ngIf="
												f.lastName.errors.required ||
												f.lastName.errors?.['whitespace']
											"
										>
											{{ this.pageLanguage?.lastNameRequired }}
										</div>
										<div
											*ngIf="
												!f.lastName.errors.required &&
												f.lastName.errors.maxlength?.actualLength > 40
											"
										>
											{{ this.pageLanguage?.lastNameRequired }}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="flex-column formInnerCol">
							<label>{{ this.pageLanguage?.email }}</label>
							<div class="input-group">
								<span class="credIcons">
									<input
										formControlName="email"
										type="email"
										maxlength="320"
										name="email"
										class="form-control"
										aria-describedby="emailHelp"
										[ngClass]="{
											'danger-class': f.email.errors && f.email.touched,
										}"
										placeholder="{{ this.pageLanguage?.emailAddress }}"
									/>

									<app-change-email
										(changePrimaryEmail)="changePrimaryEmail($event)"
									></app-change-email>
								</span>
								<div
									*ngIf="f.email.errors && f.email.touched"
									class="invalid-feedback"
								>
									<div *ngIf="f.email.errors.required">
										{{ this.pageLanguage?.emailRequired }}
									</div>
									<div *ngIf="f.email.errors.pattern">
										{{ this.pageLanguage?.emailInvalid }}
									</div>
								</div>
							</div>
						</div>

						<div class="flex-column formInnerCol">
							<label>{{ this.pageLanguage?.currentPass }}</label>
							<div class="input-group">
								<span class="credIcons">
									<input
										formControlName="currentPassword"
										(change)="validateForm()"
										type="{{ hideCurrentPassword ? 'password' : 'text' }}"
										maxlength="32"
										[ngClass]="{
											'danger-class':
												f.currentPassword.errors && f.currentPassword.touched,
										}"
										name="password"
										class="form-control"
										placeholder="{{ this.pageLanguage?.currentPass }}"
									/>
									<button
										type="button"
										(click)="hideCurrentPassword = !hideCurrentPassword"
									>
										<i
											class="fas {{
												hideCurrentPassword ? 'fa-eye-slash' : 'fa-eye'
											}}"
										></i>
									</button>
								</span>
								<div
									*ngIf="f.currentPassword.errors && f.currentPassword.touched"
									class="invalid-feedback"
								>
									<div *ngIf="f.currentPassword.errors.required">
										{{ this.pageLanguage?.currentPassRequired }}
									</div>
								</div>
							</div>
						</div>

						<div class="flex-column formInnerCol">
							<label>{{ this.pageLanguage?.newPass }}</label>
							<span
								class="pl-2"
								type="button"
								[ngbTooltip]="popTemplate"
								tooltipClass="my-custom-class"
								placement="bottom"
								triggers="click"
							>
								<img src="./../../../../assets/img/Info-icon.svg" />
							</span>
							<div class="input-group">
								<span class="credIcons">
									<input
										formControlName="newPassword"
										(change)="validateForm()"
										type="{{ hideNewPassword ? 'password' : 'text' }}"
										maxlength="32"
										[ngClass]="{
											'danger-class':
												f.newPassword.errors && f.newPassword.touched,
										}"
										name="password"
										class="form-control"
										placeholder="{{ this.pageLanguage?.newPass }}"
									/>
									<button
										type="button"
										(click)="hideNewPassword = !hideNewPassword"
									>
										<i
											class="fas {{
												hideNewPassword ? 'fa-eye-slash' : 'fa-eye'
											}}"
										></i>
									</button>
								</span>
								<div
									*ngIf="f.newPassword.errors && f.newPassword.touched"
									class="invalid-feedback"
								>
									<div *ngIf="f.newPassword.errors.required">
										{{ this.pageLanguage?.passRequired }}
									</div>
									<div *ngIf="f.newPassword.errors.pattern">
										{{ this.pageLanguage?.passRequirements }}
									</div>
								</div>
							</div>
						</div>

						<div class="flex-column formInnerCol">
							<label class="mb-1">{{ this.pageLanguage?.confirmPass }}</label>
							<div class="input-group">
								<span class="credIcons">
									<input
										formControlName="confirmPassword"
										(change)="validateForm()"
										type="{{ hideConfirmPassword ? 'password' : 'text' }}"
										maxlength="32"
										[ngClass]="{
											'danger-class':
												f.confirmPassword.errors && f.confirmPassword.touched,
										}"
										name="password"
										class="form-control"
										placeholder="{{ this.pageLanguage?.confirmPass }}"
									/>
									<button
										type="button"
										(click)="hideConfirmPassword = !hideConfirmPassword"
									>
										<i
											class="fas {{
												hideConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
											}}"
										></i>
									</button>
								</span>
								<div
									*ngIf="f.confirmPassword.errors && f.confirmPassword.touched"
									class="invalid-feedback"
								>
									<div *ngIf="f.confirmPassword.errors.required">
										{{ this.pageLanguage?.confirmPassRequired }}
									</div>
								</div>
							</div>
							<div *ngIf="f.newPassword.value !== f.confirmPassword.value">
								<small class="text-danger">{{
									this.pageLanguage?.passMisMatch
								}}</small>
							</div>
						</div>
					</div>
					<div class="registerBtn footer-inner-action btn-c">
						<button
							[disabled]="!form.dirty || !form.valid"
							class="btn btn-primary"
						>
							{{ this.pageLanguage?.update }}
						</button>
						<div class="col-12 delete-acc">
							<a
								(click)="openModal(template)"
								role="button"
								class="del-acc-link button-style"
								>{{ this.pageLanguage?.deleteAccount }}</a
							>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<ng-template #popTemplate>
	<ul class="list-unstyled text-left">
		<li>
			<strong>{{ this.pageLanguage?.passRequirements }}</strong>
		</li>
		<ul class="text-left">
			<li>{{ this.pageLanguage?.passLength }}</li>
			<li>{{ this.pageLanguage?.lowercaseRequired }}</li>
			<li>{{ this.pageLanguage?.uppercaseRequired }}</li>
			<li>{{ this.pageLanguage?.numberRequired }}</li>
			<li>{{ this.pageLanguage?.specialRequired }}</li>
		</ul>
	</ul>
</ng-template>
<ng-template #template>
	<div class="modal-body text-center modal-confirm">
		<div class="modal-body">
			<div class="msg-wrapper">
				<div>
					<img src="assets/img/new_delete.svg" alt="new_delete.svg" />
				</div>
				<p class="mt-3 text-center delete-msg">
					{{ this.pageLanguage?.confirmAcctDelete }}
				</p>
			</div>
		</div>
		<div class="pt-4 border-top">
			<button (click)="decline()" type="button" class="btn btn-md ml-3">
				{{ this.pageLanguage?.cancel }}
			</button>
			<button
				(click)="deleteProfile()"
				type="button"
				class="btn btn-md btn-danger"
			>
				{{ this.pageLanguage?.delete }}
			</button>
		</div>
	</div>
</ng-template>
