// Tigrinya
import { LocaleData } from 'ngx-bootstrap/chronos';

export const tiLocale: LocaleData = {
	months: [
		'ጃንዋሪ',
		'ፌብሩወሪ',
		'ማርች',
		'ኤፕሪል',
		'ሜይ',
		'ጁን',
		'ጁላይ',
		'ኦገስት',
		'ሴፕቴምበር',
		'ኦክቶበር',
		'ኖቬምበር',
		'ዲሴምበር',
	],
	monthsShort: [
		'ጃን',
		'ፌብ',
		'ማር',
		'ኤፕ',
		'ሜይ',
		'ጁን',
		'ጁላይ',
		'ኦግ',
		'ሴፕ',
		'ኦክት',
		'ኖቬ',
		'ዲሴ',
	],
	weekdays: ['እሑድ', 'ሰኞ', 'ምሽት', 'ከረቡዕ', 'ሐሙስ', 'ዓረብ', 'ቅዳሜ'],
	weekdaysShort: ['እሑድ', 'ሰኞ', 'ምሽ', 'ከረ', 'ሐሙ', 'ዓረ', 'ቅዳ'],
	weekdaysMin: ['እሑ', 'ሰኞ', 'ምሽ', 'ከረ', 'ሐሙ', 'ዓረ', 'ቅዳ'],
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd, D MMMM YYYY HH:mm',
	},
	calendar: {
		sameDay: '[ዛሬ] LT',
		nextDay: '[ነገ] LT',
		nextWeek: 'dddd [ንብረት] LT',
		lastDay: '[ትምህርት] LT',
		lastWeek: '[ቅድሚ] dddd [ንብረት] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s ውስጥ',
		past: '%s በፊት',
		s: 'ጥቂት ሰከንድ',
		ss: '%d ሰከንድ',
		m: 'አንድ ደቂቀ ጊዜ',
		mm: '%d ደቂቀ ጊዜ',
		h: 'አንድ ሰዓት',
		hh: '%d ሰዓት',
		d: 'አንድ ቀን',
		dd: '%d ቀን',
		M: 'አንድ ወር',
		MM: '%d ወር',
		y: 'አንድ ዓመት',
		yy: '%d ዓመት',
	},
	ordinal: (num: number): string => `${num}ኛ`,
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
};
