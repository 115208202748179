// Odia (Oriya)
import { LocaleData } from 'ngx-bootstrap/chronos';

export const orLocale: LocaleData = {
	months: [
		'ଜାନୁଆରୀ',
		'ଫେବୃଆରୀ',
		'ମାର୍ଚ୍ଚ',
		'ଅପ୍ରିଲ',
		'ମେ',
		'ଜୁନ',
		'ଜୁଲାଇ',
		'ଅଗଷ୍ଟ',
		'ସେପ୍ଟେମ୍ବର',
		'ଅକ୍ଟୋବର',
		'ନଭେମ୍ବର',
		'ଡିସେମ୍ବର',
	],
	monthsShort: [
		'ଜାନ',
		'ଫେବ',
		'ମାର୍ଚ୍ଚ',
		'ଅପ୍ରିଲ',
		'ମେ',
		'ଜୁନ',
		'ଜୁଲାଇ',
		'ଅଗଷ୍ଟ',
		'ସେପ୍ଟ',
		'ଅକ୍ଟୋ',
		'ନଭେ',
		'ଡିସ',
	],
	weekdays: [
		'ରବିବାର',
		'ସୋମବାର',
		'ମଙ୍ଗଳବାର',
		'ବୁଧବାର',
		'ବିହିବାର',
		'ଶୁକ୍ରବାର',
		'ଶନିବାର',
	],
	weekdaysShort: ['ରବି', 'ସୋମ', 'ମଙ୍ଗଳ', 'ବୁଧ', 'ବିହି', 'ଶୁକ୍ର', 'ଶନି'],
	weekdaysMin: ['ର', 'ସୋ', 'ମ', 'ବୁ', 'ବି', 'ଶୁ', 'ଶ'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[ଆଜି] LT',
		nextDay: '[ଆସନ୍ତାକାଲି] LT',
		nextWeek: 'dddd [ରାତି] LT',
		lastDay: '[କାଲି] LT',
		lastWeek: '[ଗତ] dddd [ରାତି] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s ମଧ୍ୟରେ',
		past: '%s ପୂର୍ବରୁ',
		s: 'କିଛି ସେକେଣ୍ଡ',
		m: 'ଏକ ମିନିଟ୍',
		mm: '%d ମିନିଟ୍',
		h: 'ଏକ ଘଣ୍ଟା',
		hh: '%d ଘଣ୍ଟା',
		d: 'ଏକ ଦିନ',
		dd: '%d ଦିନ',
		M: 'ଏକ ମାସ',
		MM: '%d ମାସ',
		y: 'ଏକ ବର୍ଷ',
		yy: '%d ବର୍ଷ',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 0, // Sunday is the first day of the week
		doy: 6, // The week that contains Jan 6 is the first week of the year
	},
};
