import { Component, Input, OnInit } from '@angular/core';
import {
	CalendarColors,
	CalendarDate,
	CalendarEvent,
	CalendarLegend,
	PieChartData,
} from '@components';
import { JournalService } from '@services/member';
import { LanguageService } from '@services/public';
import { APINoData, isAPINoData } from '@util';
import { JournalAccuracy, JournalReportData } from '../common';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.journal.pages.report';
const SCORE_CALENDAR_COLORS: { [key: string]: CalendarColors } = {
	[JournalAccuracy.NOT_ACCURATE]: CalendarColors.VERY_BAD,
	[JournalAccuracy.MISSING_DETAILS]: CalendarColors.OKAY,
	[JournalAccuracy.ACCURATE]: CalendarColors.GOOD,
};

@Component({
	selector: 'app-member-skill-builder-journal-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderJournalReportComponent implements OnInit {
	@Input() noData: () => void;
	display: boolean = false;
	pieData: Array<PieChartData> = [];
	calendarEvents: Map<CalendarDate, CalendarEvent> = new Map<
		CalendarDate,
		CalendarEvent
	>();
	calendarLegend: Array<CalendarLegend> = [];
	journalsCompleted: number = 0;
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		pieTitle: '',
		accurate: '',
		missingDetails: '',
		notAccurate: '',
		subtext: '',
		journalsCompleted: '',
	};

	constructor(
		private _journalSvc: JournalService,
		private _languageSvc: LanguageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<any>> = [];
			for (const key in this.page)
				promises.push(
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value)),
				);
			Promise.all(promises).then(() => this._languageLoaded.resolve());
		});

		await this._languageLoaded;

		this._journalSvc
			.getReport()
			.subscribe((res: Array<JournalReportData> | APINoData) => {
				if (!isAPINoData(res)) {
					this.display = true;
					const accuracyCounts: { [key: string]: number } = {
						[JournalAccuracy.ACCURATE]: 0,
						[JournalAccuracy.MISSING_DETAILS]: 0,
						[JournalAccuracy.NOT_ACCURATE]: 0,
					};
					for (const log of res) {
						accuracyCounts[log.accuracy]++;
						this.calendarEvents.set(log.date, {
							color: SCORE_CALENDAR_COLORS[log.accuracy],
							summary: log.summary,
						});
					}
					for (const key in accuracyCounts)
						accuracyCounts[key] = Math.round(
							(accuracyCounts[key] / res.length) * 100,
						);
					this.pieData = [
						{
							name: this.page.accurate,
							value: accuracyCounts[JournalAccuracy.ACCURATE],
							label: `${accuracyCounts[JournalAccuracy.ACCURATE]}%`,
						},
						{
							name: this.page.missingDetails,
							value: accuracyCounts[JournalAccuracy.MISSING_DETAILS],
							label: `${accuracyCounts[JournalAccuracy.MISSING_DETAILS]}%`,
						},
						{
							name: this.page.notAccurate,
							value: accuracyCounts[JournalAccuracy.NOT_ACCURATE],
							label: `${accuracyCounts[JournalAccuracy.NOT_ACCURATE]}%`,
						},
					];
					this.calendarLegend = [
						{
							color: CalendarColors.GOOD,
							display: this.page.accurate,
						},
						{
							color: CalendarColors.OKAY,
							display: this.page.missingDetails,
						},
						{
							color: CalendarColors.VERY_BAD,
							display: this.page.notAccurate,
						},
					];
					this.journalsCompleted = res.length;
				} else {
					this.display = false;
					this.noData();
				}
			});
	}
}
