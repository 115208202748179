import { Component, Input, OnInit } from '@angular/core';
import { STRESS_PAGE, StressData } from '../common';
import { SelectListNext, SelectListOption } from '@components';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { StressService } from '@services/member';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.stress.pages.changePlan';

@Component({
	selector: 'app-member-skill-builder-stress-change-plan',
	templateUrl: './change-plan.component.html',
	styleUrls: ['./change-plan.component.scss'],
})
export class MemberSkillBuilderStressChangePlanComponent implements OnInit {
	@Input() data: StressData;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	defaultOptions: Array<SelectListOption> = [];
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
	};

	constructor(
		private _stressSvc: StressService,
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key], {
						feel: `<br>${this.data?.feel
							.map((item, index) => `${index + 1}. ${item}`)
							.join('<br>')}<br>`,
					})
					.then((value) => (this.page[key] = value));
		});

		const path: string = 'skillBuilders.stress.changePlan';
		this._languageSvc.get([path]).then((value) => {
			if (typeof value[path] === 'object' && value[path] !== null)
				this.defaultOptions = Object.values(value[path]);

			this.options.next(this.defaultOptions);
		});
	}

	nextOnClick(option: SelectListOption): void {
		if (option.value) {
			this._storageSvc.setTempStorage(TEMP_KEYS.STRESS, {
				...this.data,
				page: STRESS_PAGE.FEEL,
			});
			return;
		}
		if (this.data.tryDifferentReliever) {
			this._stressSvc
				.createPlan(this.data)
				.subscribe((res: APISuccess | APIErrors) => {
					if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
				});
		} else this._router.navigate(['member/dashboard']);
	}
}
