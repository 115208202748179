// Maltese
import { LocaleData } from 'ngx-bootstrap/chronos';

export const mtLocale: LocaleData = {
	months: [
		'Jannar',
		'Frar',
		'Marzu',
		'Awwissu',
		'Mejju',
		'Ġunju',
		'Lulju',
		'Awwissu',
		'Settembru',
		'Ottubru',
		'Novembru',
		'Diċembru',
	],
	monthsShort: [
		'Jan',
		'Frar',
		'Mar',
		'Aww',
		'Mej',
		'Ġun',
		'Lul',
		'Aww',
		'Set',
		'Ott',
		'Nov',
		'Diċ',
	],
	weekdays: [
		'Il-Ħadd',
		'Il-Ġimgħa',
		'It-Tnejn',
		'It-Tlieta',
		'Il-Erbgħa',
		'Il-Ħamis',
		'Is-Sibt',
	],
	weekdaysShort: ['Ħad', 'Ġim', 'Tne', 'Tli', 'Erb', 'Ħam', 'Sib'],
	weekdaysMin: ['Ħa', 'Ġi', 'Tn', 'Tl', 'Er', 'Ħa', 'Si'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Illum] LT',
		nextDay: '[Għada] LT',
		nextWeek: 'dddd [li] LT',
		lastDay: '[Ilbieraħ] LT',
		lastWeek: '[Il-Ħadd] dddd [li] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'fi %s',
		past: '%s ilu',
		s: 'ftit sekondi',
		m: 'minuta waħda',
		mm: '%d minuti',
		h: 'siegħa waħda',
		hh: '%d sigħat',
		d: 'ġurnata waħda',
		dd: '%d ġranet',
		M: 'xahar wieħed',
		MM: '%d xhur',
		y: 'sena waħda',
		yy: '%d snin',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
