import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '@services/public';
import { AssessmentService } from '@services/member';
import { WeeklyChallengeService } from '@pages/member/challenges/services/weekly-challenge.service';
import { APIErrors, APISuccess, isAPIErrors } from '@util';

interface WeeklyChallengeData {
	weeklyChallenge: boolean;
	domainUUID: number;
	uuid: string;
	logUUID: string;
}

interface Inventory {
	title: string;
	description: string;
	questions: Array<Question>;
}

interface Question {
	uid: number;
	display: string;
	type: 'list' | 'multilist' | 'star';
	answers: Array<Answer>;
	selected?: number;
}

interface Answer {
	value: number;
	display: string;
	selected?: boolean;
}

interface InventoryLanguage {
	title: string;
	description: string;
	questions: {
		[key: string]: {
			display: string;
			type: 'list' | 'multilist' | 'star';
			answers: {
				[key: string]: {
					display: string;
				};
			};
		};
	};
}

const PAGE_PATH: string = 'pages.member.inventory.page';

@Component({
	selector: 'app-inventory',
	templateUrl: './inventory.component.html',
	styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
	inventory: Inventory = {
		title: '',
		description: '',
		questions: [],
	};
	submitted = false;
	weeklyChallengeData: WeeklyChallengeData;

	// Page langauge.
	page: { [key: string]: string } = {
		errorAnswerAll: '',
	};
	labels: { [key: string]: string } = {
		close: '',
		submit: '',
	};

	constructor(
		private assessmentSvc: AssessmentService,
		private router: Router,
		private _languageSvc: LanguageService,
		private weeklyChallengeSvc: WeeklyChallengeService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});
		this._languageSvc.getLabels(this.labels);

		// Get inventory data.
		this.weeklyChallengeData = JSON.parse(
			localStorage.getItem('weeklyActivities'),
		);
		const path: string = `domains2.${
			this.weeklyChallengeData.domainUUID
		}.inventory`;
		this._languageSvc
			.get([path])
			.then((value: { [key: string]: InventoryLanguage }) => {
				if (typeof value[path] === 'object' && value[path] !== null)
					this.inventory = {
						title: value[path].title,
						description: value[path].description,
						questions: Object.entries(value[path].questions)
							.map((value) => {
								return {
									uid: +value[0],
									display: value[1].display,
									type: value[1].type,
									answers: Object.entries(value[1].answers)
										.map((value) => {
											return {
												value: +value[0],
												display: value[1].display,
											};
										})
										.sort((a, b) => a.value - b.value),
								};
							})
							.sort((a, b) => a.uid - b.uid),
					};
			});
	}

	onSubmit() {
		this.submitted = false;
		const scores: Array<{ uid: number; score: number }> = [];
		for (const question of this.inventory.questions) {
			if (
				(question.type === 'list' || question.type === 'star') &&
				typeof question.selected === 'number'
			) {
				scores.push({
					uid: question.uid,
					score: question.selected,
				});
			} else if (
				question.type === 'multilist' &&
				question.answers.some((answer) => !!answer.selected)
			) {
				scores.push(
					...question.answers
						.filter((answer) => !!answer.selected)
						.map((answer) => {
							return {
								uid: question.uid,
								score: answer.value,
							};
						}),
				);
			} else {
				this.submitted = true;
				return;
			}
		}
		this.assessmentSvc
			.submitInventory({ scores })
			.subscribe((res: APISuccess | APIErrors) => {
				if (!isAPIErrors(res)) {
					this.activityCompleted();
				}
			});
	}

	activityCompleted() {
		this.weeklyChallengeSvc
			.completeWeeklyChallenge({ uuid: this.weeklyChallengeData.uuid })
			.subscribe(() => {
				this.router.navigate(['/member/dashboard']);
			});
	}

	backstep() {
		this.router.navigate(['member/dashboard']);
	}

	closeTab() {
		this.router.navigate(['member/dashboard']);
	}
}
