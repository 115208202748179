import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	isLoading: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	private _loaders: Array<{ loader: symbol; name?: string }> = [];

	constructor() {
		this.isLoading.next(false);
	}

	addLoader(loader: symbol, name?: string): void {
		this.isLoading.next(true);
		this._loaders.push({ loader, name });
	}

	removeLoader(loader: symbol): void {
		this._loaders = this._loaders.filter((value) => value.loader !== loader);
		if (this._loaders.length === 0) this.isLoading.next(false);
	}

	clearLoaders(): void {
		this._loaders = [];
		this.isLoading.next(false);
	}
}
