<div
	class="main-container w-100 d-flex align-items-center justify-content-center"
	*ngIf="display"
>
	<div class="container-fluid pb-5-sm-c pb-5-md-c">
		<div class="col-12 px-0">
			<div class="col-12 d-flex justify-content-center align-items-center px-0">
				<div class="col-sm-12 col-lg-5">
					<div class="col-12 px-0">
						<div
							class="card card-rounded d-flex align-items-center justify-content-center"
						>
							<div class="card-body w-100 pt-3">
								<div
									class="col-sm-12 d-flex align-items-center justify-content-center"
								>
									<select class="w-50" [(ngModel)]="goalFilter.value">
										<option
											*ngFor="let goal of goalFilter.options"
											[ngValue]="goal"
										>
											{{ goal.name }}
										</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="card pt-5 card-rounded">
						<div class="card-body pt-3 d-flex align-items-center">
							<div class="col-12 d-flex align-items-center px-0">
								<div
									class="count-box d-flex align-items-center justify-content-center"
								>
									{{ goalFilter.value.streak }}
								</div>
								<h4 class="text-center font-weight-normal pl-3 pl-md-5">
									{{ page.streak }}
								</h4>
							</div>
						</div>
					</div>
					<div
						*ngIf="goalFilter.value.logs.length > 0"
						class="calender-wrapper"
					>
						<app-skill-builder-calendar
							[events]="goalFilter.value.calendarEvents"
							[legend]="calendarLegend"
							title=""
							skillBuilderType="brain"
							class="w-100 mb-3"
						></app-skill-builder-calendar>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
