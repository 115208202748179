// Kinyarwanda
import { LocaleData } from 'ngx-bootstrap/chronos';

export const rwLocale: LocaleData = {
	months: [
		'Mutarama',
		'Gashyantare',
		'Werurwe',
		'Mata',
		'Gicurasi',
		'Kamena',
		'Nyakanga',
		'Kanama',
		'Nzeli',
		'Ukwakira',
		'Ugushyingo',
		'Ukuboza',
	],
	monthsShort: [
		'Mut',
		'Gash',
		'Wer',
		'Mat',
		'Gic',
		'Kam',
		'Nya',
		'Kan',
		'Nze',
		'Ukw',
		'Ugu',
		'Ukub',
	],
	weekdays: [
		'Kuwa Mbere',
		'Kuwa Kabiri',
		'Kuwa Gatatu',
		'Kuwa Kane',
		'Kuwa Gatanu',
		'Kuwa Gatandatu',
		'Kuwa Kiri',
	],
	weekdaysShort: ['Mbe', 'Kab', 'Gat', 'Kan', 'Gat', 'Gat', 'Kir'],
	weekdaysMin: ['Mb', 'Ka', 'Ga', 'Ka', 'Ga', 'Ga', 'Ki'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Uyu munsi] LT',
		nextDay: '[Ejo] LT',
		nextWeek: 'dddd [ya] LT',
		lastDay: '[Ejo hashize] LT',
		lastWeek: '[Icyumweru gishize] dddd [ya] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s',
		past: '%s ishize',
		s: 'amasegonda make',
		m: 'umunota',
		mm: '%d iminota',
		h: 'isaha',
		hh: '%d amasaha',
		d: 'umunsi',
		dd: '%d iminsi',
		M: 'ukwezi',
		MM: '%d amezi',
		y: 'umwaka',
		yy: '%d imyaka',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
