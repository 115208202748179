import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'searchFilterPipe',
})
export class SearchFilterPipePipe implements PipeTransform {
	transform(value: any, input: any): any {
		if (input) {
			return value.filter(
				(val) => val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0,
			);
		} else {
			return value;
		}
	}
}
