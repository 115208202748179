import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class VideoViewerService {
	videoElement: HTMLVideoElement;

	init(videoElement: HTMLVideoElement): void {
		this.videoElement = videoElement;
	}

	play(): void {
		this.videoElement.play();
	}

	pause(): void {
		this.videoElement.pause();
	}

	stop(): void {
		this.videoElement.pause();
		this.videoElement.currentTime = 0;
		this.videoElement.src = '';
	}
}
