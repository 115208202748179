import { Component, Input, OnInit } from '@angular/core';
import { GoalService } from '@services/member';
import { Domain2Service, LanguageService } from '@services/public';
import { APINoData, Filter, isAPINoData } from '@util';
import { GoalCompleted, GoalReportData } from '../common';
import {
	CalendarColors,
	CalendarDate,
	CalendarEvent,
	CalendarLegend,
} from '@components';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.goal.pages.report';
const SCORE_CALENDAR_COLORS: { [key: string]: CalendarColors } = {
	[GoalCompleted.NO]: CalendarColors.VERY_BAD,
	[GoalCompleted.PROCRASTINATED]: CalendarColors.OKAY,
	[GoalCompleted.YES]: CalendarColors.GOOD,
};

@Component({
	selector: 'app-member-skill-builder-goal-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderGoalReportComponent implements OnInit {
	@Input() noData: () => void;
	display: boolean = false;
	goalFilter: Filter<GoalReportData> = {
		key: 'goals',
		value: null,
		options: [],
	};
	calendarLegend: Array<CalendarLegend> = [];
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		streak: '',
		no: '',
		procrastinated: '',
		yes: '',
	};

	constructor(
		private _domainSvc: Domain2Service,
		private _goalSvc: GoalService,
		private _languageSvc: LanguageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<any>> = [];
			for (const key in this.page)
				promises.push(
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value)),
				);
			Promise.all(promises).then(() => this._languageLoaded.resolve());
		});

		await this._domainSvc.loaded;
		await this._languageLoaded;

		this._goalSvc
			.getReport()
			.subscribe((res: Array<GoalReportData> | APINoData) => {
				if (!isAPINoData(res)) {
					this.display = true;
					for (const goal of res) {
						goal.name = `${
							this._domainSvc.getDomain(goal.domainUID).name
						} - ${goal.skill} - ${goal.action}`;
						goal.calendarEvents = new Map<CalendarDate, CalendarEvent>();
						for (const log of goal.logs)
							goal.calendarEvents.set(log.date, {
								color: SCORE_CALENDAR_COLORS[log.completed],
							});
					}
					this.goalFilter.options = res;
					this.goalFilter.value = this.goalFilter.options[0];
					this.calendarLegend = [
						{
							color: CalendarColors.GOOD,
							display: this.page.yes,
						},
						{
							color: CalendarColors.OKAY,
							display: this.page.procrastinated,
						},
						{
							color: CalendarColors.VERY_BAD,
							display: this.page.no,
						},
					];
				} else {
					this.display = false;
					this.noData();
				}
			});
	}
}
