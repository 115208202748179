import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	TemplateRef,
} from '@angular/core';
import { ProfileService } from '@services/member/profile.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { LanguageService } from '@services/public';

@Component({
	selector: 'app-add-email',
	templateUrl: './add-email.component.html',
	styleUrls: ['./add-email.component.scss'],
})
export class AddEmailComponent implements OnInit {
	addEmailForm: UntypedFormGroup;
	submitted = false;
	message?: string;
	modalRef: BsModalRef;
	addEmail: string = '';
	pageLanguage: any;
	labels: any;
	path: string = `pages.member.group.add`;
	@Output() addEmailEvent = new EventEmitter<any>();

	constructor(
		private profileSvc: ProfileService,
		private modalService: BsModalService,
		private formBuilder: UntypedFormBuilder,
		private _languageSvc: LanguageService,
	) {}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, {
			class: 'modal-sm modal-dialog-centered',
		});
	}

	confirm(): void {
		this.message = this.labels.confirmed;
		this.modalRef?.hide();
	}

	decline(): void {
		this.submitted = false;
		this.message = this.labels.declined;
		this.modalRef?.hide();
		this.addEmailForm.reset({ email: '' });
	}

	ngOnInit(): void {
		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});
		this.addEmailForm = this.formBuilder.group({
			email: [
				'',
				[
					Validators.required,
					Validators.email,
					Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
				],
			],
		});
	}
	get f() {
		return this.addEmailForm.controls;
	}
	onAddEmail() {
		this.submitted = true;
		if (this.addEmailForm.invalid) {
			return;
		} else {
			let reqBody = {
				email: this.addEmail,
			};
			this.profileSvc.addEmail(reqBody).subscribe(
				(res) => {
					this.addEmailEvent.emit(res.emails);
					this.modalRef?.hide();
				},
				(err) => {
					// console.error(err);
				},
			);
		}
		this.addEmailForm.reset({ email: '' });
		this.submitted = false;
	}
}
