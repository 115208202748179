// Mizo
import { LocaleData } from 'ngx-bootstrap/chronos';

export const lusLocale: LocaleData = {
	months: [
		'Zlaikhuak',
		'März',
		'Chhli',
		'Khawngai',
		'Mä',
		'Puh',
		'Zula',
		'Awng',
		'Tuvai',
		'Tib',
		'Tula',
		'Khawt',
	],
	monthsShort: [
		'Zla',
		'Mär',
		'Chh',
		'Khw',
		'Mä',
		'Puh',
		'Zul',
		'Awn',
		'Tuv',
		'Tib',
		'Tul',
		'Khw',
	],
	weekdays: ['Kiphthar', 'Buanthar', 'Puitu', 'Buchit', 'Ngei', 'Bung', 'Reng'],
	weekdaysShort: ['Kip', 'Bua', 'Pui', 'Buc', 'Nge', 'Bun', 'Ren'],
	weekdaysMin: ['Ki', 'Bu', 'Pu', 'Bu', 'Ng', 'Bu', 'Re'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Inni] LT',
		nextDay: '[Hria] LT',
		nextWeek: 'dddd [ah] LT',
		lastDay: '[Inra] LT',
		lastWeek: '[Hriat] dddd [ah] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s hma',
		past: '%s hi',
		s: 'khat ta',
		m: 'chiṭ minit',
		mm: '%d minit',
		h: 'chiṭ nawn',
		hh: '%d nawn',
		d: 'chiṭ ni',
		dd: '%d ni',
		M: 'chiṭ buan',
		MM: '%d buan',
		y: 'chiṭ tawh',
		yy: '%d tawh',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
