import { Component, OnInit } from '@angular/core';
import { ProfileService } from '@services/member';
import {
	LABELS,
	LanguageService,
	StorageService,
	TEMP_KEYS,
} from '@services/public';
import { APIErrors, AVATARS, getAvatarCoordinates, isAPIErrors } from '@util';
import { BsModalRef } from 'ngx-bootstrap/modal';

const PAGE_PATH: string = 'pages.member.modal.selectAvatar.page';

@Component({
	selector: 'app-member-modal-select-avatar',
	templateUrl: './select-avatar.component.html',
	styleUrls: ['./select-avatar.component.scss'],
})
export class MemberModalSelectAvatarComponent implements OnInit {
	avatars: Array<AVATARS> = Object.values(AVATARS);
	avatarPreview: AVATARS;
	userName: string;
	error: boolean = false;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
	};
	labels: { [key: string]: string } = {
		[LABELS.CANCEL]: '',
		[LABELS.CLOSE]: '',
		[LABELS.ERROR_SELECT_OPTION]: '',
		[LABELS.SAVE]: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _modalRef: BsModalRef,
		private _profileSvc: ProfileService,
		private _storageSvc: StorageService,
	) {
		this.avatarPreview = this._storageSvc.getTempStorage(TEMP_KEYS.AVATAR);
		this.userName = this._storageSvc.getTempStorage(TEMP_KEYS.NAME);
	}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
			}
		});
		this._languageSvc.getLabels(this.labels);
	}

	getAvatarStyle(avatar: AVATARS, size?: number) {
		let { x, y } = getAvatarCoordinates(avatar, size);

		// Adjust for selected border.
		if (avatar === this.avatarPreview && !size) {
			x += 4;
			y += 4;
		}
		return { 'background-position': `${-x}px ${-y}px` };
	}

	avatarOnClick(avatar?: AVATARS): any {
		this.error = false;
		this.avatarPreview = avatar;
	}

	cancelOnClick() {
		if (!this.avatarPreview) this.error = true;
		else this._modalRef.hide();
	}

	saveOnClick() {
		this._profileSvc
			.updateProfile({ avatar: this.avatarPreview })
			.subscribe((res: any | APIErrors) => {
				if (!isAPIErrors(res)) {
					this._storageSvc.setTempStorage(TEMP_KEYS.AVATAR, this.avatarPreview);
					this._modalRef.hide();
				}
			});
	}
}
