import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	TokenService,
	LoaderService,
	Role,
	LogoutService,
	LOGOUT_EVENT,
} from '@services/public';
import { config } from 'environment';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	private _profile: ReplaySubject<any> = new ReplaySubject<any>(1);
	private _role: Role = Role.MEMBER;

	constructor(
		private http: HttpClient,
		private tokenSvc: TokenService,
		private logoutSvc: LogoutService,
		private loaderSvc: LoaderService,
	) {
		this.logoutSvc.subscribe(LOGOUT_EVENT.POST_API, this.logout.bind(this));
		this.tokenSvc.role.subscribe((role) => (this._role = role));
	}

	// Use getProfileSubject with loadProfile to get updated group data.
	get profile(): Observable<any> {
		return this._profile.asObservable();
	}

	loadProfile(): void {
		const loader: unique symbol = Symbol();
		this.loaderSvc.addLoader(loader);
		this.http
			.get<any>(`${config.apiBase}member/profile/view`)
			.pipe(
				map((response: any) => {
					if (!!response) {
						this._profile.next(response);
						this.loaderSvc.removeLoader(loader);
					}
				}).bind(this),
			)
			.subscribe();
	}

	updateProfile(profile: any): Observable<any> {
		return this.http.put<any>(`${config.apiBase}member/profile/edit`, profile);
	}

	deleteProfile() {
		return this.http.delete<any>(`${config.apiBase}member/profile/delete`, {});
	}

	addEmail(reqBody) {
		return this.http.post<any>(`${config.apiBase}member/email/add`, reqBody);
	}

	getProfile() {
		return this.http.get<any>(`${config.apiBase}member/profile/view`);
	}

	verifyEmail(reqBody) {
		return this.http.post<any>(`${config.apiBase}member/email/verify`, reqBody);
	}

	makePrimary(reqBody) {
		return this.http.put<any>(`${config.apiBase}member/email/primary`, reqBody);
	}

	removeEmail(reqBody) {
		return this.http.delete<any>(`${config.apiBase}member/email/delete`, {
			body: reqBody,
		});
	}

	// Push Notification
	addDevice(deviceToken: string) {
		return this.http.post<any>(`${config.apiBase}member/device/add`, {
			deviceToken,
		});
	}

	// Link Apple
	linkApple(appleToken: string): Observable<any> {
		return this.http.put<any>(`${config.apiBase}member/profile/link/apple`, {
			token: appleToken,
		});
	}

	private logout(): void {
		this._profile.complete();
		this._profile = new ReplaySubject<any>(1);
	}
}
