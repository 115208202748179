import { Component, Input, OnInit } from '@angular/core';
import { PieChartData } from '@components';
import { DecisionService } from '@services/member';
import { LanguageService } from '@services/public';
import { APINoData, isAPINoData } from '@util';
import { DecisionReportData } from '../common';
import { ExposedPromise } from 'jakapa-utilities';

const PAGE_PATH: string = 'skillBuilders.decision.pages.report';

@Component({
	selector: 'app-member-skill-builder-decision-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberSkillBuilderDecisionReportComponent implements OnInit {
	@Input() noData: () => void;
	display: boolean = false;
	pieData: Array<PieChartData> = [];
	bestAction: string;
	decisions: number;
	private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

	// Page langauge.
	page: { [key: string]: string } = {
		pieTitle: '',
		bestAction: '',
		decisions: '',
		yes: '',
		no: '',
		notSure: '',
	};

	constructor(
		private _decisionSvc: DecisionService,
		private _languageSvc: LanguageService,
	) {}

	async ngOnInit(): Promise<void> {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			const promises: Array<Promise<any>> = [];
			for (const key in this.page)
				promises.push(
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value)),
				);
			Promise.all(promises).then(() => this._languageLoaded.resolve());
		});

		await this._languageLoaded;

		this._decisionSvc
			.getReport()
			.subscribe((res: DecisionReportData | APINoData) => {
				if (!isAPINoData(res)) {
					this.display = true;
					this.bestAction = `${res.bestAction}%`;
					this.decisions = res.decisions;
					this.pieData = [
						{
							name: this.page.yes,
							value: res.pie.yes,
							label: `${res.pie.yes}%`,
						},
						{
							name: this.page.notSure,
							value: res.pie.notSure,
							label: `${res.pie.notSure}%`,
						},
						{
							name: this.page.no,
							value: res.pie.no,
							label: `${res.pie.no}%`,
						},
					];
				} else {
					this.display = false;
					this.noData();
				}
			});
	}
}
