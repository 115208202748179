<div class="assessmentListMain assessmentInfoMain">
	<div class="tabHeadMain px-5">
		<div class="container">
			<div class="tabHead">
				<span (click)="backstep()" class="back-arrow"
					><i class="fas fa-arrow-left"></i>
				</span>
				{{ page?.viewAssessment }}
			</div>
		</div>
	</div>
</div>

<div
	*ngIf="
		assessment.status === AssessmentStatus.ACTIVE ||
		assessment.status === AssessmentStatus.COMPLETED
	"
>
	<div class="rytPanalMain">
		<div class="rytPanal">
			<div class="rytHeadMain">
				<h3>{{ page?.assessmentDetail }}</h3>
			</div>

			<div class="pageTopCard">
				<div class="topCardCntr">
					<div class="row">
						<div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
							<label>{{ page?.assessmentName }}</label>
							<span>{{ assessment.name }}</span>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
							<label>{{ page?.assessmentType }}</label>
							<span class="text-capitalize">{{
								!!assessment.type ? assessment.type : '-'
							}}</span>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
							<label>{{ page?.groupName }}</label>
							<span>{{ assessment.group?.name }}</span>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
							<label>{{ page?.startDate }}</label>
							<span>{{ assessment.startDate }}</span>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
							<label>{{ page?.endDate }}</label>
							<span>{{ assessment.endDate }}</span>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
							<label>{{ page?.status }}</label>
							<span>{{ assessment.status }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="studentsTblMainNxt">
				<h3>{{ page?.userStatus }}</h3>
				<div class="">
					<div class="">
						<table class="table-c">
							<thead>
								<tr>
									<th>{{ page?.users }}</th>
									<th>{{ page?.assessmentStatus }}</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngIf="!!results">
									<tr *ngFor="let userUUID of Object.keys(results)">
										<td>{{ results[userUUID].name }}</td>
										<td>{{ results[userUUID].status }}</td>
									</tr>
								</ng-container>
								<ng-container *ngIf="!results">
									<tr>
										<td class="text-center" colspan="2">{{ page?.noUsers }}</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div
				*ngIf="
					!!results &&
					(assessment.type !== AssessmentType.LEADER ||
						assessment.status === AssessmentStatus.COMPLETED)
				"
				class="studentsTblMainNxt"
			>
				<h3>{{ page?.results }}</h3>
				<div *ngIf="assessment.type === AssessmentType.PEER" class="row">
					<div class="col-xs-12 col-sm-3 col-md-2">
						<div class="studentTblLft">
							<div class="studentTblLftHd">{{ page?.assessee }}</div>
							<div class="studentTblLftBtm">
								<div class="studentBtmScroll">
									<ul>
										<ng-container *ngFor="let userUUID of Object.keys(results)">
											<li
												class="{{
													selectedAssessee === results[userUUID] ? 'active' : ''
												}} d-flex"
											>
												<label
													class="container-check"
													[ngStyle]="{
														'padding-right':
															assessment.status === AssessmentStatus.COMPLETED
																? '35px'
																: '0px',
													}"
												>
													<ng-container
														*ngIf="
															assessment.status ===
																AssessmentStatus.COMPLETED &&
															!results[userUUID].released
														"
													>
														<input
															(change)="
																onChangeReleaseResults(
																	$event,
																	results[userUUID]
																)
															"
															type="checkbox"
														/>
														<span class="checkmark"></span>
													</ng-container>
												</label>
												<label
													style="padding-left: 0px"
													class="w-100 container-check"
													(click)="selectedAssessee = results[userUUID]"
												>
													{{ results[userUUID].name }}
												</label>
											</li>
										</ng-container>
									</ul>
								</div>
								<div
									*ngIf="
										assessment.status === AssessmentStatus.COMPLETED &&
										!allResultsReleased
									"
									class="mb-3 btn-c align-items-center justify-content-center d-flex"
								>
									<button
										[disabled]="releasedResults.length === 0"
										(click)="releaseResults()"
										class="btn btn-primary"
									>
										{{ page?.releasePeer }}
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xs-12 col-sm-9 col-md-10">
						<div class="assmntTblMain">
							<div class="table-responsive">
								<table class="table-c">
									<thead>
										<tr>
											<th class="assessor_names">{{ page?.assessor }}</th>
											<th
												class="assessor_name"
												*ngFor="
													let assessorUUID of Object.keys(
														selectedAssessee.assessors
													)
												"
											>
												<span class="name">{{
													selectedAssessee?.assessors[assessorUUID].name
												}}</span>
												<span class="actionColumn"
													><img
														src="./../../../../assets/img/delete-gray.svg"
														alt="delete.svg"
												/></span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let skill of assessment.skills">
											<td class="skill_names">
												{{ this.allDomainsAndSkills[skill.toString()]?.name }}
											</td>
											<ng-container
												*ngFor="
													let assessorUUID of Object.keys(
														selectedAssessee.assessors
													)
												"
											>
												<td
													class="skill_score {{
														selectedAssessee.assessors[assessorUUID].outlier
															? 'outlier'
															: ''
													}}"
												>
													{{
														!selectedAssessee.assessors[assessorUUID].scores[
															skill.toString()
														]
															? '-'
															: selectedAssessee.assessors[assessorUUID].scores[
																	skill.toString()
																]
													}}
												</td>
											</ng-container>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div
					*ngIf="assessment.type === AssessmentType.SELF"
					class="assmntTblMain studentTblInner"
				>
					<div class="teacherAssmntTbl">
						<table class="table-c">
							<thead>
								<tr>
									<th>{{ page?.student }}</th>
									<th
										class="text-center"
										*ngFor="let skill of assessment.skills"
									>
										{{ this.allDomainsAndSkills[skill.toString()]?.name }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let userUUID of Object.keys(results)">
									<td>{{ results[userUUID].name }}</td>
									<td
										class="text-center"
										*ngFor="let skill of assessment.skills"
									>
										{{ results[userUUID]?.scores?.[skill.toString()] }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div
					*ngIf="assessment.type === AssessmentType.LEADER"
					class="assmntTblMain studentTblInner"
				>
					<div class="teacherAssmntTbl">
						<table class="table-c">
							<thead>
								<tr>
									<th
										class="text-center"
										*ngFor="let skill of assessment.skills"
									>
										{{ this.allDomainsAndSkills[skill.toString()]?.name }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										class="text-center"
										*ngFor="let skill of leaderAvg.skills"
									>
										{{ leaderAvg?.[skill] | number }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
