import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DecisionData,
	DecisionReportData,
} from '@pages/member/skill-builder/decision/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DecisionService {
	constructor(private http: HttpClient) {}

	createLog(data: DecisionData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/decision/log/create`,
			{
				affect: data.affect,
				actions: data.actions,
				goalPlanUUID: data.goalPlanUUID ?? null,
			},
		);
	}

	updateLog(data: DecisionData): Observable<APISuccess | APIErrors> {
		return this.http.put<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/decision/log/update`,
			{
				logUUID: data.logUUID,
				action: data.action,
				rightChoice: data.rightChoice,
				reason: data.reason,
			},
		);
	}

	getReport(): Observable<DecisionReportData | APINoData> {
		return this.http.get<DecisionReportData | APINoData>(
			`${config.apiBase}member/challenge/decision/report`,
		);
	}
}
