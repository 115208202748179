import { SKILL_BUILDER_TYPES, SkillBuilderData } from '@services/member';

export enum JOURNAL_PAGE {
	YESTERDAY = 'YESTERDAY',
	HIGHLIGHT = 'HIGHLIGHT',
	FREQUENCY = 'FREQUENCY',
	ENJOYMENT = 'ENJOYMENT',
	SOCIAL = 'SOCIAL',
	CONFIDENCE = 'CONFIDENCE',
	EMOTIONS = 'EMOTIONS',
	SYMPTOMS = 'SYMPTOMS',
	THOUGHTS = 'THOUGHTS',
	USER_SUMMARY = 'USER_SUMMARY',
	GENERATED_SUMMARY = 'GENERATED_SUMMARY',
	ACCURACY = 'ACCURACY',
}

export const JOURNAL_PREVIOUS_PAGE = {};

// Start flow.
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.YESTERDAY] = null;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.HIGHLIGHT] = JOURNAL_PAGE.YESTERDAY;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.FREQUENCY] = JOURNAL_PAGE.HIGHLIGHT;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.ENJOYMENT] = JOURNAL_PAGE.FREQUENCY;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.SOCIAL] = JOURNAL_PAGE.ENJOYMENT;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.CONFIDENCE] = JOURNAL_PAGE.SOCIAL;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.EMOTIONS] = JOURNAL_PAGE.CONFIDENCE;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.SYMPTOMS] = JOURNAL_PAGE.EMOTIONS;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.THOUGHTS] = JOURNAL_PAGE.SYMPTOMS;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.USER_SUMMARY] = JOURNAL_PAGE.THOUGHTS;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.GENERATED_SUMMARY] =
	JOURNAL_PAGE.USER_SUMMARY;
JOURNAL_PREVIOUS_PAGE[JOURNAL_PAGE.ACCURACY] = JOURNAL_PAGE.GENERATED_SUMMARY;

export enum JournalAccuracy {
	NOT_ACCURATE = 'not-accurate',
	MISSING_DETAILS = 'missing-details',
	ACCURATE = 'accurate',
}

export interface JournalData extends SkillBuilderData {
	type: SKILL_BUILDER_TYPES.JOURNAL;
	page?: JOURNAL_PAGE;
	dailyCompleted?: boolean;
	yesterdayCompleted?: boolean;
	yesterday?: boolean;
	highlight?: string;
	frequency?: string;
	enjoyment?: string;
	social?: string;
	confidence?: string;
	emotions?: Array<string>;
	symptoms?: Array<string>;
	thoughts?: string;
	userSummary?: string;
	generatedSummary?: string;
	accuracy?: JournalAccuracy;
}

export interface JournalReportData {
	date: string;
	summary: string;
	accuracy: JournalAccuracy;
}
