import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
	LanguageService,
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	VOLATILE_KEYS,
} from '@services/public';
import { Subscription } from 'rxjs';
import { MESSAGE_PAGE, MESSAGE_PREVIOUS_PAGE, MessageData } from './common';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import { APIErrors, isAPIErrors } from '@util';

const PAGE_PATH: string = 'skillBuilders.message.pages.main';

@Component({
	selector: 'app-member-skill-builder-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss'],
})
export class MemberSkillBuilderMessageComponent implements OnInit, OnDestroy {
	MESSAGE_PAGE: typeof MESSAGE_PAGE = MESSAGE_PAGE;
	data: MessageData;
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
	};

	constructor(
		private _router: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _storageSvc: StorageService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Get decision data.
		this.data = this._storageSvc.getTempStorage(TEMP_KEYS.MESSAGE);
		const goalPlanUUID = this._storageSvc.getVolatileStorage(
			VOLATILE_KEYS.GOAL_PLAN_UUID,
			true,
		);
		if (!this.data) {
			this._skillBuilderSvc
				.getStatus(SKILL_BUILDER_TYPES.MESSAGE, goalPlanUUID)
				.subscribe((res: MessageData | APIErrors) => {
					if (!isAPIErrors(res) && !this.data) {
						this.data = {
							...res,
							page: res.start
								? MESSAGE_PAGE.NAME
								: MESSAGE_PAGE.DELIVERED_MESSAGE,
						};

						this._setStorageSubscription();
					}
				});
		} else this._setStorageSubscription();
	}

	private _setStorageSubscription() {
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
					this.data = update.value[TEMP_KEYS.MESSAGE];
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) this.data = null;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.MESSAGE)
					this.data = update.value;
				if (!this.data && !this._router.getCurrentNavigation())
					this._router.navigate(['member/dashboard']);
			}),
		);
	}

	back() {
		const page = MESSAGE_PREVIOUS_PAGE[this.data.page];
		if (!page) {
			this._router.navigate(['member/dashboard']);
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.MESSAGE, { ...this.data, page });
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this._storageSvc.setTempStorage(TEMP_KEYS.MESSAGE, null);
	}
}
