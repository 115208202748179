import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageData } from '../common';
import { LanguageService } from '@services/public';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { APIErrors, APISuccess, NO_HTML_PATTERN, isAPISuccess } from '@util';
import { MessageService } from '@services/member/skill-builder/message.service';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.message.pages.positiveMessage';

@Component({
	selector: 'app-member-skill-builder-message-positive-message',
	templateUrl: './positive-message.component.html',
	styleUrls: ['./positive-message.component.scss'],
})
export class MemberSkillBuilderMessagePositiveMessageComponent
	implements OnInit
{
	@ViewChild('exampleTemplate', { static: false })
	exampleTemplate: TemplateRef<void>;
	@Input() data: MessageData;
	form: UntypedFormGroup;
	submitted: boolean = false;
	modalRef?: BsModalRef;

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		needExample: '',
		example: '',
		values: '',
		placeholder: '',
		error: '',
		submit: '',
	};

	constructor(
		private _modalSvc: BsModalService,
		private _languageSvc: LanguageService,
		private _messageSvc: MessageService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				if (key === 'values')
					this._languageSvc
						.template(this.page[key], { values: this.data?.values.join(',') })
						.then((value) => (this.page[key] = value));
				else
					this._languageSvc
						.template(this.page[key])
						.then((value) => (this.page[key] = value));
		});

		// Setup form.
		this.form = new UntypedFormGroup({
			message: new UntypedFormControl(this.data?.message || '', [
				Validators.required,
				Validators.pattern(NO_HTML_PATTERN),
			]),
		});
	}

	openModal() {
		this.modalRef = this._modalSvc.show(this.exampleTemplate, {
			class: 'modal-sm modal-dialog-centered',
		});
	}

	closeModal() {
		this.modalRef?.hide();
	}

	next(): void {
		this.form.controls.message.setValue(
			(this.form.controls.message.value || '').trim(),
		);
		this.submitted = true;
		if (!this.form.valid) return;
		const data: MessageData = Object.assign({}, this.data, {
			message: this.form.controls.message.value,
		});
		this._messageSvc
			.createLog(data)
			.subscribe((res: APISuccess | APIErrors) => {
				if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
			});
	}
}
