import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	BrainData,
	BrainReportData,
} from '@pages/member/skill-builder/brain/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BrainService {
	constructor(private http: HttpClient) {}

	createLog(data: BrainData): Observable<APISuccess | APIErrors> {
		return this.http.post<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/brain/log/create`,
			{
				affirmation: data.affirmation,
				diary: data.diary,
				score: data.score,
				doTomorrow: data.doTomorrow,
				yesterday: data.yesterday ?? false,
				goalPlanUUID: data.goalPlanUUID ?? null,
			},
		);
	}

	updateLog(data: BrainData): Observable<APISuccess | APIErrors> {
		return this.http.put<APISuccess | APIErrors>(
			`${config.apiBase}member/challenge/brain/log/update`,
			{
				logUUID: data.logUUID,
				completed: data.completed,
				impact: data.impact ?? null,
			},
		);
	}

	getReport(): Observable<BrainReportData | APINoData> {
		return this.http.get<BrainReportData | APINoData>(
			`${config.apiBase}member/challenge/brain/report`,
		);
	}
}
