import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CardService {
	private _videoCardLanguage: { [key: string]: string };
	private _checklistCardLanguage: { [key: string]: string };
	private _viewCardLanguage: { [key: string]: string };
	constructor() {}
	private closeEvent = new Subject<boolean>();
	private nextCardEvent = new Subject<boolean>();

	closeEvent$ = this.closeEvent.asObservable();
	nextCardEvent$ = this.nextCardEvent.asObservable();

	emitCloseEvent(value: boolean) {
		this.closeEvent.next(value);
	}

	emitNextCardEvent(value: boolean) {
		this.nextCardEvent.next(value);
	}

	setVideoCardLanguage(language: { [key: string]: string }) {
		this._videoCardLanguage = language;
	}

	setChecklistCardLanguage(language: { [key: string]: string }) {
		this._checklistCardLanguage = language;
	}

	setViewCardLanguage(language: { [key: string]: string }) {
		this._viewCardLanguage = language;
	}

	getVideoCardLanguage(): { [key: string]: string } {
		return this._videoCardLanguage;
	}
	getChecklistCardLanguage(): { [key: string]: string } {
		return this._checklistCardLanguage;
	}
	getViewCardLanguage(): { [key: string]: string } {
		return this._viewCardLanguage;
	}
}
