import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from 'environment';

export enum RANK_DELTAS {
	DOWN = 'down',
	SAME = 'same',
	UP = 'up',
}

export interface LeaderboardCard {
	userUUID: string;
	genericName: string;
	points: string;
	streak: number;
	score: string;
	rank: string;
	rankDelta: RANK_DELTAS;
	name: string;
	avatar: string;
	class?: string;
	idx?: number;
	size?: number;
}

export interface RewardMetric {
	startDate: string;
	endDate: string;
	domainUID: number;
	domain?: string;
	score: number;
	extraCredit: number;
	class?: string;
}

@Injectable({
	providedIn: 'root',
})
export class RewardsService {
	public _leaderboard: Array<LeaderboardCard> | null = null;

	constructor(private http: HttpClient) {}

	loadRewardData(): Observable<any> {
		return this.http.get<any>(`${config.apiBase}member/reward/view`);
	}

	loadLeaderboardData(orgUUID?: string, userUUID?: string): Observable<any> {
		let optionalParameters: string = '';
		if (userUUID !== undefined && userUUID !== '') {
			optionalParameters += `?uuid=${userUUID}`;
		}
		if (orgUUID !== undefined && orgUUID !== 'null') {
			let paramSymbol = optionalParameters.length > 0 ? '&' : '?';
			optionalParameters += `${paramSymbol}organizationUUID=${orgUUID}`;
		}
		return this.http.get<any>(
			`${config.apiBase}member/reward/leaderboard/view${optionalParameters}`,
		);
	}

	loadMetricsData(
		startDate: string = '',
		endDate: string = '',
	): Observable<Array<RewardMetric>> {
		return this.http.get<any>(
			`${config.apiBase}member/reward/metrics?startDate=${
				startDate
			}&endDate=${endDate}`,
		);
	}
}
