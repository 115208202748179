<div
	class="main-container w-100 align-items-center justify-content-center d-flex"
>
	<div class="container px-0 w-sm-100 w-50">
		<div
			class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column"
		>
			<div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
				<div class="row justify-content-center">
					<div class="col-12">
						<div class="card card-white card-white-sm">
							<div class="card-body">
								<h5>
									{{ page.title }}
								</h5>
								<div class="pt-4">
									<button
										type="button"
										(click)="choice = false; submitted = false"
										class="btn btn-md ml-3 {{
											choice === false
												? BUTTON_CLASS.SELECTED
												: BUTTON_CLASS.NOT_SELECTED
										}}"
									>
										{{ page.no }}
									</button>
									<button
										type="button"
										(click)="choice = true; submitted = false"
										class="btn btn-md ml-3 {{
											choice ? BUTTON_CLASS.SELECTED : BUTTON_CLASS.NOT_SELECTED
										}}"
									>
										{{ page.yes }}
									</button>
								</div>
							</div>
						</div>
						<div *ngIf="submitted && choice === null" class="invalid-feedback">
							{{ page.error }}
						</div>
					</div>
				</div>
			</div>
			<app-skill-builder-select-list
				*ngIf="choice"
				[mainTitle]="page.title2"
				[options]="options"
				[change]="onChange.bind(this)"
				[submitted]="submitted"
			></app-skill-builder-select-list>
		</div>
	</div>
	<div class="footer-inner-action">
		<button
			type="button"
			class="btn btn-primary btn-lg px-4"
			(click)="submitOnClick()"
		>
			{{ page.submit }}
		</button>
	</div>
</div>

<ng-template #reminder>
	<div class="modal-body modal-center">
		<p class="mt-3 text-center success-msg px-5 title-main">
			{{ page.reminder }}
		</p>
		<div class="pt-4 d-flex align-items-center justify-content-center">
			<button
				(click)="closeReminder()"
				type="button"
				class="btn btn-md btn-bordered-purple"
			>
				{{ page.ok }}
			</button>
		</div>
	</div>
</ng-template>
