import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService, LOGOUT_EVENT, LogoutService } from '@services/public';
import { config } from 'environment';
import { Observable, ReplaySubject, lastValueFrom, map } from 'rxjs';

const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

// User metadata
export interface User {
	uuid: string;
	name: string;
	email: string;
	organizationUUID: string;
}

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _users: ReplaySubject<Array<User>> = new ReplaySubject<Array<User>>(
		1,
	);
	private _updated: Date | null = null;
	private _loading: Array<Promise<any>> = [];
	private _usersArray: Array<User> = [];

	constructor(
		private http: HttpClient,
		private logoutSvc: LogoutService,
		private loaderSvc: LoaderService,
	) {
		// Bind the logout function to clear data.
		this.logoutSvc.subscribe(LOGOUT_EVENT.POST_API, this.logout.bind(this));
	}

	// Reloads all user metadata.
	loadUsers(): void {
		const loader: unique symbol = Symbol();
		// Only show loader for the initial load.
		if (!this._updated)
			this.loaderSvc.addLoader(loader, 'services/viewer/user:loadUsers');
		this._loading.push(
			lastValueFrom(
				this.http.get<any>(`${config.apiBase}viewer/user/list`).pipe(
					map((response: any) => {
						if (!response.errors) {
							this._users.next(response);
							this._usersArray = response;
							this._updated = new Date();
							this.loaderSvc.removeLoader(loader);
						}
					}).bind(this),
				),
			),
		);
	}

	async getUserByUUID(uuid: string): Promise<User> {
		await Promise.all(this._loading);
		return this._usersArray.find((user) => user.uuid == uuid);
	}

	// Gets all user metadata. Refreshes if data is outdated.
	get users(): Observable<Array<User>> {
		if (
			!this._updated ||
			this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()
		)
			this.loadUsers();
		return this._users.asObservable();
	}

	// Clears the user data upon logout and resets the service.
	private logout(): void {
		this._users.complete();
		this._users = new ReplaySubject<Array<User>>(1);
		this._updated = null;
	}
}
