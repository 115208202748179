import { ViewportRuler } from '@angular/cdk/scrolling';
import { ComponentRef, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { PublicAsyncDelayService } from './async-delay.service';
import { PublicDomService } from './dom.service';
import { LOGOUT_EVENT, LogoutFunction, LogoutService } from './logout.service';

export enum WalkthroughToolTipBackdrop {
	CLEAR = 'clear',
	BLUR = 'blur',
	GREY = 'grey',
}

export enum WalkthroughToolTipScreenPosition {
	TOP_LEFT = 'top-left',
	TOP = 'top',
	TOP_RIGHT = 'top-right',
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
	BOTTOM_LEFT = 'bottom-left',
	BOTTOM = 'bottom',
	BOTTOM_RIGHT = 'bottom-right',
}

export enum WalkthroughToolTipElementPosition {
	TOP = 'top',
	LEFT = 'left',
	RIGHT = 'right',
	BOTTOM = 'bottom',
}

export interface WalkthroughToolTipElement {
	bottom: number;
	left: number;
	right: number;
	top: number;
}
export const NO_WALKTHROUGH_ELEMENT: WalkthroughToolTipElement = {
	bottom: 0,
	left: 0,
	right: 0,
	top: 0,
};

export interface WalkthroughToolTipOptionsScreen {
	backdrop: WalkthroughToolTipBackdrop;
	position: {
		type: 'screen';
		position: WalkthroughToolTipScreenPosition;
	};
	display: string;
	displaySize?: string;
	next?: () => void;
	back?: () => void;
}

export interface WalkthroughToolTipOptionsElement {
	backdrop: WalkthroughToolTipBackdrop;
	position: {
		type: 'element';
		position: WalkthroughToolTipElementPosition;
		element: HTMLElement | string;
		elementPosition?: WalkthroughToolTipElement;
		scrollBuffer?: number;
		disableScroll?: boolean;
		fixed?: boolean;
	};
	display: string;
	displaySize?: string; //The size of the tooltip text.
	clickElement?: string; //An element that moves to the next tooltip when clicked.
	hideNext?: boolean;
	disableElement?: string; //An element that will have its pointer-events set to none.
	next?: () => void;
	back?: () => void;
}

export type WalkthroughToolTipOptions =
	| WalkthroughToolTipOptionsScreen
	| WalkthroughToolTipOptionsElement;

export function isWalkthroughToolTipElement(
	value: WalkthroughToolTipOptions | null,
): value is WalkthroughToolTipOptionsElement {
	if (value === null) return false;
	return value.position.type === 'element';
}

@Injectable({
	providedIn: 'root',
})
export class WalkthroughToolTipService {
	private _optionsSubject: ReplaySubject<WalkthroughToolTipOptions | null> =
		new ReplaySubject<WalkthroughToolTipOptions | null>(1);
	private _elementSubject: ReplaySubject<WalkthroughToolTipElement | null> =
		new ReplaySubject<WalkthroughToolTipElement | null>(1);
	private _element: WalkthroughToolTipElement | null = null;
	private _options: WalkthroughToolTipOptions | null = null;
	private _tooltipQueue: Array<WalkthroughToolTipOptions> = [];
	private _currentTooltipIndex: number = -1;
	private _onResizeSubscription: Subscription;
	private _componentRef: ComponentRef<any>;

	constructor(
		private _asyncDelaySvc: PublicAsyncDelayService,
		private _domSvc: PublicDomService,
		private _logoutSvc: LogoutService,
		private _viewportRuler: ViewportRuler,
	) {
		// Bind the logout service.
		this._logoutSvc.subscribe(LOGOUT_EVENT.POST_API, this._afterLogout);
	}

	get optionsObservable(): Observable<WalkthroughToolTipOptions> {
		return this._optionsSubject.asObservable();
	}

	get elementObservable(): Observable<WalkthroughToolTipElement> {
		return this._elementSubject.asObservable();
	}

	private ttCompleted = new Subject<{ complete: boolean }>();
	ttEvent$ = this.ttCompleted.asObservable();

	emitTTCompleted(value: { complete: boolean }): void {
		this.ttCompleted.next(value);
	}

	private externalFormValid = new Subject<{ valid: boolean }>();
	externalFormValid$ = this.externalFormValid.asObservable();

	emitExternalFormValid(value: { valid: boolean }): void {
		this.externalFormValid.next(value);
	}

	// Get the elements bounding box
	getBoundingClientRect(
		element: HTMLElement | string,
		fixed: boolean = false,
	): WalkthroughToolTipElement {
		if (typeof element === 'string') {
			element = <HTMLElement>document.querySelector(element);
		}
		if (!element) return NO_WALKTHROUGH_ELEMENT;
		const rect = element.getBoundingClientRect();
		return {
			bottom:
				rect.bottom +
				(fixed ? 0 : this._viewportRuler.getViewportScrollPosition().top),
			left:
				rect.left +
				(fixed ? 0 : this._viewportRuler.getViewportScrollPosition().left),
			right:
				rect.right +
				(fixed ? 0 : this._viewportRuler.getViewportScrollPosition().left),
			top:
				rect.top +
				(fixed ? 0 : this._viewportRuler.getViewportScrollPosition().top),
		};
	}

	setTooltipQueue(queue: Array<WalkthroughToolTipOptions>): void {
		this._tooltipQueue = queue;
		this._currentTooltipIndex = 0;
		if (this._tooltipQueue.length > 0) {
			this.show(this._tooltipQueue[this._currentTooltipIndex]);
		}
	}

	showNext: () => void = () => {
		this._currentTooltipIndex++;
		if (this._currentTooltipIndex < this._tooltipQueue.length) {
			const options = this._tooltipQueue[this._currentTooltipIndex];
			this.show(options);
		} else {
			this.hide();
		}
	};

	showPrevious: () => void = () => {
		if (this._currentTooltipIndex > 0) {
			this._currentTooltipIndex--;
			const options = this._tooltipQueue[this._currentTooltipIndex];
			this.show(options);
		}
	};

	show(options: WalkthroughToolTipOptions): void {
		if (!this._componentRef) this._create();
		if (isWalkthroughToolTipElement(options)) {
			// Set resize subscription.
			if (!this._onResizeSubscription) {
				this._onResizeSubscription = this._viewportRuler
					.change()
					.subscribe(this.updateElement);
			}
			// Set modal scroll event.
			document
				.querySelector('modal-container')
				?.addEventListener('scroll', this.updateElement);
			this._options = options;
			this._loadElement();
		} else {
			// Remove resize subscription.
			if (this._onResizeSubscription) {
				this._onResizeSubscription.unsubscribe();
			}
			this._options = options;
			this._optionsSubject.next(this._options);
		}
	}

	private async _create(): Promise<void> {
		// Dynamically imported to bypass circular dependencies.
		const { WalkthroughToolTipComponent } = await import('@components');
		this._componentRef = this._domSvc.injectComponent(
			WalkthroughToolTipComponent,
		);
	}

	private _loadElement: () => void = () => {
		if (!this._options || !isWalkthroughToolTipElement(this._options)) return;
		this._element = this.getBoundingClientRect(
			this._options.position.element,
			this._options.position.fixed,
		);
		if (
			this._element.top === this._element.bottom ||
			this._element.left === this._element.right
		) {
			setTimeout(this._loadElement, 50);
			return;
		}
		this._options.position.elementPosition = this._element;
		this._optionsSubject.next(this._options);
		this.updateElement();
		if (this._options.disableElement) {
			this.disableElement(this._options.disableElement);
		}
	};

	updateElement: () => void = () => {
		this._asyncDelaySvc.asyncDelay(this._updateElementKey, this._updateElement);
	};

	private readonly _updateElementKey: symbol = Symbol();
	private _updateElement: () => void = () => {
		if (!this._options || !isWalkthroughToolTipElement(this._options)) return;
		const element: WalkthroughToolTipElement = this.getBoundingClientRect(
			this._options.position.element,
			this._options.position.fixed,
		);
		if (element.top === element.bottom || element.left === element.right) {
			setTimeout(this.updateElement, 100);
			return;
		}
		if (
			element.top === this._element.top &&
			element.bottom === this._element.bottom &&
			element.left === this._element.left &&
			element.right === this._element.right
		)
			return;
		this._element = element;
		this._elementSubject.next(this._element);
	};

	hide: () => void = () => {
		// Remove resize subscription.
		if (this._onResizeSubscription) this._onResizeSubscription.unsubscribe();
		this._element = null;
		this._options = null;
		this._optionsSubject.next(null);
		this._elementSubject.next(null);
		this._currentTooltipIndex = -1;
		this._tooltipQueue = [];
		this.emitTTCompleted({ complete: true });
	};

	destroy: () => void = () => {
		this.hide();
		if (this._componentRef) this._componentRef.destroy();
		this._componentRef = null;
	};

	// Manipulate the DOM to through the tooltip
	clickElement(element: any): void {
		element = <HTMLElement>document.querySelector(element);
		element.click();
	}

	// To disable click functionality of an element
	disableElement(element: any): void {
		element = <HTMLElement>document.querySelector(element);
		element.style.pointerEvents = 'none';
	}

	private _afterLogout: LogoutFunction = () => {
		this.destroy();
	};
}
