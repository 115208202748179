<div [@slideInOut]="isOpen ? 'in' : 'out'" class="sliding-panel">
	<!-- Content of the sliding component -->
	<div class="sliding-content">
		<div class="certificate-download-wrapper">
			<div
				class="certificate-download"
				*ngFor="let certificate of certificates"
			>
				<img src="assets/img/certificate/certificate.svg" alt="certificate" />
				<div class="certificate-data">
					<div class="cert-title">
						<span class="certificate-title">
							{{
								certificate.type == 'weekly'
									? page.weekly
									: domains[certificate.domain_uid]
							}}</span
						>
						<span>{{ page.level }} {{ certificate.level }}</span>
					</div>
					<div class="cert-date">
						<span>{{ certificate.achieved | date }}</span>
					</div>
				</div>
				<button
					(click)="
						downloadCertificate(
							certificate.type,
							certificate.level,
							certificate.domain_uid,
							certificate.achieved,
							language
						)
					"
					type="button"
					class="btn btn-md cert-btn"
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_214_2112)">
							<path
								d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z"
								fill="black"
							/>
						</g>
						<defs>
							<clipPath id="clip0_214_2112">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</button>
			</div>
		</div>
	</div>
</div>
