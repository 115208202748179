<!-- Skill builder header -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
				<span class="cursor-pointer txt-md">
					<i class="fas fa-arrow-left" (click)="back()"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
			</div>
		</div>
	</div>
</div>

<!-- Skill builder start flow -->
<ng-container *ngIf="data?.page === JOURNAL_PAGE.YESTERDAY">
	<app-member-skill-builder-journal-yesterday [data]="data">
	</app-member-skill-builder-journal-yesterday>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.HIGHLIGHT">
	<app-member-skill-builder-journal-highlight [data]="data">
	</app-member-skill-builder-journal-highlight>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.FREQUENCY">
	<app-member-skill-builder-journal-frequency [data]="data">
	</app-member-skill-builder-journal-frequency>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.ENJOYMENT">
	<app-member-skill-builder-journal-enjoyment [data]="data">
	</app-member-skill-builder-journal-enjoyment>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.SOCIAL">
	<app-member-skill-builder-journal-social [data]="data">
	</app-member-skill-builder-journal-social>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.CONFIDENCE">
	<app-member-skill-builder-journal-confidence [data]="data">
	</app-member-skill-builder-journal-confidence>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.EMOTIONS">
	<app-member-skill-builder-journal-emotions [data]="data">
	</app-member-skill-builder-journal-emotions>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.SYMPTOMS">
	<app-member-skill-builder-journal-symptoms [data]="data">
	</app-member-skill-builder-journal-symptoms>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.THOUGHTS">
	<app-member-skill-builder-journal-thoughts [data]="data">
	</app-member-skill-builder-journal-thoughts>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.USER_SUMMARY">
	<app-member-skill-builder-journal-user-summary [data]="data">
	</app-member-skill-builder-journal-user-summary>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.GENERATED_SUMMARY">
	<app-member-skill-builder-journal-generated-summary [data]="data">
	</app-member-skill-builder-journal-generated-summary>
</ng-container>
<ng-container *ngIf="data?.page === JOURNAL_PAGE.ACCURACY">
	<app-member-skill-builder-journal-accuracy [data]="data">
	</app-member-skill-builder-journal-accuracy>
</ng-container>

<ng-template #dailyCompletedTemplate>
	<div class="modal-body modal-center">
		<p class="mt-3 text-center success-msg px-5 title-main">
			{{ page.reminder }}
		</p>
		<div class="pt-4 d-flex align-items-center justify-content-center">
			<button
				(click)="closePopup()"
				type="button"
				class="btn btn-md btn-bordered-purple"
			>
				{{ page.ok }}
			</button>
		</div>
	</div>
</ng-template>
