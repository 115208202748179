import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { GraphComponent } from '@pages/member/modal';
import { Filter } from '@util';
import { Chart } from 'chart.js';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Component({
	selector: 'app-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnDestroy {
	@ViewChild('barChart', { read: ElementRef }) barChart!: ElementRef;
	@Input() title: string = '';
	@Input() filters: Array<Filter<any>> = [];
	@Input() getChartConfiguration!: Function;
	@Input() refresh!: Subject<boolean>;
	@Input() isViewAll!: BehaviorSubject<boolean>;
	@Input() info: string | null = null;
	@Input() view?: string;

	private _chart?: Chart;
	private _subscriptions: Subscription = new Subscription();

	constructor(private _modalSvc: BsModalService) {}

	ngOnInit(): void {
		this._subscriptions.add(this.refresh.subscribe((_next) => this.getChart()));
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	getChart(): void {
		// Build filter.
		const filters: { [key: string]: any } = {};
		for (const filter of this.filters) filters[filter.key] = filter.value;

		// Generate new chart.
		const configuration = this.getChartConfiguration(filters, false);
		if (!configuration) return;
		if (!!this._chart) this._chart.destroy();
		this._chart = new Chart(this.barChart.nativeElement, configuration);
	}

	viewAll(): void {
		// Build initial state.
		const initialState = {
			title: this.title,
			filters: this.filters.map((filter) => {
				return { ...filter };
			}),
			update: this.getChartConfiguration,
			refresh: this.refresh,
		};

		// Display modal.
		this._modalSvc.show(GraphComponent, {
			class: 'modal-md modal-dialog-centered',
			backdrop: 'static',
			keyboard: true,
			initialState,
		});
	}
}
