import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	TemplateRef,
} from '@angular/core';
import { ProfileService } from '@services/member/profile.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LanguageService } from '@services/public';
@Component({
	selector: 'app-change-email',
	templateUrl: './change-email.component.html',
	styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
	@Output() changePrimaryEmail = new EventEmitter<any>();
	message?: string;
	modalRef: BsModalRef;
	existingEmails = [];
	primarySelected: any;
	isButtonVisible: boolean = true;
	temporaryText: string = '';
	disabled: boolean = true;
	pageLanguage: any;
	labels: any;
	path: string = `pages.member.profile.changeEmail`;

	openModal(template: TemplateRef<any>) {
		this.getMailList();
		this.modalRef = this.modalService.show(template, {
			class: 'modal-md-c modal-dialog-centered',
		});
	}

	confirm(): void {
		this.disabled = true;
		this.message = this.labels.confirmed;
		this.modalRef?.hide();
	}

	decline(): void {
		this.disabled = true;
		this.message = this.labels.declined;
		this.modalRef?.hide();
	}
	constructor(
		private modalService: BsModalService,
		private profileSvc: ProfileService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});
		this.getMailList();
	}

	addEmailEvent(value) {
		this.existingEmails = value;
	}

	emailVerify(email, i) {
		const reqBody = {
			email: email,
		};
		this.profileSvc.verifyEmail(reqBody).subscribe((res) => {
			this.existingEmails[i].verified = true;
			this.existingEmails[i].newVerified = true;
		});

		this.isButtonVisible = false;
	}

	onChange(e, i) {
		this.disabled = false;
		this.existingEmails.forEach((item, j) => {
			if (i !== j) {
				this.existingEmails[j].primary = false;
			}
		});
		this.existingEmails[i].primary = true;
		this.primarySelected = this.existingEmails[i].email;
	}

	onSubmit(): void {
		const reqBody = {
			email: this.primarySelected,
		};
		if (JSON.stringify(reqBody) !== '{}') {
			this.profileSvc.makePrimary(reqBody).subscribe(
				(res) => {
					this.changePrimaryEmail.emit(res);
					this.disabled = true;
					this.modalRef?.hide();
				},
				(err) => {
					// console.error(err);
				},
			);
		}
	}

	onDelete(i): void {
		const reqBody = {
			email: this.existingEmails[i].email,
		};
		this.profileSvc.removeEmail(reqBody).subscribe(
			(res) => {
				this.getMailList();
				// this.existingEmails = res;
			},
			(err) => {
				// console.error(err);
			},
		);
	}

	getMailList() {
		this.profileSvc.getProfile().subscribe((res) => {
			this.existingEmails = res.emails;
		});
	}
}
