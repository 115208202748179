import {
	Component,
	OnInit,
	Input,
	OnDestroy,
	TemplateRef,
	ElementRef,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { User, UserService } from '@services/viewer';
import { GroupService, Group } from '@services/member';
import { Assessment } from '@services/leader';
import { LanguageService, LABELS } from '@services/public';
import { Filter } from '@util';

const PAGE_PATH = `pages.leader.assessment.create`;

@Component({
	selector: 'app-leader-assessment-create',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.scss'],
})
export class LeaderAssessmentCreateComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	@Input() public set assessment(assessment: Assessment | null) {
		if (assessment) {
			this.form.patchValue(assessment);
			this.f.type.disable();
			if (this.f.type.value === 'peer')
				this.groupFilter.options = this.groupFilter.options.filter(
					(option: Group) => option.memberUUIDs.length > 1,
				);
			const group = this.groupFilter.options.find(
				(option: Group) => option.uuid === assessment.group.uuid,
			);
			if (!!group) this.f.group.patchValue(group);
		}
	}

	@ViewChild('tabSet') tabSet: ElementRef;
	@ViewChild('container') container: ElementRef<HTMLElement>;
	@ViewChild('checkAll') checkAll: ElementRef<HTMLInputElement>;

	groupFilter: Filter<Group> = {
		key: 'groups',
		value: {
			endDate: '',
			member: false,
			uuid: '',
			name: '',
			abbreviation: '',
			timezone: '',
			startDate: '',
			organizationUUID: '',
			users: [],
			role: '',
			memberUUIDs: [],
		},
		options: [],
	};

	form: UntypedFormGroup;
	users: Array<User> | null = null;
	user: User | null = null;
	selectedGroup: Group | null = null;
	filteredUsers: Array<User> = [];
	previousTypeValue: string | null = null;
	selectedUsers: string | Array<User> = null;
	minStartDate: Date;
	maxEndDate: Date;
	modalRef: BsModalRef;

	// Page language
	page: { [key: string]: string } = {
		addAssessment: '',
		assessmentName: '',
		assessmentNameRequired: '',
		assessmentType: '',
		self: '',
		peer: '',
		leaderFeedback: '',
		assignSelfAssessment: '',
		group: '',
		selectGroup: '',
		groupRequired: '',
		startDate: '',
		startDateRequired: '',
		startBeforeEnd: '',
		startTodayOrLater: '',
		endDate: '',
		endDateRequired: '',
		individuals: '',
		search: '',
		searchUser: '',
		selectUsers: '',
		selectAll: '',
		close: '',
	};
	labels: { [key: string]: string } = {
		[LABELS.CANCEL]: '',
		[LABELS.SAVE]: '',
	};

	private _subscriptions: Subscription = new Subscription();

	constructor(
		private _groupSvc: GroupService,
		private _router: Router,
		private _modalSvc: BsModalService,
		private _usersSvc: UserService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		this._languageSvc.getLabels(this.labels);
		this.form = new UntypedFormGroup(
			{
				uuid: new UntypedFormControl(null),
				searchUser: new UntypedFormControl(null),
				name: new UntypedFormControl(null, [Validators.required]),
				type: new UntypedFormControl('self'),
				group: new UntypedFormControl(null, [Validators.required]),
				startDate: new UntypedFormControl(null, [Validators.required]),
				endDate: new UntypedFormControl(null, [Validators.required]),
				selectedUsers: new UntypedFormControl(null),
			},
			this.dateValidator,
		);

		// Set checkAll and previousTypeValue.
		this.previousTypeValue = this.f.type.value;

		// Get groups.
		this._subscriptions.add(
			this._groupSvc.groups.subscribe((next) => {
				if (!this.f.uuid.value || this.f.type.value !== 'peer') {
					this.groupFilter.options = next.filter(
						(option: Group) =>
							!!option.memberUUIDs && option.memberUUIDs.length > 0,
					);
					this.groupFilter.value = this.groupFilter.options.find(
						(option: Group) => option.uuid === this.form.value.group?.uuid,
					);
				} else {
					this.groupFilter.options = next.filter(
						(option: Group) =>
							!!option.memberUUIDs && option.memberUUIDs.length > 1,
					);
					this.groupFilter.value = this.groupFilter.options.find(
						(option: Group) => option.uuid === this.form.value.group?.uuid,
					);
				}
				const group = this.groupFilter.options.find(
					(option: Group) => option.uuid === this.form.value.group?.uuid,
				);
				if (!group) this.f.group.patchValue(null);
				else this.f.group.patchValue(group);
			}),
		);
		this._groupSvc.loadGroups();

		// Get users.
		this._subscriptions.add(
			this._usersSvc.users.subscribe((users) => {
				this.users = users;
			}),
		);
		this._usersSvc.loadUsers();
		this.form.controls.type.valueChanges.subscribe((value) => {
			if (value !== 'self') this.tabSet.nativeElement.classList.add('hide');
			else this.tabSet.nativeElement.classList.remove('hide');
		});
	}

	get f(): UntypedFormGroup['controls'] {
		return this.form.controls;
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this._modalSvc.show(template, {
			class: 'modal-dialog-centered',
		});
	}

	selectAllUsers(): void {
		const checkboxes = this.container.nativeElement.querySelectorAll(
			'input[type="checkbox"]',
		);
		const checked = Array.from(checkboxes).filter((check: HTMLInputElement) => {
			check.checked = this.checkAll.nativeElement.checked;
			console.log(check);
			return check.checked;
		});
		if (checked.length > 0) {
			this.selectedUsers = 'ALL';
		} else {
			this.selectedUsers = null;
		}
	}

	selectIndividual(event: Event): void {
		const check = event.target as HTMLInputElement;
		console.log(check);

		const selectedUsers: Array<User> | 'ALL' | [] = Array.isArray(
			this.selectedUsers,
		)
			? this.selectedUsers
			: this.selectedUsers === 'ALL'
				? this.filteredUsers
				: [];
		const user = this.users
			.filter((user: User) =>
				this.form.value.group.memberUUIDs.includes(user.uuid),
			)
			.find((user: User) => user.email === check.value);

		if (check.checked) {
			this.selectedUsers = [...selectedUsers, user];
		} else {
			this.selectedUsers = selectedUsers.filter(
				(u: User) => u.email !== check.value,
			);
		}
		const groupMembers = this.users.filter((user: User) =>
			this.form.value.group.memberUUIDs.includes(user.uuid),
		);
		this.checkAll.nativeElement.checked =
			this.selectedUsers.length === groupMembers.length;
	}

	// check if user is selected
	isSelected(email: string): boolean {
		return (
			this.selectedUsers === 'ALL' ||
			(Array.isArray(this.selectedUsers) &&
				this.selectedUsers.some((user) => user.email === email))
		);
	}

	//User Search
	searchUser(event: Event): void {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		if (this.form.value.group) {
			if (!value) {
				this.filteredUsers = this.users.filter((user) =>
					this.form.value.group.memberUUIDs.includes(user.uuid),
				);
			} else {
				this.filteredUsers = this.users
					.filter((user) =>
						this.form.value.group.memberUUIDs.includes(user.uuid),
					)
					.filter(
						(user: User) =>
							user.name.toLowerCase().includes(value.toLowerCase()) ||
							user.email.toLowerCase().includes(value.toLowerCase()),
					);
			}
		}
	}

	private dateValidator(
		form: UntypedFormGroup,
	): { [key: string]: boolean } | null {
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		if (new Date(form.value.startDate) < currentDate) {
			return { startDate: true };
		}
		if (
			!form.value.startDate ||
			!form.value.endDate ||
			new Date(form.value.startDate) > new Date(form.value.endDate)
		) {
			return { dateConflict: true };
		}
		return null;
	}

	public onGroupChange(): void {
		if (!this.form.value.group) return;
		const group = this.form.value.group;
		this.filteredUsers = this.users.filter((user) =>
			group.memberUUIDs.includes(user.uuid),
		);
		this.selectedUsers = null;
		this.checkAll.nativeElement.checked = false;
		const checkboxes = this.container.nativeElement.querySelectorAll(
			'input[type="checkbox"]',
		);
		Array.from(checkboxes).forEach((check: HTMLInputElement) => {
			check.checked = false;
		});
		if (group.memberUUIDs.length === 1) this.f.type.patchValue('self');
		if (
			!!this.form.value.startDate &&
			new Date(this.form.value.startDate) < new Date(group.startDate)
		)
			this.f.startDate.patchValue(null);
		if (
			!!this.form.value.startDate &&
			new Date(this.form.value.startDate) > new Date(group.endDate)
		)
			this.f.startDate.patchValue(null);
		if (
			!!this.form.value.endDate &&
			!!group.endDate &&
			new Date(this.form.value.endDate) < new Date(group.startDate)
		)
			this.f.endDate.patchValue(null);
		if (
			!!this.form.value.endDate &&
			!!group.endDate &&
			new Date(this.form.value.endDate) > new Date(group.endDate)
		)
			this.f.endDate.patchValue(null);
		this.minStartDate = new Date(group.startDate);
		if (!group.endDate) delete this.maxEndDate;
		else this.maxEndDate = new Date(group.endDate);
	}

	public onSubmit(): void {
		// Validate form.
		this.f.selectedUsers.patchValue(this.selectedUsers);
		this.form.markAllAsTouched();
		if (this.form.invalid) return;
		const loader: unique symbol = Symbol();
		if (!this.form.value.uuid) {
			localStorage.setItem(
				'createAssessment',
				JSON.stringify({ formValue: this.form.value, from: 'create' }),
			);
			this.closeModal();
			this._router.navigate(['/leader/assessment/skills']);
		} else {
			// Update assessment.
			localStorage.setItem(
				'createAssessment',
				JSON.stringify({ formValue: this.form.value, from: 'view' }),
			);
			this.closeModal();
			this._router.navigate(['/leader/assessment/skills']);
		}
	}

	closeModal() {
		this._modalSvc.hide();
	}

	ngAfterViewInit(): void {
		// Set Form Rules.
		this._subscriptions.add(
			this.form.valueChanges.subscribe((_next) => {
				if (
					Array.isArray(this.selectedUsers) &&
					this.selectedUsers?.length ===
						this.form.value.group?.memberUUIDs?.length
				) {
					this.selectedUsers = 'ALL';
				}
				// Leader Feedback is only for groups.
				if (this.f.type.value !== this.previousTypeValue) {
					this.previousTypeValue = this.f.type.value;
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
