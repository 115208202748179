import {
	Injectable,
	EventEmitter,
	ComponentFactoryResolver,
	ApplicationRef,
	Injector,
	EmbeddedViewRef,
	ComponentFactory,
	ComponentRef,
	ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CarouselComponent } from '@components';

export interface GenModalInfo {
	title?: string;
	subTitle?: string;
	message?: string;
	linkText?: string;
	linkDestination?: string;
	pdfSrc?: string;
	videoSrc?: string;
	transcript?: string;
}

@Injectable({
	providedIn: 'root',
})
export class CarouselService {
	// BehaviorSubject to manage configuration updates
	private configSubject: BehaviorSubject<{
		[key: string]: string | number | boolean;
	}> = new BehaviorSubject<{ [key: string]: string | number | boolean }>({});
	private enterAnimationClassSubject: BehaviorSubject<string> =
		new BehaviorSubject<string>('animate__fadeInLeft');
	private exitAnimationClassSubject: BehaviorSubject<string> =
		new BehaviorSubject<string>('animate__fadeOutLeft');
	private domElem: HTMLElement;
	private _carouselFactory: ComponentFactory<CarouselComponent>;
	private _carouselRef: ComponentRef<CarouselComponent>;

	carouselCreated: boolean = false;
	isCarouselVisible: boolean = false;
	currentIndex: number;

	constructor(
		private componentFRS: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
	) {}

	private onCloseEvent = new Subject<boolean>();
	private onOpenEvent = new Subject<boolean>();
	private onSlideEvent = new Subject<object>();

	onCloseEvent$ = this.onCloseEvent.asObservable();
	onOpenEvent$ = this.onOpenEvent.asObservable();
	onSlideEvent$ = this.onSlideEvent.asObservable();

	emitOpenEvent(value: boolean) {
		this.onOpenEvent.next(value);
	}
	emitCloseEvent(value: boolean) {
		this.onCloseEvent.next(value);
	}
	emitSlideEvent(value: object) {
		this.onSlideEvent.next(value);
	}

	toggleCarousel() {
		this.isCarouselVisible = !this.isCarouselVisible;
		if (this.isCarouselVisible) {
			this.emitOpenEvent(true);
		} else {
			this.onClose.emit('finished');
		}
	}
	// When the carousel starts to close
	onClose?: EventEmitter<unknown> = new EventEmitter<unknown>();

	// Method to get the current configuration as an observable
	getConfig(): Observable<{ [key: string]: string | number | boolean }> {
		return this.configSubject.asObservable();
	}

	// Method to update the configuration
	setConfig(configuration: { [key: string]: string | number | boolean }) {
		this.configSubject.next(configuration);
	}

	// Method to get the current enter animation class as an observable
	getEnterAnimationClass(): Observable<string> {
		return this.enterAnimationClassSubject.asObservable();
	}

	// Method to get the current exit animation class as an observable
	getExitAnimationClass(): Observable<string> {
		return this.exitAnimationClassSubject.asObservable();
	}

	// Method to update the enter animation class dynamically
	updateEnterAnimationClass(newClass: string): void {
		this.enterAnimationClassSubject.next(newClass);
	}

	// Method to update the exit animation class dynamically
	updateExitAnimationClass(newClass: string): void {
		this.exitAnimationClassSubject.next(newClass);
	}

	setCurrentIndex(index: number) {
		this.currentIndex = index;
	}

	getCurrentIndex(): number {
		return this.currentIndex;
	}

	destroyCarousel() {
		this.carouselCreated = false;
		if (this._carouselRef) this._carouselRef.destroy();
	}

	// Method to create a new component and add it to the DOM
	createComponent(): CarouselComponent | null {
		this._carouselFactory =
			this.componentFRS.resolveComponentFactory(CarouselComponent);
		this._carouselRef = this._carouselFactory.create(this.injector);
		this.appRef.attachView(this._carouselRef.hostView);
		this.domElem = (this._carouselRef.hostView as EmbeddedViewRef<any>)
			.rootNodes[0] as HTMLElement;
		document.body.appendChild(this.domElem);
		this.carouselCreated = true;
		this._carouselRef.onDestroy(() => {
			this.appRef.detachView(this._carouselRef.hostView);
			this.carouselCreated = false;
		});
		return this._carouselRef.instance;
	}

	// Method to append the component to another element
	appendComponentContainerView(
		viewContainerRef: ViewContainerRef,
	): CarouselComponent | null {
		viewContainerRef.insert(this._carouselRef.hostView);
		return this._carouselRef.instance;
	}
}
