import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	LanguageService,
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	VOLATILE_KEYS,
} from '@services/public';
import { WWYDData, WWYD_PAGE, WWYD_PREVIOUS_PAGE } from './common';
import { Subscription } from 'rxjs';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import { APIErrors, isAPIErrors } from '@util';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.main';

@Component({
	selector: 'app-member-skill-builder-wwyd',
	templateUrl: './wwyd.component.html',
	styleUrls: ['./wwyd.component.scss'],
})
export class MemberSkillBuilderWwydComponent implements OnInit, OnDestroy {
	WWYD_PAGE: typeof WWYD_PAGE = WWYD_PAGE;
	data: WWYDData;
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
	};

	constructor(
		private _languageSvc: LanguageService,
		private _router: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});

		// Get wwyd data.
		this.data = this._storageSvc.getTempStorage(TEMP_KEYS.WWYD);
		const goalPlanUUID = this._storageSvc.getVolatileStorage(
			VOLATILE_KEYS.GOAL_PLAN_UUID,
			true,
		);
		if (!this.data) {
			this._skillBuilderSvc
				.getStatus(SKILL_BUILDER_TYPES.WWYD, goalPlanUUID)
				.subscribe((res: WWYDData | APIErrors) => {
					if (!isAPIErrors(res) && !this.data) {
						this.data = {
							...res,
							page: WWYD_PAGE.DILEMMA,
						};

						this._setStorageSubscription();
					}
				});
		} else this._setStorageSubscription();
	}

	private _setStorageSubscription() {
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
					this.data = update.value[TEMP_KEYS.WWYD];
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) this.data = null;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.WWYD)
					this.data = update.value;
				if (!this.data && !this._router.getCurrentNavigation())
					this._router.navigate(['member/dashboard']);
			}),
		);
	}

	back() {
		let page =
			this.data.page === WWYD_PAGE.FIRST_CHOICE && this.data.dilemma?.custom
				? WWYD_PAGE.CUSTOM_DILEMMA
				: WWYD_PREVIOUS_PAGE[this.data.page];
		if (!page) {
			this._router.navigate(['member/dashboard']);
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, { ...this.data, page });
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, null);
	}
}
