// Tajik
import { LocaleData } from 'ngx-bootstrap/chronos';

export const tgLocale: LocaleData = {
	months: [
		'Январ',
		'Феврал',
		'Март',
		'Апрел',
		'Май',
		'Июн',
		'Июл',
		'Август',
		'Сентябр',
		'Октябр',
		'Ноябр',
		'Декабр',
	],
	monthsShort: [
		'Янв',
		'Фев',
		'Мар',
		'Апр',
		'Май',
		'Июн',
		'Июл',
		'Авг',
		'Сен',
		'Окт',
		'Ноя',
		'Дек',
	],
	weekdays: [
		'Якшанбе',
		'Душанбе',
		'Сешанбе',
		'Чоршанбе',
		'Панҷшанбе',
		'Ҷумъа',
		'Шанбе',
	],
	weekdaysShort: ['Як', 'Ду', 'Се', 'Чо', 'Пан', 'Ҷу', 'Ша'],
	weekdaysMin: ['Як', 'Ду', 'Се', 'Чо', 'Па', 'Ҷу', 'Ша'],
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd, D MMMM YYYY HH:mm',
	},
	calendar: {
		sameDay: '[Имрӯз] LT',
		nextDay: '[Фардо] LT',
		nextWeek: 'dddd [ҳафта] LT',
		lastDay: '[Давр] LT',
		lastWeek: '[Хонед] dddd [ҳафта] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'ба %s',
		past: '%s пеш',
		s: 'якчанд сония',
		ss: '%d сония',
		m: 'як дақиқа',
		mm: '%d дақиқа',
		h: 'як соат',
		hh: '%d соат',
		d: 'як рӯз',
		dd: '%d рӯз',
		M: 'як моҳ',
		MM: '%d моҳ',
		y: 'як сол',
		yy: '%d сол',
	},
	ordinal: (num: number): string => `${num}-ум`,
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
};
