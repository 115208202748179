import { Injectable } from '@angular/core';

import { LanguageService } from './language.service';
import { Domain, Skill } from './domain2.service';

/**
 * @deprecated
 */
@Injectable({
	providedIn: 'root',
})
export class DomainService {
	private _LOGOS: any = {
		1: 'assets/img/self-management.svg',
		2: 'assets/img/innovation.svg',
		3: 'assets/img/emotional-resilience.svg',
		4: 'assets/img/social-engagement.svg',
		5: 'assets/img/cooperation.svg',
	};

	private _loading: Promise<any>;
	private _domains: Array<Domain> = [];
	private _skills: Array<Skill> = [];
	private _all: any = {};

	constructor(private languageSvc: LanguageService) {
		const path: string = 'domains';
		this._loading = this.languageSvc.get([path]).then((value) => {
			value = this._generateArrays(value);
			this._domains = value[path].filter((domain) => domain.skills);
			for (const domain of this._domains) {
				this._skills.push(...domain.skills);
				this._all[domain.uid] = domain;
				for (const skill of domain.skills) this._all[skill.uid] = skill;
			}
		});
	}

	private _generateArrays(value: {
		[key: string]: any;
	}): typeof value | Array<any> {
		const keys = Object.keys(value);
		for (const key of keys)
			if (typeof value[key] === 'object' && value[key] !== null)
				value[key] = this._generateArrays(value[key]);
		if (keys.length === 0 || !/^\d+$/.test(keys[0])) return value;
		return Object.entries(value)
			.sort(([key1], [key2]) => +key1 - +key2)
			.map(([_key, value]) => value); // eslint-disable-line
	}

	async getDomains(): Promise<Array<Domain>> {
		await this._loading;
		return this._domains;
	}

	async getDomain(domainUID: number): Promise<Domain> {
		await this._loading;
		return this._all[domainUID];
	}

	async getSkills(): Promise<Array<Skill>> {
		await this._loading;
		return this._skills;
	}

	async getSkill(skillUID: number): Promise<Skill> {
		await this._loading;
		return this._all[skillUID];
	}

	async getAll(): Promise<any> {
		await this._loading;
		return this._all;
	}

	getLogo(domainUID: number): string {
		return this._LOGOS[domainUID.toString()];
	}
}
