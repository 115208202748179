<div class="member-dashboard-rewards">
	<div class="header">
		<div class="header-left">
			<div class="avatar">
				<div class="avatar-edit" (click)="editAvatarOnClick()">
					<img class="avatar-edit-image" src="assets/img/edit-icon.png" />
				</div>
				<div
					class="avatar-image"
					[ngStyle]="getAvatarStyle(user.avatar, 80)"
					alt="user-avatar"
				></div>
			</div>

			<div class="welcome-instructions">
				<h5 class="welcome">{{ page.welcome }}</h5>
				<p class="instructions">{{ page.instructions }}</p>
			</div>

			<div class="tabs">
				<div
					class="tab {{ selectedTab === TABS.LEADERBOARD ? 'selected' : '' }}"
					(click)="selectedTab = TABS.LEADERBOARD"
				>
					<div class="tab-rank {{ user.delta }}"></div>
					<span class="tab-text">
						{{ user.rank }}
					</span>
				</div>
				<div
					class="tab {{ selectedTab === TABS.METRICS ? 'selected' : '' }}"
					(click)="selectedTab = TABS.METRICS"
				>
					<div class="tab-block">
						<img class="crown" src="assets/img/crown.svg" alt="crown" />
					</div>
					<span class="tab-text">
						{{ user.points }}
					</span>
				</div>
				<div
					class="tab {{ selectedTab === TABS.BADGES ? 'selected' : '' }}"
					(click)="selectedTab = TABS.BADGES"
				>
					<div class="tab-relative">
						<img class="tab-icons" src="assets/img/progress.png" />
					</div>
				</div>
				<div
					class="tab {{ selectedTab === TABS.ALL ? 'selected' : '' }}"
					(click)="selectedTab = TABS.ALL"
				>
					<img class="tab-icons" src="assets/img/top-list.png" />
				</div>
			</div>

			<app-member-dashboard-rewards-journey
				class="journey-small"
				[domains]="journeyDomains"
			></app-member-dashboard-rewards-journey>
		</div>

		<app-member-dashboard-rewards-journey
			class="journey-large"
			[domains]="journeyDomains"
		></app-member-dashboard-rewards-journey>
	</div>
	<div class="body">
		<app-leaderboard
			class="leaderboard {{
				[TABS.ALL, TABS.LEADERBOARD].includes(selectedTab) ? '' : 'hide-small'
			}}"
			[user]="user"
		></app-leaderboard>
		<div class="body-right">
			<app-member-dashboard-rewards-metrics
				class="{{
					[TABS.ALL, TABS.METRICS].includes(selectedTab) ? '' : 'hide-small'
				}}"
			></app-member-dashboard-rewards-metrics>
			<app-member-dashboard-rewards-skill-builder-badges
				class="hide-small"
				[title]="page.dailySkillBuilderBadges"
				[skillBuilderBadges]="dailySkillBuilderBadges"
			></app-member-dashboard-rewards-skill-builder-badges>
			<app-member-dashboard-rewards-skill-builder-badges
				class="hide-small"
				[title]="page.repeatableSkillBuilderBadges"
				[skillBuilderBadges]="repeatableSkillBuilderBadges"
			></app-member-dashboard-rewards-skill-builder-badges>
			<app-member-dashboard-rewards-challenge-badges
				class="hide-small"
				[challengeBadges]="challengeBadges"
			></app-member-dashboard-rewards-challenge-badges>

			<div
				class="badges-small {{
					[TABS.ALL, TABS.BADGES].includes(selectedTab) ? '' : 'hide'
				}} hide-large"
			>
				<div class="badges-small-title">
					{{ page.badgesEarned }}
				</div>
				<div class="badges-container">
					<tabset>
						<tab heading="Daily Skill Builders">
							<app-member-dashboard-rewards-skill-builder-badges
								[skillBuilderBadges]="dailySkillBuilderBadges"
							></app-member-dashboard-rewards-skill-builder-badges>
						</tab>
						<tab heading="Repeatable Skill Builders">
							<app-member-dashboard-rewards-skill-builder-badges
								[skillBuilderBadges]="repeatableSkillBuilderBadges"
							></app-member-dashboard-rewards-skill-builder-badges>
						</tab>
						<tab heading="Weekly Challenges">
							<app-member-dashboard-rewards-challenge-badges
								[challengeBadges]="challengeBadges"
							></app-member-dashboard-rewards-challenge-badges>
						</tab>
					</tabset>
				</div>
			</div>
		</div>
	</div>
</div>
