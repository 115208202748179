import { Component, OnDestroy, OnInit } from '@angular/core';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import {
	StorageService,
	TEMP_KEYS,
	TEMP_KEY_PREFIX,
	VOLATILE_KEYS,
} from '@services/public';
import { ABSTRACT_PAGE, ABSTRACT_PREVIOUS_PAGE, AbstractData } from './common';
import { Subscription } from 'rxjs';
import { APIErrors, isAPIErrors } from '@util';
import { Router } from '@angular/router';

@Component({
	selector: 'app-member-skill-builder-abstract',
	templateUrl: './abstract.component.html',
	styleUrls: ['./abstract.component.scss'],
})
export class MemberSkillBuilderAbstractComponent implements OnInit, OnDestroy {
	title: string;
	ABSTRACT_PAGE: typeof ABSTRACT_PAGE = ABSTRACT_PAGE;
	data: AbstractData;
	private _subscriptions: Subscription = new Subscription();

	constructor(
		private _router: Router,
		private _skillBuilderSvc: SkillBuilderService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get abstract data.
		this.data = this._storageSvc.getTempStorage(TEMP_KEYS.ABSTRACT);
		const goalPlanUUID = this._storageSvc.getVolatileStorage(
			VOLATILE_KEYS.GOAL_PLAN_UUID,
			true,
		);
		if (!this.data) {
			this._skillBuilderSvc
				.getStatus(SKILL_BUILDER_TYPES.ABSTRACT, goalPlanUUID)
				.subscribe((res: AbstractData | APIErrors) => {
					if (!isAPIErrors(res) && !this.data) {
						this.data = {
							...res,
							page: ABSTRACT_PAGE.EXAMPLE,
						};

						this._setStorageSubscription();
					}
				});
		} else this._setStorageSubscription();

		// Set the title.
		this.title = this._skillBuilderSvc.getName(SKILL_BUILDER_TYPES.ABSTRACT);
	}

	private _setStorageSubscription() {
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
					this.data = update.value[TEMP_KEYS.ABSTRACT];
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) this.data = null;
				if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ABSTRACT)
					this.data = update.value;
				if (!this.data && !this._router.getCurrentNavigation())
					this._router.navigate(['member/dashboard']);
			}),
		);
	}

	back() {
		let page = ABSTRACT_PREVIOUS_PAGE[this.data.page];
		if (!page) {
			this._router.navigate(['member/dashboard']);
			return;
		}
		this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, { ...this.data, page });
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, null);
	}
}
