<div class="main-container w-100" *ngIf="display">
	<div class="container-fluid pb-5-sm-c pb-5-md-c">
		<div class="row">
			<div class="col-sm-12 col-lg-6">
				<div *ngIf="isPie" class="col-12 px-0">
					<div class="card card-rounded">
						<div class="col-12 donut-wrapper position-relative">
							<app-skill-builder-pie-chart
								[title]="page.pieTitle"
								[data]="pieData"
								class="w-100 mb-3"
							></app-skill-builder-pie-chart>
						</div>
					</div>
				</div>

				<div class="col-12 px-0">
					<div class="card card-rounded">
						<div class="card-body pt-3 d-flex align-items-center">
							<div class="col-12 d-flex align-items-center px-0">
								<div
									class="count-box d-flex align-items-center justify-content-center"
								>
									{{ positiveMessagesDelivered }}
								</div>
								<div class="analysis-txt">
									{{ page.positiveMessagesDelivered }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-12 col-lg-6">
				<div class="col-12 px-0">
					<div class="card card-rounded">
						<div class="card-header card-header-with-ico">
							<div class="col-12">
								<div class="row">
									<div class="col-10 pl-0">
										{{ page.peopleComplimented }}
									</div>
									<div
										class="col-2 d-flex align-items-center justify-content-end"
									>
										<div
											*ngIf="peopleList.length > show || show !== SHOW_MIN"
											class="circle-md d-flex align-items-center justify-content-center"
											(click)="toggleMore()"
										>
											<i
												class="{{
													show === SHOW_MIN
														? ICON_CLASSES.DOWN
														: ICON_CLASSES.UP
												}}"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<ul class="list-bordered-btm list-bordered-btm-dotted px-2">
								<li
									*ngFor="
										let person of peopleList | slice: 0 : show;
										let i = index
									"
								>
									<div class="col-12">
										<div class="font-md txt-green">
											{{ person.name }}
										</div>
									</div>
									<div class="col-12">
										<div class="row">
											<div class="col-auto pr-4 border-right">
												<div class="lbl-gray">
													{{ page.totalMessages }}
													<span class="lbl-gray-md pl-1">
														{{ person.messages }}
													</span>
												</div>
											</div>
											<div class="col-auto px-2 px-lg-4">
												{{ page.positivelyReceived }}
												<span class="lbl-gray-md pl-1">
													{{ person.positivePercentage }}%
												</span>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div
							*ngIf="peopleList.length > show || show !== SHOW_MIN"
							class="btn-row py-4 d-flex align-items-center justify-content-center"
						>
							<button
								type="button"
								(click)="toggleMore()"
								class="btn btn-small btn-outline btn-outline-purple"
							>
								{{ show === SHOW_MIN ? page.viewMore : page.viewLess }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
