import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
	PublicAssessmentExternalComponent,
	PublicEmailVerifyComponent,
	PublicLoginComponent,
	PublicPasswordForgotComponent,
	PublicPasswordResetComponent,
	PublicRegisterComponent,
	SettingsComponent,
	UnsubscribeComponent,
} from '@pages/public';

import {
	InventoryComponent,
	MemberAssessmentExternalListComponent,
	MemberAssessmentExternalReportComponent,
	MemberAssessmentExternalUpdateSkillsComponent,
	MemberAssessmentListComponent,
	MemberAssessmentPeerComponent,
	MemberDashboardActivitiesComponent,
	MemberDashboardComponent,
	MemberDashboardReportsComponent,
	MemberDashboardRewardsComponent,
	MemberSkillBuilderAbstractComponent,
	MemberSkillBuilderAdventureComponent,
	MemberSkillBuilderBrainComponent,
	MemberSkillBuilderDecisionComponent,
	MemberSkillBuilderGoalComponent,
	MemberSkillBuilderJournalComponent,
	MemberSkillBuilderMessageComponent,
	MemberSkillBuilderReflectionComponent,
	MemberSkillBuilderStressComponent,
	MemberSkillBuilderWwydComponent,
	MemberTasksComponent,
} from '@pages/member';

import {
	LeaderAssessmentListComponent,
	LeaderAssessmentSkillsComponent,
	LeaderAssessmentSubgroupsComponent,
	LeaderAssessmentViewComponent,
	LeaderDashboardComponent,
	LeaderReportComponent,
} from '@pages/leader';

import { ChallengesComponent } from '@pages/member/challenges/challenges.component';
import { MemberMainComponent } from '@pages/member/main';
import { MemberProfileComponent } from '@pages/member/profile/profile.component';
import { DummyComponent } from '@pages/public/dummy/dummy.component';
import { ResearcherDashboardComponent } from '@pages/researcher/dashboard/dashboard.component';
import { TrainDashboardComponent } from '@pages/researcher/dashboard/train-dashboard/train-dashboard.component';
import { JoinGroupTagComponent } from '@pages/member/join-group-tag/join-group-tag.component';
import { AcceptInvitationComponent } from '@pages/public/accept-invitation/accept-invitation.component';
import { MemberAssessmentSelfComponent } from '@pages/member/assessment/self/self.component';
import { MemberAssessmentLeaderComponent } from '@pages/member/assessment/leader/leader.component';

const routes: Routes = [
	{
		path: 'member',
		component: MemberMainComponent,
		children: [
			{
				path: 'profile',
				component: MemberProfileComponent,
			},
			{
				path: 'assessment/external/list',
				component: MemberAssessmentExternalListComponent,
			},
			{
				path: 'assessment/external/report/:uuid',
				component: MemberAssessmentExternalReportComponent,
			},
			{
				path: 'assessment/external/update-skills',
				component: MemberAssessmentExternalUpdateSkillsComponent,
			},
			{
				path: 'assessment/list',
				component: MemberAssessmentListComponent,
			},
			{
				path: 'assessment/peer/:uuid',
				component: MemberAssessmentPeerComponent,
			},
			{
				path: 'assessment/self/:uuid',
				component: MemberAssessmentSelfComponent,
			},
			{
				path: 'assessment/leader/:uuid',
				component: MemberAssessmentLeaderComponent,
			},
			{
				path: 'challenges',
				component: ChallengesComponent,
			},
			{
				path: 'tasks',
				component: MemberTasksComponent,
			},
			{
				path: 'settings',
				component: SettingsComponent,
			},
			{
				path: 'dashboard',
				component: MemberDashboardComponent,
				children: [
					{
						path: 'activities',
						component: MemberDashboardActivitiesComponent,
					},
					{
						path: 'reports',
						component: MemberDashboardReportsComponent,
					},
					{
						path: 'rewards',
						component: MemberDashboardRewardsComponent,
					},
					{
						path: '**',
						redirectTo: 'activities',
					},
				],
			},
			{
				path: 'join',
				component: JoinGroupTagComponent,
			},

			// Inventory.
			{
				path: 'inventory',
				component: InventoryComponent,
			},

			// Skill builders.
			{
				path: 'skill-builder/abstract',
				component: MemberSkillBuilderAbstractComponent,
			},
			{
				path: 'skill-builder/adventure',
				component: MemberSkillBuilderAdventureComponent,
			},
			{
				path: 'skill-builder/brain',
				component: MemberSkillBuilderBrainComponent,
			},
			{
				path: 'skill-builder/decision',
				component: MemberSkillBuilderDecisionComponent,
			},
			{
				path: 'skill-builder/goal',
				component: MemberSkillBuilderGoalComponent,
			},
			{
				path: 'skill-builder/journal',
				component: MemberSkillBuilderJournalComponent,
			},
			{
				path: 'skill-builder/message',
				component: MemberSkillBuilderMessageComponent,
			},
			{
				path: 'skill-builder/reflection',
				component: MemberSkillBuilderReflectionComponent,
			},
			{
				path: 'skill-builder/stress',
				component: MemberSkillBuilderStressComponent,
			},
			{
				path: 'skill-builder/wwyd',
				component: MemberSkillBuilderWwydComponent,
			},
		],
	},

	{
		path: 'researcher',
		component: MemberMainComponent,
		children: [
			{
				path: 'dashboard',
				component: ResearcherDashboardComponent,
			},
			{
				path: 'train-dashboard',
				component: TrainDashboardComponent,
			},
		],
	},
	{
		path: 'leader',
		component: MemberMainComponent,
		children: [
			{
				path: 'dashboard',
				component: LeaderDashboardComponent,
			},
			{
				path: 'assessment/list',
				component: LeaderAssessmentListComponent,
			},
			{
				path: 'assessment/skills',
				component: LeaderAssessmentSkillsComponent,
			},
			{
				path: 'assessment/subgroups',
				component: LeaderAssessmentSubgroupsComponent,
			},
			{
				path: 'assessment/view/:uuid',
				component: LeaderAssessmentViewComponent,
			},

			{
				path: 'dashboard',
				component: LeaderDashboardComponent,
			},
			{
				path: 'dashboard',
				component: LeaderDashboardComponent,
			},
			{
				path: 'report',
				component: LeaderReportComponent,
			},
			{
				path: 'dashboard',
				component: LeaderDashboardComponent,
			},
		],
	},
	{
		path: 'admin',
		component: MemberMainComponent,
		children: [],
	},
	{
		path: 'dummy',
		component: DummyComponent,
	},
	{
		path: 'assessment/external/:code',
		component: PublicAssessmentExternalComponent,
	},
	{
		path: 'email/verify/:code',
		component: PublicEmailVerifyComponent,
	},
	{
		path: 'login',
		component: PublicLoginComponent,
	},
	{
		path: 'password/forgot',
		component: PublicPasswordForgotComponent,
	},
	{
		path: 'password/reset/:code',
		component: PublicPasswordResetComponent,
	},
	{
		path: 'register',
		component: PublicRegisterComponent,
	},
	{
		path: 'account/organization/request/:code',
		component: AcceptInvitationComponent,
	},
	{
		path: 'unsubscribe',
		component: UnsubscribeComponent,
	},
	{
		path: '**',
		redirectTo: 'login',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
