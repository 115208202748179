import {
	HttpContextToken,
	HttpEvent,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { config } from 'environment';
import { Observer, ReplaySubject, Subscription } from 'rxjs';

// Controls non-production debug messages for this module.
const DEBUG = false;

// Default cache max age is 1 second.
export const HTTP_CACHE_MAX_AGE = new HttpContextToken<number>(() => 1000);

export class ActiveHttpRequest<T> implements Observer<HttpEvent<T>> {
	response: ReplaySubject<HttpEvent<T>> = new ReplaySubject<HttpEvent<any>>();
	subscription: Subscription = new Subscription();
	checkTokens: () => Promise<boolean> = async () => {
		return false;
	};
	send: () => void = () => {};
	clearLoader: () => void = () => {};
	completed: boolean = false;
	expires: number = 0;

	constructor(
		public request: HttpRequest<T>,
		public nextHandler: HttpHandler,
	) {
		this.expires =
			new Date().valueOf() +
			(this.request.context?.get(HTTP_CACHE_MAX_AGE) ||
				HTTP_CACHE_MAX_AGE.defaultValue());
		if (!config.production && DEBUG)
			console.debug({
				request,
				currentTime: new Date().valueOf(),
				expires: this.expires,
			});
	}

	next(value: HttpEvent<T>): void {
		this.response.next(value);
	}

	async error(err: any): Promise<void> {
		if (err.status === 401) {
			if (await this.checkTokens()) {
				this.send();
				return;
			} else {
				this.response.error(new Error('Invalid access tokens.'));
			}
		} else {
			this.response.error(err);
		}
		this.completed = true;
		this.clearLoader();
	}

	complete(): void {
		this.response.complete();
		this.completed = true;
		this.clearLoader();
	}
}
