<!-- Track Challenge -->
<div
	*ngIf="navigationFrom !== 'dashboard'; else otherContent"
	class="tabHeadMain innertabHeadMain-sm d-none d-md-flex"
>
	<div class="container px-0">
		<div
			class="tabHead text-center-sm d-md-flex align-items-center"
			routerLink="/member/dashboard"
		>
			<span class="cursor-pointer d-none d-md-flex pr-2">
				<i class="fas fa-arrow-left"></i>
			</span>
			<span class="txt-md">{{ pageLanguage?.skillBuilders }}</span>
		</div>
	</div>
</div>
<!-- Header -->

<!-- Achieve a Goal challenege -->
<div class="main-container" *ngIf="navigationFrom !== 'dashboard'">
	<div class="container-fluid px-0 pb-5-sm-c pn-5-md-c">
		<div class="col-12">
			<div class="row">
				<div class="col-md-12 col-lg-6 challenge-card challenge-card-accordion">
					<div class="card card-accordion h-100">
						<div class="card-header align-items-center px-sm-2-c">
							<div
								class="col-sm-6"
								class="plus-icon"
								(click)="expandAcc()"
								aria-controls="collapseOne"
							>
								<i
									[ngClass]="[
										accExpanded == true
											? 'fas fa-plus icon'
											: 'fa fa-minus icon',
									]"
									aria-hidden="false"
								></i>
							</div>
							{{ pageLanguage?.achieveAGoal }}
							<span class="track-btn right-btn">
								<button
									type="button"
									(click)="achieveGoal()"
									class="btn btn-small btin-icon btn-primary ml-2"
								>
									{{ pageLanguage?.start }}
									<img
										src="./../../../../assets/img/new/play-filled.svg"
										alt="play-filled"
										class="play-icon flex-shrink-0"
									/>
								</button>
							</span>
						</div>
						<div
							class="{{
								accExpanded == true
									? 'card-body collapse collapse'
									: 'card-body collapse collapse show'
							}}"
							aria-labelledby="headingOne"
						>
							<ul class="list-group">
								<li
									class="list-group-item d-flex justify-content-between align-items-center"
									*ngFor="let goal of goals"
								>
									<div class="col-sm-6">{{ goal.name }}</div>
									<div class="col-sm-6 d-flex justify-content-end pr-0">
										<span class="track-btn" *ngIf="!goal.start">
											<button
												type="button"
												class="btn btn-small btn-outline btn-outline-danger"
												(click)="endAchieveGoal(goal)"
											>
												{{ pageLanguage?.end }}
											</button>
											<button
												type="button"
												class="btn btn-small btin-icon btn-secondary ml-3"
												(click)="trackGoal(goal)"
											>
												{{ pageLanguage?.track }}
												<img
													src="./../../../../assets/img/new/play-filled.svg"
													alt="play-filled"
													class="play-icon flex-shrink-0"
												/>
											</button>
										</span>
										<span class="track-btn" *ngIf="goal.start">
											<button
												type="button"
												(click)="endAchieveGoal(goal)"
												class="btn btn-small btn-outline btn-outline-danger"
											>
												{{ pageLanguage?.end }}
											</button>
											<button
												type="button"
												(click)="achieveGoalChallenges(goal.type)"
												class="btn btn-small btin-icon btn-primary ml-2"
											>
												{{ pageLanguage?.start }}
												<img
													src="./../../../../assets/img/new/play-filled.svg"
													alt="play-filled"
													class="play-icon flex-shrink-0"
												/>
											</button>
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- challenge-card 1 -->

				<div class="col-md-12 col-lg-6 challenge-card">
					<div class="card h-100">
						<div class="card-body content-center-sm">
							<ul class="list-group track-list-name item-header">
								<li
									class="list-group-item d-flex justify-content-between align-items-center"
									*ngFor="let challenge of challenges"
								>
									<div class="col-sm-6">{{ challenge.name }}</div>
									<span class="" *ngIf="challenge.start">
										<button
											type="button"
											(click)="startChalleng(challenge.type)"
											class="btn btn-small btin-icon btn-primary ml-2"
										>
											{{ pageLanguage?.start }}
											<img
												src="./../../../../assets/img/new/play-filled.svg"
												alt="play-filled"
												class="play-icon flex-shrink-0"
											/>
										</button>
										<ng-template #starttemplate>
											<div class="modal-body text-center modal-confirm">
												<div>{{ pageLanguage?.startOnceADay }}</div>
												<div class="pt-4">
													<button
														(click)="decline()"
														type="button"
														class="btn btn-md btn-primary"
													>
														{{ pageLanguage?.ok }}
													</button>
												</div>
											</div>
										</ng-template>
									</span>
									<div
										class="col-sm-6 d-flex justify-content-end pr-0"
										*ngIf="!challenge.start"
									>
										<button
											type="button"
											class="btn btn-small btn-outline btn-outline-danger ml-3"
											(click)="end(challenge.uuid, challenge.type)"
										>
											{{ pageLanguage?.end }}
										</button>
										<button
											type="button"
											(click)="
												trackChalleng(challenge.type, challenge.completed)
											"
											class="btn btn-small btin-icon btn-secondary ml-3"
										>
											{{ pageLanguage?.track }}
											<img
												src="./../../../../assets/img/new/play-filled.svg"
												alt="play-filled"
												class="play-icon flex-shrink-0"
											/>
										</button>

										<ng-template #template>
											<div class="modal-body text-center modal-confirm">
												<div>{{ pageLanguage?.trackOnceADay }}</div>
												<div class="pt-4">
													<button
														(click)="decline()"
														type="button"
														class="btn btn-md btn-primary"
													>
														{{ pageLanguage?.ok }}
													</button>
												</div>
											</div>
										</ng-template>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- challenge-card 2 -->
			</div>
		</div>
	</div>
</div>
<!-- Track Challenge -->

<ng-template #otherContent>
	<div class="col-md-12 challenge-card-tab challenge-card-accordion px-0">
		<div class="card card-accordion h-100">
			<div
				class="card-header align-items-center px-sm-2-c d-flex justify-content-between"
			>
				<div class="col-9 px-0">
					<div
						class="plus-icon"
						(click)="expandAcc()"
						aria-controls="collapseOne"
					>
						<i
							[ngClass]="[
								accExpanded == true ? 'fas fa-plus icon' : 'fa fa-minus icon',
							]"
							aria-hidden="false"
						></i>
					</div>
					<span class="font-weight-bold">{{ pageLanguage?.achieveAGoal }}</span>
					<div class="col-6 d-flex justify-content-start">
						<span class="track-btn right-btn">
							<button
								type="button"
								(click)="achieveGoal()"
								class="btn btn-small btin-icon btn-primary ml-2"
							>
								{{ pageLanguage?.start }}
								<img
									src="./../../../../assets/img/new/play-filled.svg"
									alt="play-filled"
									class="play-icon flex-shrink-0"
								/>
							</button>
						</span>
					</div>
				</div>
				<div class="col-3 col-md-3">
					<div _ngcontent-tro-c213="" class="single-chart position-relative">
						<img
							src="assets/img/Achieve-Goal.svg"
							class="img-fluid mid-img-chart img-sm goal-img"
							alt="play-filled"
						/>
						<svg viewBox="0 0 36 36" [attr.class]="this.goalLevel?.cssprop">
							<path
								class="circle-bg-chart"
								d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
							<path
								class="circle"
								[attr.stroke-dasharray]="this.goalLevel?.strokes"
								d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
						</svg>
					</div>
					<div class="d-flex align-items-center justify-content-center">
						<div class="level-badge">
							<span
								>{{ pageLanguage?.level | slice: 0 : 1 }}
								{{ this.goalLevel?.level }}</span
							>
						</div>
					</div>
				</div>
			</div>
			<div
				class="{{
					accExpanded == true
						? 'card-body collapse collapse'
						: 'card-body pt-0 px-0 pb-0 collapse collapse show'
				}}"
				aria-labelledby="headingOne"
			>
				<ul class="list-group w-100">
					<li
						class="list-group-item d-flex justify-content-between align-items-center"
						*ngFor="let goal of goals"
					>
						<div class="col-9 col-md-9 font-weight-bold">
							<div>{{ goal.name }}</div>
							<span class="track-btn" *ngIf="!goal.start">
								<button
									type="button"
									class="btn btn-small btin-icon btn-secondary"
									(click)="trackGoal(goal)"
								>
									{{ pageLanguage?.track }}
									<img
										src="./../../../../assets/img/new/play-filled.svg"
										alt="play-filled"
										class="play-icon flex-shrink-0"
									/>
								</button>
								<button
									type="button"
									class="btn btn-small btn-outline btn-outline-danger ml-2"
									(click)="endAchieveGoal(goal)"
								>
									{{ pageLanguage?.end }}
								</button>
							</span>
							<span class="track-btn" *ngIf="goal.start">
								<button
									type="button"
									(click)="achieveGoalChallenges(goal)"
									class="btn btn-small btin-icon btn-primary ml-2"
								>
									{{ pageLanguage?.start }}
									<img
										src="./../../../../assets/img/new/play-filled.svg"
										alt="play-filled"
										class="play-icon flex-shrink-0"
									/>
								</button>
								<button
									type="button"
									(click)="endAchieveGoal(goal)"
									class="btn btn-small btn-outline btn-outline-danger"
								>
									{{ pageLanguage?.end }}
								</button>
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<!-- challenge-card 1 -->

	<div class="col-md-12 challenge-card">
		<div class="card h-100">
			<div class="card-body content-center-sm">
				<ul class="list-group track-list-name item-header w-100">
					<li
						class="list-group-item d-flex justify-content-between align-items-center"
						*ngFor="let challenge of challenges"
					>
						<div class="col-9 col-md-9 font-weight-bold">
							<div>{{ challenge.name }}</div>
							<span class="track-btn" *ngIf="challenge.start">
								<button
									type="button"
									(click)="startChalleng(challenge.type)"
									class="btn btn-small btin-icon btn-primary"
								>
									{{ pageLanguage?.start }}
									<img
										src="./../../../../assets/img/new/play-filled.svg"
										alt="play-filled"
										class="play-icon flex-shrink-0"
									/>
								</button>
								<ng-template #starttemplate>
									<div class="modal-body text-center modal-confirm">
										<div>{{ pageLanguage?.startOnceADay }}</div>
										<div class="pt-4">
											<button
												(click)="decline()"
												type="button"
												class="btn btn-md btn-primary"
											>
												{{ pageLanguage?.ok }}
											</button>
										</div>
									</div>
								</ng-template>
							</span>
							<span class="track-btn" *ngIf="!challenge.start">
								<button
									type="button"
									(click)="trackChalleng(challenge.type, challenge.completed)"
									class="btn btn-small btin-icon btn-secondary"
								>
									{{ pageLanguage?.track }}
									<img
										src="./../../../../assets/img/new/play-filled.svg"
										alt="play-filled"
										class="play-icon flex-shrink-0"
									/>
								</button>
								<button
									type="button"
									class="btn btn-small btn-outline btn-outline-danger ml-2"
									(click)="end(challenge.uuid, challenge.type)"
								>
									{{ pageLanguage?.end }}
								</button>

								<ng-template #template>
									<div class="modal-body text-center modal-confirm">
										<div>{{ pageLanguage?.trackOnceADay }}</div>
										<div class="pt-4">
											<button
												(click)="decline()"
												type="button"
												class="btn btn-md btn-primary"
											>
												{{ pageLanguage?.ok }}
											</button>
										</div>
									</div>
								</ng-template>
							</span>
						</div>
						<div class="col-3 col-md-3">
							<div
								_ngcontent-tro-c213=""
								class="single-chart position-relative challenge-badges align-items-center justify-content-center"
							>
								<img
									[src]="skillBuilderIcon[challenge.type]"
									class="img-fluid mid-img-chart img-sm goal-img"
									alt="play-filled"
								/>
								<svg viewBox="0 0 36 36" [attr.class]="challenge.cssprop">
									<path
										class="circle-bg-chart"
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
									/>
									<path
										class="circle"
										[attr.stroke-dasharray]="challenge.strokes"
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
									/>
								</svg>
							</div>
							<div class="d-flex align-items-center justify-content-center">
								<div class="level-badge">
									<span
										>{{ pageLanguage?.level | slice: 0 : 1
										}}{{ challenge.level }}</span
									>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</ng-template>
