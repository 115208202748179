<!-- Skill builder header -->
<!-- TODO: Refactor SCSS -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
	<div class="container px-0">
		<div class="col-12 d-flex">
			<div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
				<span class="cursor-pointer txt-md">
					<i class="fas fa-arrow-left" (click)="back()"></i>
				</span>
				<span class="text-center-sm pl-2 txt-md">{{ title }}</span>
			</div>
		</div>
	</div>
</div>

<ng-container [ngSwitch]="data?.page">
	<!-- Skill builder start flow -->
	<app-member-skill-builder-abstract-example
		*ngSwitchCase="ABSTRACT_PAGE.EXAMPLE"
		[data]="data"
	></app-member-skill-builder-abstract-example>
	<app-member-skill-builder-abstract-idea
		*ngSwitchCase="ABSTRACT_PAGE.IDEA"
		[data]="data"
	></app-member-skill-builder-abstract-idea>
	<app-member-skill-builder-abstract-positive-reasons
		*ngSwitchCase="ABSTRACT_PAGE.POSITIVE_REASONS"
		[data]="data"
	></app-member-skill-builder-abstract-positive-reasons>
	<app-member-skill-builder-abstract-negative-reasons
		*ngSwitchCase="ABSTRACT_PAGE.NEGATIVE_REASONS"
		[data]="data"
	></app-member-skill-builder-abstract-negative-reasons>
	<app-member-skill-builder-abstract-positive-impact
		*ngSwitchCase="ABSTRACT_PAGE.POSITIVE_IMPACT"
		[data]="data"
	></app-member-skill-builder-abstract-positive-impact>
	<app-member-skill-builder-abstract-negative-impact
		*ngSwitchCase="ABSTRACT_PAGE.NEGATIVE_IMPACT"
		[data]="data"
	></app-member-skill-builder-abstract-negative-impact>
	<app-member-skill-builder-abstract-rating
		*ngSwitchCase="ABSTRACT_PAGE.RATING"
		[data]="data"
	></app-member-skill-builder-abstract-rating>
	<app-member-skill-builder-abstract-summary
		*ngSwitchCase="ABSTRACT_PAGE.SUMMARY"
		[data]="data"
	></app-member-skill-builder-abstract-summary>
	<app-member-skill-builder-abstract-comfort
		*ngSwitchCase="ABSTRACT_PAGE.COMFORT"
		[data]="data"
	></app-member-skill-builder-abstract-comfort>
</ng-container>
