<div class="d-flex justify-content-center plain-rounded-card px-4">
	<div class="card card-white col-md-12 col-lg-6 mt-4 mb-lg">
		<div class="card-body align-items-start p-0">
			<div class="col-12 px-0 unsubscribe">
				<nav
					class="main-header navbar navbar-expand navbar-white navbar-light flex-grow-1 header-inner position-relative"
				>
					<div class="container align-items-lg-center">
						<ul class="logoMain pt-1 pt-md-0">
							<li class="logoInner">
								<a>
									<img src="assets/img/logo.svg" alt="" />
								</a>
							</li>
						</ul>
					</div>
				</nav>
				<div class="col-12 text-center py-5">
					<h4>{{ pageLanguage?.title }}</h4>
					<p>{{ pageLanguage?.subtitle }}</p>
				</div>
				<div
					class="btn-c border-top d-flex justify-content-center align-items-center py-5"
				>
					<button
						type="button"
						class="btn cancel mr-2"
						(click)="declineRequest()"
					>
						{{ pageLanguage?.decline }}
					</button>
					<button class="btn btn-primary mb-0" (click)="acceptRequest()">
						{{ pageLanguage?.accept }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
