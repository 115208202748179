import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { UsersService } from '@services/leader';
import { GroupService, OrganizationService } from '@services/member';
import {
	LanguageService,
	MAIN_KEY_PREFIX,
	MAIN_KEYS,
	StorageService,
} from '@services/public';
import { UserService as UserResearcher } from '@services/researcher/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VALIDATIONS } from '../../../../../shared/constant/constants';

const PAGE_PATH = `pages.researcher.dashboard.userManagement.addEditUser`;

@Component({
	selector: 'app-add-edit-user-dialog',
	templateUrl: './add-edit-user-dialog.component.html',
	styleUrls: ['./add-edit-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit, OnDestroy {
	@Output() submitPopUp: EventEmitter<any> = new EventEmitter();
	form: UntypedFormGroup;
	rowData: any;
	dialogTitle: string;
	isAdduser: boolean;
	isBulkdata: boolean = false;
	isSubmitted: boolean = false;
	isFieldDisabled: boolean = false;
	validationMessage = VALIDATIONS;
	searchText = new UntypedFormControl('');
	saveButton: string = 'Send Invite';
	selectedDefaultVaule: any = 'Select Organization';
	groupData: any;
	organizations: any;
	selectOrganization: any;
	groupUsers: any = [];
	groupMember: any = [];
	groupList: any = [];
	allGroup: any;
	userView: any;
	csvfiledata: any;
	isFileType: boolean = false;
	logUser: any;
	isOrganization: boolean = false;
	loggedInUser: any;
	validateMessage: any;

	private _subscriptions: Subscription = new Subscription();

	// Page language.
	page: { [key: string]: string } = {};

	constructor(
		public bsModalRef: BsModalRef,
		public fb: UntypedFormBuilder,
		private orgSVC: OrganizationService,
		private grpSvc: GroupService,
		private userSvc: UsersService,
		private researcherSvc: UserResearcher,
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {
		this.form = fb.group({
			firstName: new UntypedFormControl(null, [
				Validators.required,
				Validators.pattern('[a-zA-Z@_-]*'),
				this.noWhitespaceValidator1,
			]),
			lastName: new UntypedFormControl(null, [
				Validators.required,
				Validators.pattern('[a-zA-Z@_-]*'),
				this.noWhitespaceValidator1,
			]),
			email: new UntypedFormControl(null, [
				Validators.required,
				Validators.email,
				Validators.maxLength(200),
				Validators.pattern(
					'^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
				),
				this.noWhitespaceValidator1,
			]),
			organizationUUID: new UntypedFormControl(null, [Validators.required]),
			groups: fb.array([]),
		});
	}

	downloadFile() {
		let link = document.createElement('a');
		link.download = 'sample.csv';
		link.href = 'assets/sample.csv';
		link.click();
	}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
		});
		const orgUUID = localStorage.getItem('organizationUUID');
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL)
					this.loggedInUser = this._storageSvc.userUUID;
			}),
		);
		this.loggedInUser = this._storageSvc.userUUID;
		this.orgSVC.organizations.subscribe((organization) => {
			this.organizations = organization.filter(
				(element) => element.addUser === true,
			);
		});

		this.grpSvc.groups.subscribe((groups) => {
			this.groupList = groups;
			this.groupList.map((group) => {
				if (group.name) {
					group.name = this.capitalizeFirstLetter(group.name);
				}
			});
		});
		// load groups
		this.grpSvc.loadGroups();

		if (this.rowData) {
			this.loadUser();
		} else {
			this.isAdduser = true;
			this.dialogTitle = this.page?.addUser;
			if (orgUUID) {
				this.form.controls['organizationUUID'].setValue(orgUUID);
				this.loadGroups(orgUUID);
			}
		}
	}

	noWhitespaceValidator1(control: UntypedFormControl): ValidationErrors | null {
		const isWhitespace = (control.value || '').trim().length === 0;
		return isWhitespace ? { whitespace: true } : null;
	}

	get f() {
		return this.form.controls;
	}

	get groups() {
		return this.form.controls['groups'] as UntypedFormArray;
	}

	addAction(action, group) {
		const add = this.form.get(action) as UntypedFormArray;
		add.push(
			this.fb.group({
				groupName: new UntypedFormControl(group.name),
				role: new UntypedFormControl('null'),
				uuid: new UntypedFormControl(group.uuid),
			}),
		);
	}

	onSelectOrganization() {
		const organizationUUID = this.form.value.organizationUUID;
		this.loadGroups(organizationUUID);
	}

	loadGroups(organizationUUID) {
		this.groupData = this.groupList.filter(
			(group: any) => group.organizationUUID === organizationUUID,
		);
		this.groupData.map((ele) => {
			ele.assignRole = '';
			this.addAction('groups', ele);
		});
	}

	bulkUploadUser() {
		if (this.isOrganization) {
			this.isSubmitted = false;
			let userObj = {
				organizationUUID: this.selectOrganization,
				users: this.csvfiledata,
			};
			this.userSvc.bulkUploadUsers(userObj).subscribe((res) => {
				this.bsModalRef.hide();
				let title = this.page.usersInvited;
				this.successDialogBox(title);
			});
		} else {
			this.isSubmitted = true;
		}
	}

	onChangeOrganization(event) {
		if (event !== this.page?.selectOrg) {
			this.selectOrganization = event;
			this.isOrganization = true;
			this.isSubmitted = false;
		}
	}

	onSubmit() {
		let title: any;
		if (this.rowData === null) {
			if (this.form.invalid) {
				return;
			}
			this.isSubmitted = true;
			this.form.value.groups = this.form.value.groups.filter(
				(group: any) => group.role !== 'null',
			);
			this.form.value.groups.forEach((e) => {
				delete e.groupName;
			});

			const userObj = this.form.value;
			this.userSvc.createUser(userObj).subscribe((res) => {
				this.submitPopUp.emit(true);
				this.bsModalRef.hide();
				this.form.reset();
				title = this.page?.inviteSent;
				this.successDialogBox(title);
			});
		} else {
			const groupAssign = this.assignRole(
				this.groupUsers,
				this.userView.groups,
			);
			let userUpdateObj = {
				uuid: this.userView.uuid,
				groupMembers: this.groupMember,
				groupUsers: groupAssign.user,
			};
			this.researcherSvc.updateUser(userUpdateObj).subscribe((res) => {
				this.submitPopUp.emit(true);
				this.bsModalRef.hide();
				this.form.reset();
				title = 'User details updated successfully';
				this.successDialogBox(title);
			});
		}
	}

	decline() {
		this.form.reset();
		this.bsModalRef.hide();
	}

	successDialogBox(title: any) {
		Swal.fire({
			title: title,
			icon: 'success',
			confirmButtonColor: '#456790',
			confirmButtonText: 'Ok',
		});
	}

	loadUser() {
		this.isFieldDisabled = true;
		this.isAdduser = false;
		this.saveButton = 'Save';
		this.dialogTitle = 'Edit User';
		this.researcherSvc
			.getUserById(this.rowData.row.userUUID)
			.subscribe((res) => {
				this.userView = res;
				this.form.patchValue(this.userView);
				this.form.controls['organizationUUID'].setValue(
					this.userView.organizations[0].name,
				);
				this.form.controls['organizationUUID'].disable();
				this.groupListBind(this.userView.groups);
			});
	}

	groupListBind(groups) {
		const add = this.form.get('groups') as UntypedFormArray;
		if (this.loggedInUser === this.userView.uuid) {
			this.groupData = this.uniqueDuplicateObject(groups);
		} else {
			this.groupData = this.groupList.filter(
				(group: any) =>
					group.organizationUUID === this.userView.organizations[0].uuid &&
					group.role !== null,
			);
			this.groupData.forEach((element) => {
				const groupList = groups.filter((user) => user.uuid === element.uuid);
				if (groupList.length >= 2) {
					groupList.forEach((group) => {
						if (group.role === 'member') {
							element.memberChecked = true;
						} else {
							element.assignRole = group.role;
						}
					});
				} else if (groupList.length === 1) {
					if (groupList[0].role === 'member') {
						element.assignRole = '';
						element.memberChecked = true;
					} else {
						element.assignRole = groupList[0].role;
						element.memberChecked = false;
					}
				} else {
					element.assignRole = '';
					element.memberChecked = false;
				}
			});
		}
		this.groupData.forEach((element) => {
			if (element.role === 'leader') {
				element.isLeader = true;
				element.isMember = false;
			} else if (element.role === 'admin') {
				element.isLeader = false;
				element.isMember = false;
			} else {
				element.isMember = true;
				element.isLeader = true;
			}

			add.push(
				this.fb.group({
					groupName: new UntypedFormControl(element.name),
					role: new UntypedFormControl(element.assignRole),
					uuid: new UntypedFormControl(element.uuid),
				}),
			);
		});
		const sortAssignUser = this.groupData.sort((a, b) =>
			a.memberChecked ? -1 : b.memberChecked ? 1 : 0,
		);
		this.groupData = sortAssignUser;
	}

	uniqueDuplicateObject(duplicateArray) {
		const combinedArray = duplicateArray.reduce((acc, obj) => {
			const existingObj = acc.find((item) => item.uuid === obj.uuid);
			if (existingObj) {
				if (existingObj.role === 'member') {
					existingObj.memberChecked = true;
					existingObj.assignRole = obj.role;
					existingObj.role = obj.role;
				} else {
					existingObj.memberChecked = true;
					existingObj.assignRole = existingObj.role;
				}
			} else {
				if (obj.role === 'member') {
					obj.memberChecked = true;
					obj.assignRole = '';
				} else {
					obj.memberChecked = false;
					obj.assignRole = obj.role;
				}
				acc.push(obj);
			}
			return acc;
		}, []);
		return combinedArray;
	}

	csvToArray(str, delimiter = ';') {
		const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
		const rows = str.slice(str.indexOf('\n') + 1).split('\n');
		const arr = rows.map(function (row) {
			const values = row.split(delimiter);
			if (values.length === headers.length) {
				const el = headers.reduce(function (object, header, index) {
					object[header] = values[index];
					return object;
				}, {});
				return el;
			} else {
				return true;
			}
		});
		return arr;
	}

	handleFileSelect(evt) {
		var files = evt.target.files;
		var file = files[0];
		this.csvfiledata = [];
		this.isFileType = false;
		if (file.type !== 'text/csv') {
			this.isFileType = true;
			this.validateMessage = this.validationMessage.uploadOtherFile.required;
			return;
		}
		if (file) {
			const fileSizeInKB = file.size / 1024;
			if (fileSizeInKB <= 10) {
				this.isBulkdata = true;
				var reader = new FileReader();
				const lowerCase = (str) => str[0].toLowerCase() + str.slice(1);
				reader.readAsText(file);
				reader.onload = (event: any) => {
					var csv = event.target.result;
					const lines = csv.split('\n');
					if (lines.length <= 1) {
						this.isFileType = true;
						this.validateMessage = this.validationMessage.fileEmpty.required;
						return;
					} else {
						const data = this.csvToArray(csv);
						const filedMissing = data.filter((user) => user !== true);
						if (!filedMissing) {
							this.isFileType = true;
							this.validateMessage =
								this.validationMessage.missingFiled.required;
						} else {
							let csvfiledata = data.map((obj) =>
								Object.fromEntries(
									Object.entries(obj).map(([key, value]) => [
										lowerCase(key),
										value,
									]),
								),
							);
							csvfiledata = csvfiledata.filter(
								(user) =>
									user.email !== undefined &&
									user.firstName !== '' &&
									user.lastName !== '',
							);
							csvfiledata.forEach((ele) => {
								if (!this.validateEmail(ele.email)) {
									this.isFileType = true;
									this.validateMessage =
										this.validationMessage.emailVerify.required;
								}
							});
							if (!this.isFileType) {
								const uniqueUser = [];
								const duplicatesUsers = csvfiledata.filter((user) => {
									if (uniqueUser.find((i) => i.email === user.email)) {
										return true;
									}
									uniqueUser.push(user);
									return false;
								});
								this.csvfiledata = uniqueUser;
							}
						}
					}
				};
			} else {
				this.isFileType = true;
				this.validateMessage = this.validationMessage.fileSize.required;
			}
		}
	}

	//check mail valid or not from csv file
	validateEmail(email) {
		const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,3}/;
		return emailPattern.test(email);
	}

	// Delete group
	deleteGroup(event) {
		const deleteGroupId = event.uuid;
		this.groupData = this.groupData.filter(
			(group) => group.uuid !== deleteGroupId,
		);
		const deletedGroup = this.userView.groups.find(
			(group) => group.uuid === deleteGroupId,
		);

		if (deletedGroup) {
			if (event.memberChecked) {
				this.groupMember = this.groupMember.filter(
					(member) => member.uuid !== deleteGroupId,
				);
				this.groupMember.push({ uuid: event.uuid, action: 'D' });
			}
			event.isDeleted = true;
			if (event.assignRole !== '') {
				const groupIndex = this.groupUsers.findIndex(
					(group) => group.uuid === deleteGroupId,
				);
				if (groupIndex !== -1) {
					this.groupUsers.splice(groupIndex, 1, event);
				} else {
					this.groupUsers.push(event);
				}
			}
		}
	}

	// delete user from csv data
	deleteUser(deleteUser) {
		this.csvfiledata = this.csvfiledata.filter(
			(item) => item.email !== deleteUser.email,
		);
	}

	onselectGroup(selectedGroup, role) {
		const user = this.groupUsers.find((ele) => ele.uuid === selectedGroup.uuid);
		if (user) {
			this.groupUsers = this.groupUsers.filter(
				(ele) => ele.uuid !== selectedGroup.uuid,
			);
			this.groupUsers.push({
				uuid: selectedGroup.uuid,
				assignRole: selectedGroup.assignRole,
				role: role,
				action: 'I',
			});
		} else {
			this.groupUsers.push({
				uuid: selectedGroup.uuid,
				assignRole: selectedGroup.assignRole,
				role: role,
				action: 'I',
			});
		}
	}

	assignRole(userList, existingUser) {
		let assignUser = [];
		userList.forEach((currentUser) => {
			const user = existingUser.find((user) => user.uuid === currentUser.uuid);
			if (user) {
				if (user.role !== currentUser.role) {
					if (currentUser.role === 'null') {
						assignUser.push({
							uuid: user.uuid,
							role: currentUser.assignRole,
							action: 'D',
						});
					} else {
						if (currentUser.isDeleted) {
							assignUser.push({
								uuid: user.uuid,
								role: currentUser.assignRole,
								action: 'D',
							});
						} else {
							if (currentUser.assignRole === '') {
								assignUser.push({
									uuid: user.uuid,
									role: currentUser.role,
									action: 'I',
								});
							} else {
								assignUser.push({
									uuid: user.uuid,
									role: currentUser.role,
									action: 'M',
								});
							}
						}
					}
				} else {
					if (user.role !== currentUser.assignRole) {
						assignUser.push({
							uuid: user.uuid,
							role: currentUser.assignRole,
							action: 'D',
						});
					}
				}
			} else {
				if (currentUser.role !== 'null') {
					assignUser.push({
						uuid: currentUser.uuid,
						role: currentUser.role,
						action: 'I',
					});
				}
			}
		});
		return { user: assignUser };
	}

	groupCheckUnchecked(event: any, group) {
		if (group.memberChecked) {
			if (event.target.checked === false) {
				this.groupMember.push({
					uuid: group.uuid,
					action: 'D',
				});
			} else {
				this.groupMember = this.groupMember.filter(
					(ele) => ele.uuid !== group.uuid,
				);
			}
		} else {
			if (event.target.checked == true) {
				this.groupMember.push({
					uuid: group.uuid,
					action: 'I',
				});
			} else {
				this.groupMember = this.groupMember.filter(
					(ele) => ele.uuid !== group.uuid,
				);
			}
		}
	}

	capitalizeFirstLetter(value) {
		return value
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
