import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FinalCardService {
	private _lastStepComplete: boolean = true;

	constructor() {}

	getFinalStatus(): boolean {
		return this._lastStepComplete;
	}

	setFinalStatus(value: boolean): void {
		this._lastStepComplete = value;
	}
}
