import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	Domain2Service,
	LanguageService,
	MAIN_KEYS,
	MAIN_KEY_PREFIX,
	StorageService,
	TEMP_KEYS,
} from '@services/public';
import { GOAL_PAGE, GoalData } from '../common';
import { ReplaySubject, Subscription } from 'rxjs';
import {
	SelectListCustom,
	SelectListNext,
	SelectListOption,
} from '@components';

const PAGE_PATH: string = 'skillBuilders.goal.pages.skill';

@Component({
	selector: 'app-member-skill-builder-goal-skill',
	templateUrl: './skill.component.html',
	styleUrls: ['./skill.component.scss'],
})
export class MemberSkillBuilderGoalSkillComponent implements OnInit, OnDestroy {
	@Input() data: GoalData;
	options: ReplaySubject<Array<SelectListOption>> = new ReplaySubject<
		Array<SelectListOption>
	>(1);
	defaultOptions: Array<SelectListOption> = [];
	customOptions: Array<SelectListOption> = [];
	custom: SelectListCustom = {
		add: this.addCustom.bind(this),
		delete: this.deleteCustom.bind(this),
	};
	next: SelectListNext = {
		onClick: this.nextOnClick.bind(this),
	};
	private _subscriptions: Subscription = new Subscription();

	// Page langauge.
	page: { [key: string]: string } = {
		title: '',
		subTitle: '',
	};

	constructor(
		private _domainSvc: Domain2Service,
		private _languageSvc: LanguageService,
		private _storageSvc: StorageService,
	) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page) {
				switch (key) {
					case 'title': {
						this._languageSvc
							.template(this.page[key], {
								domain: this._domainSvc.getDomain(this.data.domainUID).name,
							})
							.then((value) => (this.page[key] = value));
						break;
					}
					default: {
						this._languageSvc
							.template(this.page[key])
							.then((value) => (this.page[key] = value));
					}
				}
			}
		});

		// Get options list.
		this.defaultOptions = this._domainSvc
			.getSkills(this.data.domainUID)
			.map((skill) => {
				return { display: skill.name, value: skill.uid };
			});
		this._subscriptions.add(
			this._storageSvc.updates.subscribe((update) => {
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL) {
					this.customOptions = [];
					if (Array.isArray(update.value[MAIN_KEYS.GOAL_SKILLS])) {
						for (const option of update.value[MAIN_KEYS.GOAL_SKILLS]) {
							if (typeof option !== 'string') continue;
							this.customOptions.push({ display: option, custom: true });
						}
					}
					this._mergeAndSelectOptions();
				}
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR) {
					this.customOptions = [];
					this._mergeAndSelectOptions();
				}
				if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.GOAL_SKILLS) {
					this.customOptions = [];
					if (Array.isArray(update.value)) {
						for (const option of update.value) {
							if (typeof option !== 'string') continue;
							this.customOptions.push({ display: option, custom: true });
						}
					}
					this._mergeAndSelectOptions();
				}
			}),
		);
		let options: Array<string> = this._storageSvc.getMainStorage(
			MAIN_KEYS.GOAL_SKILLS,
		);
		if (Array.isArray(options)) {
			for (const option of options) {
				if (typeof option !== 'string') continue;
				this.customOptions.push({ display: option, custom: true });
			}
		}

		this._mergeAndSelectOptions();
	}

	private _mergeAndSelectOptions(): void {
		this.options.next(
			[...this.customOptions, ...this.defaultOptions].map(
				(option: SelectListOption) => {
					if (option.display === this.data.skill) option.selected = true;
					return option;
				},
			),
		);
	}

	addCustom(customOption: string): void {
		if (
			this.customOptions.findIndex(
				(option) => option.display === customOption,
			) !== -1
		)
			return;
		if (
			this.defaultOptions.findIndex(
				(option) => option.display === customOption,
			) !== -1
		)
			return;
		this.customOptions.push({ display: customOption, custom: true });
		this._storageSvc.setMainStorage(
			MAIN_KEYS.GOAL_SKILLS,
			this.customOptions.map((option) => option.display),
		);
	}

	deleteCustom(option: SelectListOption): void {
		this.customOptions = this.customOptions.filter(
			(customOption) => customOption.display !== option.display,
		);
		if (this.customOptions.length === 0)
			this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_SKILLS, null);
		else
			this._storageSvc.setMainStorage(
				MAIN_KEYS.GOAL_SKILLS,
				this.customOptions.map((option) => option.display),
			);
	}

	nextOnClick(option: SelectListOption): void {
		this._storageSvc.setTempStorage(TEMP_KEYS.GOAL, {
			...this.data,
			page: !option.value ? GOAL_PAGE.CUSTOM_ACTION : GOAL_PAGE.ACTION,
			skillUID: option.value ?? null,
			skill: option.display,
		});
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}
