<div class="walkthrough-card">
	<div
		class="walkthrough-card-content video {{
			slide.src == 'welcome' ? 'welcome' : ''
		}}"
	>
		<div class="walkthrough-card-header">
			<div class="walkthrough-card-header-title">
				<h1>{{ slide.title }}</h1>
			</div>
			<div class="walkthrough-card-subtitle">
				<p>{{ slide.subTitle }}</p>
			</div>
		</div>
		<div class="walkthrough-card-body">
			<app-video-viewer videoPlayer [videoSrc]="slide.src"></app-video-viewer>
		</div>
		<div class="walkthrough-card-footer">
			<button class="btn btn-primary walkthrough-button" (click)="onClose()">
				{{ slide.buttonLabel }}
			</button>
			<span class="walkthrough-card-footer-text">{{ slide.message }}</span>
		</div>
	</div>
</div>
