// Malay
import { LocaleData } from 'ngx-bootstrap/chronos';

export const msLocale: LocaleData = {
	months: [
		'Januari',
		'Februari',
		'Mac',
		'April',
		'Mei',
		'Jun',
		'Julai',
		'Ogos',
		'September',
		'Oktober',
		'November',
		'Disember',
	],
	monthsShort: [
		'Jan',
		'Feb',
		'Mac',
		'Apr',
		'Mei',
		'Jun',
		'Jul',
		'Ogos',
		'Sep',
		'Okt',
		'Nov',
		'Dis',
	],
	weekdays: ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu'],
	weekdaysShort: ['Aha', 'Isn', 'Sel', 'Rab', 'Kha', 'Jum', 'Sab'],
	weekdaysMin: ['Ah', 'Is', 'Se', 'Ra', 'Kh', 'Ju', 'Sa'],
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY LT',
		LLLL: 'dddd, D MMMM YYYY LT',
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
	},
	calendar: {
		sameDay: '[Hari ini] LT',
		nextDay: '[Esok] LT',
		nextWeek: 'dddd [akan datang] LT',
		lastDay: '[Semalam] LT',
		lastWeek: '[Minggu lalu] dddd [lepas] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'dalam %s',
		past: '%s yang lalu',
		s: 'beberapa saat',
		m: 'satu minit',
		mm: '%d minit',
		h: 'satu jam',
		hh: '%d jam',
		d: 'satu hari',
		dd: '%d hari',
		M: 'sebulan',
		MM: '%d bulan',
		y: 'setahun',
		yy: '%d tahun',
	},
	ordinal: (number: number) => `${number}`,
	week: {
		dow: 1,
		doy: 4,
	},
};
