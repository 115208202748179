import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListItem } from '@components';
import { AssessmentService, InventoryViewResponse } from '@services/member';
import { Domain2, Domain2Service, LanguageService } from '@services/public';
import { Filter } from '@util';
import { JDate } from 'jakapa-utilities';

interface Question {
	uid: number;
	display: string;
	type: 'list' | 'multilist' | 'star';
	answers: Array<Answer>;
	dates: Array<ListItem>;
}

interface Answer {
	value: number;
	display: string;
}

interface QuestionLanguage {
	[key: string]: {
		display: string;
		type: 'list' | 'multilist' | 'star';
		answers: {
			[key: string]: {
				display: string;
			};
		};
	};
}

const PAGE_PATH: string = 'pages.member.inventory.report.page';

@Component({
	selector: 'app-member-inventory-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class MemberInventoryReportComponent implements OnInit {
	domainFilter: Filter<Domain2>;
	questions: Array<Question> = [];
	display: boolean = true;
	private _data: InventoryViewResponse;

	// Page langauge.
	page: { [key: string]: string } = {
		noData: '',
		takeInventory: '',
	};

	constructor(
		private _assessmentSvc: AssessmentService,
		private _domainSvc: Domain2Service,
		private _languageSvc: LanguageService,
		private _router: Router,
	) {
		const options = this._domainSvc.getDomains();
		this.domainFilter = {
			key: 'domains',
			value: options[0],
			options,
		};
	}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] === 'object' && value[PAGE_PATH] !== null)
				this.page = value[PAGE_PATH];
		});

		// Get inventory data.
		this._assessmentSvc
			.viewInventory()
			.subscribe((res: InventoryViewResponse) => {
				this._data = res;
				this.domainOnChange();
			});
	}

	domainOnChange(): void {
		const domainData = this._data.domains.find(
			(domain) => domain.uid === this.domainFilter.value.uid,
		);
		if (!domainData) {
			this.display = false;
			return;
		}
		this.display = true;
		const path: string = `domains2.${
			this.domainFilter.value.uid
		}.inventory.questions`;
		this._languageSvc
			.get([path])
			.then((value: { [key: string]: QuestionLanguage }) => {
				if (typeof value[path] === 'object' && value[path] !== null)
					this.questions = Object.entries(value[path])
						.map((value) => {
							return {
								uid: +value[0],
								display: value[1].display,
								type: value[1].type,
								answers: Object.entries(value[1].answers)
									.map((value) => {
										return {
											value: +value[0],
											display: value[1].display,
										};
									})
									.sort((a, b) => a.value - b.value),
								dates: (
									domainData.questions.find(
										(question) => question.uid === +value[0],
									)?.answers ?? []
								)
									.sort((a, b) => {
										if (a.date < b.date) return 1;
										if (b.date < a.date) return -1;
										return 0;
									})
									.map((answer) => {
										return {
											left: new JDate(answer.date).toEnString(),
											right:
												value[1].type === 'star'
													? answer.score + '/5'
													: value[1].answers[answer.score]?.display,
										};
									}),
							};
						})
						.sort((a, b) => a.uid - b.uid);
			});
	}

	goToActivities(): void {
		this._router.navigate(['member/dashboard/activities']);
	}
}
