import { Component, OnInit, Input } from '@angular/core';
import { CardService } from '@services/member';
import { LanguageService } from '@services/public';

const PAGE_PATH: string = 'pages.member.walkthrough.page';

interface slide {
	type: string;
	title?: string;
	subTitle?: string;
	message?: string;
	buttonLabel?: string;
	checklistItems?: Array<checklistItem>;
	itemsCompleted?: number;
	daysTillStart?: number;
	buttonType?: string;
	isLast?: boolean;
}
interface checklistItem {
	text: string;
	completed: boolean;
	subItems?: Array<{ text: string; complete: boolean }>;
}

@Component({
	selector: 'app-checklist-card',
	templateUrl: './checklist-card.component.html',
	styleUrls: ['./checklist-card.component.scss'],
})
export class ChecklistCardComponent implements OnInit {
	@Input() slide: slide;
	absoluteValueOfDays: number = 0; //Absolute value of days till start
	subItems: Array<string> = []; //Sub items for checklist items
	page: { [key: string]: string } = {
		buttonLabelContinue: '',
		buttonLabelNext: '',
		listAvatar: '',
		listCelebrate: '',
		listCompleteTitle: '',
		listCompleteSubTitle: '',
		listCompleteButtonLabel: '',
		listCompleteMessage: '',
		listExternal: '',
		listTitle: '',
		listSkills: '',
		listSubTitleCelebrate: '',
		listSubTitleLearn: '',
		listSubTitleSkills: '',
		sublistCooperation: '',
		sublistEmotional: '',
		sublistInnovation: '',
		sublistManage: '',
		sublistBrain: '',
		sublistGoal: '',
		sublistJournal: '',
		sublistResilience: '',
	};

	constructor(
		private _cardService: CardService,
		private _languageSvc: LanguageService,
	) {}

	ngOnInit(): void {
		this._languageSvc.get([PAGE_PATH]).then((value) => {
			if (typeof value[PAGE_PATH] !== 'object' || value[PAGE_PATH] === null)
				return;
			this.page = value[PAGE_PATH];
			for (const key in this.page)
				this._languageSvc
					.template(this.page[key])
					.then((value) => (this.page[key] = value));
			this._cardService.setChecklistCardLanguage(this.page);
		});

		// Calculate how many day until Monday
		const today = new Date().getDay();
		this.slide.daysTillStart = today === 0 ? 1 : 8 - today;
		this.absoluteValueOfDays = Math.abs(this.slide.daysTillStart - 7);
	}

	onClose() {
		this._cardService.emitCloseEvent(true);
	}
	onNext() {
		this._cardService.emitNextCardEvent(true);
	}
}
