import { Component, Input, OnInit } from '@angular/core';
import { SkillBuilder } from '@services/member';
import { LABELS, LanguageService } from '@services/public';

export interface SkillBuilderBadge extends SkillBuilder {
	class: string;
	progress: any;
	level: number;
}

@Component({
	selector: 'app-member-dashboard-rewards-skill-builder-badges',
	templateUrl: './skill-builder-badges.component.html',
	styleUrls: ['./skill-builder-badges.component.scss'],
})
export class MemberDashboardRewardsSkillBuilderBadgesComponent
	implements OnInit
{
	@Input() title: string = '';
	@Input() skillBuilderBadges: Array<SkillBuilderBadge> = [];

	// Page langauge.
	labels: { [key: string]: string } = {
		[LABELS.LEVEL]: '',
	};

	constructor(private _languageSvc: LanguageService) {}

	ngOnInit(): void {
		// Get page language.
		this._languageSvc.getLabels(this.labels);
	}
}
