import { Component, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { GroupService } from '@services/member';
import { LanguageService } from '@services/public';
import { UserService } from '@services/viewer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-group-tag-popup',
	templateUrl: './group-tag-popup.component.html',
})
export class GroupTagPopupComponent implements OnInit {
	formGroup: UntypedFormGroup;
	successMessage: string;
	searchText: any;
	masterSelected: boolean;
	checkedList: any = [];
	modalRef?: BsModalRef;
	message?: string;
	dialogTitle: string;
	records: any;
	placeholder: string;
	memberRecords: any;
	//TODO members
	groupsData = [];

	//Page Language
	pageLanguage: any;
	labels: any;
	path: string = `pages.member.groupTagPopUp`;

	constructor(
		private fb: UntypedFormBuilder,
		public bsModalRef: BsModalRef,
		public viewerSvc: UserService,
		public grpSvc: GroupService,
		private _languageSvc: LanguageService,
	) {
		this.masterSelected = false;
	}
	ngOnInit() {
		this._languageSvc.get([this.path]).then((value) => {
			this.pageLanguage = value[this.path];
		});
		this._languageSvc.get([`labels`]).then((value) => {
			this.labels = value[`labels`];
		});

		this.dialogTitle = this.pageLanguage.joinGroup;
		this.placeholder = this.pageLanguage.groupPlaceholder;
		this.grpSvc.groups.subscribe((groups) => {
			this.groupsData = groups;
			this.records = this.groupsData.filter(
				(element) => element.pending === false && element.member === false,
			);
			this.records.map((group) => {
				if (group.name) {
					group.name = this.capitalizeFirstLetter(group.name);
				}
			});
		});
		this.formGroup = this.fb.group({
			recordList: [null, Validators.requiredTrue],
		});

		this.memberRecords = this.records;
	}

	capitalizeFirstLetter(value) {
		return value
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}
	searchKey(data: string) {
		this.searchText = data;
		this.search();
	}

	search() {
		this.records =
			this.searchText === ''
				? this.memberRecords
				: this.memberRecords.filter(
						(val) =>
							val.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >=
							0,
					);
	}

	decline() {
		this.bsModalRef.hide();
	}

	isAllSelected() {
		this.masterSelected = this.records.every(function (item: any) {
			return item.checked == true;
		});
		this.getCheckedItemList();
	}

	getCheckedItemList() {
		this.checkedList = [];
		for (var i = 0; i < this.records.length; i++) {
			if (this.records[i].checked) this.checkedList.push(this.records[i].uuid);
		}
	}

	onSendRequest() {
		let requredObj: any;
		requredObj = {
			groupUUIDs: this.checkedList,
		};
		this.grpSvc.sendGroupRequest(requredObj).subscribe((res) => {
			this.bsModalRef.hide();
			this.successDialogBox(this.pageLanguage.requestSentSuccess);
			this.grpSvc.loadGroups();
		});
	}

	successDialogBox(title: any) {
		Swal.fire({
			title: title,
			icon: 'success',
			confirmButtonColor: '#456790',
			confirmButtonText: 'Ok',
		});
	}
}
