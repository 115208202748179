<div class="walkthrough-card">
	<div
		*ngIf="slide.type == 'completion'"
		class="walkthrough-card-content completion"
	>
		<div class="walkthrough-card-header">
			<div class="walkthrough-card-header-title">
				<h1>{{ slide.title }}</h1>
			</div>
			<div class="walkthrough-card-subtitle">
				<p>{{ slide.subTitle }}</p>
			</div>
		</div>
		<div class="walkthrough-card-body">
			<div class="completion-message">
				<h2>{{ slide.message }}</h2>
			</div>
		</div>
		<div class="walkthrough-card-footer">
			<button
				class="btn btn-primary walkthrough-button"
				(click)="slide.buttonType == 'next' ? onNext() : onClose()"
			>
				{{ slide.buttonLabel }}
			</button>
		</div>
	</div>

	<div
		*ngIf="slide.type == 'userTurn'"
		class="walkthrough-card-content userTurn"
	>
		<div class="walkthrough-card-header">
			<div class="walkthrough-card-header-title">
				<h1>{{ slide.title }}</h1>
			</div>
			<div class="walkthrough-card-subtitle">
				<p>{{ slide.subTitle }}</p>
			</div>
		</div>
		<div class="walkthrough-card-body"></div>
		<div class="walkthrough-card-footer">
			<div class="completion-message">
				<h2>{{ slide.message }}</h2>
			</div>
			<button class="btn btn-primary walkthrough-button" (click)="onClose()">
				{{ slide.buttonLabel }}
			</button>
		</div>
	</div>
	<div
		*ngIf="slide.type == 'information'"
		class="walkthrough-card-content information"
	>
		<div class="walkthrough-card-header">
			<div class="walkthrough-card-header-title">
				<h1>{{ slide.title }}</h1>
			</div>
		</div>
		<div class="walkthrough-card-body">
			<p>{{ slide.message }}</p>
		</div>
		<button class="btn btn-primary walkthrough-button" (click)="onClose()">
			Close
		</button>
	</div>
</div>
